import {ApiErrorResponse, ApiErrors} from "../entities/base"

/**
 * Состояние загрузки списка сущностей.
 *
 * @usage Статический вызов LoadState.Pending() - возвращает состояние LoadStatePending
 */
export abstract class LoadState {
    private static _none: LoadState
    private static _pending: LoadState
    private static _completed: LoadState

    public static Completed() {
        return this._completed || (this._completed = new LoadStateCompleted())
    }

    public static None() {
        return this._none || (this._none = new LoadStateNone())
    }

    public static Pending() {
        return this._pending || (this._pending = new LoadStatePending())
    }

    public static Error(error: ApiErrorResponse) {
        return new LoadStateError(error)
    }

    public isNone() {
        return this === LoadState._none
    }
    public isPending() {
        return this === LoadState._pending
    }
    public isCompleted() {
        return this === LoadState._completed
    }
    public isError() {
        return this instanceof LoadStateError
    }
    public getErrors(): ApiErrors {
        throw new Error("You can not get error message from non-error state!")
    }
}
export default LoadState
// состояния в порядке их установки
class LoadStateNone extends LoadState {}
export class LoadStatePending extends LoadState {}
class LoadStateCompleted extends LoadState {}
class LoadStateError extends LoadState {
    protected error: ApiErrorResponse

    constructor(error: ApiErrorResponse) {
        super()
        this.error = error;
    }

    public getErrors(): ApiErrors {
        return this.error.meta.errors
    }
}
