/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface EntityDescription extends H.BaseValue {
    value?: string;
    contentType: "EntityDescription"; // Object type
}

export module EntityDescription {
    export const contentType: "EntityDescription" = "EntityDescription";
    export const endpoint = "/api/v3/entityDescription";

    export const newObject: EntityDescription = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const value = {contentType: "StringField" as "StringField", name: "value", isMultiple: false, isSortable: false, id: "EntityDescription.value", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isEntityDescription(arg: any): arg is EntityDescription {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "EntityDescription"
}
