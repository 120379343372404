/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface Megamail extends H.BaseEntity {
    email?: string; // E-mail адрес
    isPersonal?: boolean; // Персональный
    id?: string; // Идентификатор
    contentType: "Megamail"; // Object type
}

export module Megamail {
    export const contentType: "Megamail" = "Megamail";
    export const endpoint = "/api/v3/megamail";

    export const newObject: Megamail = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const email = {contentType: "StringField" as "StringField", name: "email", isMultiple: false, isSortable: false, id: "Megamail.email", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isPersonal = {contentType: "BoolField" as "BoolField", name: "isPersonal", isMultiple: false, isSortable: false, id: "Megamail.isPersonal", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isMegamail(arg: any): arg is Megamail {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Megamail"
}
