import firebase from "firebase/app"
import "firebase/analytics"
import "firebase/performance"
import {Transport} from "src/bums/common/stores/Tracker"
import {PurchaseEvent} from "src/bums/common/stores/Tracker/Transport"
import {AccountInfoStore} from "src/lib/entities/store/AccountInfoStore"
import {UserStore} from "src/lib/entities/store/UserStore"


export class FirebaseTrackerTransport implements Transport {

    private analytics: firebase.analytics.Analytics

    constructor(
        private userStore: UserStore,
        private accountInfoStore: AccountInfoStore,
    ) {
        void this.init()
    }

    trackScreen = (url: string) => {
        this.analytics.setCurrentScreen(url)
    }

    trackEvent = async (key: string, value?: string, param?: string) => {
        this.analytics.logEvent(key + "_" + value, param ? this.parseParams(param) : void 0)
    }

    trackTiming(type: string, name: string, time: number) {
        // noop
    }

    trackPurchase = (event: PurchaseEvent) => {
        // noop
    }

    private init = async () => {

        this.analytics = firebase.analytics()

        const user = this.userStore.userOrNull
        const uid = window.location.origin + "_" + user.uid

        this.analytics.setUserId(uid)
        this.analytics.setUserProperties({
            account: window.location.origin,
            accountId: this.accountInfoStore.id,
            position: user.position,
            isAdmin: this.userStore.isAdmin ? "1" : "0",
            birthday: AccountInfoStore.getDateString(user.birthday),
            gender: `${user.gender}`,
            age: `${user.age}`,
            ...this.accountInfoStore.licenseInfo,
        })

        firebase.performance()
    }

    private parseParams = (params: string) => {
        try {
            return JSON.parse(params)
        } catch (e) {
            return params
        }
    }
}
