export enum BaseSize {
    SMALL = 1,
    MEDIUM = 2,
}

export enum BaseWidth {
    Width1 = 1,
    Width2 = 2,
    Width3 = 3,
    Width4 = 4,
    Width5 = 5,
    Width6 = 6,
    Width7 = 7,
    Width8 = 8,
    Width9 = 9,
    Width10 = 10,
    Width11 = 11,
    Width12 = 12
}

export enum BaseOffset {
    Offset1 = 1,
    Offset2 = 2,
    Offset3 = 3,
    Offset4 = 4,
    Offset5 = 5,
    Offset6 = 6,
    Offset7 = 7,
    Offset8 = 8,
    Offset9 = 9,
    Offset10 = 10,
    Offset11 = 11,
    Offset12 = 12
}

export enum BaseRight {
    Right0 = 1,
    Right1 = 2,
}

