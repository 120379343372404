import {autobind} from "core-decorators"
import {computed} from "mobx"
import * as Api from "src/lib/entities/api"
import {AbstractMultiSubscription} from "src/lib/services/utils"

export interface EntityEventInterface {
    entity: Api.BaseEntity
    fields?: string[]
}

@autobind
export class RealTimeSubscription extends AbstractMultiSubscription<EntityEventInterface> {

    @computed
    protected get eventHandleMap() {
        return new Map([
            [`onEntityUpdated`, this.entityUpdateListener],
            [`onEntityDropped`, this.entityDroppedListener],
        ])
    }

    protected entityUpdateListener(data: EntityEventInterface) {
        this.$eventEmitter.emit("update", data)
    }

    protected async entityDroppedListener(data: EntityEventInterface) {
        this.$eventEmitter.emit("drop", data)
    }
}
