import {PlainRoute} from "react-router"

export const discussRoutes: PlainRoute[] = [
    {
        path: "/discuss(/:groupId)(/:id)",
        async getComponent() {
            return (await import("./list/page/CDiscussListPage")).CDiscussListPage
        },
    },
    {
        path: "/topics/:id/card*",
        onEnter({params}, replace) {
            replace({pathname: `/discuss/chat/${params.id}/`})
        },
        async getComponent() {
            return (await import("./list/page/CDiscussListPage")).CDiscussListPage
        },
    },
    {
        path: "/topics*",
        onEnter(_, replace) {
            replace({pathname: "/discuss/chat/"})
        },
        async getComponent() {
            return (await import("./list/page/CDiscussListPage")).CDiscussListPage
        },
    },
    {
        path: "/BumsDiscuss/Message/card(/:id).html",
        onEnter({params}, replace) {
            replace({pathname: `/discuss/mail/${params.id}/`})
        },
        async getComponent() {
            return (await import("./list/page/CDiscussListPage")).CDiscussListPage
        },
    }
];
