import * as ReduxActions from "redux-actions"
import {ReportConfigImmutable} from "../types"

export const CHANGE_CONFIG = "bums/common/report/edit/action/CHANGE_CONFIG"

export type ChangeConfig = ReduxActions.Action<{
    config: ReportConfigImmutable
}>

export function changeConfig(config: ReportConfigImmutable): ChangeConfig {
    return {
        type: CHANGE_CONFIG,
        payload: {
            config
        }
    }
}
