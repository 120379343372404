/* tslint:disable */
import * as H from "../metadataHelper"
import {AudioFileMetadata} from "./audioFileMetadata"
import {ImageFileMetadata} from "./imageFileMetadata"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"
import {DateTimeField} from "./dateTimeField"
import {FloatField} from "./floatField"
import {EnumField} from "./enumField"

export interface File extends H.BaseEntity {
    name?: string; // Название файла вместе с расширением
    extension?: string; // Расширение
    size?: number; // Размер файла в байтах
    path?: string; // Путь к файлу для скачивания и/или отображения
    error?: string; // Текст ошибки
    mimeType?: string; // MIME-тип
    timeCreated?: Date; // Время создания
    aspectRatio?: number; // Соотношение сторон
    thumbnail?: string; // Миниатюра
    possibleActions?: H.List<File.PossibleActions>; // Список возможных действий над файлом
    metadata?: AudioFileMetadata|ImageFileMetadata;
    id?: string; // Идентификатор
    contentType: "File"; // Object type
}

export module File {
    export const contentType: "File" = "File";
    export const endpoint = "/api/v3/file";
    export type PossibleActions = "act_share";
    export module PossibleActions {
        export const act_share: PossibleActions = "act_share";
    }
    export const newObject: File = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "File.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const extension = {contentType: "StringField" as "StringField", name: "extension", isMultiple: false, isSortable: false, id: "File.extension", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const size = {contentType: "IntegerField" as "IntegerField", name: "size", isMultiple: false, isSortable: false, id: "File.size", defaultValue: null as IntegerField["defaultValue"]};
        export const path = {contentType: "StringField" as "StringField", name: "path", isMultiple: false, isSortable: false, id: "File.path", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const error = {contentType: "StringField" as "StringField", name: "error", isMultiple: false, isSortable: false, id: "File.error", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const mimeType = {contentType: "StringField" as "StringField", name: "mimeType", isMultiple: false, isSortable: false, id: "File.mimeType", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: false, id: "File.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const aspectRatio = {contentType: "FloatField" as "FloatField", name: "aspectRatio", isMultiple: false, isSortable: false, id: "File.aspectRatio", defaultValue: null as FloatField["defaultValue"]};
        export const thumbnail = {contentType: "StringField" as "StringField", name: "thumbnail", isMultiple: false, isSortable: false, id: "File.thumbnail", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "File.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["act_share"])};
        export const metadata = {contentType: "RefLinkField" as "RefLinkField", name: "metadata", isMultiple: false, isSortable: false, id: "File.metadata", refContentType: H.List(["AudioFileMetadata", "ImageFileMetadata"])};
    }
}

export function isFile(arg: any): arg is File {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "File"
}
