/* tslint:disable */
import * as H from "../metadataHelper"
import {Invoice} from "./invoice"
import {Employee} from "./employee"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Money} from "./money"
import {FinRealAccount} from "./finRealAccount"
import {FinOperationType} from "./finOperationType"
import {File} from "./file"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"
import {EnumField} from "./enumField"
import {DateTimeField} from "./dateTimeField"
import {MoneyField} from "./moneyField"
import {BoolField} from "./boolField"

export interface FinOperation extends H.BaseEntity {
    number?: string; // Номер этой операции
    invoices?: H.List<Invoice>; // Сделки
    invoicesCount?: number; // Количество сделок
    kind?: FinOperation.Kind; // Вид
    status?: FinOperation.Status; // Статус
    description?: string; // Описание
    date?: Date; // Дата
    timeUpdated?: Date; // Дата обновления
    creator?: Employee; // Создатель
    contractor?: ContractorCompany|ContractorHuman; // Клиент
    incomeSum?: Money; // Сумма дохода
    expenseSum?: Money; // Сумма расхода
    sum?: Money; // Сумма
    realAccount?: FinRealAccount; // Расчётный счёт операции.
    type?: FinOperationType; // Тип операции.
    controlDate?: Date; // Контрольная дата
    possibleActions?: H.List<FinOperation.PossibleActions>; // Возможные действия
    attaches?: H.List<File>; // Вложения
    attachesCount?: number; // Количество вложений
    pairedOperation?: FinOperation; // Парные операции
    id?: string; // Идентификатор
    isFavorite?: boolean; // Избранная сущность или нет
    contentType: "FinOperation"; // Object type
}

export module FinOperation {
    export const contentType: "FinOperation" = "FinOperation";
    export const endpoint = "/api/v3/finOperation";
    export type Kind = "expense"|"income"|"transfer"|"virtual";
    export module Kind {
        export const expense: Kind = "expense";
        export const income: Kind = "income";
        export const transfer: Kind = "transfer";
        export const virtual: Kind = "virtual";
    }
    export type Status = "closed"|"opened";
    export module Status {
        export const closed: Status = "closed";
        export const opened: Status = "opened";
    }
    export type PossibleActions = "act_read";
    export module PossibleActions {
        export const act_read: PossibleActions = "act_read";
    }
    export const newObject: FinOperation = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const number = {contentType: "StringField" as "StringField", name: "number", isMultiple: false, isSortable: true, id: "FinOperation.number", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const invoices = {contentType: "RefLinkField" as "RefLinkField", name: "invoices", isMultiple: true, isSortable: false, id: "FinOperation.invoices", refContentType: H.List(["Invoice"])};
        export const invoicesCount = {contentType: "IntegerField" as "IntegerField", name: "invoicesCount", isMultiple: false, isSortable: false, id: "FinOperation.invoicesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const kind = {contentType: "EnumField" as "EnumField", name: "kind", isMultiple: false, isSortable: false, id: "FinOperation.kind", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["expense", "income", "transfer", "virtual"])};
        export const status = {contentType: "EnumField" as "EnumField", name: "status", isMultiple: false, isSortable: false, id: "FinOperation.status", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["closed", "opened"])};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: false, id: "FinOperation.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const date = {contentType: "DateTimeField" as "DateTimeField", name: "date", isMultiple: false, isSortable: true, id: "FinOperation.date", defaultValue: null as DateTimeField["defaultValue"]};
        export const timeUpdated = {contentType: "DateTimeField" as "DateTimeField", name: "timeUpdated", isMultiple: false, isSortable: true, id: "FinOperation.timeUpdated", defaultValue: null as DateTimeField["defaultValue"]};
        export const creator = {contentType: "RefLinkField" as "RefLinkField", name: "creator", isMultiple: false, isSortable: true, id: "FinOperation.creator", refContentType: H.List(["Employee"])};
        export const contractor = {contentType: "RefLinkField" as "RefLinkField", name: "contractor", isMultiple: false, isSortable: true, id: "FinOperation.contractor", refContentType: H.List(["ContractorCompany", "ContractorHuman"])};
        export const incomeSum = {contentType: "MoneyField" as "MoneyField", name: "incomeSum", isMultiple: false, isSortable: true, id: "FinOperation.incomeSum", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const expenseSum = {contentType: "MoneyField" as "MoneyField", name: "expenseSum", isMultiple: false, isSortable: true, id: "FinOperation.expenseSum", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const sum = {contentType: "MoneyField" as "MoneyField", name: "sum", isMultiple: false, isSortable: false, id: "FinOperation.sum", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const realAccount = {contentType: "RefLinkField" as "RefLinkField", name: "realAccount", isMultiple: false, isSortable: true, id: "FinOperation.realAccount", refContentType: H.List(["FinRealAccount"])};
        export const type = {contentType: "RefLinkField" as "RefLinkField", name: "type", isMultiple: false, isSortable: true, id: "FinOperation.type", refContentType: H.List(["FinOperationType"])};
        export const controlDate = {contentType: "DateTimeField" as "DateTimeField", name: "controlDate", isMultiple: false, isSortable: true, id: "FinOperation.controlDate", defaultValue: null as DateTimeField["defaultValue"]};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "FinOperation.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["act_read"])};
        export const attaches = {contentType: "RefLinkField" as "RefLinkField", name: "attaches", isMultiple: true, isSortable: false, id: "FinOperation.attaches", refContentType: H.List(["File"])};
        export const attachesCount = {contentType: "IntegerField" as "IntegerField", name: "attachesCount", isMultiple: false, isSortable: false, id: "FinOperation.attachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const pairedOperation = {contentType: "RefLinkField" as "RefLinkField", name: "pairedOperation", isMultiple: false, isSortable: false, id: "FinOperation.pairedOperation", refContentType: H.List(["FinOperation"])};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: false, id: "FinOperation.isFavorite", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isFinOperation(arg: any): arg is FinOperation {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "FinOperation"
}
