import {BaseEntity} from "src/lib/entities/types";
import {EntitiesStorageInterface} from "src/lib/entities/store/EntitiesStorage/EntitiesStorageInterface";

export class EntitiesNullStorage implements EntitiesStorageInterface {
    async save(entities: BaseEntity[]) {
        return [] as BaseEntity[]
    }

    async load(entityLinks: BaseEntity[]): Promise<BaseEntity[]> {
        return []
    }

    async remove(entityLink: BaseEntity) {
        // ничего не делаем
    }

    async clear() {
        // ничего не делаем
    }
}
