import {Action} from "redux-actions"

export const CLOSE_REPORT_PROMO = "bums/common/report/list/action/CLOSE_REPORT_PROMO"

export type CloseReportPromo = Action<void>

export function closeReportPromo(): CloseReportPromo {
    return {
        type: CLOSE_REPORT_PROMO,
    }
}
