import * as ReduxActions from "redux-actions"

export const SWITCH_SORT_COLUMN = "bums/common/report/card/action/SWITCH_SORT_COLUMN"

export type SwitchSortColumn = ReduxActions.Action<{
    sortColumn: string
}>

export function switchSortColumn(sortColumn: string): SwitchSortColumn {
    return {
        type: SWITCH_SORT_COLUMN,
        payload: {
            sortColumn
        }
    }
}
