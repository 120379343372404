import {handleActions} from "redux-actions"
import * as Actions from "./actions/index"
import * as Api from "src/lib/entities/api"
import * as Collections from "src/lib/collections"
import {TableSortOrderBy, ReportData} from "../entities"
import {ReportImmutable} from "./types"
import {ReportObjectImmutable} from "./types"

export interface State {
    report: ReportImmutable
    objectList: Collections.List<ReportObjectImmutable>
    reportObject: ReportObjectImmutable
    showPermission: boolean
    showFilter: boolean
    selectedSortColumn: Api.ReportConfigColumn
    sortingDirection: number
    btSavePending: boolean
    reportData: ReportData
    isSaved: boolean
    loadObject: boolean
    reportPreviewLoading: boolean
    newReportId: string
}

const defaultState: State = {
    report: null,
    objectList: Collections.List([]),
    reportObject: null,
    showFilter: false,
    selectedSortColumn: null,
    sortingDirection: TableSortOrderBy.ASC,
    showPermission: false,
    btSavePending: false,
    reportData: null,
    isSaved: false,
    loadObject: true,
    reportPreviewLoading: false,
    newReportId: null
}

function setColumns(state: State, columns: Collections.List<Api.ReportConfigColumn>) {
    const report = Object.assign({}, state.report, {
        config: Object.assign({}, state.report.config, {columns})
    })
    return Object.assign({}, state, {report})
}

function setGrouping(state: State, grouping: Collections.List<Api.ReportConfigGrouping>) {
    const report = Object.assign({}, state.report, {
        config: Object.assign({}, state.report.config, {grouping})
    })
    return Object.assign({}, state, {report})
}

function setReaders(state: State, readers: Collections.List<Api.Employee | Api.Group>) {
    const report = Object.assign({}, state.report, {readers})
    return Object.assign({}, state, {report})
}

function setEditors(state: State, editors: Collections.List<Api.Employee | Api.Group>) {
    const report = Object.assign({}, state.report, {editors})
    return Object.assign({}, state, {report})
}

function setSortColumn(state: State, column: string, sortingDirection: number) {
    const sortColumn = `${sortingDirection === TableSortOrderBy.ASC ? "" : "-"}${column}`
    const report = Object.assign({}, state.report, {
        config: Object.assign({}, state.report.config, {sortColumn})
    })
    return Object.assign({}, state, {report})
}

export const reducer = handleActions({
        [Actions.FETCH_REPORT_TEMPLATE_SUCCESS](state: State, action: Actions.FetchReportTemplateSuccess): State {
            const {report} = action.payload
            return Object.assign({}, state, {report})
        },
        [Actions.FETCH_REPORT_OBJECT_START](state: State, action: Actions.FetchReportObjectStart): State {
            return Object.assign({}, state, {loadObject: true})
        },
        [Actions.FETCH_REPORT_OBJECTS_SUCCESS](state: State, action: Actions.FetchReportObjectsSuccess): State {
            let {objectList} = action.payload
            objectList = objectList.sort((a: ReportObjectImmutable, b: ReportObjectImmutable) => {
                return a.title ? a.title.localeCompare(b.title) : 0
            })
            return Object.assign({}, state, {objectList, loadObject: false})
        },
        [Actions.CHANGE_REPORT_NAME](state: State, action: Actions.ChangeReportName): State {
            const {name} = action.payload
            const report = Object.assign({}, state.report, {name})
            return Object.assign({}, state, {report})
        },
        [Actions.CHANGE_REPORT_DESCRIPTION](state: State, action: Actions.ChangeReportDescription): State {
            const {description} = action.payload
            const report = Object.assign({}, state.report, {description})
            return Object.assign({}, state, {report})
        },
        [Actions.CHANGE_REPORT_OBJECT](state: State, action: Actions.ChangeReportObject): State {
            const {selectedObject} = action.payload
            const report = Object.assign({}, state.report, {
                config: Object.assign({}, state.report.config, {entityType: selectedObject})
            })
            return Object.assign({}, state, {report})
        },
        [Actions.FETCH_REPORT_OBJECT_START](state: State, action: Actions.FetchReportObjectStart): State {
            return Object.assign({}, state, {loadObject: true})
        },
        [Actions.FETCH_REPORT_OBJECT_SUCCESS](state: State, action: Actions.FetchReportObjectSuccess): State {
            const {reportObject} = action.payload
            return Object.assign({}, state, {reportObject, loadObject: false})
        },
        [Actions.CHANGE_CONFIG](state: State, action: Actions.ChangeConfig): State {
            const {config} = action.payload
            const report = Object.assign({}, state.report, {config})
            return Object.assign({}, state, {report})
        },
        [Actions.CHANGE_PROGRAM](state: State, action: Actions.ChangeProgram): State {
            const {programId} = action.payload
            const report = Object.assign({}, state.report, {
                config: Object.assign({}, state.report.config, {programId: programId})
            })
            return Object.assign({}, state, {report})
        },
        [Actions.ADD_NEW_COLUMN](state: State, action: Actions.AddNewColumn): State {
            const {column} = action.payload
            const columns = state.report.config.columns.push(column)
            return setColumns(state, columns)
        },
        [Actions.CHANGE_COLUMN](state: State, action: Actions.ChangeColumn): State {
            const {column, oldKey} = action.payload
            const columns = state.report.config.columns.map((configColumn: Api.ReportConfigColumn) => {
                if (configColumn.name === oldKey) {
                    configColumn = Object.assign({}, configColumn, {
                        name: column.name,
                        formula: column.formula
                    })
                }
                return configColumn
            })
            return setColumns(state, columns)
        },
        [Actions.RENAME_COLUMN](state: State, action: Actions.RenameColumn): State {
            const {column} = action.payload
            const columns = state.report.config.columns.map((configColumn: Api.ReportConfigColumn) => {
                if (configColumn.name === column.name) {
                    configColumn = Object.assign({}, configColumn, {
                        alias: column.alias,
                        currency: column.currency,
                    })
                }
                return configColumn
            })
            return setColumns(state, columns)
        },
        [Actions.REMOVE_COLUMN](state: State, action: Actions.RemoveColumn): State {
            const {key} = action.payload
            const columns = state.report.config.columns.filter((column: Api.ReportConfigColumn) => {
                return key !== String(column.name)
            })
            return setColumns(state, columns)
        },
        [Actions.CHANGE_COLUMNS_ORDER](state: State, action: Actions.ChangeColumnsOrder): State {
            const {columnsOrder} = action.payload
            const columns = Collections.List(columnsOrder.map((orderKey: string, index: number) => {
                return state.report.config.columns.find((column) => {
                    return column.name === orderKey
                })
            }))
            return setColumns(state, columns)
        },
        [Actions.CHANGE_SORT_COLUMN](state: State, action: Actions.ChangeSortColumn): State {
            const {selectedSortColumn} = action.payload
            return Object.assign({}, setSortColumn(state, selectedSortColumn ? selectedSortColumn.name : "",
                state.sortingDirection), {selectedSortColumn})
        },
        [Actions.CHANGE_SORTING_DIRECTION](state: State, action: Actions.ChangeSortingDirection): State {
            const {sortingDirection} = action.payload
            return Object.assign({}, setSortColumn(state, state.selectedSortColumn ? state.selectedSortColumn.name : "",
                sortingDirection), {sortingDirection})
        },
        [Actions.ADD_NEW_GROUP](state: State, action: Actions.AddNewGroup): State {
            const {group} = action.payload
            const grouping = state.report.config.grouping.push(group)
            return setGrouping(state, grouping)
        },
        [Actions.CHANGE_GROUP](state: State, action: Actions.ChangeGroup): State {
            const {group, oldKey} = action.payload
            const grouping = state.report.config.grouping.map((configGroup: Api.ReportConfigGrouping) => {
                if (configGroup.name === oldKey) {
                    configGroup = Object.assign({}, configGroup, {
                        name: group.name,
                        additionalAgg: group.additionalAgg,
                        collapsed: group.collapsed,
                        sumUp: group.sumUp,
                        extraSettings: group.extraSettings,
                    })
                }
                return configGroup
            })
            return setGrouping(state, grouping)
        },
        [Actions.REMOVE_GROUP](state: State, action: Actions.RemoveGroup): State {
            const {key} = action.payload
            const grouping = state.report.config.grouping.filter((group: Api.ReportConfigGrouping) => {
                return key !== String(group.name)
            })
            return setGrouping(state, grouping)
        },
        [Actions.CHANGE_GROUPS_ORDER](state: State, action: Actions.ChangeGroupsOrder): State {
            const {groupsOrder} = action.payload
            const groups = Collections.List(groupsOrder.map((orderKey: string) => {
                return state.report.config.grouping.find((group) => {
                    return group.name === orderKey
                })
            }))
            return setGrouping(state, groups)
        },
        [Actions.CHANGE_SHOW_PERMISSION](state: State, action: Actions.ChangeShowPermission): State {
            const {showPermission} = action.payload
            return Object.assign({}, state, {showPermission})
        },
        [Actions.ADD_NEW_PERMISSION](state: State, action: Actions.AddNewPermission): State {
            const {permission} = action.payload
            const readers = state.report.readers ? state.report.readers.push(permission) : Collections.List([permission])
            return setReaders(state, readers)
        },
        [Actions.CHANGE_PERMISSION](state: State, action: Actions.ChangePermission): State {
            const {permission, isEditor} = action.payload
            let readers = state.report.readers ? state.report.readers.filter((reader) => {
                return reader.id !== permission.id
            }) : Collections.List([])
            let editors = state.report.editors ? state.report.editors.filter((editor) => {
                return editor.id !== permission.id
            }) : Collections.List([])
            if (isEditor) {
                editors = editors.push(permission)
            } else {
                readers = readers.push(permission)
            }
            return setEditors(setReaders(state, readers), editors)
        },
        [Actions.REMOVE_PERMISSION](state: State, action: Actions.RemovePermission): State {
            const {key} = action.payload
            let readers = state.report.readers ? state.report.readers.filter((reader) => {
                return reader.id !== key
            }) : Collections.List([])
            let editors = state.report.editors ? state.report.editors.filter((editor) => {
                return editor.id !== key
            }) : Collections.List([])
            return setEditors(setReaders(state, readers), editors)
        },
        [Actions.CHANGE_SHOW_FILTER](state: State, action: Actions.ChangeShowFilter): State {
            const {showFilter} = action.payload
            return Object.assign({}, state, {showFilter})
        },
        [Actions.FETCH_REPORT_DATA_SUCCESS](state: State, action: Actions.FetchReportDataSuccess): State {
            const {reportData} = action.payload
            return Object.assign({}, state, {reportData, reportPreviewLoading: false})
        },
        [Actions.FETCH_REPORT_OBJECT_START](state: State, action: Actions.FetchReportObjectStart): State {
            return Object.assign({}, state, {loadObject: true})
        },
        [Actions.CHANGE_FILTER_RULES](state: State, action: Actions.ChangeFilterRules): State {
            const {filterRules} = action.payload
            const report = Object.assign({}, state.report, {
                config: Object.assign({}, state.report.config, {filterRules})
            })
            return Object.assign({}, state, {report})
        },
        [Actions.SAVE_REPORT_TEMPLATE_START](state: State, action: Actions.SaveReportTemplateStart): State {
            return Object.assign({}, state, {btSavePending: true})
        },
        [Actions.SAVE_REPORT_TEMPLATE_SUCCESS](state: State, action: Actions.SaveReportTemplateSuccess): State {
            const {report} = action.payload
            return Object.assign({}, state, {btSavePending: false, isSaved: true, report})
        },
        [Actions.FETCH_REPORT_DATA_START](state: State): State {
            return Object.assign({}, state, {reportPreviewLoading: true})
        }
    },
    defaultState
)
