import React, {
  Context,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"
import hoistNonReactStatic from "hoist-non-react-statics"
import dispatchTrackingEvent from "./dispatchTrackingEvent"
import {Options, TrackingComponentProps} from "src/lib/tracking/types"
// import {IReactComponent} from "mobx-react"

export const ReactTrackingContext: Context<any>  = React.createContext({})

export default function withTrackingComponentDecorator(
  trackingData = {},
  { dispatch = dispatchTrackingEvent, dispatchOnMount = false, process }: Options = {}
) {
  return (DecoratedComponent: any) => {
    const decoratedComponentName =
      DecoratedComponent.displayName || DecoratedComponent.name || "Component"
    function WithTracking(props: TrackingComponentProps) {
      const { tracking } = useContext(ReactTrackingContext)
      const latestProps = useRef(props)

      useEffect(() => {
        // keep the latest props in a mutable ref object to avoid creating
        // additional dependency that could cause unnecessary re-renders
        // see https://reactjs.org/docs/hooks-faq.html#what-can-i-do-if-my-effect-dependencies-change-too-often
        latestProps.current = props
      })

      // statically extract tracking.process for hook dependency
      const trkProcess = tracking && tracking.process
      const getProcessFn = useCallback(() => trkProcess, [trkProcess])

      const getOwnTrackingData = useCallback(() => {
        const ownTrackingData =
          typeof trackingData === "function"
            ? trackingData(latestProps.current)
            : trackingData
        return ownTrackingData || {}
      }, [])

      const getTrackingDataFn = useCallback(() => {
        const contextGetTrackingData =
          (tracking && tracking.getTrackingData) || getOwnTrackingData

        return () =>
          contextGetTrackingData === getOwnTrackingData
            ? getOwnTrackingData()
            : Object.assign(contextGetTrackingData(), getOwnTrackingData())
      }, [getOwnTrackingData, tracking])

      const getTrackingDispatcher = useCallback(() => {
        const contextDispatch =  (tracking && tracking.dispatch) || dispatch
        return (data: Partial<any>) => contextDispatch(
            Object.assign(
                getOwnTrackingData(),
                data || {},
                dispatchOnMount ? null : {params: data?.params}
            )
        )
      }, [getOwnTrackingData, tracking])

      const trackEvent = useCallback(
        (data = {}) => {
          getTrackingDispatcher()(data)
        },
        [getTrackingDispatcher]
      )

      useEffect(() => {
        const contextProcess = getProcessFn()
        const getTrackingData = getTrackingDataFn()

        if (getProcessFn() && process) {
          // eslint-disable-next-line
          console.error(
            "[react-tracking] options.process should be defined once on a top-level component"
          )
        }

        if (
          typeof contextProcess === "function" &&
          typeof dispatchOnMount === "function"
        ) {
          trackEvent(
              Object.assign(
              contextProcess(getOwnTrackingData()) || {},
              dispatchOnMount(getTrackingData()) || {}
            )
          )
        } else if (typeof contextProcess === "function") {
          const processed = contextProcess(getOwnTrackingData())
          if (processed || dispatchOnMount === true) {
            trackEvent(processed)
          }
        } else if (typeof dispatchOnMount === "function") {
          trackEvent(dispatchOnMount(getTrackingData()))
        } else if (dispatchOnMount === true) {
          trackEvent()
        }
      }, [getOwnTrackingData, getProcessFn, getTrackingDataFn, trackEvent])

      const trackingProp = useMemo(
        () => ({
          trackEvent,
          getTrackingData: getTrackingDataFn(),
        }),
        [trackEvent, getTrackingDataFn]
      )

      const contextValue = useMemo(
        () => ({
          tracking: {
            dispatch: getTrackingDispatcher(),
            getTrackingData: getTrackingDataFn(),
            process: getProcessFn() || process,
          },
        }),
        [getTrackingDispatcher, getTrackingDataFn, getProcessFn]
      )

      return useMemo(
        () => (
          <ReactTrackingContext.Provider value={contextValue}>
            <DecoratedComponent {...props} ref={props.forwardedRef} tracking={trackingProp} />
          </ReactTrackingContext.Provider>
        ),
        [contextValue, props, trackingProp]
      )
    }

    WithTracking.displayName = `WithTracking(${decoratedComponentName})`
    WithTracking.contextTypes = DecoratedComponent.contextTypes

    hoistNonReactStatic(WithTracking, DecoratedComponent)

    return WithTracking
  }
}
