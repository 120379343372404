// хранит карту: gid фильтра - имена листов
export class ListNamesByFilterGidStore {

    private $map: Map<string, Set<string>>

    constructor() {
        this.$map = new Map()
    }

    add(filterGID: string, listName: string) {
        this.$map.set(filterGID, (this.$map.get(filterGID) || new Set()).add(listName))
    }

    get(filterGid: string): Set<string> {
        return this.$map.get(filterGid) || new Set()
    }

    // получить имена листов, которые содержат (не содержат) в карте любой из переданных filtersGID
    getListNamesWithFilterGIDs(filtersGID: string[], isListMustContainFilterGID: boolean): string[] {
        const result: string[] = []
        this.$map.forEach((listNames, filterGID) => {
            const listContainFilterGID = !!filtersGID.find(gid => gid === filterGID)
            if (
                isListMustContainFilterGID && listContainFilterGID ||
                !isListMustContainFilterGID && !listContainFilterGID
            ) {
                listNames.forEach(listName => {
                    result.push(listName)
                })
            }
        })

        return result
    }

}
