import {Component} from "src/lib/components"
import * as React from "react"

export interface CWindowEventProps {
    event: keyof WindowEventMap | "closeModalFromIframe" // Добавь сюда, что еще нужно будет
    handler: (e: React.UIEvent<any>) => void
}

/**
 * Компонент для автонавешивания и снятия обработчика глобального события
 */
export class CWindowEvent extends Component<CWindowEventProps, {}> {

    public componentDidMount() {
        window.addEventListener(this.props.event, this.props.handler as any)
    }

    public componentWillUnmount() {
        window.removeEventListener(this.props.event, this.props.handler as any)
    }

    render(): JSX.Element | null {
        return null
    }
}
