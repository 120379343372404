import * as Immutable from "./immutableImpl"
import * as Observable from "./observableImpl"
import {
    ListConstructor,
    List as IList,
    OrderedMapConstructor,
    OrderedMap as IOrderedMap,
    BaseIterable
} from "./interfaces"
import {Map} from "./types"

export * from "./types"
export const List: ListConstructor = Observable.List;
export type List<T> = IList<T>;
export function isList(arg: any): arg is List<any> {
    return Immutable.isList(arg) || Observable.isList(arg);
}
export function createListPropTypeValidator(required = false) {
    return function(props: any, propName: string, componentName: string) {
        if (!required && props[propName] == null) {
            return;
        }
        if (!isList(props[propName])) {
            return new Error(
                "Invalid prop `" + propName + "` supplied to" +
                " `" + componentName + "`. Expected to be List."
            )
        }
    }
}

export function isMap(arg: any): arg is Map<any, any> {
    return Immutable.isMap(arg);
}
export const OrderedMap: OrderedMapConstructor = Immutable.OrderedMap;
export type OrderedMap<K, V> = IOrderedMap<K, V>;

export function isIterable(arg: any): arg is BaseIterable<any, any> {
    return Immutable.isIterable(arg)
}
