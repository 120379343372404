import {TransportLayer} from "src/lib/entities/store/types";
import {realFetch} from "src/lib/entities/store/transport/fetchTransport";
import {Response, RequestInit, checkStatus} from "src/lib/utils/fetch";
import * as Collections from "src/lib/collections"
import * as Api from "src/lib/entities/api"
import {ApiResponse} from "../types"
import {createErrorResponse, createResponse} from "../apiResponse"
import Promise from "bluebird";
import {throttle, noop} from "lodash"
import {isCacheableRequest} from "src/lib/utils/etag";

const FETCH_TIMEOUT = 100
const MAX_CALLS_COUNT_IN_BULK = 5

interface FetchParams {     // хранятся параметры запроса, а так же резолвер промиса запроса
    url: string,
    init?: RequestInit,
    resolver: (response: Response<ApiResponse<{}>>) => void,
}

// функция генерирует apiCall-сущность на основе параметров fetch-запроса
const generateApiCall = (url: string, init?: RequestInit) => {
    const method = init && init.method
        ? init.method
        : Api.ApiCall.Method.GET    // по умолчанию метод GET

    const body = method === Api.ApiCall.Method.POST
        ? init && init.bodyEntity ? JSON.stringify(init.bodyEntity) : void 0
        : init && init.queryParams ? JSON.stringify(init.queryParams) : void 0

    const urlWithQuery = !url.includes("?") && method === "GET" && body
        ? url + "?" + body
        : url

    return {
        ...Api.ApiCall.newObject,
        url: urlWithQuery,
        method: method,
        body: body
    } as Api.ApiCall
}

function needUseRealFetch(url: string, init?: RequestInit): boolean {
    const method = init && init.method      // определяем метод запроса, по умолчанию "GET"
        ? init.method
        : "GET"

    return method !== "GET" || isCacheableRequest(url, init)
}

export class FetchMultiplexer implements TransportLayer {
    private fetchStack: FetchParams[] = []

    constructor(
        private transport = realFetch,
        private fetchFreqTime = FETCH_TIMEOUT,
        private maxCallsCountInBulk = MAX_CALLS_COUNT_IN_BULK
    ) {

    }

    public on = noop

    private processStack() {
        if (this.fetchStack.length >= this.maxCallsCountInBulk) {   // сразу запускаем обновление, при достижении максимального количества
            this.processStackForce()                                // обрабатываемых элементов за раз
        } else {                                                    // чтобы не ждать следующего вызова throttle
            this.processStackThrottle()
        }
    }

    private processStackForce() {
        while (this.fetchStack.length > 0) {
            void this.process(this.fetchStack.splice(0, this.maxCallsCountInBulk))
        }
    }

    private processStackThrottle = throttle(
        this.processStackForce,
        this.fetchFreqTime,
        {
            leading: false, // если будет тру, то при первом запросе всегда будет выполнен processStackForce с одним элементом
            trailing: true
        }
    )

    fetch<V>(url: string, init?: RequestInit): Promise<Response<ApiResponse<V>>> {
        if (needUseRealFetch(url, init)) {
            return this.transport.fetch<V>(url, init)
        }

        const promise = new Promise<Response<ApiResponse<V>>>(
            (resolve: () => void, reject: () => void) => {    // отдадим обещание по запросу, но
                this.fetchStack.push({                        // сам запрос положим в стек на обработку
                    url: url,
                    init: init,
                    resolver: resolve
                })
            }
        )

        this.processStack()

        return promise.then(checkStatus).catch(createErrorResponse)
    }

    private async process(stack: FetchParams[]) {
        if (stack.length === 0) { // на нет и запроса нет
            return
        } else if (stack.length === 1) {    // один запрос ложить в балк не нужно, сходим на сервер за ответом
            const {resolver, url, init} = stack[0]
            resolver(await this.transport.fetch(url, init))

            return
        }

        const calls = Collections.List<Api.ApiCall>(
            stack.map(fetch => generateApiCall(fetch.url, fetch.init))
        )

        const bulkResponse = await this.transport.fetch<ApiResponse<{}>[]>("/api/v3/bulk", {
            method: "POST",
            bodyEntity: {
                ...Api.BulkApiCall.newObject,
                calls: calls.toArray()
            }
        })

        bulkResponse.value.data.forEach((apiResponse: ApiResponse<{}>, index: number) => {
            const fetch = stack[index]

            const resolvedResponse = createResponse({   // генерируем полноценный Response, т.к. ожидается он, а не ApiResponse
                url: fetch.url,
                statusText: "",    // TODO Текст статуса в данном случае должен быть в ApiResponse
                status: apiResponse.meta.status,
                headers: bulkResponse.headers,
                value: apiResponse,
            })

            fetch.resolver(resolvedResponse)
        })
    }
}
