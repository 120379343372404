import {autobind} from "core-decorators"
import {observable, action, computed} from "mobx"
import {Feed} from "./Feed"
import {RemoteFeed} from "./RemoteFeed"
import {RemoteFeedCollection} from "./RemoteFeedCollection"
import {DeviceState, ParticipantState, Participant} from "../../types"
import {
    VideoRoomMessage,
    VideoRoomPluginHandle,
    Publisher,
    LocalFeed as LocalFeedInterface,
    JSEP,
    PluginOptions
} from "./types"
import {opaqueIdGenerator, stopStream, convertDeviceStateToBool} from "../../utils"

@autobind
export class LocalFeed extends Feed implements LocalFeedInterface {

    @observable.ref
    protected $stream: MediaStream

    protected $private_id: number

    protected $videoCodec: string

    protected $opaqueId = opaqueIdGenerator()

    protected $remoteFeedCollection = new RemoteFeedCollection()

    @computed
    public get remoteFeeds() {
        return this.$remoteFeedCollection.items
    }

    public get type() {
        return "local" as "local"
    }

    protected getPluginOptions(): Partial<PluginOptions> {
        return {
            opaqueId: this.$opaqueId,
            onlocalstream: this.localStreamHandler
        }
    }

    protected messageHandler(message: VideoRoomMessage, jsep?: JSEP) {
        const event = message.videoroom
        switch (event) {
            case "joined":
                this.$id = message.id
                this.$private_id = message.private_id
                this.publishLocalFeed()

                if (message.publishers?.length > 0) {
                    message.publishers.forEach(remoteFeed => this.publishRemoteFeed(remoteFeed))
                }

                this.emit("joined", this)

                break
            case "talking":
            case "stopped-talking":
                this.talkHandler(event === "talking" ? "on" : "off", message.id)
                break
            case "event":
                if (message.publishers?.length > 0) {
                    message.publishers.forEach(remoteFeed => this.publishRemoteFeed(remoteFeed))
                } else if (message.leaving) {
                    this.$remoteFeedCollection.dettachById(message.leaving)
                } else if (message.unpublished) {
                    if (message.unpublished === "ok") {
                        this.emit("leave")
                    } else {
                        this.$remoteFeedCollection.dettachById(message.unpublished)
                    }
                } else if (message.display) {
                    const participant = JSON.parse(message.display)
                    this.mediaStateHandler(participant, message.id)
                }

                break
        }

        if (jsep) {
            this.$handle.handleRemoteJsep({jsep})
        }
    }

    protected successHandler(handle: VideoRoomPluginHandle) {
        super.successHandler(handle)
        this.registerUser()
    }

    @action
    protected localStreamHandler(stream: MediaStream) {
        if (stream instanceof MediaStream) {
            stream = new MediaStream(stream.getVideoTracks())
        } else if (this.$stream instanceof MediaStream) {
            stopStream(this.$stream)
        }

        this.$stream = stream
    }

    protected registerUser() {
        const message = {
            request: "join",
            room: this.$auth.room,
            ptype: "publisher",
            display: JSON.stringify({
                entity: this.$participant.entity,
                state: {
                    video: this.$mediaControl.video,
                    audio: this.$mediaControl.audio
                }
            } as Participant),
            token: this.$auth.token,
        }

        this.$handle.send({message})
    }

    protected publishLocalFeed() {
        this.$handle.createOffer({
            media: {
                videoRecv: false,
                audioRecv: false,
                video: this.$mediaControl.hasVideoPermission() ? "stdres" : false,
                audioSend: this.$mediaControl.hasAudioPermission()
            },
            success: (jsep: JSEP) => {
                const message = {
                    request: "configure",
                    video: this.$mediaControl.hasVideoPermission(),
                    audio: this.$mediaControl.hasAudioPermission(),
                    audiocodec: "opus",
                    videocodec: "vp9"
                }

                this.$handle.send({message, jsep})
                this.emit("ready")
            },
            error: (errorStruct: {message: string}) => {
                this.emit("leave")
            }
        })
    }

    protected publishRemoteFeed(remoteFeed: Publisher) {
        const participant = JSON.parse(remoteFeed.display)
        const feed = new RemoteFeed(
            this.$janus,
            {
                room: this.$auth.room,
                token: this.$auth.token,
                feed: remoteFeed.id,
                private_id: this.$private_id,
                videoCodec: remoteFeed.video_codec,
                opaqueId: this.$opaqueId
            },
            participant
        )

        this.$remoteFeedCollection.attach(feed)
    }

    protected talkHandler(state: DeviceState, id: number) {
        if (this.$id === id) {
            this.$mediaControl.changeTalkState(state)
        } else {
            const feed = this.$remoteFeedCollection.getById(id)

            if (feed) {
                feed.mediaControl.changeTalkState(state)
            }
        }
    }

    protected mediaStateHandler(participant: Participant, id: number) {
        if (this.$id === id) {
            return
        }

        const feed = this.$remoteFeedCollection.getById(id)

        if (feed) {
            feed.mediaControl.changeVideoState(participant.state.video)
            feed.mediaControl.changeAudioState(participant.state.audio)
        }
    }

    @action
    public muteVideo() {
        this.$mediaControl.changeVideoState("off", () => {
            this.$handle.muteVideo()
            this.mediaStateConfigure({video: "off"})
        })
    }

    @action
    public unmuteVideo() {
        this.$mediaControl.changeVideoState("on", () => {
            this.$handle.unmuteVideo()
            this.mediaStateConfigure({video: "on"})
        })
    }

    @action
    public muteAudio() {
        this.$mediaControl.changeAudioState("off", () => {
            this.$handle.muteAudio()
            this.mediaStateConfigure({audio: "off"})
        })
    }

    @action
    public unmuteAudio() {
        this.$mediaControl.changeAudioState("on", () => {
            this.$handle.unmuteAudio()
            this.mediaStateConfigure({audio: "on"})
        })
    }

    protected mediaStateConfigure(state: Partial<ParticipantState>) {
        const newState = {
            video: this.$mediaControl.video,
            audio: this.$mediaControl.audio,
            ...state
        }

        this.$handle.send({message: {
            request: "configure",
            display: JSON.stringify({
                entity: this.$participant.entity,
                state: newState
            } as Participant),
            video: convertDeviceStateToBool(state.video === void 0 ? this.$mediaControl.video : state.video),
            audio: convertDeviceStateToBool(state.audio === void 0 ? this.$mediaControl.audio : state.audio),
        }})
    }
}
