import * as Collections from "../collections"
import {LoadState} from "../utils/loadState"
import {BaseEntity, BaseValue, isBaseEntity} from "./base"
import {isTaskFilter, TaskFilter} from "./bums/taskFilter"
import {CrmFilter, isCrmFilter} from "./bums/crmFilter"
import {isTradeFilter} from "./bums/tradeFilter"
import {isEmployeeFilter} from "./bums/employeeFilter"
import * as Api from "src/lib/entities/bums"
import * as AdditionalMetadata from "src/lib/entities/additionalMetadata"
import {isProject, isTask, Project, ProjectRights, Task, TaskRights} from "src/lib/entities/bums";
import {List} from "../collections";
import {ProjectPossibleActions, TaskPossibleActions} from "src/lib/entities/compatibilityTypes";

export * from "./base"

export const UNKNOWN_ID_PREFIX = "###unknown_id_"
export type Id = string
export type LinksList = Collections.List<BaseEntity>
export type ListDirection = "Next" | "Prev"

/**
 * Хранилище ссылок на несохранённые сущности.
 * Зранятся имена списков, в которых присутствуют ссылки на несохранённые модели.
 * Immutable.Map<contentType: string, Immutable.Map<entityId: Id, Immutable.List<listName: string>>>
 */
export type UnsavedEntitiesReferencesMap = Collections.Map<string, Collections.Map<Id, Collections.List<string>>>

export type User = (Api.Employee | Api.ContractorHuman) & {
    possibleActions?: Collections.List<Api.Employee.PossibleActions | Api.ContractorHuman.PossibleActions>
}

function isFillRightsEntity(entity: Task | Project) {
    return "rights" in entity && !!entity["rights"] && !entity["possibleActions"]
}

export function hasRights<T extends BaseEntity & {
    possibleActions?: List<string>,
    availableActions?: List<string>
}>(entity: T, right: string): boolean {
    if (isTask(entity) && isFillRightsEntity(entity)) {
        if (right in entity.rights) {
            return !!entity.rights[right as keyof TaskRights]
        } else if (!!TaskPossibleActions[right] && TaskPossibleActions[right] in entity.rights) {
            return !!entity.rights[TaskPossibleActions[right] as keyof TaskRights]
        }

        return false
    } else if (isProject(entity) && isFillRightsEntity(entity)) {
        if (right in entity.rights) {
            return !!entity.rights[right as keyof ProjectRights]
        } else if (!!ProjectPossibleActions[right] && ProjectPossibleActions[right] in entity.rights) {
            return !!entity.rights[ProjectPossibleActions[right] as keyof ProjectRights]
        }

        return false
    }

    return !!((entity.possibleActions && entity.possibleActions.includes(right)) ||
        (entity.availableActions && entity.availableActions.includes(right)))
}

export function isNamedEntity<T extends BaseEntity>(entity: T): entity is T & {name: string} {
    return "name" in entity
}

export type FilterEntity = TaskFilter | CrmFilter

export function isFilterEntity(entity: any): entity is FilterEntity {
    return !!entity && (isTaskFilter(entity) || isCrmFilter(entity) || isTradeFilter(entity) || isEmployeeFilter(entity))
}

export function isTemplatedEntity<T extends BaseEntity>(entity: T): entity is T & {isTemplate: boolean} {
    return "isTemplate" in entity
}

export interface NormalizedList {
    loadStateNext: LoadState
    loadStatePrev: LoadState
    hasMoreNext?: boolean
    hasMorePrev?: boolean
    loadedWithOptions?: any   // с какими опциями этот список был загружен.
                              // если придёт запрос на загрузку с другими опциями, то список перезагружается.
    totalItemsCount?: number
    items: LinksList
}

export const emptyList: NormalizedList = {
    loadStateNext: LoadState.None(),
    loadStatePrev: LoadState.None(),
    items: Collections.List<BaseEntity>(),
    loadedWithOptions: {},
}

export interface Money extends BaseValue {
    value: number
    currency: string
    rate: number
}

export interface HasMore {
    Prev?: boolean
    Next?: boolean
}

export interface RequestOptions {
    [optionName: string]: RequestOptions | {} | void
}



export interface ContentTypeMetadata {
    newObject: {}
}

export function isExtraField(arg: any): arg is AdditionalMetadata.ExtraField {
    return !!arg && isBaseEntity(arg) && [
        Api.StringField.contentType,
        Api.IntegerField.contentType,
        Api.FloatField.contentType,
        Api.BigIntField.contentType,
        Api.DateField.contentType,
        Api.DateTimeField.contentType,
        Api.DurationField.contentType,
        Api.EnumField.contentType,
        Api.ExternalSourceField.contentType,
        Api.BoolField.contentType,
        Api.RefLinkField.contentType,
        Api.RatingField.contentType,
        Api.MoneyField.contentType,
        Api.ResourceField.contentType,
    ].includes((arg as AdditionalMetadata.ExtraField).contentType)
}
