import {uniqueId} from "lodash"

export function defaultComparator(a: any, b: any) {
  return a > b ? 1 : a < b ? -1 : 0;
}
export function isIterable(arg: any): arg is Iterable<any> {
    return arg != null && typeof arg === "object" && typeof arg[Symbol.iterator] === "function";
}
const objectHashes = new WeakMap<Object, string>();
function getObjectHash(object: Object): string {
    if (!objectHashes.has(object)) {
        objectHashes.set(object, uniqueId("@@__object_hash_"))
    }
    return objectHashes.get(object)
}
export function getStringKey(element: any): string {
    if (typeof element === "string") {
        return element;
    } else if (typeof element === "number" || typeof element === "boolean") {
        return String(element);
    } else if (Object(element) === element) {
        return getObjectHash(element);
    } else {
        throw new Error("Unsupported value for Map key!")
    }
}
