import {noop} from "lodash"
import {TransportLayer} from "../types"
import baseFetch, {addRequestHeader, RequestInit, Response} from "src/lib/utils/fetch"
import {ApiResponse} from "../../types"
import {createResponse, createErrorResponse} from "../../apiResponse"
import md5 from "md5";

declare var window: any;

export const realFetch: TransportLayer = {
    on: noop,
    fetch<V>(url: string, init?: RequestInit) {
        return baseFetch<ApiResponse<V>>(url, init)
            .then<Response<ApiResponse<V>>, Response<ApiResponse<V>>>(createResponse, createErrorResponse)
    }
}

export function createAppTypedTransport(appType: "Web" | "Mobile", transport = realFetch): TransportLayer {
    return {
        fetch<V>(url: string, init: RequestInit = {}) {
            addRequestHeader(init, "X-Megaplan-App-Type", appType)
            return transport.fetch<V>(url, init)
        },
        on(events) {
            return transport.on(events)
        }
    }
}

export function createPendingRequestsCounterTransport(transport = realFetch): TransportLayer {
    return {
        fetch<V>(url: string, init: RequestInit = {}) {
            const key = md5(url + Date.now())
            window.mp_pending_requests = (window.mp_pending_requests || {})

            window.mp_pending_requests[key] = {url, init: JSON.stringify(init)}

            return transport.fetch<V>(url, init)
                .finally(() => {
                    delete window.mp_pending_requests[key];
                });
        },
        on(events) {
            return transport.on(events)
        }
    }
}
