import {PlainRoute} from "react-router"

enum FixedProgramRoutes {
    all = "all",
    bpm = "bpm",
    deals = "deals",
}

const filterIdHashExp = /^#filterId=(.+)/i

const ProgramRoutesMap: { [key: number]: FixedProgramRoutes } = {
    [-1]: FixedProgramRoutes.all,
    [-2]: FixedProgramRoutes.bpm,
    [-3]: FixedProgramRoutes.deals,
}

export const bpmRoutes: PlainRoute[] = [
    {
        path: "/bp/:slug/list",
        async getComponent() {
            return (await import("./list/page/CTradeListPage")).CTradeListPage
        },
    },
    {
        path: "/bp/:slug/list/:filterId",
        async getComponent() {
            return (await import("./list/page/CTradeListPage")).CTradeListPage
        },
    },
    {
        path: "/bp/:slug/card/:dealId",
        async getComponent() {
            return (await import("./card/containers/CPageContainer")).CTradePageWrapper
        },
    },
]

export const tradeRoutes: PlainRoute[] = [
    {
        path: "/deals/list/",
        onEnter(nextState, replace) {
            if (!nextState.location.query.program && !nextState.location.hash) {
                return
            }

            const {program} = nextState.location.query
            const programId = ProgramRoutesMap[program] ? ProgramRoutesMap[program] : program > 0 ? program : FixedProgramRoutes.all

            const matchFilterId = (nextState.location.hash || "").match(filterIdHashExp)
            const filterId = (matchFilterId && matchFilterId[1])
                || (nextState.location.query && (nextState.location.query as any)["filterId"])
                || "all"

            replace({pathname: `/deals/list/${programId}/${filterId}`})
        },
        async getComponent() {
            return (await import("./list/page/CTradeListPage")).CTradeListPage
        },
    },
    {
        path: "/deals/list/:programId/:filterId",
        async getComponent() {
            return (await import("./list/page/CTradeListPage")).CTradeListPage
        },
    },
    {
        path: "/deals/list/:programGroupId",
        async getComponent() {
            return (await import("./list/page/CTradeListPage")).CTradeListPage
        },
    },
    {
        path: "/deals/list/:programGroupId/:programId/:filterId",
        async getComponent() {
            return (await import("./list/page/CTradeListPage")).CTradeListPage
        },
    },
    {
        path: "deals/:dealId/card*",
        async getComponent() {
            return (await import("./card/containers/CPageContainer")).CTradePageWrapper
        },
    },
    ...bpmRoutes
]
