import {Component} from "src/lib/components"
import * as React from "react"
import {BaseSize} from "src/lib/components/common/BaseProps"
import classNames from "classnames/bind"

interface SpinnerProps {
    className?: string
    size?: BaseSize
    title?: string
    greenSpinner?: boolean
}

let style = classNames.bind(require("./CSpinner.styl"))

/**
 * Лоадинг
 */

export default class CSpinner extends Component<SpinnerProps, {}> {

    public static Size = BaseSize

    public render() {
        const selectedSizeString = BaseSize[this.props.size]

        //  data-loading="true" для браузерных тестов
        return <span className={style(
                "spinner",
                {["spinner_" + selectedSizeString]: Boolean(selectedSizeString)},
                this.props.className ? this.props.className : "",
                {"green": this.props.greenSpinner}
            )}
            title={this.props.title}
            data-loading="true"
        />
    }
}
