import {Map as IMap, OrderedMap as IOrderedMap, BaseIterable} from "./interfaces"
import {Map as ImmutableMap} from "./immutableImpl"
import {getStringKey, isIterable} from "./utils"

export class MapClass<K, V> implements IMap<K, V> {

    private inner: Map<string, [K, V]>

    constructor()
    constructor(source: Array<[K, V]>)
    constructor(source: Iterable<[K, V]>)
    constructor(source: {[index: string]: V} | {[index: number]: V})
    constructor(
        source?: {[index: string]: V} | {[index: number]: V} | Iterable<[K, V]> | Array<[K, V]>
    ) {
        this.inner = new Map<string, [K, V]>();
        if (null == source) {
            return;
        } else if (Array.isArray(source)) {
            source.forEach(([k, v]) => {
                this.set(k, v);
            })
        } else if (isIterable(source)) {
            for (let [k, v] of source) {
                this.set(k, v);
            }
        } else {
            Object.keys(source).forEach(key => {
                (this as any).set(key, (source as {[index: string]: V})[key])
            })
        }
    }

    get length() {
        return this.inner.size;
    }

    get size() {
        return this.inner.size;
    }

    forEach(callback: (value: V, index: K) => void): void {
        for (let [k, v] of this) {
            callback(v, k);
        }
    }

    find(predicate: (value: V, index: K) => boolean): V | undefined {
        for (let [k, v] of this) {
            if (predicate(v, k)) {
                return v;
            }
        }
    }

    findEntry(predicate: (value: V, index: K) => boolean): [K, V] | undefined {
        for (let [k, v] of this) {
            if (predicate(v, k)) {
                return [k, v];
            }
        }
    }

    some(callback: (value: V, index: K) => boolean): boolean {
        for (let [k, v] of this) {
            if (callback(v, k)) {
                return true;
            }
        }
        return false;
    }

    includes(value: V): boolean {
        for (let [, v] of this) {
            if (value === v) {
                return true;
            }
        }
        return false;
    }

    get(index: K, notExistsValue?: V): V {
        const stringKey = getStringKey(index);
        if (this.inner.has(stringKey)) {
            return this.inner.get(stringKey)[1]
        } else {
            return notExistsValue
        }
    }

    has(index: K): boolean {
        return this.inner.has(getStringKey(index))
    }

    toArray(): Array<V> {
        const res: Array<V> = [];
        for (let [, v] of this) {
            res.push(v);
        }
        return res;
    }

    toObject(): {[index: string]: V} {
        const res: {[index: string]: V} = {};
        for (let [k, v] of this) {
            res[String(k)] = v
        }
        return res;
    }

    set(index: K, value: V): this {
        this.inner.set(getStringKey(index), [index, value]);
        return this;
    }
    remove(index: K): this {
        this.inner.delete(getStringKey(index));
        return this;
    }

    update(index: K, updater: (v: V) => V): this;
    update(index: K, notExistsValue: V, updater: (v: V) => V): this;
    update(index: K, notExistsValue: V | ((v: V) => V), updater?: (v: V) => V): this {
        let resolvedUpdater: (v: V) => V;
        let resolvedNotExistsValue: V;
        if (arguments.length === 2) {
            resolvedUpdater = notExistsValue as (v: V) => V;
        } else {
            resolvedUpdater = updater;
            resolvedNotExistsValue = notExistsValue as V;
        }
        this.set(index, resolvedUpdater(this.get(index, resolvedNotExistsValue)))
        return this;
    }

    merge(other: BaseIterable<K, V>): this {
        other.forEach((value, index) => {
            this.set(index, value);
        })
        return this;
    }

    mergeWith(merger: (previous: V, next: V, key: K) => V, other: BaseIterable<K, V>): this {
        other.forEach((value, index) => {
            if (this.has(index)) {
                this.set(index, merger(this.get(index), value, index))
            } else {
                this.set(index, value);
            }
        })
        return this;
    }

    [Symbol.iterator](): IterableIterator<[K, V]> {
        return this.inner.values();
    }

    *keys(): IterableIterator<K> {
        for (let [k] of this) {
            yield k;
        }
    }

    *values(): IterableIterator<V> {
        for (let [, v] of this) {
            yield v;
        }
    }

    entries(): IterableIterator<[K, V]> {
        return this.inner.values();
    }

    map<U>(mapper: (value: V, index: K) => U): IMap<K, U> {
        const mapped = new MapClass<K, U>();
        for (let [k, v] of this) {
            mapped.set(k, mapper(v, k))
        }
        return mapped;
    }

    filter(predicate: (value: V, index: K) => boolean): IMap<K, V> {
        const filtered = new MapClass<K, V>();
        for (let [k, v] of this) {
            if (predicate(v, k)) {
                filtered.set(k, v)
            }
        }
        return filtered;
    }

    filterNot(predicate: (value: V, index: K) => boolean): IMap<K, V> {
        const filtered = new MapClass<K, V>();
        for (let [k, v] of this) {
            if (!predicate(v, k)) {
                filtered.set(k, v)
            }
        }
        return filtered;
    }

    sort(comparator: (a: V, b: V) => number): IOrderedMap<K, V> {
        // todo fix after ordered map implemented
        return ImmutableMap(this).sort(comparator)
    }

    sortBy<C>(comparatorValueMapper: (value: V, index: K) => C, comparator: (a: C, v: C) => number): IOrderedMap<K, V> {
        // todo fix after ordered map implemented
        return ImmutableMap(this).sortBy(comparatorValueMapper, comparator);
    }
}
