import {observable, action, runInAction} from "mobx"
import * as Api from "src/lib/entities/api"
import {OnlineSubscription} from "src/bums/common/subscriptions/OnlineSubscription"
import {SocketTransport} from "src/lib/services/types"
import {AppStateStore} from "src/lib/entities/store/online/AppStateStore"
import LoadState from "src/lib/utils/loadState"

export class UserOnlineStore {

    private onlineSubscription: OnlineSubscription
    private onlineMap = observable.map<string, "web"|"mobile">({})

    @observable
    private loadState = LoadState.None()

    constructor(
        private transport: SocketTransport,
        private appStateStore: AppStateStore,
    ) {
        this.onlineSubscription = new OnlineSubscription(this.transport, this.subscriptionListener)

        this.subscribe()

        this.appStateStore.addStateListener("active", this.subscribe)
        this.appStateStore.addStateListener("background", this.unsubscribe)
    }

    public getStatus = (entity: Api.User) => {
        try {
            if (entity?.uid) {

                if (Array.from(this.onlineMap.keys()).length === 0 && this.loadState.isCompleted()) {
                    return entity.isOnline ? "web" : null
                }

                return this.onlineMap.get(entity.uid.toString())
            }
        } catch (e) {
            console.error(e)
        }

        return null
    }

    @action
    private subscriptionListener = (message: {}) => {

        this.loadState = LoadState.Completed()

        try {
            this.onlineMap.replace(message)
        } catch (e) {
            console.error(e)
        }
    }

    @action
    private subscribe = () => {
        this.loadState = LoadState.Pending()
        this.onlineSubscription.subscribe()

        setTimeout(() => {
            if (this.loadState.isPending()) {
                runInAction(() => {
                    this.loadState = LoadState.Completed()
                })
            }
        }, 3000)
    }

    @action
    private unsubscribe = () => {
        this.onlineSubscription.unsubscribe()
    }
}
