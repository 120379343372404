import {computed, action, observable} from "mobx"
import * as Api from "src/lib/entities/api"
import {GlobalDraftStorage} from "./GlobalDraftStorage"
import {persist} from "mobx-persist"
import {getNow} from "src/lib/utils/date"

export interface CommentDraft {
    content: string
    date: number
}

export class DraftStore {

    private globalDraftStorage: GlobalDraftStorage

    @persist("map")
    private localDrafts = observable.map<string, CommentDraft>({})

    constructor(
        apiStore?: Api.Store,
    ) {
        if (apiStore) {
            this.setApiStore(apiStore)
        }
    }

    public setApiStore = (apiStore: Api.Store) => {
        this.globalDraftStorage = new GlobalDraftStorage(apiStore)
    }

    public getDraftForEntity = (entity: Api.BaseEntity) => {
        const globalDraft = this.globalDraftStorage.getDraft(entity)
        const localDraft = this.getLocalDraft(entity)

        if (localDraft && globalDraft) {
            const localDate = new Date(localDraft.date)
            const globalDate = new Date(globalDraft.date)
            return globalDate >= localDate ? globalDraft.content : localDraft.content
        }

        return globalDraft?.content || localDraft?.content
    }

    public saveDraft = (entity: Api.BaseEntity, content?: string, forseLocal?: boolean) => {
        if (!content || content.length === 0) {
            this.clear(entity)
            return
        }

        if (forseLocal) {
            this.addLocalDraft(entity, content)
            return
        }

        try {
            this.globalDraftStorage.addDraft(entity, content)
        } catch (e) {
            this.addLocalDraft(entity, content)
        }
    }

    public clear = (entity: Api.BaseEntity) => {
        try {
            void this.globalDraftStorage.removeDraft(entity)
            void this.removeLocalDraft(entity)
        } catch (e) {
            //noop
        }
    }

    @action
    private addLocalDraft = (entity: Api.BaseEntity, content: string) => {
        const gid = Api.getGID(entity)

        if (!this.localDrafts.has(gid) || this.localDrafts.get(gid).content !== content) {
            this.localDrafts.set(gid, {content, date: getNow().getTime()})
        }
    }

    @action
    private removeLocalDraft = (entity: Api.BaseEntity) => {
        const gid = Api.getGID(entity)

        if (this.localDrafts?.has(gid)) {
            this.localDrafts.delete(gid)
        }
    }

    private getLocalDraft = (entity: Api.BaseEntity) => {
        const gid = Api.getGID(entity)

        if (this.localDrafts?.has(gid)) {
            return this.localDrafts.get(gid)
        }

        return null
    }

    @computed
    public get isReady() {
        return this.globalDraftStorage.isReady
    }
}
