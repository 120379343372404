import * as Api from "src/lib/entities/api"
import * as WebApi from "src/lib/entities/apiWeb"
import * as ReduxActions from "redux-actions"
import {ApiErrorResponse} from "src/lib/entities/api"
import {ReportObjectImmutable} from "../types"
import * as Utils from "../utils"

export const FETCH_REPORT_OBJECT_START = "bums/common/report/edit/action/FETCH_REPORT_OBJECT_START"
export const FETCH_REPORT_OBJECT_SUCCESS = "bums/common/report/edit/action/FETCH_REPORT_OBJECT_SUCCESS"
export const FETCH_REPORT_OBJECT_FAILED = "bums/common/report/card/action/FETCH_REPORT_OBJECT_FAILED"

export type FetchReportObjectStart = ReduxActions.Action<void>
export type FetchReportObjectSuccess = ReduxActions.Action<{
    reportObject: ReportObjectImmutable
}>
export type FetchReportObjectFailedAction = ReduxActions.Action<{
    error: ApiErrorResponse
}>


export function fetchReportObjectStart(): FetchReportObjectStart {
    return {
        type: FETCH_REPORT_OBJECT_START
    }
}

export function fetchReportObjectSuccess(reportObject: ReportObjectImmutable): FetchReportObjectSuccess {
    return {
        type: FETCH_REPORT_OBJECT_SUCCESS,
        payload: {
            reportObject
        }
    }
}

export function fetchReportObjectFailed(error: ApiErrorResponse): FetchReportObjectFailedAction {
    return {
        type: FETCH_REPORT_OBJECT_FAILED,
        payload: {
            error
        }
    }
}

export function fetchReportObject(objectType: string, programId: string = null) {
    return WebApi.apiFetch<Api.ReportObject>(
        `/api/v3/report/object/${objectType}/`,
        programId ? {queryParams: {programId}} : null,
        [
            fetchReportObjectStart,
            (response: Api.ReportObject) => {
                const entity = Utils.normalizeEntity(response) as ReportObjectImmutable
                return fetchReportObjectSuccess(entity)
            },
            fetchReportObjectFailed
        ]
    )
}
