import * as ReduxActions from "redux-actions"

export const CHANGE_GROUPS_ORDER = "bums/common/report/edit/action/CHANGE_GROUPS_ORDER"

export type ChangeGroupsOrder = ReduxActions.Action<{
    groupsOrder: Array<string>
}>

export function changeGroupsOrder(groupsOrder: Array<string>): ChangeGroupsOrder {
    return {
        type: CHANGE_GROUPS_ORDER,
        payload: {
            groupsOrder
        }
    }
}
