/* tslint:disable */
import * as H from "../metadataHelper"
import {Country} from "./country"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {StringField} from "./stringField"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"

export interface Payer extends H.BaseEntity {
    [fieldName: string]: any;
    id?: string; // Идентификатор
    name?: string; // Имя
    type?: Payer.Type; // Тип плательщика
    country?: Country; // Страна плательщика
    isDropped?: boolean; // Удален
    bank?: string; // Банк плательщика
    bik?: string; // Бик плательщика
    inn?: string; // ИНН плательщика
    edrpou?: string; // ЕДРПОУ(ЕГРПОУ) плательщика
    correspondentAccount?: string; // Корреспондентский счёт плательщика
    kpp?: string; // КПП плательщика
    ogrn?: string; // ОГРН плательщика
    firstName?: string; // Имя плательщика
    lastName?: string; // Фамилия плательщика
    address?: string; // Адрес плательщика
    legalAddress?: string; // Юр адрес плательщика
    currentAccount?: string; // р/с (расчётный счёт) плательщика
    mfo?: string; // МФО плательщика
    unp?: string; // УНП плательщика
    okpo?: string; // ОКПО плательщика
    contractor?: ContractorCompany|ContractorHuman; // Контрагент
    contentType: "Payer"; // Object type
}

export module Payer {
    export const contentType: "Payer" = "Payer";
    export const endpoint = "/api/v3/payer";
    export type Type = "Legal"|"Natural";
    export module Type {
        export const Legal: Type = "Legal";
        export const Natural: Type = "Natural";
    }
    export const newObject: Payer = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Payer.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const type = {contentType: "EnumField" as "EnumField", name: "type", isMultiple: false, isSortable: false, id: "Payer.type", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["Legal", "Natural"])};
        export const country = {contentType: "RefLinkField" as "RefLinkField", name: "country", isMultiple: false, isSortable: false, id: "Payer.country", refContentType: H.List(["Country"])};
        export const isDropped = {contentType: "BoolField" as "BoolField", name: "isDropped", isMultiple: false, isSortable: false, id: "Payer.isDropped", defaultValue: null as BoolField["defaultValue"]};
        export const bank = {contentType: "StringField" as "StringField", name: "bank", isMultiple: false, isSortable: false, id: "Payer.bank", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const bik = {contentType: "StringField" as "StringField", name: "bik", isMultiple: false, isSortable: false, id: "Payer.bik", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const inn = {contentType: "StringField" as "StringField", name: "inn", isMultiple: false, isSortable: false, id: "Payer.inn", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const edrpou = {contentType: "StringField" as "StringField", name: "edrpou", isMultiple: false, isSortable: false, id: "Payer.edrpou", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const correspondentAccount = {contentType: "StringField" as "StringField", name: "correspondentAccount", isMultiple: false, isSortable: false, id: "Payer.correspondentAccount", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const kpp = {contentType: "StringField" as "StringField", name: "kpp", isMultiple: false, isSortable: false, id: "Payer.kpp", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const ogrn = {contentType: "StringField" as "StringField", name: "ogrn", isMultiple: false, isSortable: false, id: "Payer.ogrn", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const firstName = {contentType: "StringField" as "StringField", name: "firstName", isMultiple: false, isSortable: false, id: "Payer.firstName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const lastName = {contentType: "StringField" as "StringField", name: "lastName", isMultiple: false, isSortable: false, id: "Payer.lastName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const address = {contentType: "StringField" as "StringField", name: "address", isMultiple: false, isSortable: false, id: "Payer.address", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const legalAddress = {contentType: "StringField" as "StringField", name: "legalAddress", isMultiple: false, isSortable: false, id: "Payer.legalAddress", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const currentAccount = {contentType: "StringField" as "StringField", name: "currentAccount", isMultiple: false, isSortable: false, id: "Payer.currentAccount", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const mfo = {contentType: "StringField" as "StringField", name: "mfo", isMultiple: false, isSortable: false, id: "Payer.mfo", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const unp = {contentType: "StringField" as "StringField", name: "unp", isMultiple: false, isSortable: false, id: "Payer.unp", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const okpo = {contentType: "StringField" as "StringField", name: "okpo", isMultiple: false, isSortable: false, id: "Payer.okpo", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const contractor = {contentType: "RefLinkField" as "RefLinkField", name: "contractor", isMultiple: false, isSortable: false, id: "Payer.contractor", refContentType: H.List(["ContractorCompany", "ContractorHuman"])};
    }
}

export function isPayer(arg: any): arg is Payer {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Payer"
}
