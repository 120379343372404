import * as ReduxActions from "redux-actions"
import * as Actions from "./actions/index"

export interface State {
    openMenuId?: string
}

const initialState: State = {
    openMenuId: "",
}

export const reducer = ReduxActions.handleActions({
    [Actions.TOGGLE_CONTEXT_MENU](state: State, action: Actions.ToggleContextMenuAction): State {
        return Object.assign({}, state, {
            openMenuId: action.payload || "",
        })
    },
}, initialState)
