/*
 В данном файле собраны все используемые по проекту различные применения globalize.
 Данный файл используется компилятором чтобы собрать все возможные использования Globalize
 и построить правильный конфиг для globalize-config-loader.
 См. также .webpack/extract-globalize-formatters-loader
 */

import {Globalize} from "globalize"
import {memoize} from "lodash"

abstract class Formatter {
    constructor(protected g: Globalize) {
    }
}

export class NumberFormatter extends Formatter {

    public static moneyOptions = {minimumFractionDigits: 0, maximumFractionDigits: 2}
    public static moneyFraction = {minimumFractionDigits: 2, maximumFractionDigits: 2}

    public getByName = (formatName: string): Globalize.NumberFormatter => {
        if (!((formatName as string) in this)) {
            throw new Error(`"${formatName}" does not exists at NumberFormatter!`)
        } else {
            return (this as any)[formatName]
        }
    }

    public money = memoize(() => this.g.numberFormatter(NumberFormatter.moneyFraction))
    public decimal = memoize(() => this.g.numberFormatter({style: "decimal"}))
    public percent = memoize(() => this.g.numberFormatter({style: "percent"}))
}

export class CurrencyFormatter extends Formatter {
    private fallbackUsSign: {[style: string]: string}

    public static symbolOptions: Globalize.CurrencyFormatterConstructorOptions = {style: "symbol", ...NumberFormatter.moneyOptions}
    public static codeOptions: Globalize.CurrencyFormatterConstructorOptions = {style: "code", ...NumberFormatter.moneyOptions}
    public static nameOptions: Globalize.CurrencyFormatterConstructorOptions = {style: "name", ...NumberFormatter.moneyOptions}

    public getByName = (formatName: string): Globalize.CurrencyFormatter => {
        if (!((formatName as string) in this)) {
            throw new Error(`"${formatName}" does not exists at CurrencyFormatter!`)
        } else {
            return (this as any)[formatName]
        }
    }

    private fallbackCurrencyFormatter = (currency: string) =>
        (options: Globalize.CurrencyFormatterConstructorOptions) =>
            (value: number) => {
                if (!options.style) {
                    return ""
                }
                if (!this.fallbackUsSign) {
                    this.fallbackUsSign = {}
                }
                if (!this.fallbackUsSign[options.style]) {
                    this.fallbackUsSign[options.style] = this.g.currencyFormatter("USD", options)(0)
                        .replace("0", "").replace(/\s+/, "")
                }
                return this.g.currencyFormatter("USD", options)(value).replace(this.fallbackUsSign[options.style], currency)
            }


    public symbol = (currency: string) => this.g.currencyFormatter(currency, CurrencyFormatter.symbolOptions) ||
        this.fallbackCurrencyFormatter(currency)(CurrencyFormatter.symbolOptions)

    public code = (currency: string) => this.g.currencyFormatter(currency, CurrencyFormatter.codeOptions) ||
        this.fallbackCurrencyFormatter(currency)(CurrencyFormatter.codeOptions)

    public name = (currency: string) => this.g.currencyFormatter(currency, CurrencyFormatter.nameOptions) ||
        this.fallbackCurrencyFormatter(currency)(CurrencyFormatter.nameOptions)
}

/**
 * Содержит в себе форматтеры для различных скелетов даты.
 * Для добавления нового форматтера используйте
 * @see http://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 *
 * yMMMM и сопутствующие форматы не работают, см
 * @see https://github.com/jquery/globalize/issues/271
 */
export namespace DateFormatter {
    export type KnownFormat = keyof DateFormatter
}
export class DateFormatter extends Formatter {

    public getByName = (formatName: DateFormatter.KnownFormat): Globalize.DateFormatter => {
        if (!((formatName as string) in this)) {
            throw new Error(`"${formatName}" does not exists at DateFormatter!`)
        } else {
            return (this as any)[formatName]()
        }
    }

    // combined skeletons
    public MMdd = memoize(() => this.g.dateFormatter({skeleton: "MMdd"}))
    public MMMd = memoize(() => this.g.dateFormatter({skeleton: "MMMd"}))
    public MMMMd = memoize(() => this.g.dateFormatter({skeleton: "MMMMd"}))
    public MMMMdHm = memoize(() => this.g.dateFormatter({skeleton: "MMMMdHm"}))
    public MMMMdHHmmss = memoize(() => this.g.dateFormatter({skeleton: "MMMMdHHmmss"}))
    public yMMMMdHHmmss = memoize(() => this.g.dateFormatter({skeleton: "yMMMMdHHmmss"}))
    public yMMMMdHHmm = memoize(() => this.g.dateFormatter({skeleton: "yMMMMdHHmm"}))
    public yMMMMdHmm = memoize(() => this.g.dateFormatter({skeleton: "yMMMMdHmm"}))
    public yMMMMd = memoize(() => this.g.dateFormatter({skeleton: "yMMMMd"}))
    public yMMMd = memoize(() => this.g.dateFormatter({skeleton: "yMMMd"}))
    public yMMMdd = memoize(() => this.g.dateFormatter({skeleton: "yMMMdd"}))
    public yMMMddHHmm = memoize(() => this.g.dateFormatter({skeleton: "yMMMddHHmm"}))
    public yMMdd = memoize(() => this.g.dateFormatter({skeleton: "yMMdd"}))
    public Hm = memoize(() => this.g.dateFormatter({skeleton: "Hm"}))
    public HHmm = memoize(() => this.g.dateFormatter({skeleton: "HHmm"}))
    public HHmmss = memoize(() => this.g.dateFormatter({skeleton: "HHmmss"}))
    public mmss = memoize(() => this.g.dateFormatter({skeleton: "mmss"}))
    public EEEEHm = memoize(() => this.g.dateFormatter({skeleton: "EEEEHm"}))
    public MMMcccd = memoize(() => this.g.dateFormatter({skeleton: "MMMcccd"}))
    public MMMMcd = memoize(() => this.g.dateFormatter({skeleton: "MMMMcd"}))
    public yMMMM = memoize(() => this.g.dateFormatter({skeleton: "yMMMM"}))
    public MMMcd = memoize(() => this.g.dateFormatter({skeleton: "MMMcd"}))
    public MMMMccccd = memoize(() => this.g.dateFormatter({skeleton: "MMMMccccd"}))
    public yyMMddHHmmss = memoize(() => this.g.dateFormatter({skeleton: "yMMddHHmmss"}))
    public yyMMddHHmm = memoize(() => this.g.dateFormatter({skeleton: "yMMddHHmm"}))
    // raw standalone formats
    public d = memoize(() => this.g.dateFormatter({skeleton: "d"}))
    public dd = memoize(() => this.g.dateFormatter({skeleton: "dd"}))
    public MM = memoize(() => this.g.dateFormatter({skeleton: "MM"}))
    public MMM = memoize(() => this.g.dateFormatter({skeleton: "MMM"}))
    public MMMraw = memoize(() => this.g.dateFormatter({raw: "MMM"}))
    public MMMM = memoize(() => this.g.dateFormatter({skeleton: "MMMM"}))
    public MMMMraw = memoize(() => this.g.dateFormatter({raw: "MMMM"}))
    public LL = memoize(() => this.g.dateFormatter({skeleton: "LL"}))
    public LLL = memoize(() => this.g.dateFormatter({skeleton: "LLL"}))
    public LLLL = memoize(() => this.g.dateFormatter({skeleton: "LLLL"}))
    public EE = memoize(() => this.g.dateFormatter({skeleton: "EE"}))
    public EEE = memoize(() => this.g.dateFormatter({skeleton: "EEE"}))
    public EEEE = memoize(() => this.g.dateFormatter({skeleton: "EEEE"}))
    public cc = memoize(() => this.g.dateFormatter({skeleton: "cc"}))
    public ccc = memoize(() => this.g.dateFormatter({skeleton: "ccc"}))
    public cccc = memoize(() => this.g.dateFormatter({skeleton: "cccc"}))
    public y = memoize(() => this.g.dateFormatter({skeleton: "y"}))
    // presets
    public dateFull = memoize(() => this.g.dateFormatter({date: "full"}))
    public dateLong = memoize(() => this.g.dateFormatter({date: "long"}))
    public dateMedium = memoize(() => this.g.dateFormatter({date: "medium"}))
    public dateShort = memoize(() => this.g.dateFormatter({date: "short"}))
    public timeFull = memoize(() => this.g.dateFormatter({time: "full"}))
    public timeLong = memoize(() => this.g.dateFormatter({time: "long"}))
    public timeMedium = memoize(() => this.g.dateFormatter({time: "medium"}))
    public timeShort = memoize(() => this.g.dateFormatter({time: "short"}))
    public dateTimeFull = memoize(() => this.g.dateFormatter({datetime: "full"}))
    public dateTimeLong = memoize(() => this.g.dateFormatter({datetime: "long"}))
    public dateTimeMedium = memoize(() => this.g.dateFormatter({datetime: "medium"}))
    public dateTimeShort = memoize(() => this.g.dateFormatter({datetime: "short"}))

    public skeleton = memoize((skeleton: string) => this.g.dateFormatter({skeleton}))
}

export namespace RelativeTimeFormatter {
    export type KnownFormat = keyof RelativeTimeFormatter
}
export class RelativeTimeFormatter extends Formatter {

    public getByName = (formatName: RelativeTimeFormatter.KnownFormat): Globalize.RelativeTimeFormatter => {
        if (!((formatName as string) in this)) {
            throw new Error(`"${formatName}" does not exists at RelativeTimeFormatter!`)
        } else {
            return (this as any)[formatName]()
        }
    }

    public secondLong = memoize(() => this.g.relativeTimeFormatter("second"))
    public minuteLong = memoize(() => this.g.relativeTimeFormatter("minute"))
    public hourLong = memoize(() => this.g.relativeTimeFormatter("hour"))
    public dayLong = memoize(() => this.g.relativeTimeFormatter("day"))
    public monthLong = memoize(() => this.g.relativeTimeFormatter("month"))
    public yearLong = memoize(() => this.g.relativeTimeFormatter("year"))
    public secondShort = memoize(() => this.g.relativeTimeFormatter("second", {form: "short"}))
    public minuteShort = memoize(() => this.g.relativeTimeFormatter("minute", {form: "short"}))
    public hourShort = memoize(() => this.g.relativeTimeFormatter("hour", {form: "short"}))
    public dayShort = memoize(() => this.g.relativeTimeFormatter("day", {form: "short"}))
    public monthShort = memoize(() => this.g.relativeTimeFormatter("month", {form: "short"}))
    public yearShort = memoize(() => this.g.relativeTimeFormatter("year", {form: "short"}))
    public secondNarrow = memoize(() => this.g.relativeTimeFormatter("second", {form: "narrow"}))
    public minuteNarrow = memoize(() => this.g.relativeTimeFormatter("minute", {form: "narrow"}))
    public hourNarrow = memoize(() => this.g.relativeTimeFormatter("hour", {form: "narrow"}))
    public dayNarrow = memoize(() => this.g.relativeTimeFormatter("day", {form: "narrow"}))
    public monthNarrow = memoize(() => this.g.relativeTimeFormatter("month", {form: "narrow"}))
    public yearNarrow = memoize(() => this.g.relativeTimeFormatter("year", {form: "narrow"}))
}

export namespace UnitFormatter {
    export type KnownFormat = keyof UnitFormatter
}
export class UnitFormatter extends Formatter {

    public getByName = (formatName: UnitFormatter.KnownFormat): Globalize.UnitFormatter => {
        if (!((formatName as string) in this)) {
            throw new Error(`"${formatName}" does not exists at UnitFormatter!`)
        } else {
            return (this as any)[formatName]()
        }
    }

    public byteShort = memoize(() => this.g.unitFormatter("byte", {form: "short"}))
    public kilobyteShort = memoize(() => this.g.unitFormatter("kilobyte", {form: "short"}))
    public megabyteShort = memoize(() => this.g.unitFormatter("megabyte", {form: "short"}))
    public gigabyteShort = memoize(() => this.g.unitFormatter("gigabyte", {form: "short"}))
    public terabyteShort = memoize(() => this.g.unitFormatter("terabyte", {form: "short"}))
    public yearShort = memoize(() => this.g.unitFormatter("year", {form: "short"}))
    public monthShort = memoize(() => this.g.unitFormatter("month", {form: "short"}))
    public weekShort = memoize(() => this.g.unitFormatter("week", {form: "short"}))
    public dayShort = memoize(() => this.g.unitFormatter("day", {form: "short"}))
    public hourShort = memoize(() => this.g.unitFormatter("hour", {form: "short"}))
    public minuteShort = memoize(() => this.g.unitFormatter("minute", {form: "short"}))
    public secondShort = memoize(() => this.g.unitFormatter("second", {form: "short"}))
    public yearLong = memoize(() => this.g.unitFormatter("year"))
    public monthLong = memoize(() => this.g.unitFormatter("month"))
    public weekLong = memoize(() => this.g.unitFormatter("week"))
    public dayLong = memoize(() => this.g.unitFormatter("day"))
    public hourLong = memoize(() => this.g.unitFormatter("hour"))
    public minuteLong = memoize(() => this.g.unitFormatter("minute"))
    public secondLong = memoize(() => this.g.unitFormatter("second"))
    public weekNarrow = memoize(() => this.g.unitFormatter("week", {form: "narrow"}))
}
