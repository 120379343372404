import {autobind} from "core-decorators"
import {Json} from "src/lib/types"
import {AbstractTabTransport, TabMessageEvent} from "./AbstractTabTransport"

const WebpackSharedWorker = require("same-origin-loader!sharedworker-loader!./SharedWorkerTransportWorker")

/**
 * Транспорт обмена сообщениями между вкладками через SharedWorker
 */
@autobind
export class SharedWorkerTransport extends AbstractTabTransport {

    protected worker: SharedWorker

    constructor(transportChannelName: string) {
        super(transportChannelName)
        this.worker = new WebpackSharedWorker(this.eventsScopeName);
        this.worker.onerror = (e: ErrorEvent) => {
            console.error(e)
        }
        this.worker.port.onmessage = (event: MessageEvent) => {
            this.emitMessage(event.data as TabMessageEvent);
        }
    }

    public static isSupported = () => {
        return Boolean(window.SharedWorker)
    }

    public broadcastMessage<T = Json>(message: TabMessageEvent<T>) {
        if (!message.timestamp) {
            message.timestamp = (new Date()).getTime()
        }
        this.worker.port.postMessage(message)
        message.isFromSameTab = true;
        this.emitMessage(message)
    }
}
