import {toPath} from "lodash"
import * as Collections from "src/lib/collections"
import {isApiErrorResponse} from "src/lib/entities/types"
import {isFetchError} from "src/lib/utils/fetch"
import {OwnError} from "src/lib/utils/formError"
import * as React from "react"
import {FormValue} from "src/lib/utils/form/FormValue"

export interface FormContract<T> extends WithErrors {
    readonly value: T
    readonly isDirty: boolean
    readonly isValid: boolean
    reset(): void
}

export interface WithErrors {
    readonly ownErrors: Collections.List<FormError>
    setErrors(fromError: FetchErrors | FormError): void
    removeErrors(): void
    showErrors(): void
    hideErrors(): void
    readonly isErrorsShown: boolean
}

export interface PlainForm<T> {
    get<K extends keyof T>(prop: K): T[K]
    set<K extends keyof T>(prop: K, value: T[K]): void
    renderValue<K extends keyof T>(
        prop: K,
        render: ((bind: BindObject<T[K]>) => JSX.Element | null)
    ): React.ComponentElement<FormValue.Props<T[K]>, FormValue<T[K]>>
    renderValue<K extends keyof T>(
        prop: K,
        extraOpts: {key?: React.Key, ref?: React.Ref<FormValue<T[K]>>},
        render: ((bind: BindObject<T[K]>) => JSX.Element | null)
    ): React.ComponentElement<FormValue.Props<T[K]>, FormValue<T[K]>>
    renderValue<K extends keyof T>(
        prop: K,
        extraOptsOrRender: {key?: React.Key, ref?: React.Ref<FormValue<T[K]>>} | ((bind: BindObject<T[K]>) => JSX.Element | null),
        render?: ((bind: BindObject<T[K]>) => JSX.Element | null)
    ): React.ComponentElement<FormValue.Props<T[K]>, FormValue<T[K]>>

}

export type FormError = {
    type: string
    message: string
}

export const ownErrorProp = "__reserved_for_own_error"

export function isFormError(v: any): v is FormError {
    return !!v && "type" in v && "message" in v
}

export type FetchErrors = {
    own: OwnError[]
    children: {[name: string]: FetchErrors}
}

export function createFetchErrors(error: OwnError, field?: string): FetchErrors {
    if (!field) {
        return {
            own: [error],
            children: {}
        }
    }
    return {
        own: [] as OwnError[],
        children: {
            [field]: { own: [error], children: {}}
        }
    }
}

export function getFetchError(value: any, messageFormatter?: (originalMessage: string) => string): FetchErrors {
    if (!value) {
        return null
    }
    if (isFetchError(value) && isApiErrorResponse(value.response.value)) {
        return value.response.value.meta.errors.reduce((errors, {field, message, type}) => {
            const error = {message: messageFormatter ? messageFormatter(message) : message, type}
            const path = toPath(field)
            // Собираем все ошибки без указания филда
            if (!path.length) {
                errors.own.push(error)
                return errors
            }
            let current = errors
            path.forEach((pathItem, index) => {
                if (!(pathItem in current.children)) {
                    current.children[pathItem] = { own: [], children: {} }
                }
                if (index === path.length - 1) {
                    current.children[pathItem].own.push(error)
                } else {
                    current = current.children[pathItem]
                }
            })
            return errors
        }, {own: [], children: {}} as FetchErrors)
    } else {
        throw value
    }
}

export interface BindObject<T> {
    readonly value: T
    onChange: (newValue: T) => void
    readonly errors: Collections.List<FormError>
    readonly isDirty: boolean
}

export type CollectionChildForm<T, F extends FormContract<T>> = {
    readonly form: F,
    readonly remove: () => void,
    readonly replace: (v: T) => void
}
