import * as ReduxActions from "redux-actions"
import * as Api from "src/lib/entities/api"

export const CHANGE_PROGRAM = "bums/common/report/edit/action/CHANGE_PROGRAM"

export type ChangeProgram = ReduxActions.Action<{
    programId: number
}>

export function changeProgram(selectedProgram: Api.Program): ChangeProgram {
    return {
        type: CHANGE_PROGRAM,
        payload: {
            programId: Api.isProgram(selectedProgram) ? Number(selectedProgram.id) : null
        }
    }
}
