import {PlainRoute} from "react-router"

export const commonRoutes: PlainRoute[] = [
    {
        path: "/newdashboard",
        async getComponent() {
            return (
                await import("./dashboard/containers/CDashboardContainer")
            ).CDashboardContainer
        },
    },
    {
        path: "/dashboard",
        async getComponent() {
            return (
                await import("./dashboard/containers/CDashboardContainer")
            ).CDashboardContainer
        },
    },
    {
        path: "/dashboard/promo",
        async getComponent() {
            return (
                await import("./onboarding/tutorial/COnboardingTutorial/COnboardingTutorial")
            ).COnboardingTutorial
        },
    },
];
