import {autobind} from "core-decorators"
import {debounce, Cancelable} from "lodash"
import {inject} from "src/lib/utils/inject"
import {BrowserTab, BrowserTabFactory} from "src/bums/common/browserTabs"
import {MainTabEvent} from "src/bums/common/browserTabs/MainTab"
import {TabMessageEvent} from "src/bums/common/browserTabs/transports/AbstractTabTransport"
import {notificationSound, SoundVariable, videocallIncomingSound} from "src/bums/common/stores/types"

const soundLatency: {[key: string]: number} = {
    notificationSound: 1000,
    videocallIncomingSound: 0
}

const soundPlayEventName = "play"
const soundStopEventName = "stop"

interface SoundEvent {
    type: SoundVariable
}


@autobind
export class SoundStore {

    protected $browserTab: BrowserTab

    protected $isMain = false

    protected $soundPlayTypeDebounce = new Map<SoundVariable, (() => void) & Cancelable>()

    protected $soundMap: Map<SoundVariable, HTMLAudioElement>

    constructor(@inject(BrowserTabFactory) browserTabFactory: BrowserTabFactory) {
        this.$soundMap = new Map<SoundVariable, HTMLAudioElement>([
            [notificationSound, new Audio(require("!url-loader!./media/message.mp3"))],
            [videocallIncomingSound, new Audio(require("!url-loader!./media/videocall_incoming.mp3"))]
        ])

        this.$browserTab = browserTabFactory.createBrowserTabChannel("sound")
        this.$browserTab.on<boolean>(MainTabEvent.main_tab_changed, this.initialize)
    }

    protected initialize(message: TabMessageEvent) {
        if (message.isMainTab) {
            if (this.$isMain) {
                return
            }

            this.$browserTab.on<SoundEvent>(soundPlayEventName, this.playListener)
            this.$browserTab.on<SoundEvent>(soundStopEventName, this.stopListener)
        } else if (this.$isMain) {
            this.$soundPlayTypeDebounce.forEach(handle => handle.cancel())
            this.$browserTab.off<SoundEvent>(soundPlayEventName, this.playListener)
            this.$browserTab.off<SoundEvent>(soundStopEventName, this.stopListener)
        }

        this.$isMain = message.isMainTab
    }

    protected playListener(message: TabMessageEvent<SoundEvent>) {
        const type = message.data.type

        if (!this.$isMain) {
            return
        }

        if (!this.$soundPlayTypeDebounce.has(type)) {
            this.$soundPlayTypeDebounce.set(type, debounce(async () => {
                try {
                    await this.$soundMap.get(type).play()
                } catch (e) {
                    return
                }
            }, soundLatency[type]))
        }

        this.$soundPlayTypeDebounce.get(type)()
    }

    protected stopListener(message: TabMessageEvent<SoundEvent>) {
        const type = message.data.type

        this.$soundMap.get(type).pause()
        this.$soundMap.get(type).currentTime = 0;
    }

    public play(type: SoundVariable) {
        if (this.$soundMap.has(type)) {
            void this.$browserTab.broadcastMessage<SoundEvent>({
                eventName: soundPlayEventName,
                data: {
                    type: type
                }
            })
        }
    }

    public stop(type: SoundVariable) {
        if (this.$soundMap.has(type)) {
            void this.$browserTab.broadcastMessage<SoundEvent>({
                eventName: soundStopEventName,
                data: {
                    type: type
                }
            })
        }
    }

}
