import * as ReduxActions from "redux-actions"
import {ApiErrorResponse} from "src/lib/entities/api"

export const FETCH_DATA_FAILED = "bums/trade/funnel/action/FETCH_DATA_FAILED"

export type FetchDataFailedAction = ReduxActions.Action<{
    error: ApiErrorResponse
}>

export function fetchDataFailed(error: ApiErrorResponse): FetchDataFailedAction {
    return {
        type: FETCH_DATA_FAILED,
        payload: {
            error
        }
    }
}
