/* tslint:disable */
import * as H from "../metadataHelper"
import {MailServerConfig} from "./mailServerConfig"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {Group} from "./group"
import {Program} from "./program"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"
import {IntegerField} from "./integerField"
import {EnumField} from "./enumField"

export interface Smtp extends H.BaseEntity {
    email?: string; // E-mail адрес
    isDefault?: boolean; // Используется по умолчанию
    footer?: string; // Футер письма
    serverConfig?: MailServerConfig; // Конфигурация почтового сервера
    visibleFor?: H.List<ContractorCompany | ContractorHuman | Employee | Group>; // Кому виден
    visibleForCount?: number;
    subject?: Employee|Program; // К чему прикреплена
    status?: Smtp.Status;
    userCreated?: Employee;
    id?: string; // Идентификатор
    contentType: "Smtp"; // Object type
}

export module Smtp {
    export const contentType: "Smtp" = "Smtp";
    export const endpoint = "/api/v3/smtp";
    export type Status = "disabled"|"enabled"|"error"|"unknown";
    export module Status {
        export const disabled: Status = "disabled";
        export const enabled: Status = "enabled";
        export const error: Status = "error";
        export const unknown: Status = "unknown";
    }
    export const newObject: Smtp = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const email = {contentType: "StringField" as "StringField", name: "email", isMultiple: false, isSortable: false, id: "Smtp.email", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isDefault = {contentType: "BoolField" as "BoolField", name: "isDefault", isMultiple: false, isSortable: false, id: "Smtp.isDefault", defaultValue: null as BoolField["defaultValue"]};
        export const footer = {contentType: "StringField" as "StringField", name: "footer", isMultiple: false, isSortable: false, id: "Smtp.footer", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const serverConfig = {contentType: "RefLinkField" as "RefLinkField", name: "serverConfig", isMultiple: false, isSortable: false, id: "Smtp.serverConfig", refContentType: H.List(["MailServerConfig"])};
        export const visibleFor = {contentType: "RefLinkField" as "RefLinkField", name: "visibleFor", isMultiple: true, isSortable: false, id: "Smtp.visibleFor", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const visibleForCount = {contentType: "IntegerField" as "IntegerField", name: "visibleForCount", isMultiple: false, isSortable: false, id: "Smtp.visibleForCount", defaultValue: null as IntegerField["defaultValue"]};
        export const subject = {contentType: "RefLinkField" as "RefLinkField", name: "subject", isMultiple: false, isSortable: false, id: "Smtp.subject", refContentType: H.List(["Employee", "Program"])};
        export const status = {contentType: "EnumField" as "EnumField", name: "status", isMultiple: false, isSortable: false, id: "Smtp.status", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["disabled", "enabled", "error", "unknown"])};
        export const userCreated = {contentType: "RefLinkField" as "RefLinkField", name: "userCreated", isMultiple: false, isSortable: false, id: "Smtp.userCreated", refContentType: H.List(["Employee"])};
    }
}

export function isSmtp(arg: any): arg is Smtp {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Smtp"
}
