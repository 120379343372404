import {fetchDataStarted, fetchDataSuccess, fetchDataFailed} from "./index"
import {DataObject} from "query-string";
import * as Collections from "src/lib/collections"
import {FunnelData, FunnelRow, PresetData} from "../entities"
import * as WebApi from "src/lib/entities/apiWeb"


export function fetchFunnelData(filters: DataObject) {
    interface ServerResponse {
        preset: PresetData
        rows: Array<FunnelRow>
        positive: number
        negative: number
        total: number
        totalPrice: number
        active: number
        currency: string
        cacheKey: string
    }
    return WebApi.apiFetch<ServerResponse>(
        "/BumsReport/SalesFunnel/funnelData.json",
        {queryParams: filters},
        [
            fetchDataStarted,
            response => {
                const data: FunnelData =  Object.assign({}, response, {
                    rows: Collections.List(response ? response.rows.map(row => {
                        // округлим до минут, но оставим значения в секундах
                        row.duration = Math.round(row.duration / 60) * 60
                        return row
                    }) : [])
                })
                return fetchDataSuccess(data)
            },
            fetchDataFailed
        ]
    )
}
