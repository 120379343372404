class LocalStorageMigrate {

    //Мигрируем сторадж первый раз, если он не доступен или версия уже проставлена то делать ничего не надо
    //Если версии нет, то потрём ненужные ключи и проставим версию в сторадж
    public static migration1() {
        if (!Boolean(window.localStorage)) {
            return
        }
        if (!!window.localStorage.getItem("mpVersion")) {
            return
        }
        for (let i = window.localStorage.length - 1 ; i >= 0; --i) {
            const key = window.localStorage.key(i)
            if (key === "bums_call_notifier_key" ||
                key === "sdf_storage_active_tab_key" ||
                key === "undefined" ||
                key.indexOf("WindowsCommunicator") === 0 ) {
                window.localStorage.removeItem(key)
            }
        }
        window.localStorage.setItem("mpVersion", "1")
    }
}

export function runMigrations () {
    LocalStorageMigrate.migration1()
}
