import {PlainRoute} from "react-router"

export const upgradeRoutes: PlainRoute[] = [
    {
        path: "/upgrades",
        async getComponent() {
            return (await import("./containers/CPageContainer")).CPageContainer
        },
    }
]
