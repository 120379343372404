import {PlainRoute} from "react-router"

export const dealSettingsRoutes: PlainRoute[] = [
    {
        path: "/LogicStm/Trigger/add*",
        async getComponent() {
            return (await import("./containers/CPageContainer")).CPageContainer
        },
    },
    {
        path: "/LogicStm/Trigger/edit(/:id)*",
        async getComponent() {
            return (await import("./containers/CPageContainer")).CPageContainer
        },
    },
]
