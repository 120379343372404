import {last} from "lodash"
import {BaseEntity, isBaseEntity} from "src/lib/entities/types"
import {
    ContractorCompany,
    ContractorHuman,
    Deal,
    Employee,
    Topic,
    Invoice,
    isBpmSettings,
    isComment,
    isContractor,
    isCrmFilter,
    isDeal,
    isDoc,
    isDocFolder,
    isDocVersion,
    isEmployee,
    isEmployeeFilter,
    isFile,
    isFinOperation,
    isInvoice,
    isLeadForm,
    isLoyaltyProgram,
    isMessage,
    isMessenger,
    isPayer,
    isProgram,
    isProject,
    isRelationLink,
    isReport,
    isSendingTemplate,
    isTask,
    isTaskFilter,
    isTodo,
    isTopic,
    isTradeFilter,
    isVacation,
    Project,
    Comment,
    Task, isTodoFilter
} from "src/lib/entities/api"
import {getEntityName} from "src/lib/entities/utils"
import {Todo} from "src/lib/entities/bums";


const hrefComparedWithTextInLinkExpr = /^<a.*href="(.+?)".*>(?:\1)<\/a>$/i
const linkTagExp = /^<a.*?>.*?<\/a>$/

/* tslint:disable:max-line-length */
const linkExp =  /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
/* tslint:enable:max-line-length */
const hrefInTagExp = /href="(.*?)"/
const protocolExp = /^https?:\/\//

interface AdditionalOptions {
    firstUnread?: boolean
    c?: string
    [index: string]: any
}

function addOptions(url: string, options: AdditionalOptions) {
    const keys = Object.keys(options)
    if (keys.length === 0) {
        return url
    }
    if (!options.firstUnread) {
        delete options.firstUnread
    }
    return [url].concat(keys.map((key: string) => {
        const value = options[key]
        if (key === "firstUnread") {
            return key
        } else {
            return key + value.toString()
        }
    })).join("#")
}

/**
 * Генерация url адреса на основе переданной сущности
 * @param entity сущность
 * @param options Дополнительные параметры
 * @returns {string}
 */
export function generateUrlByEntity(entity: BaseEntity, options: AdditionalOptions = {}): string {
    let url = ""

    if (isTask(entity) || isProject(entity)) {
        url = `/${entity.contentType.toLowerCase()}/${entity.id}/${entity.isTemplate ? "template" : "card"}/`
    } else if (isTodo(entity)) {
        url = `/event/${entity.id}/card/`
    } else if (isDeal(entity) && isProgram(entity.program) && entity.program.isBpm && isBpmSettings(entity.program.bmpSettings)) {
        url = `/bp/${entity.program.bmpSettings.slug}/card/${entity.id}`
    } else if (isTopic(entity) || isDeal(entity) || isInvoice(entity)) {
        url = `/${entity.contentType.toLowerCase()}s/${entity.id}/card/`
    } else if (isEmployee(entity) || isContractor(entity)) {
        url = `/${isEmployee(entity) ? "staff" : "crm"}/${entity.id}/card/`
    } else if (isPayer(entity)) {
        return generateUrlByEntity(entity.contractor, options)
    } else if (isDoc(entity) || isDocFolder(entity)) {
        url = `/docs/${isDoc(entity) ? "" : "list/?root="}${entity.id}`
    } else if (isDocVersion(entity)) {
        url = entity.file.path;
    } else if (isReport(entity)) {
        url = `/report/${entity.id}/card/`
    } else if (isVacation(entity)) {
        url =  `/vacation/list/#vacation${entity.id}`
    } else if (isFinOperation(entity)) {
        url = `/finance/operation/${entity.id}/card/`
    } else if (isTaskFilter(entity)) {
        url = `/task/filter/${entity.id}`;
    } else if (isCrmFilter(entity)) {
        url = `/crm/filter/${entity.id}`;
    } else if (isTodoFilter(entity)) {
        url = `/todo/filter/${entity.id}`;
    } else if (isTradeFilter(entity)) {
        const [programId, filterId] = entity.id.split("/")
        url = `/deals/list/${programId || (entity.program ? entity.program.id : "all")}/${filterId || "all"}`;
    } else if (isEmployeeFilter(entity)) {
        url = `/staff/filter/${entity.id}`;
    } else if (isLeadForm(entity)) {
        url = `/form/${entity.uuid}`
    } else if (isLoyaltyProgram(entity)) {
        url = `/autoscript/${entity.id}/card/`
    } else if (isSendingTemplate(entity)) {
        url = `/crm/sending/card/${entity.id}.html`
    } else if (isComment(entity)) {
        if (isBaseEntity(entity.subject) && entity.id) {
            options["c"] = entity.id
            return generateUrlByEntity(entity.subject, options)
        } else {
            return ""
        }
    } else if (isRelationLink(entity)) {
        url = (entity.origin && hrefComparedWithTextInLink(entity.origin) ? entity.representation : entity.origin) || ""
    } else if (isMessage(entity)) {
        url = `/BumsDiscuss/Message/card/${entity.id}.html`
    } else if (isMessenger(entity)) {
        url = `/settings/integrations/messengers/${entity.id}/card/`
    } else if (isFile(entity)) {
        url = entity.path;
    } else if (isProgram(entity)) {
        url = `/logic/program/${entity.id}/card`
    }

    return addOptions(url, options)
}

export function generateMessageCopyLink(entity: Comment) {
    if (isTopic(entity.subject) && entity.id) {
        return `/discuss/chat/${entity.subject.id}/#c${entity.id}`
    } else {
        return ""
    }
}

/**
 * Генерация названия ссылки на основе переданной сущности
 * @param entity - сущность
 * @param defaultName - название по умолчанию
 * @returns {string}
 */
export function generateUrlNameByEntity(entity: BaseEntity, defaultName = ""): string {
    if (!isBaseEntity(entity)) {
        return defaultName
    }
    if (isComment(entity) && entity.subject) {
        return generateUrlNameByEntity(entity.subject, defaultName)
    }
    const name = getEntityName(entity);
    return name === entity.contentType ? defaultName : name

}

/**
 * Генерация url адреса списка на основе переданного типа
 * @returns {string}
 */
export function generateListUrlByEntity(entity: BaseEntity) {
    const contentType = entity.contentType
    switch (contentType) {
        case Task.contentType:
            return "/task"
        case Project.contentType:
            return "/task/filter/project"
        case ContractorCompany.contentType:
        case ContractorHuman.contentType:
            return "/crm"
        case Invoice.contentType:
            return "/invoices"
        case Deal.contentType:
            return "/deals/list/"
        case Employee.contentType:
            return "/staff/list"
        case Todo.contentType:
            return "/time/"
        case Topic.contentType:
            return "/discuss/chat"
    }
    return ""
}


export function getQueryParam(name: string) {
    const regular = new RegExp(`[?&]${encodeURIComponent(name)}=([^&]*)`)
    if (regular.exec(location.search)) {
        return decodeURIComponent(name[1])
    }
    return ""
}

/**
 * Осуществялет переход на другую страницу.
 * Вынес в хелпер, чтобы можно было модифицировать в дальнейшем
 */
export function transitionToPage(url: string, newWindow = false) {
    if (newWindow) {
        window.open(url)
    } else {
        window.location.href = url
    }
}

export function isLink(text: string) {
    return text && linkExp.test(text)
}

export function hasInternalLink(url: string) {
    const currentHost = window.location.origin
    return url.indexOf(currentHost) === 0 || url.indexOf("/") === 0;
}

export function hrefComparedWithTextInLink(link: string) {
    return hrefComparedWithTextInLinkExpr.test(link)
}

export function isLinkTag(text: string) {
    return linkTagExp.test(text)
}

export function getHrefInTag(htmlText: string) {
    const match = htmlText.match(hrefInTagExp)
    return match ? match[1] : ""
}

export function normalizeUrl(url: string, withProtocol: boolean = true): string {
    if (url && url.charAt(0) !== "/" && url.indexOf("mailto:") === -1 && !protocolExp.test(url) && !url.includes("#")) {
        return withProtocol ? `http://${url}` : `//${url}`
    }
    return url
}

export function getUrlDataString (url: string, split = "?") {
    const parts = url.split(split)
    return parts.length > 1 ? `${split}${last(parts)}` : ""
}

// return parse url, exapmple https://lodash.com/docs/4.16.2/ => lodash.com/docs/4.16.2
export function getParseUrl(url: string) {
    const prefix = /^https?:\/\//i
    const lastSl = /\/$/
    url = url.replace(prefix, "").replace(lastSl, "")
    if (url.charAt(0) === "/") {
        url = window.location.hostname + url
    }
    return url
}

export function parseVersion(text: string) {
    try {
        const version = parseInt(text.split(".")[0].substr(1), 10)

        if (!version || isNaN(version)) {
            return 99999999
        }

        return version
    } catch (err) {
        return 1
    }
}

/* tslint:disable */
const WHITESPACES_REGEX = /\s+/g
const urlRex = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
const ipRex = /^(http[s]?:\/\/)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
/* tslint:enable */

export function isUrl(host: string) {
    return urlRex.test(host) || ipRex.test(host)
}

const underscopeReg = new RegExp("_", "g")

export function getHostFromAccount (account: string, double?: boolean) {
    if (!account) {
        return null
    }

    let host = account
        .replace(underscopeReg, "-")
        .replace(WHITESPACES_REGEX, "")
        .trim()

    if (host.indexOf("://") !== -1) {
        const strArr = host.split("://")
        if (strArr.length > 1) {
            host = strArr[1]
        }
    }

    if (double) {
        host = `http://${host}`
    } else {
        host = `https://${host}`
    }

    if (!isUrl(host)) {
        host = `${host}.megaplan.ru`
    }

    return host
}
