/**
 * Роутер для статического сайта хелпа
 */
import {Component} from "src/lib/components"
import {Router, PlainRoute, applyRouterMiddleware} from "react-router"
import {History} from "history"
import {helpRoutes} from "src/bums/help/routes"
import { useScroll } from "react-router-scroll"

const routes = helpRoutes.map((route: PlainRoute) => {
    /** вырезаем /help из пути */
    return {
        path: route.path === "/help" ? "/" : route.path.substr(5),
        getComponent: route.getComponent
    }
})

interface MegaplanRouterProps {
    history: History;
}
export default class MegaplanHelpRouter extends Component<MegaplanRouterProps, {}> {
    public render() {
        return <Router
            history={this.props.history}
            routes={routes}
            render={applyRouterMiddleware(useScroll())}
        />
    }
}
