import {Component} from "src/lib/components/Component/Component";
import {Router} from "src/lib/utils/router";
import {inject} from "src/lib/utils/inject"
import {UserStore} from "src/lib/entities/store/UserStore";
import {UserChangeAuthSubscription} from "src/bums/common/subscriptions/UserChangeAuthSubscription";
import {Ecomet} from "src/lib/services/Ecomet";
import DOM from "react-dom-factories"
import {UserRevokeAuthSubscription} from "src/bums/common/subscriptions/UserRevokeAuthSubscription";

export class CGlobalSubscriptions extends Component<{}, {}> {

    @inject(Router)
    protected router: Router

    @inject(Ecomet)
    protected ecomet: Ecomet

    @inject(UserStore)
    protected userStore: UserStore

    protected userChangeAuthSubscription: UserChangeAuthSubscription


    constructor(props: {}, context: {}) {
        super(props, context);
        this.initUserChangeAuthSubscribe()
    }

    protected initUserChangeAuthSubscribe() {
        this.userChangeAuthSubscription = new UserChangeAuthSubscription(this.ecomet, this.userStore, this.getUserChangeAuthHandler())
        this.userChangeAuthSubscription.subscribe();
        (new UserRevokeAuthSubscription(this.ecomet, this.userStore)).subscribe();
    }

    protected getUserChangeAuthHandler() {
        let matches = document.cookie.match(/SID1=(.+);/)
        let router = this.router

        return function (data: any) {
            if (matches[1] !== data.key) {
                router.push("/")
            }
        }
    }

    render() {
        return DOM.noscript()
    }
}
