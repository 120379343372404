import Promise from "bluebird"
import * as Megaplan from "src/megaplan"
import * as Api from "src/lib/entities/api"
import {RequestInit} from "src/lib/utils/fetch"
import {withApiStoreMiddleware} from "./apiStoreMiddleware"

export * from "./componentCreators"
export * from "./highOrderActions"

/**
 * Выполняет апи запрос.
 * Можно передать массив dispatchActions, который будут отправлены в начале запроса, в конце и при ошибке.
 * @param endpoint Адрес, куда отсылать запрос
 * @param requestInit Объект инициализации запроса
 * @param dispatchActions Массив действий, которые надо отправить.
 * @returns {function(Megaplan.Dispatch, Megaplan.State): Promise<TResult>}
 */
export function apiFetch<T>(
    endpoint: string,
    requestInit?: RequestInit,
    dispatchActions?: [
        () => any,
        (result: T) => any,
        (error: Api.ApiErrorResponse) => any
        ]
) {
    return (dispatch: Megaplan.Dispatch, getState: () => Megaplan.State) => dispatch(
        withApiStoreMiddleware(apiStore => {
            if (dispatchActions && dispatchActions[0]) {
                dispatch(dispatchActions[0]())
            }
            return apiStore.fetch<T>(endpoint, requestInit)
                .then(result => {
                    if (dispatchActions && dispatchActions[1]) {
                        dispatch(dispatchActions[1](result.value.data))
                    }
                    return result
                })
                .catch(error => {
                    if ((error instanceof Api.FetchError) && dispatchActions && dispatchActions[2]) {
                        dispatch(dispatchActions[2](error.response.value))
                    }
                    throw error // keep promise rejected
                })
        })
    )
}

/**
 * Реализует привязку промиза к функции по имени
 * типичный кейз применения - сохранение сущности на бекенд.
 * Если подряд будет выполнена несколько раз для одной и той же сущности с одним и тем же именем, то
 * выстроит вызовы в цепочку.
 * Принимает коллбек, который вызывает тогда, когда это действительно нужно: по окончании предыдущих промизов.
 * Коллбек должен вернуть промиз.
 * Частный случай можно подсмотреть в методе entityBasedFetch
 * @param entity
 * @param fetchName
 * @param createPromiseCb                                                               fetc
 * @returns {function(Megaplan.Dispatch, function(): Megaplan.State): *}
 */
export function entityBasedPromise<T>(
    entity: Api.BaseEntity,
    fetchName: string,
    createPromiseCb: () => Promise<T>
) {
    return (dispatch: Megaplan.Dispatch, getState: () => Megaplan.State) => dispatch(
        withApiStoreMiddleware(apiStore => apiStore.entityBasedPromise(
            entity,
            fetchName,
            createPromiseCb,
        ))
    )
}

/**
 * Выполняет апи запрос, привязанный к сущности.
 * Последовательный вызовы выстраивает в цепочку, как описа в доке к функции entityBasedPromise
 * @param entity
 * @param fetchName
 * @param endpoint
 * @param requestInit
 * @returns {function(Megaplan.Dispatch, function(): Megaplan.State): undefined}
 */
export function entityBasedFetch(
    entity: Api.BaseEntity,
    fetchName: string,
    endpoint: string,
    requestInit?: RequestInit
) {
    return (dispatch: Megaplan.Dispatch, getState: () => Megaplan.State) => dispatch(
        withApiStoreMiddleware(apiStore => apiStore.entityBasedFetch(
            entity,
            fetchName,
            endpoint,
            requestInit
        ))
    )
}


/**
 * Выполняет апи-запрос, который возвращает сущность или массив сущностей.
 * Дополнительно складывает его в сторейдж.
 * @param endpoint
 * @param requestInit
 * @returns {function(Megaplan.Dispatch, Megaplan.State): *}
 */
export function fetchEntities(
    endpoint: string,
    requestInit?: RequestInit
) {
    return (dispatch: Megaplan.Dispatch, getState: () => Megaplan.State) => dispatch(
        withApiStoreMiddleware(apiStore => apiStore.fetchEntities(
            endpoint,
            requestInit,
        ))
    )
}

/**
 * Выполняет загрузку именованного списка сущностей. Поддерживает пагинацию.
 * Список может быть разнотипным.
 *
 * @param listName
 * @param endpoint
 * @param options - опции загрузки списка, при их изменении список уничтожается и загружается заново
 * @param softOptions - "мягкие" опции загрузки списка, такие как лимит загрузки и т.д., которые не приводят к пересозданию списка
 * @param direction - направление загрузки следующей стрaницы "Next", "Prev"
 * @returns {function(Megaplan.Dispatch, function(): Megaplan.State): (undefined|Promise<TResult>|Promise<T>)}
 */
export function fetchList(
    listName: string,
    endpoint: string,
    options: Api.RequestOptions = {},
    softOptions: Api.RequestOptions = {},
    direction: "Next" | "Prev" = "Next"
) {
    return (dispatch: Megaplan.Dispatch, getState: () => Megaplan.State) => dispatch(
        withApiStoreMiddleware(apiStore => apiStore.fetchList(
            listName,
            endpoint,
            options,
            softOptions,
            direction,
        ))
    )
}

/**
 * Загружает сущность по переданной ссылке
 * @param entity
 * @param endpoint
 */
export function fetchFullEntity(
    entity: Api.BaseEntity,
    endpoint: string = Api.getEntityTypeEndpoint(entity)
) {
    if (!entity.id) {
        throw new Error("You can not fetch full entity without id")
    }
    return (dispatch: Megaplan.Dispatch, getState: () => Megaplan.State) => dispatch(
        withApiStoreMiddleware(apiStore => apiStore.fetchFullEntity(
            entity,
            endpoint,
        ))
    )
}

/**
 * Добавляет сущность в поле типа set.
 * Генерирует entityFetchState с названием getFetchNameInSet
 *
 * @param entityToAddTo
 * @param entityToAdd
 * @param fieldToAddTo
 * @param endpoint
 * @param requestEntity
 * @param prependEntity
 * @returns {function(Megaplan.Dispatch, function(): Megaplan.State): Promise<T>|Promise<T|U>}
 */
export function addEntityToSet(
    entityToAddTo: Api.BaseEntity,
    entityToAdd: Api.BaseEntity,
    fieldToAddTo: string,
    endpoint: string = Api.getListEndpoint(entityToAddTo, fieldToAddTo),
    requestEntity?: Api.BaseEntity,
    prependEntity?: boolean
) {
    return (dispatch: Megaplan.Dispatch, getState: () => Megaplan.State) => dispatch(
        withApiStoreMiddleware(apiStore => apiStore.addEntityToSet(
            entityToAddTo,
            entityToAdd,
            fieldToAddTo,
            endpoint,
            requestEntity,
            prependEntity,
        ))
    )
}

/**
 * Удаляет сущность из поля типа set
 *
 * @param entityToDeleteFrom У какой сущности удаляем
 * @param entityToDelete Какое значение set'а удаляем
 * @param fieldToDeleteFrom Из какого поля удаляем
 * @param endpoint Удаление напрямую по определенному endpoint'у
 */
export function deleteEntityFromSet(
    entityToDeleteFrom: Api.BaseEntity,
    entityToDelete: Api.BaseEntity,
    fieldToDeleteFrom: string,
    endpoint: string = Api.getListEndpoint(entityToDeleteFrom, fieldToDeleteFrom) +
    "/" + entityToDelete.contentType + "/" + entityToDelete.id
) {
    return (dispatch: Megaplan.Dispatch, getState: () => Megaplan.State) => dispatch(
        withApiStoreMiddleware(apiStore => apiStore.deleteEntityFromSet(
            entityToDeleteFrom,
            entityToDelete,
            fieldToDeleteFrom,
            endpoint,
        ))
    )
}

/**
 * Удаляет сущность. Удаляет насовсем, для этого у сущности должны быть описаны метаданные.
 * @param entity
 * @returns {function(Megaplan.Dispatch, function(): Megaplan.State): Promise<TResult>}
 */
export function deleteEntity(
    entity: Api.BaseEntity,
    endpoint: string = Api.getEntityTypeEndpoint(entity)
) {
    return (dispatch: Megaplan.Dispatch, getState: () => Megaplan.State) => dispatch(
        withApiStoreMiddleware(apiStore => apiStore.deleteEntity(
            entity,
            endpoint,
        ))
    )
}

/**
 * Обновляет сущность
 *
 * @param entity
 * @param endpoint
 * @param fetchName
 * @returns {function(Megaplan.Dispatch, function(): Megaplan.State): Promise<Api.BaseEntity[]>}
 */
export function update<T extends Api.BaseEntity>(
    entity: T,
    endpoint: string = Api.getEntityTypeEndpoint(entity),
    fetchName = "update"
) {
    return (dispatch: Megaplan.Dispatch, getState: () => Megaplan.State) => dispatch(
        withApiStoreMiddleware(apiStore => apiStore.update(
            entity,
            {endpoint, fetchName}
        ))
    )
}

export function registerNewEntity<T extends Api.BaseEntity>(
    entity: T
) {
    return (dispatch: Megaplan.Dispatch) =>
        dispatch(withApiStoreMiddleware(store => store.registerNewEntity(entity)))
}

export function listAddEntities(
    listName: string,
    entity: Api.BaseEntity | Api.BaseEntity[]
) {
    return (dispatch: Megaplan.Dispatch) =>
        dispatch(withApiStoreMiddleware(store =>
            store.listAppendEntities(listName, Array.isArray(entity) ? entity : [entity])
        ))
}

export function listPrependEntities(
    listName: string,
    entity: Api.BaseEntity | Api.BaseEntity[]
) {
    return (dispatch: Megaplan.Dispatch) =>
        dispatch(withApiStoreMiddleware(store =>
            store.listPrependEntities(listName, Array.isArray(entity) ? entity : [entity])
        ))
}

export function listRemoveEntities(
    listName: string,
    entity: Api.BaseEntity | Api.BaseEntity[]
) {
    return (dispatch: Megaplan.Dispatch) =>
        dispatch(withApiStoreMiddleware(store =>
            store.listRemoveEntities(listName, Array.isArray(entity) ? entity : [entity])
        ))
}

export function listResetEntities(
    listName: string,
) {
    return (dispatch: Megaplan.Dispatch) =>
        dispatch(withApiStoreMiddleware(store => {
            store.removeList(listName)
        }))
}

export function listRemove(
    listName: string,
) {
    return (dispatch: Megaplan.Dispatch) =>
        dispatch(withApiStoreMiddleware(store => {
            store.removeList(listName)
        }))
}
