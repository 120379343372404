/* tslint:disable */
import * as H from "../metadataHelper"
import {FieldSetting} from "./fieldSetting"
import {IntegerField} from "./integerField"
import {BoolField} from "./boolField"
import {StringField} from "./stringField"

export interface FloatField extends H.BaseEntity {
    minValue?: number;
    maxValue?: number;
    defaultValue?: number;
    isUnique?: boolean;
    isIndexable?: boolean; // Доступно для быстрого поиска
    name?: string; // Название поля
    hrName?: string; // Человекопонятное название поля
    type?: string; // Тип поля
    isRequired?: boolean; // Обязательное поле
    isMultiple?: boolean; // Множественное значение
    targetEntity?: string; // Целевая сущность
    settings?: H.List<FieldSetting>; // Массив настроек
    settingsCount?: number; // Количество настроек
    orderPos?: number; // Позиция поля при сортировке
    isSystem?: boolean; // Системное поле
    isSortable?: boolean; // Сортируемое поле
    owner?: H.BaseEntity; // Сущность владельца
    id?: string; // Идентификатор
    contentType: "FloatField"; // Object type
}

export module FloatField {
    export const contentType: "FloatField" = "FloatField";
    export const endpoint = "/api/v3/floatField";

    export const newObject: FloatField = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const minValue = {contentType: "IntegerField" as "IntegerField", name: "minValue", isMultiple: false, isSortable: false, id: "FloatField.minValue", defaultValue: null as IntegerField["defaultValue"]};
        export const maxValue = {contentType: "IntegerField" as "IntegerField", name: "maxValue", isMultiple: false, isSortable: false, id: "FloatField.maxValue", defaultValue: null as IntegerField["defaultValue"]};
        export const defaultValue = {contentType: "FloatField" as "FloatField", name: "defaultValue", isMultiple: false, isSortable: false, id: "FloatField.defaultValue", defaultValue: null as FloatField["defaultValue"]};
        export const isUnique = {contentType: "BoolField" as "BoolField", name: "isUnique", isMultiple: false, isSortable: false, id: "FloatField.isUnique", defaultValue: null as BoolField["defaultValue"]};
        export const isIndexable = {contentType: "BoolField" as "BoolField", name: "isIndexable", isMultiple: false, isSortable: false, id: "FloatField.isIndexable", defaultValue: null as BoolField["defaultValue"]};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "FloatField.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const hrName = {contentType: "StringField" as "StringField", name: "hrName", isMultiple: false, isSortable: false, id: "FloatField.hrName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const type = {contentType: "StringField" as "StringField", name: "type", isMultiple: false, isSortable: false, id: "FloatField.type", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isRequired = {contentType: "BoolField" as "BoolField", name: "isRequired", isMultiple: false, isSortable: false, id: "FloatField.isRequired", defaultValue: null as BoolField["defaultValue"]};
        export const isMultiple = {contentType: "BoolField" as "BoolField", name: "isMultiple", isMultiple: false, isSortable: false, id: "FloatField.isMultiple", defaultValue: null as BoolField["defaultValue"]};
        export const targetEntity = {contentType: "StringField" as "StringField", name: "targetEntity", isMultiple: false, isSortable: false, id: "FloatField.targetEntity", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const settings = {contentType: "RefLinkField" as "RefLinkField", name: "settings", isMultiple: true, isSortable: false, id: "FloatField.settings", refContentType: H.List(["FieldSetting"])};
        export const settingsCount = {contentType: "IntegerField" as "IntegerField", name: "settingsCount", isMultiple: false, isSortable: false, id: "FloatField.settingsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const orderPos = {contentType: "IntegerField" as "IntegerField", name: "orderPos", isMultiple: false, isSortable: false, id: "FloatField.orderPos", defaultValue: null as IntegerField["defaultValue"]};
        export const isSystem = {contentType: "BoolField" as "BoolField", name: "isSystem", isMultiple: false, isSortable: false, id: "FloatField.isSystem", defaultValue: null as BoolField["defaultValue"]};
        export const isSortable = {contentType: "BoolField" as "BoolField", name: "isSortable", isMultiple: false, isSortable: false, id: "FloatField.isSortable", defaultValue: null as BoolField["defaultValue"]};
        export const owner = {contentType: "RefLinkField" as "RefLinkField", name: "owner", isMultiple: false, isSortable: false, id: "FloatField.owner", refContentType: H.List(["BaseEntity"])};
    }
}

export function isFloatField(arg: any): arg is FloatField {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "FloatField"
}
