import * as ReduxActions from "redux-actions"

export const FETCH_DATA_START = "bums/common/report/card/action/FETCH_DATA_START"

export type FetchDataStartAction = ReduxActions.Action<void>

export function fetchDataStart(): FetchDataStartAction {
    return {
        type: FETCH_DATA_START,
    }
}
