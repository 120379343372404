import * as React from "react"
import * as Collections from "src/lib/collections"

import {BaseEntity, RequestOptions} from "./types"
import {EntityReadyCallback, ListRenderCallback} from "./components/types"

import {CEntity} from "./components/CEntity"
import CList from "./components/CList"

/**
 * Вспомогательная функция, которая создаёт CEntity компонент.
 * Позволяет более строготипизировать свойства и использовать дженерики (typescript не умеет в компоненты-дженерики)
 *
 * @param contentType
 * @param id
 * @param endpoint
 * @param loader
 */
export function renderEntity<T extends BaseEntity>(props: CEntity.Props) {
    return function(readyCallback: EntityReadyCallback<T>) {
        return React.createElement(
            CEntity,
            Object.assign({}, props, {readyCallback})
        );
    }
}

export {ListRenderProps, ListOptions} from "./components/types"
/**
 * Вспомогательная функция, которая создаёт CEntity компонент.
 * Позволяет более строготипизировать свойства и использовать дженерики (typescript не умеет в компоненты-дженерики)
 */
export function renderList<T extends BaseEntity>(props: {
    listName?: string
    endpoint: string
    initialCopyFromList?: string
    onEntitiesLoaded?: (entities: Collections.List<T>) => void
    onRenderComplete?: (entities: Collections.List<T>) => void
    options?: RequestOptions,
    softOptions?: RequestOptions,
    loadOnMount?: boolean,
    key?: React.Key
    onError?: (e?: Error) => void
    fullLoad?: boolean
}) {
    return function (renderCallback: ListRenderCallback<T>) {
        return React.createElement(
            CList,
            Object.assign({}, props, {renderCallback})
        )
    }
}
