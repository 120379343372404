import {PlainRoute} from "react-router"

export const applicationRoutes: PlainRoute[] = [
    {
        path: "/settings/application/install/:identifier",
        async getComponent() {
            return (
                await import("./components/CApplicationInstall")
            ).CApplicationInstall
        },
    }
];
