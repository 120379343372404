import Promise from "bluebird"
import axios, {AxiosRequestConfig, AxiosResponse, AxiosError} from "axios"
import ExtendableError from "es6-error"

const OAUTH_ENDPOINT = "/api/v3/auth/access_token"

export interface RequestInit {
    method?: "GET" | "PUT" | "POST" | "HEAD" | "DELETE"
    bodyEntity?: Object
    body?: Blob | FormData | string | {}
    queryParams?: any
    headers?: { [index: string]: string }
    responseType?: "arraybuffer" | "blob" | "document" | "json" | "text" | "stream"
    onUploadProgress?: (event: ProgressEvent) => void
    onDownloadProgress?: (event: ProgressEvent) => void
    credentials?: string
    timeout?: number
}

/**
 * Parsed decoded response
 */
export interface Response<V> {
    cached?: boolean
    url: string
    status: number
    statusText: string
    headers: Object
    /**
     * Decoded body of response
     */
    value: V
}

export class FetchError<V> extends ExtendableError {
    private _response: Response<V>
    constructor(response: Response<V>) {
        super(`${response.url}: ${response.status} ${response.statusText}`)
        this._response = response
    }

    get response(): Response<V> {
        return this._response
    }

    get status(): number {
        return this._response.status
    }
}

export function isFetchError<V>(v: any): v is FetchError<V> {
    return v != null && (v instanceof FetchError)
}

export function checkStatus<T>(response: Response<T>): Response<T> | never {
    if (response.status >= 200 && response.status < 400) {
        return response
    } else {
        throw new FetchError<T>(response)
    }
}

function deserialize(response: AxiosResponse): Promise<any> {
    if (response.config.url.indexOf(OAUTH_ENDPOINT) !== -1) {

        if (response.status !== 200) {
            return Promise.reject({
                data: {},
                meta: {status: response.status, errors: response}
            })
        }

        return Promise.resolve({
            data: response.data,
            meta: {
                status: response.status,
            }
        })
    }

    return Promise.resolve(response.data)
}

function createResponse<V>(response: AxiosResponse): Promise<Response<V>> {
    return deserialize(response).then((value: V) => ({
        url: response.config.url,
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        value: value,
    }) as Response<V>)
}

const currentTimezoneOffset = (new Date().getTimezoneOffset() * 60).toString() // в секундах

export function addRequestHeader(init: RequestInit, name: string, value: string) {
    init.headers = Object.assign({}, init.headers, {
        [name]: value
    })
}

export function rawFetch(config: AxiosRequestConfig) {
    return Promise.resolve(axios.request(config))
}

export default function fetch<V>(url: string, init?: RequestInit): Promise<Response<V>> {
    const config: AxiosRequestConfig = {url}
    if (init) {
        if (init.bodyEntity) {
            if (init.body) {
                throw new Error("Invalid fetch request init: you can not pass both `body` and `bodyEntity` items")
            }
            init.body = JSON.stringify(init.bodyEntity)
        }

        // TODO перепилить на передачу contentType'а (но не хочется его передавать, если в body - FormData).
        if (init.method && init.method.toUpperCase() !== "GET" && (typeof url !== "string" || url.indexOf("/api/v3") !== -1)) {
            addRequestHeader(init, "Content-Type", "application/json")
            // axios не позволяет отправить POST с контент-типом application/json с пустым body
            if (!init.body) {
                init.body = {}
            }
        }
        addRequestHeader(init, "X-Time-Zone", currentTimezoneOffset)
        Object.assign(config, init)

        if (init.body) {
            config.data = init.body
        }
        config.withCredentials = init.credentials !== "omit"
    }

    return rawFetch(config)
        .catch((e: AxiosError) => {
            if (!e.response) {
                throw new Error(`${e}\r\nAxiosRequestConfig: ${JSON.stringify(e.config)}`)
            }
            return e.response
        })
        .then<Response<V>>(createResponse)
        .then(checkStatus)

}

