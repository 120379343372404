import {autobind} from "core-decorators"
import {FetchError, isFetchError} from "src/lib/utils/fetch"
import {ApiError, isApiError, isApiErrorResponse, isApiResponse} from "src/lib/entities/types"

type SubscribeHandler = (error: ApiError, type?: string) => void

/**
 * Класс для подписки на ошибки API
 * Используйте из строго из ApiStore
 */
@autobind
export class ApiErrorSubscriber {

    protected subscribers = new Map<string, SubscribeHandler[]>()

    private fileHandlers(type: string, error: ApiError) {
        if (this.subscribers.has(type)) {
            this.subscribers.get(type).forEach(cb => cb(error, type))
        }
    }

    public add(type: string, handler: SubscribeHandler) {
        if (this.subscribers.has(type)) {
            const registered = this.subscribers.get(type)
            const exist = registered.find(registeredHandler => registeredHandler === handler)
            if (exist) {
                return
            } else {
                registered.push(handler)
            }
        } else {
            this.subscribers.set(type, [handler])
        }
    }

    public remove(type: string, handler: SubscribeHandler) {
        if (this.subscribers.has(type)) {
            const registered = this.subscribers.get(type)
            this.subscribers.set(type, registered.filter(registeredHandler => registeredHandler !== handler))
        }
    }

    public handleErrorResponse(error: FetchError<any>) {
        if (isFetchError(error)) {
            const { value } = error.response
            if (isApiResponse(value) && isApiErrorResponse(value)) {
                value.meta.errors.forEach((err: ApiError) => {
                    if (isApiError(err)) {
                        this.fileHandlers(err.type, err)
                    }
                })
            }
        }
    }
}

export namespace ApiErrorSubscriber {
    export type Handler = SubscribeHandler
}
