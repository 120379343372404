import {MapCache} from "lodash"

export class MemoizeCache implements MapCache {

    private objectStorage = new WeakMap<{}, {}>()
    private storage = new Map<{}, {}>()

    private getStorage(key: {}) {
        if (null !== key && (typeof key === "object" || typeof key === "function")) {
            return this.objectStorage
        } else {
            return this.storage
        }
    }

    delete(key: {}): boolean {
        return this.getStorage(key).delete(key)
    }

    get (key: {}): any {
        return this.getStorage(key).get(key)
    }

    has(key: {}): boolean {
        return this.getStorage(key).has(key)
    }

    set(key: {}, value: any) {
        return this.getStorage(key).set(key, value)
    }

    clear() {
        this.objectStorage = new WeakMap()
        this.storage = new Map()
    }
}
