import {Response, FetchError} from "../utils/fetch"
import {
    ApiResponse,
    ApiErrorResponse,
    isApiResponse,
    isApiErrorResponse,
    isApiErrorBulkResponse
} from "./types"


export function createResponse<T>(response: Response<ApiResponse<T>>): Response<ApiResponse<T>> {
    if (!isApiResponse(response.value)) {
        throw new Error("Unexpected server response: ApiResponse structure expected!")
    }

    return response
}

/**
 * Если в ошибке нет валидной APIv3 ошибки, необходимо ее добавить
 *
 * @param error
 *
 * @throws FetchError
 */
export function createErrorResponse<T>(error: FetchError<T>): Response<ApiResponse<T>> {
    if (error.response && error.response.value && !isApiErrorResponse(error.response.value)) {
        let errorResponse: ApiErrorResponse
        if (isApiErrorBulkResponse(error.response.value)) {
            errorResponse = {
                meta: {
                    status: error.response.status,
                    errors: error.response.value.data
                        .reduce((errors, response) => {
                            if (isApiErrorResponse(response)) {
                                errors = errors.concat(response.meta.errors)
                            } else {
                                errors.push({
                                    type: "FakeBulkError",
                                    message: "",
                                    field: "",
                                    trace: []
                                })
                            }
                            return errors
                        }, [])
                }
            }
        } else {
            errorResponse = {
                meta: {
                    status: error.response.status,
                    errors: [{
                        type: "Unknown",
                        message: `${error.response.status} ${error.response.statusText}`,
                        field: "",
                        trace: []
                    }]
                }
            }
        }
        error = new FetchError<T>(Object.assign(error.response, {value: errorResponse}))
    }
    throw error
}
