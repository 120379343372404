/* tslint:disable */
import * as H from "../metadataHelper"
import {ReportDisplaySetting} from "./reportDisplaySetting"
import {ReportConfig} from "./reportConfig"
import {Employee} from "./employee"
import {Group} from "./group"
import {ReportDesktopWidget} from "./reportDesktopWidget"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"

export interface Report extends H.BaseEntity {
    name?: string;
    description?: string;
    displaySetting?: ReportDisplaySetting;
    config?: ReportConfig;
    readers?: H.List<Employee | Group>;
    readersCount?: number;
    editors?: H.List<Employee | Group>;
    editorsCount?: number;
    desktopWidget?: ReportDesktopWidget;
    possibleActions?: H.List<Report.PossibleActions>;
    id?: string; // Идентификатор
    isFavorite?: boolean; // Избранная сущность или нет
    contentType: "Report"; // Object type
}

export module Report {
    export const contentType: "Report" = "Report";
    export const endpoint = "/api/v3/report";
    export type PossibleActions = "act_read";
    export module PossibleActions {
        export const act_read: PossibleActions = "act_read";
    }
    export const newObject: Report = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Report.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: false, id: "Report.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const displaySetting = {contentType: "RefLinkField" as "RefLinkField", name: "displaySetting", isMultiple: false, isSortable: false, id: "Report.displaySetting", refContentType: H.List(["ReportDisplaySetting"])};
        export const config = {contentType: "RefLinkField" as "RefLinkField", name: "config", isMultiple: false, isSortable: false, id: "Report.config", refContentType: H.List(["ReportConfig"])};
        export const readers = {contentType: "RefLinkField" as "RefLinkField", name: "readers", isMultiple: true, isSortable: false, id: "Report.readers", refContentType: H.List(["Employee", "Group"])};
        export const readersCount = {contentType: "IntegerField" as "IntegerField", name: "readersCount", isMultiple: false, isSortable: false, id: "Report.readersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const editors = {contentType: "RefLinkField" as "RefLinkField", name: "editors", isMultiple: true, isSortable: false, id: "Report.editors", refContentType: H.List(["Employee", "Group"])};
        export const editorsCount = {contentType: "IntegerField" as "IntegerField", name: "editorsCount", isMultiple: false, isSortable: false, id: "Report.editorsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const desktopWidget = {contentType: "RefLinkField" as "RefLinkField", name: "desktopWidget", isMultiple: false, isSortable: false, id: "Report.desktopWidget", refContentType: H.List(["ReportDesktopWidget"])};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "Report.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["act_read"])};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: false, id: "Report.isFavorite", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isReport(arg: any): arg is Report {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Report"
}
