import {observer} from "mobx-react"
import {observable, action, computed} from "mobx"
import * as React from "react"
import {Component} from "src/lib/components"
import {omit} from "lodash"

function defaultRenderError(error: Error) {
    if (process.env.NODE_ENV === "production") {
        return <span>{error.message}</span>
    } else {
        return <pre>{error.stack}</pre>
    }
}

export namespace CErrorHandler {
    export interface Props {
        children: React.ReactElement<any>
        renderError?: false | ((error: Error, props: {}) => JSX.Element)
    }
}

/**
 * Компонент - обработчик ошибок.
 * Позволяет вместо части дерева отрисовать ошибку.
 */
@observer
export class CErrorHandler extends Component<CErrorHandler.Props> {

    @observable
    private $errorCaught: Error | void

    @computed
    private get omittedProps() {
        return omit(this.props, "children", "renderError")
    }

    public render() {
        if (this.$errorCaught) {
            if (false === this.props.renderError) {
                return null
            }
            if (this.props.renderError) {
                return this.props.renderError(this.$errorCaught, this.omittedProps)
            }
            return defaultRenderError(this.$errorCaught)
        }
        if (!this.props.children) {
            return null
        }
        return React.cloneElement(
            React.Children.only(this.props.children),
            this.omittedProps
        )
    }

    @action
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        if (process.env.NODE_ENV === "production") {
            console.error(error)
        }
        this.$errorCaught = error
    }

    @action
    componentWillReceiveProps(nextProps: Readonly<CErrorHandler.Props>, nextContext: any) {
        if (nextProps.children !== this.props.children) {
            this.$errorCaught = void 0
        }
    }
}
