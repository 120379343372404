import {StorageInterface} from "src/lib/storages/StrorageInterface";

interface TimeToLiveData {
    timestamp: number,
    timeToLive: number
}

// надстройка над хранилищем, которая учитывает время жизни элементов
export class StorageWithTTL implements StorageInterface {
    private keyTimeToLiveMap = new Map<string, TimeToLiveData>()

    constructor(
        private realStorage: StorageInterface,
        private getCurrentTimestamp = Date.now
    ) {}

    setItem(key: string, value: string, timeToLive = 0) {
        this.realStorage.setItem(key, value)
        this.keyTimeToLiveMap.set(key, {timestamp: this.getCurrentTimestamp(), timeToLive: timeToLive})
    }

    getItem(key: string) {
        this.removeOverdueKeys()
        if (!this.keyTimeToLiveMap.has(key)) {
            return null
        }

        return this.realStorage.getItem(key)
    }

    removeItem(key: string) {
        this.realStorage.removeItem(key)
        this.keyTimeToLiveMap.delete(key)
    }

    clear() {
        this.keyTimeToLiveMap.forEach((_, key) => this.removeItem(key))
    }

    get length() {
        this.removeOverdueKeys()
        return this.keyTimeToLiveMap.size
    }

    key(index: number) {
        const keys = this.getKeysArray()

        return this.getItem(keys[index])
    }

    forEach(func: (value: string, key: string) => void) {
        const keys = this.getKeysArray()
        keys.forEach(key => {
            const value = this.getItem(key)
            if (this.keyTimeToLiveMap.has(key)) {
                func(value, key)
            }
        })
    }

    private getKeysArray() {
        const keys = []
        for (let key of this.keyTimeToLiveMap.keys()) {
            keys.push(key)
        }
        return keys
    }

    private removeOverdueKeys() {
        this.keyTimeToLiveMap.forEach((timeToLiveData, key) => {
            if (this.isTimeLiveOverdue(timeToLiveData)) {
                this.removeItem(key)
            }
        })
    }

    private isTimeLiveOverdue(timeToLiveData: TimeToLiveData) {
        return timeToLiveData.timeToLive !== 0 &&
            this.getCurrentTimestamp() > timeToLiveData.timeToLive + timeToLiveData.timestamp
    }
}
