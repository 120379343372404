import {isEqual} from "lodash"
import {autobind} from "core-decorators"
import {fromPromise, PromiseBasedObservable} from "src/lib/utils/fromPromise"
import {isList} from "src/lib/collections"
import {EntityStore} from "src/lib/entities/store/EntityStore"
import {UserSetting} from "src/lib/entities/api"
import {ApiStore} from "src/lib/entities/store/ApiStore"

/**
 * Пример использования:
 *
 * import {Component} from "src/lib/components"
 * import {UserSettingStore] from "src/bums/common/stores/UserSettingStore"
 *
 * class MyComponent extends Component<Props, {}> {
 *
 *      @inject
 *      private apiStore: ApiStore
 *
 *      private mySettingStore: UserSettingStore<boolean>
 *
 *      componentWillMount() {
 *         this.mySettingStore = new UserSettingStore(this.apiStore, "mySettingName", false)
 *      }
 *
 *      private toggleSetting() {
 *          this.mySettingStore.set(!this.mySettingStore.get().value)
 *      }
 *
 *      public render() {
 *          const setting = this.mySettingStore.get()
 *
 *          if (setting.state === "pending") {
 *              return <CSpinner />
 *          }
 *
 *          if (setting.state === "rejected") {
 *              return <div>Error message: {setting.value}</div>
 *          }

 *          if (setting.state === "fulfilled") {
 *              return <div onClick={this.toggleSetting}>Setting value: {setting.value}</div>
 *          }
 *
 *          // Или так:
 *
 *          return setting.case({
 *              pending: () => <CSpinner />
 *              rejected: (value) => <div>Error message: {value}</div>
 *              fulfilled: (value) => <div onClick={this.toggleSetting}>Setting value: {value}</div>
 *          })
 *
 *      }
 * }
 *
 */

function defaultValueFn(): null {
    return null
}

@autobind
export class UserSettingStore<T> {

    private $settingValue: PromiseBasedObservable<T>
    private $entityStore: EntityStore<UserSetting<T>>

    constructor(
        $apiStore: ApiStore,
        $settingIdFactory: () => string,
        $defaultValue: (() => (T | PromiseBasedObservable<T>)) = defaultValueFn
    ) {
        this.$entityStore = new EntityStore<UserSetting<T>>(
            $apiStore,
            () => {
                const id = $settingIdFactory()

                if (id === void 0) {
                    return void 0
                }

                return {
                    contentType: UserSetting.contentType,
                    id
                }
            }
        )

        this.$settingValue = fromPromise.map(
            this.$entityStore.get(),
            entity => entity && entity.value != null ? entity.value : $defaultValue()
        ) as PromiseBasedObservable<T>
    }

    public get() {
        return this.$settingValue
    }

    public async set(value: T): Promise<void> {
        const currentValue = await this.$settingValue.promise

        if (isEqual(isList(value) ? value.toArray() : value, isList(currentValue) ? currentValue.toArray() : currentValue)) {
            return
        }

        await this.$entityStore.update({value})
    }
}
