import {JanusJS} from "janus-gateway"

import {Participant, DeviceState} from "../../types"

export interface AuthParticipant {
    room: number
    token: string
    feed?: number
    private_id?: number
    opaqueId?: string
    videoCodec?: string
}

export interface Publisher {
    id: number
    display: string
    audio_codec: string
    video_codec: string
}

export interface VideoRoomMessage extends JanusJS.Message {
    videoroom?: "joined" | "destroyed" | "event" | "attached" | "leaving" | "talking" | "stopped-talking"
    id?: number
    private_id?: number
    publishers?: Publisher[]
    leaving?: number
    unpublished?: "ok" | number
    error_code?: number
    audio_codec?: any
    video_codec?: any
    substream: any
    temporal: any
    display: string
}


export interface Feed {
    stream: MediaStream | void
    participantEntity: Participant["entity"]
    id: number
    mediaControl: MediaControl
    videoStream: MediaStream | void
    audioStream: MediaStream | void
    type: "local" | "remote"
    attachMediaControl(mediaControl: MediaControl): void
    detach(): void
    attach(): void
}

export interface MediaControl {
    video: DeviceState
    audio: DeviceState
    talk: DeviceState
    changeVideoState(state: DeviceState, successCallback?: Function): void
    changeAudioState(state: DeviceState, successCallback?: Function): void
    changeTalkState(state: DeviceState, successCallback?: Function): void
    hasVideoPermission(): boolean
    hasAudioPermission(): boolean
}

export interface LocalFeed extends Feed {
    remoteFeeds: Feed[]
    muteVideo(): void
    unmuteVideo(): void
    muteAudio(): void
    unmuteAudio(): void
}

export interface RemoteFeed extends Feed {
    once(event: string, listener: Function): void
    on(event: string, listener: Function): void
}

export interface VideoRoomPluginHandle extends JanusJS.PluginHandle {
    isAudioMuted(): boolean
    muteAudio(): void
    unmuteAudio(): void
    detach(): void
    handleRemoteJsep(params: {
        jsep: JSEP,
        success?: () => void
        error?: (error: Error) => void
    }): void
    simulcastStarted: boolean
    webrtcStuff: {
        started: boolean,
        myStream: MediaStream | void
        streamExternal: any
        remoteStream: MediaStream | void
        mySdp: any
        pc: any
        dataChannel: RTCDataChannel | void
        dtmfSender: any
        trickle: boolean
        iceDone: boolean
        sdpSent: any
        volume: {
            value: any
            timer: any
        },
        bitrate: {
            value: any
            bsnow: any
            bsbefore: any
            tsnow: any
            tsbefore: any
            timer: any
        }
    },
}

export type JSEP = JanusJS.JSEP
export interface PluginOptions extends JanusJS.PluginOptions {
    ondata?: (text: string) => void
}

export interface Janus {
    attach(options: PluginOptions): void
    destroy(): void
}

export interface JanusAuth {
    server: string,
    token: string,
    room: number
}

export interface JanusAdapter {
    init(auth: JanusAuth, participant: Participant): void
    destroy(): void
    on(event: string, listener: Function): void
    once(event: string, listener: Function): void
}

export class JanusAdapterImplement implements JanusAdapter {
    public init(auth: JanusAuth, participant: Participant) {
        throw new Error("Janus Adapter not implement")
    }

    public destroy() {
        throw new Error("Janus Adapter not implement")
    }

    public once(...args: any[]) {
        throw new Error("Janus Adapter not implement")
    }

    public on(...args: any[]) {
        throw new Error("Janus Adapter not implement")
    }
}

export interface MediaStateEvent {
    video: DeviceState
    audio: DeviceState
    id: number
}

export function isMediaStateEvent(arg: any): arg is MediaStateEvent {
    return typeof arg === "object" && "video" in arg && "audio" in arg
}
