import * as Api from "src/lib/entities/api"
import {DeviceState, Participant, VideoCallEntity, VideocallEntityNoNormalized} from "./types"

export function createVideocallEntity(entity: VideocallEntityNoNormalized): VideoCallEntity {
    return {
        ...entity,
        contentType: "VideoCallEntity",
        id: Api.getGID(entity.entity),
    }
}


export function employeeToParticipant(employee: Api.Employee): Participant {
    return {
        entity: {
            contentType: Api.Employee.contentType,
            id: employee.id,
            firstName: employee.firstName,
            lastName: employee.lastName,
            avatar: !!employee.avatar ? {
                contentType: Api.File.contentType,
                id: employee.avatar.id,
                thumbnail: employee.avatar.thumbnail
            } : void 0,
            canLogin: employee.canLogin,
            isWorking: employee.isWorking,
            position: employee.position
        },
        state: {
            audio: "off",
            video: "off"
        }
    }
}

export function opaqueIdGenerator() {
    const charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let randomString = ""
    for (let i = 0; i < 12; i++) {
        const randomPoz = Math.floor(Math.random() * charSet.length)
        randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString
}


export function stopStream(stream: MediaStream) {
    stream.getTracks().forEach(track => track.stop())
}

export function convertDeviceStateToBool(state: DeviceState) {
    return state === "on" ? true : false
}
