import * as Api from "src/lib/entities/api"
import * as WebApi from "src/lib/entities/apiWeb"


export function fetchDealsData(cacheKey: string, stateId: string, page: number) {
    return WebApi.apiFetch<{[index: string]: Array<Api.DealInfo>}>(
        "/BumsReport/SalesFunnel/dealsData.json",
        {
            queryParams: {cacheKey, stateId, page},
        }
    )
}
