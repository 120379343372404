/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"

export interface DocFolder extends H.BaseEntity {
    name?: string;
    possibleActions?: H.List<DocFolder.PossibleActions>;
    id?: string; // Идентификатор
    isFavorite?: boolean; // Избранная сущность или нет
    contentType: "DocFolder"; // Object type
}

export module DocFolder {
    export const contentType: "DocFolder" = "DocFolder";
    export const endpoint = "/api/v3/docFolder";
    export type PossibleActions = "act_read";
    export module PossibleActions {
        export const act_read: PossibleActions = "act_read";
    }
    export const newObject: DocFolder = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "DocFolder.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "DocFolder.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["act_read"])};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: false, id: "DocFolder.isFavorite", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isDocFolder(arg: any): arg is DocFolder {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "DocFolder"
}
