/* tslint:disable */
import * as H from "../metadataHelper"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {EnumField} from "./enumField"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface ContactInfo extends H.BaseValue {
    type?: ContactInfo.Type; // Тип
    value?: string; // Значение
    comment?: string; // Комментарий
    isMain?: boolean; // Основной
    subject?: ContractorCompany|ContractorHuman|Employee; // Ссылка на того чья это ContactInfo
    contentType: "ContactInfo"; // Object type
}

export module ContactInfo {
    export const contentType: "ContactInfo" = "ContactInfo";
    export const endpoint = "/api/v3/contactInfo";
    export type Type = "email"|"icq"|"instagram"|"jabber"|"link"|"phone"|"skype"|"telegram"|"viber"|"whatsapp";
    export module Type {
        export const email: Type = "email";
        export const icq: Type = "icq";
        export const instagram: Type = "instagram";
        export const jabber: Type = "jabber";
        export const link: Type = "link";
        export const phone: Type = "phone";
        export const skype: Type = "skype";
        export const telegram: Type = "telegram";
        export const viber: Type = "viber";
        export const whatsapp: Type = "whatsapp";
    }
    export const newObject: ContactInfo = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const type = {contentType: "EnumField" as "EnumField", name: "type", isMultiple: false, isSortable: false, id: "ContactInfo.type", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["email", "icq", "instagram", "jabber", "link", "phone", "skype", "telegram", "viber", "whatsapp"])};
        export const value = {contentType: "StringField" as "StringField", name: "value", isMultiple: false, isSortable: false, id: "ContactInfo.value", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const comment = {contentType: "StringField" as "StringField", name: "comment", isMultiple: false, isSortable: false, id: "ContactInfo.comment", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isMain = {contentType: "BoolField" as "BoolField", name: "isMain", isMultiple: false, isSortable: false, id: "ContactInfo.isMain", defaultValue: null as BoolField["defaultValue"]};
        export const subject = {contentType: "RefLinkField" as "RefLinkField", name: "subject", isMultiple: false, isSortable: false, id: "ContactInfo.subject", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
    }
}

export function isContactInfo(arg: any): arg is ContactInfo {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ContactInfo"
}
