import {StorageInterface} from "src/lib/storages/StrorageInterface";

// хранилище элементов в памяти
export class MemoryStorage implements StorageInterface {
    private storage = new Map<string, string>()

    get length() {
        return this.storage.size
    }

    clear() {
        this.storage.clear()
    }

    key(index: number) {
        const keys = []
        for (let key of this.storage.keys()) {
            keys.push(key)
        }
        return this.storage.get(keys[index])
    }

    getItem(key: string) {
        return this.storage.get(key) || null
    }

    removeItem(key: string) {
        this.storage.delete(key)
    }

    setItem(key: string, data: string) {
        this.storage.set(key, data)
    }
}
