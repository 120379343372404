/* tslint:disable */
import * as H from "../metadataHelper"
import {DateOnly} from "./dateOnly"
import {Currency} from "./currency"
import {Money} from "./money"
import {Payer} from "./payer"
import {Employee} from "./employee"
import {OfferRow} from "./offerRow"
import {InvoiceTemplate} from "./invoiceTemplate"
import {FinOperation} from "./finOperation"
import {DateField} from "./dateField"
import {StringField} from "./stringField"
import {MoneyField} from "./moneyField"
import {FloatField} from "./floatField"
import {EnumField} from "./enumField"
import {IntegerField} from "./integerField"
import {DateTimeField} from "./dateTimeField"
import {BoolField} from "./boolField"

export interface Invoice extends H.BaseEntity {
    invoiceDate?: DateOnly; // Дата документа
    number?: string; // Номер счета
    name?: string;
    currency?: Currency; // Валюта счета
    sum?: Money; // Общая сумма счета
    taxTotal?: number; // Общий налог счета
    status?: Invoice.Status; // Статус счета
    possibleStatuses?: H.List<Invoice.PossibleStatuses>; // Возможные статусы счета
    possibleStatusesCount?: number; // Количество возможных статусов счета
    comment?: string; // Комментарий
    conditions?: string; // Условия
    recipient?: Payer; // Получатель
    payer?: Payer; // Плательщик
    consignee?: Payer; // Грузополучатель
    deadline?: DateOnly; // Срок оплаты счета
    userCreated?: Employee; // Пользователь, создавший счет
    actualPaymentDate?: Date; // Дата и время фактической оплаты счета
    rows?: H.List<OfferRow>; // Позиции счета
    rowsCount?: number; // Количество позиций в счете
    invoiceTemplate?: InvoiceTemplate<any>; // Шаблон счета
    publicDownloadLink?: string;
    id?: string; // Идентификатор
    isFavorite?: boolean; // Избранная сущность или нет
    financeOperations?: H.List<FinOperation>; // Финансовые операции
    financeOperationsCount?: number; // Количество финансовых операций
    contentType: "Invoice"; // Object type
}

export module Invoice {
    export const contentType: "Invoice" = "Invoice";
    export const endpoint = "/api/v3/invoice";
    export type Status = "created"|"drawn"|"paid"|"rejected";
    export module Status {
        export const created: Status = "created";
        export const drawn: Status = "drawn";
        export const paid: Status = "paid";
        export const rejected: Status = "rejected";
    }
    export type PossibleStatuses = "created"|"drawn"|"paid"|"rejected";
    export module PossibleStatuses {
        export const created: PossibleStatuses = "created";
        export const drawn: PossibleStatuses = "drawn";
        export const paid: PossibleStatuses = "paid";
        export const rejected: PossibleStatuses = "rejected";
    }
    export const newObject: Invoice = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const invoiceDate = {contentType: "DateField" as "DateField", name: "invoiceDate", isMultiple: false, isSortable: false, id: "Invoice.invoiceDate", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const number = {contentType: "StringField" as "StringField", name: "number", isMultiple: false, isSortable: false, id: "Invoice.number", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Invoice.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const currency = {contentType: "RefLinkField" as "RefLinkField", name: "currency", isMultiple: false, isSortable: false, id: "Invoice.currency", refContentType: H.List(["Currency"])};
        export const sum = {contentType: "MoneyField" as "MoneyField", name: "sum", isMultiple: false, isSortable: false, id: "Invoice.sum", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const taxTotal = {contentType: "FloatField" as "FloatField", name: "taxTotal", isMultiple: false, isSortable: false, id: "Invoice.taxTotal", defaultValue: null as FloatField["defaultValue"]};
        export const status = {contentType: "EnumField" as "EnumField", name: "status", isMultiple: false, isSortable: false, id: "Invoice.status", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["created", "drawn", "paid", "rejected"])};
        export const possibleStatuses = {contentType: "EnumField" as "EnumField", name: "possibleStatuses", isMultiple: true, isSortable: false, id: "Invoice.possibleStatuses", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["created", "drawn", "paid", "rejected"])};
        export const possibleStatusesCount = {contentType: "IntegerField" as "IntegerField", name: "possibleStatusesCount", isMultiple: false, isSortable: false, id: "Invoice.possibleStatusesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const comment = {contentType: "StringField" as "StringField", name: "comment", isMultiple: false, isSortable: false, id: "Invoice.comment", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const conditions = {contentType: "StringField" as "StringField", name: "conditions", isMultiple: false, isSortable: false, id: "Invoice.conditions", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const recipient = {contentType: "RefLinkField" as "RefLinkField", name: "recipient", isMultiple: false, isSortable: false, id: "Invoice.recipient", refContentType: H.List(["Payer"])};
        export const payer = {contentType: "RefLinkField" as "RefLinkField", name: "payer", isMultiple: false, isSortable: false, id: "Invoice.payer", refContentType: H.List(["Payer"])};
        export const consignee = {contentType: "RefLinkField" as "RefLinkField", name: "consignee", isMultiple: false, isSortable: false, id: "Invoice.consignee", refContentType: H.List(["Payer"])};
        export const deadline = {contentType: "DateField" as "DateField", name: "deadline", isMultiple: false, isSortable: false, id: "Invoice.deadline", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const userCreated = {contentType: "RefLinkField" as "RefLinkField", name: "userCreated", isMultiple: false, isSortable: false, id: "Invoice.userCreated", refContentType: H.List(["Employee"])};
        export const actualPaymentDate = {contentType: "DateTimeField" as "DateTimeField", name: "actualPaymentDate", isMultiple: false, isSortable: false, id: "Invoice.actualPaymentDate", defaultValue: null as DateTimeField["defaultValue"]};
        export const rows = {contentType: "RefLinkField" as "RefLinkField", name: "rows", isMultiple: true, isSortable: false, id: "Invoice.rows", refContentType: H.List(["OfferRow"])};
        export const rowsCount = {contentType: "IntegerField" as "IntegerField", name: "rowsCount", isMultiple: false, isSortable: false, id: "Invoice.rowsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const invoiceTemplate = {contentType: "RefLinkField" as "RefLinkField", name: "invoiceTemplate", isMultiple: false, isSortable: false, id: "Invoice.invoiceTemplate", refContentType: H.List(["InvoiceTemplate"])};
        export const publicDownloadLink = {contentType: "StringField" as "StringField", name: "publicDownloadLink", isMultiple: false, isSortable: false, id: "Invoice.publicDownloadLink", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: false, id: "Invoice.isFavorite", defaultValue: null as BoolField["defaultValue"]};
        export const financeOperations = {contentType: "RefLinkField" as "RefLinkField", name: "financeOperations", isMultiple: true, isSortable: false, id: "Invoice.financeOperations", refContentType: H.List(["FinOperation"])};
        export const financeOperationsCount = {contentType: "IntegerField" as "IntegerField", name: "financeOperationsCount", isMultiple: false, isSortable: false, id: "Invoice.financeOperationsCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isInvoice(arg: any): arg is Invoice {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Invoice"
}
