import * as ReduxActions from "redux-actions"
import {FunnelData} from "../entities";

export const FETCH_DATA_SUCCESS = "bums/trade/funnel/action/FETCH_DATA_SUCCESS"

export type FetchDataSuccessAction = ReduxActions.Action<{
    data: FunnelData
}>

export function fetchDataSuccess(data: FunnelData): FetchDataSuccessAction {
    return {
        type: FETCH_DATA_SUCCESS,
        payload: {
            data
        }
    }
}
