import * as ReduxActions from "redux-actions"
import * as Api from "src/lib/entities/api"

export const ADD_NEW_COLUMN = "bums/common/report/edit/action/ADD_NEW_COLUMN"

export type AddNewColumn = ReduxActions.Action<{
    column: Api.ReportConfigColumn
}>

export function addNewColumn(column: Api.ReportConfigColumn): AddNewColumn {
    return {
        type: ADD_NEW_COLUMN,
        payload: {
            column
        }
    }
}
