import {handleAction} from "redux-actions"
import {combineReducers} from "redux"

import * as Actions from "./actions"

export interface State {
    promoClosed: boolean
}

export const initialState: State = {
    promoClosed: false,
}

export const reducer = combineReducers({
    promoClosed: handleAction(Actions.CLOSE_REPORT_PROMO, () => true, false)
})
