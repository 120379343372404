import * as Api from "src/lib/entities/api"
import {List} from "src/lib/collections"

const avatarColors = [
    "#848AAB",
    "#94776D",
    "#D08CE0",
    "#F24990",
    "#885FDE",
    "#5F73DE",
    "#3692FF",
    "#4AABDE",
    "#39CC5B",
    "#86DE4B",
    "#F8C81C",
    "#FF813B",
    "#F24949",
    "#FF1818",
]

const imageExtensions = new Set([
    "png",
    "jpeg",
    "jpg"
])

export function topicCompanion(user: Api.User, entity: Api.Topic) {
    try {

        if (entity.visavis) {
            return entity.visavis
        }

        if (entity.isPrivate && entity.isDiscussable) {

            let participants: List<Api.Employee | Api.Contractor | Api.Group> = List(entity.participants)

            if (!participants || !participants.length) {
                participants = List([entity.owner])
            }

            if (participants) {
                let companion = participants.get(0)
                if (Api.isEntityEquals(companion, user) && participants.length > 1) {
                    companion = participants.get(1)
                }
                if (Api.isEmployee(companion) || Api.isContractor(companion)) {
                    return companion
                } else {
                    return entity
                }
            }
            return null
        } else {
            return entity
        }
    } catch (e) {
        return entity
    }
}

export const isSystemTopic = (entity: Api.Topic) => {
    return entity.isPrivate === true && entity.isDiscussable === false
}

export const getRandomAvatarColor = (entity: Api.Topic | Api.Employee | Api.Contractor) => {
    return !!entity.id ? avatarColors[Number(entity.id) % avatarColors.length] : avatarColors[0]
}

export const getMessageContactName = (props: Api.MessageContact | null) => {
    if (props) {
        return props.name || props.email
    } else {
        return null
    }
}

export const getMessagePeer = ({ isOutgoingForUser, to, from }: Api.Message) => {
    return getMessageContactName(isOutgoingForUser ? to?.first() : from)
}

export const extractInitialsFromContact = ({ name, email }: Api.MessageContact) => {
    if (name !== undefined && name !== "") {
        return name.split(" ").slice(0, 2).reduce((acc, word) => acc += word.charAt(0).toUpperCase(), "")
    } else {
        return email?.charAt(0).toUpperCase()
    }
}

export const getMessageInitials = ({ isOutgoingForUser, to, from }: Api.Message) => {
    const contact = isOutgoingForUser ? to?.first() : from

    if (Api.isMessageContact(contact)) {
        return extractInitialsFromContact(contact)
    } else {
        return ""
    }
}

export const getRandomAvatarMessageContactColor = (contact: Api.MessageContact) => {
    if (!contact?.email) {
        return avatarColors[0]
    }

    const mail = contact.email.slice(0, contact.email.indexOf("@"))

    const value = (mail.charCodeAt(0) + mail.charCodeAt(mail.length - 1)) * mail.length

    return avatarColors[value % avatarColors.length]
}

export const getRandomAvatarMessageColor = ({ isOutgoingForUser, to, from }: Api.Message) => {
    return getRandomAvatarMessageContactColor(isOutgoingForUser ? to?.first() : from)
}

export function isNativeImageExtension(extension: string): boolean {
    if (extension) {
        return imageExtensions.has(extension.toLowerCase())
    }
}

export function imageAttaches(attaches: List<Api.File>) {
    if (!attaches || !attaches.length) {
        return List()
    }

    return attaches.filter(attach => isNativeImageExtension(attach.extension))
}

export function otherFiles(attaches: List<Api.File>) {
    if (!attaches || !attaches.length) {
        return List()
    }

    return attaches.filter(attach => !isNativeImageExtension(attach.extension))
}

export const ALL_CHATS_FOLDER_ID = "0"

export interface CorporateDiscussion extends Api.Topic {
    isCorporateDiscussion: boolean
}

export function isCorporateDiscussion(arg: any): arg is CorporateDiscussion {
    return Boolean(arg) && typeof arg === "object" && arg.isCorporateDiscussion
}
