/**
 * Helper to decorate class member
 * Supports class plain methods, field syntax and lazy methods
 * @param {Function} decorate Actual decorator function.
 * Example:
 *   decoratedFn => function () {
 *     // do stuff...
 *     return Reflect.apply(decoratedFn, this, arguments);
 *   }
 * @returns {Function} Class member decorator ((target, name, descriptor) => newDescriptor)
 */
export default function makeClassMemberDecorator(decorate: Function) {
  return function decorateClassMember(target: any, name: PropertyKey, descriptor: PropertyDescriptor) {
    const { configurable, enumerable, value} = descriptor

    if (value) {
      return {
        configurable,
        enumerable,
        value: decorate(value),
      }
    }

    throw new Error(
      "called makeClassMemberDecorator on unsupported descriptor"
    )
  }
}
