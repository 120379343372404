/* tslint:disable */
import * as H from "../metadataHelper"
import {IntegerField} from "./integerField"

export interface DateOnly extends H.BaseValue {
    year?: number; // Год
    month?: number; // Месяц, отсчёт начинается с 0
    day?: number; // День
    contentType: "DateOnly"; // Object type
}

export module DateOnly {
    export const contentType: "DateOnly" = "DateOnly";
    export const endpoint = "/api/v3/dateOnly";

    export const newObject: DateOnly = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const year = {contentType: "IntegerField" as "IntegerField", name: "year", isMultiple: false, isSortable: false, id: "DateOnly.year", defaultValue: null as IntegerField["defaultValue"]};
        export const month = {contentType: "IntegerField" as "IntegerField", name: "month", isMultiple: false, isSortable: false, id: "DateOnly.month", defaultValue: null as IntegerField["defaultValue"]};
        export const day = {contentType: "IntegerField" as "IntegerField", name: "day", isMultiple: false, isSortable: false, id: "DateOnly.day", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isDateOnly(arg: any): arg is DateOnly {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "DateOnly"
}
