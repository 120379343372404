import {autobind} from "core-decorators"
import {action} from "mobx"
import {Feed} from "./Feed"
import {MediaControl} from "./MediaControl"
import {
    VideoRoomMessage,
    VideoRoomPluginHandle,
    RemoteFeed as RemoteFeedInterface,
    JSEP,
    PluginOptions
} from "./types"

@autobind
export class RemoteFeed extends Feed implements RemoteFeedInterface {

    public get type() {
        return "remote" as "remote"
    }

    protected getPluginOptions(): Partial<PluginOptions> {
        return {
            opaqueId: this.$auth.opaqueId,
            onremotestream: this.remoteStreamHandler
        }
    }

    protected successHandler(handle: VideoRoomPluginHandle) {
        super.successHandler(handle)
        this.$mediaControl = new MediaControl("on", "on", this.$participant.state.video, this.$participant.state.audio)
        this.registerUser()
    }

    protected messageHandler(message: VideoRoomMessage, jsep?: JSEP) {
        const event = message.videoroom

        switch (event) {
            case "attached":
                this.$id = message.id
                this.emit("attached", this)
                break
        }

        if (jsep) {
            this.$handle.createAnswer({
                jsep: jsep,
                media: {audioSend: false, videoSend: false, data: true},
                success: (remoteJsep: JSEP) => {
                    const messageStart = {request: "start", room: this.$auth.room}
                    this.$handle.send({message: messageStart, jsep: remoteJsep})
                }
            })
        }
    }

    protected registerUser() {
        const message = {
            request: "join",
            room: this.$auth.room,
            ptype: "subscriber",
            feed: this.$auth.feed,
            private_id: this.$auth.private_id,
            videoCodec: this.$auth.videoCodec
        }

        this.$handle.send({message})
    }

    @action
    protected remoteStreamHandler(stream: MediaStream) {
        this.$stream = stream
    }
}
