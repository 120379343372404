/* tslint:disable */
import * as H from "../metadataHelper"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"
import {FloatField} from "./floatField"

export interface LeadForm extends H.BaseEntity {
    title?: string;
    description?: string;
    enabled?: boolean;
    userCreated?: ContractorCompany|ContractorHuman|Employee;
    countPost?: number;
    uuid?: string;
    id?: string; // Идентификатор
    contentType: "LeadForm"; // Object type
}

export module LeadForm {
    export const contentType: "LeadForm" = "LeadForm";
    export const endpoint = "/api/v3/leadForm";

    export const newObject: LeadForm = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const title = {contentType: "StringField" as "StringField", name: "title", isMultiple: false, isSortable: false, id: "LeadForm.title", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: false, id: "LeadForm.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const enabled = {contentType: "BoolField" as "BoolField", name: "enabled", isMultiple: false, isSortable: false, id: "LeadForm.enabled", defaultValue: null as BoolField["defaultValue"]};
        export const userCreated = {contentType: "RefLinkField" as "RefLinkField", name: "userCreated", isMultiple: false, isSortable: false, id: "LeadForm.userCreated", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const countPost = {contentType: "FloatField" as "FloatField", name: "countPost", isMultiple: false, isSortable: false, id: "LeadForm.countPost", defaultValue: null as FloatField["defaultValue"]};
        export const uuid = {contentType: "StringField" as "StringField", name: "uuid", isMultiple: false, isSortable: false, id: "LeadForm.uuid", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isLeadForm(arg: any): arg is LeadForm {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "LeadForm"
}
