import {ListNamesByFilterGidStore} from "./ListNamesByFilterGidStore"
import {ApiStore} from "src/lib/entities/store/ApiStore"
import {CrmFilter, isContractor, isProject, isTask, TaskFilter, TreeNode, TradeFilter} from "src/lib/entities/api"
import {BaseEntity} from "src/lib/entities/types"
import {getGID} from "src/lib/entities/utils"
import {isDeal} from "src/lib/entities/bums";

/**
 * Данный класс занимается добавлением свежесозданной сущности в нужные списки.
 */
export class NewEntityToListPusher {
    constructor(
        private apiStore: ApiStore,
        private listNamesByFilterGidStore: ListNamesByFilterGidStore
    ) {
    }

    public async push(entity: BaseEntity) {
        // Здесь сознательно не учитываются сортировка списка. Считаем что пользователь ожидает новое видеть сверху
        // TODO Имеет смысл сбрасывать индикацию сортировки в этом случае

        if (isTask(entity) || isProject(entity)) {
            const lists = this.listNamesByFilterGidStore.get(getGID({
                contentType: TaskFilter.contentType,
                id: entity.isTemplate ? "templates" : isTask(entity) ? "all" : "project"
            }))
            for (const listName of lists) {
                const list = this.apiStore.getList(listName)
                // Костыли для поддержки обновления древовидного списка.
                if (list.loadedEndpoint && list.loadedEndpoint.includes("tree")) {
                    if (entity.parent === null) { // Мы не умеем вставлять не корневой узел дерева =(
                        await this.apiStore.listPrependEntities(listName, [{
                            ...TreeNode.newObject,
                            id: getGID(entity) + listName,
                            value: entity
                        }])
                    }
                } else {
                    await this.apiStore.listPrependEntities(listName, [entity])
                }
            }
        }

        if (isContractor(entity)) {
            for (const listName of this.listNamesByFilterGidStore.get(getGID({contentType: CrmFilter.contentType, id: "all"}))) {
                await this.apiStore.listPrependEntities(listName, [entity])
            }
        }

        if (isDeal(entity)) {
            const listNames = this.listNamesByFilterGidStore.getListNamesWithFilterGIDs(
                [
                    // все процессы
                    getGID({
                        contentType: TradeFilter.contentType,
                        id: "all/all"
                    }),
                    // все бизнес процессы / обычные сделки
                    getGID({
                        contentType: TradeFilter.contentType,
                        id: entity.program.isBpm
                            ? "bpm/all"
                            : "deals/all"
                    }),
                    // фильтр "Мои сделки" в конкретной схеме сделок
                    getGID({
                        contentType: TradeFilter.contentType,
                        id: `${entity.program.id}/all`
                    })
                ],
                true
            )
            for (const listName of listNames) {
                await this.apiStore.listPrependEntities(listName, [entity])
            }
        }
    }
}
