import {autobind} from "core-decorators"
import {EventEmitter} from "events"
import {intersection} from "lodash"
import {BrowserTab, BrowserTabFactory} from "src/bums/common/browserTabs"
import {Json} from "src/lib/types"
import {
    SubscriptionChannelName,
    ResubscriptionEventName,
    SubscriptionEventName,
    UnsubscriptionEventName,
    HandleEventName,
    SocketEvent,
    SocketDataEvent,
    SocketTransport,
    BaseListener,
    BroadcastEventName,
    BroadcastMessage,
} from "./types"

@autobind
export class BrowserEcomet implements SocketTransport {

    protected $browserTab: BrowserTab

    protected $eventEmitter = new EventEmitter()

    constructor(browserTabFactory: BrowserTabFactory) {
        this.$browserTab = browserTabFactory.createBrowserTabChannel(SubscriptionChannelName)

        this.$browserTab.on<SocketEvent>(ResubscriptionEventName, ({data}) => this.resubscribe(data))
        this.$browserTab.on<SocketDataEvent>(HandleEventName, ({data}) => this.handle(data))

        if (window && !window.bt_mode) {
            window.addEventListener("unload", this.checkCloseTab)
        }
    }

    protected checkCloseTab() {
        void this.$browserTab.broadcastMessage<SocketEvent>({
            eventName: UnsubscriptionEventName,
            data: {
                subscriptions: this.$eventEmitter.eventNames() as string[]
            }
        })
    }

    public subscribe<T = Json>(subscriptions: string | string[], listener: BaseListener<T>) {
        subscriptions = Array.isArray(subscriptions) ? subscriptions : [subscriptions]

        subscriptions.forEach(subscription => {
            this.$eventEmitter.addListener(subscription, listener)
        })

        void this.$browserTab.broadcastMessage<SocketEvent>({
            eventName: SubscriptionEventName,
            data: {
                subscriptions,
            }
        })
    }

    public broadcast<T = Json>(message: BroadcastMessage): void {
        void this.$browserTab.broadcastMessage<BroadcastMessage>({
            eventName: BroadcastEventName,
            data: message
        })
    }

    public unsubscribe<T = Json>(subscriptions: string | string[], listener: BaseListener<T>) {
        subscriptions = Array.isArray(subscriptions) ? subscriptions : [subscriptions]

        subscriptions.forEach(subscription => {
            this.$eventEmitter.removeListener(subscription, listener)
        })

        void this.$browserTab.broadcastMessage<SocketEvent>({
            eventName: UnsubscriptionEventName,
            data: {
                subscriptions,
            }
        })
    }

    public unsubscribeAll() {
        const events = this.$eventEmitter.eventNames() as string[]
        this.$eventEmitter.removeAllListeners()

        void this.$browserTab.broadcastMessage<SocketEvent>({
            eventName: UnsubscriptionEventName,
            data: {
                subscriptions: events
            }
        })
    }

    protected resubscribe(data: SocketEvent) {
        const currentSubscriptions = this.$eventEmitter.eventNames() as string[]

        const subscriptions = data.subscriptions.length === 0
            ? currentSubscriptions
            : intersection(data.subscriptions, currentSubscriptions)

        if (subscriptions.length === 0) {
            return
        }

        void this.$browserTab.broadcastMessage<SocketEvent>({
            eventName: SubscriptionEventName,
            data: {
                subscriptions,
            }
        })
    }

    protected handle(data: SocketDataEvent) {
        data.subscriptions.forEach(subscription => {
            this.$eventEmitter.emit(subscription, data.data)
        })
    }
}
