/* tslint:disable */
import * as H from "../metadataHelper"
import {LayoutSetting} from "./layoutSetting"
import {IntegerField} from "./integerField"

export interface UserInterfaceLayout<T1> extends H.BaseEntity {
    desktop?: LayoutSetting;
    tablet?: LayoutSetting;
    cards?: H.List<T1>;
    cardsCount?: number;
    id?: string; // Идентификатор
    contentType: "UserInterfaceLayout"; // Object type
}

export module UserInterfaceLayout {
    export const contentType: "UserInterfaceLayout" = "UserInterfaceLayout";
    export const endpoint = "/api/v3/userInterfaceLayout";

    export const newObject: UserInterfaceLayout<any> = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const desktop = {contentType: "RefLinkField" as "RefLinkField", name: "desktop", isMultiple: false, isSortable: false, id: "UserInterfaceLayout.desktop", refContentType: H.List(["LayoutSetting"])};
        export const tablet = {contentType: "RefLinkField" as "RefLinkField", name: "tablet", isMultiple: false, isSortable: false, id: "UserInterfaceLayout.tablet", refContentType: H.List(["LayoutSetting"])};
        export const cardsCount = {contentType: "IntegerField" as "IntegerField", name: "cardsCount", isMultiple: false, isSortable: false, id: "UserInterfaceLayout.cardsCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isUserInterfaceLayout(arg: any): arg is UserInterfaceLayout<any> {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "UserInterfaceLayout"
}
