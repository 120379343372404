import {autobind} from "core-decorators"
import {computed} from "mobx"
import {Location} from "history"
import {InjectedRouter} from "react-router"
import {normalizeQueryParams} from "src/bums/common/CMainMenu/utils"
import {getSectionNameForCalendarByPeriod} from "src/bums/todo/utils";
import {TodoListType} from "src/bums/todo/list/types"

const DAY_QUERY_STRING = "day="
export const PERIOD_SETTING_NAME = "calendar_period_setting"

@autobind
export class TodoLocationStore {
    constructor(
        private type: () => TodoListType,
        private location: () => Location,
        private $router: InjectedRouter
    ) {}

    @computed
    public get periodName() {
        return this.type()
    }

    // TODO затирает все другие параметры в location.search если начинаем листать дни - мб дописать их сохранение если будет потребность
    public setDateRoute(setting: {periodName?: string, startDate?: Date}) {
        const startDate = setting.startDate ? setting.startDate : this.startDate
        const stringDate = startDate
            ? startDate.toFormat("YYYY-MM-DD")
            : ""
        const periodName = setting.periodName ? setting.periodName : this.periodName
        const sectionName = getSectionNameForCalendarByPeriod(periodName)
        this.$router.push(`/${sectionName}/${periodName}/?${DAY_QUERY_STRING}${stringDate}`)
    }

    public setPeriodName(periodName: string) {
        localStorage?.setItem(PERIOD_SETTING_NAME, periodName)
        this.setDateRoute({periodName})
    }

    public clearQueryParams() {
        this.$router.replace(this.location().pathname)
    }

    public setStartDate(startDate: Date) {
        this.setDateRoute({startDate})
    }

    @computed
    public get normalizeQueryParams() {
        return normalizeQueryParams(this.location().search)
    }

    @computed
    public get startDate() {
        let stringDate = this.normalizeQueryParams.find(item => item.includes(DAY_QUERY_STRING))?.replace(DAY_QUERY_STRING, "")
        if (stringDate) {
            return new Date(stringDate.replace(DAY_QUERY_STRING, ""))
        }
        return new Date()
    }
}
