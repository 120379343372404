import {action, computed, observable, runInAction} from "mobx"
import {inject} from "src/lib/utils/inject"
import {List} from "src/lib/collections"
import * as Api from "src/lib/entities/bums"
import { User, hasRights } from "src/lib/entities/types"
import { ApiStore } from "src/lib/entities/store/ApiStore"

export interface UserStoreInterface {
    user: User
    userOrNull: User | null
    getSetting: (id: string, scope?: string) => Api.UserSetting<any> | Api.SystemSetting<any>
    isGuest: boolean
    phone: Api.ContactInfo
    email: Api.ContactInfo
    readonly uid: string | void
}

export class UserStore implements UserStoreInterface {

    @observable
    private $user: User | void

    @observable
    public isLoaded = false

    public constructor(@inject(ApiStore) private apiStore: ApiStore) {}

    @computed
    public get user() {
        if (!this.$user) {
            throw new Error("UserStore is not initialized. Please await for initialize() method")
        }

        return this.$user
    }

    @computed
    public get userOrNull() {
        if (!this.$user) {
            return null
        }

        return this.$user
    }

    public getSetting(id: string, scope?: string) {
        const user = this.user as Api.Employee;
        return !!user.settings && user.settings.find((value) => {
            if (scope && Api.isSystemSetting(value)) {
                if (value.scope !== scope) {
                    return false;
                }
            }

            return value.id === id
        })
    }

    @computed
    public get isGuest() {
        return Api.isContractorHuman(this.user) && this.user.isGuestAccessEnabled
    }

    @computed
    public get isAdmin() {
        return hasRights(this.userOrNull, "employee_groups")
    }

    @action
    public async initialize() {
        try {
            const result = await this.apiStore.fetchEntities<User>("/api/v3/currentUser")
            runInAction(() => {
                const user = result.value.data[0]
                this.$user = this.apiStore.getEntity({contentType: user.contentType, id: user.id} as User)
            })
        } catch (err) {
            throw err
        } finally {
            runInAction(() => {
                this.isLoaded = true
            })
        }
    }

    public get uid() {
        const user = this.userOrNull
        if (user) {
            return String(user.uid)
        }
        return null
    }

    private getContactList(type: string): List<Api.ContactInfo> {
        const user = this.userOrNull
        if (user && user.contactInfo) {
            if (Api.isEmployee(user)) {
                return user.contactInfo.filter(contact => {
                    return contact.type === type
                }) as List<Api.ContactInfo>
            } else {
                return user.contactInfo.filter(contact => {
                    return Api.isContactInfo(contact) && contact.type === type
                }) as List<Api.ContactInfo>
            }
        } else {
            return List()
        }
    }

    @computed
    public get phone() {
        return this.getContactList("phone").reverse()
            .find((cont: Api.ContactInfo, index: number, array?: any) => cont.isMain || index === array.length - 1)
    }

    @computed
    public get email() {
        return this.getContactList("email").reverse()
            .find((cont: Api.ContactInfo, index: number, array?: any) => cont.isMain || index === array.length - 1)
    }

    @computed
    public get isNeedName() {
        return this.user
            && this.email
            && this.user.firstName === this.email.value
            && this.user.lastName === this.email.value
    }
}

