import {inject} from "src/lib/utils/inject"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {MessageDescriptor} from "src/lib/utils/intl/Intl"

interface IntlReplacerInterface {
    check(descriptorId: string): boolean
    getReplacingDescriptor(descriptorId: string): MessageDescriptor
}

const messages = require("./messages.yml")

export class IntlReplacer implements IntlReplacerInterface {

    constructor(
        @inject(FeatureStore) private featureStore: FeatureStore
    ) {
    }

    public check(descriptorId: string) {
        return descriptorId in messages && this.featureStore.isAvailable("logic.stm.b_p_m_module")
    }

    public getReplacingDescriptor(descriptorId: string): MessageDescriptor {
        return messages[descriptorId]
    }

}
