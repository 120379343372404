import {DateOnly, isDateOnly} from "src/lib/entities/bums"

/**
 * Date -> DateOnly
 *
 * @param src
 * @returns {{contentType: "DateOnly", year: number, month: number, day: number}}
 * @constructor
 */
export function DateToDateOnly(src: Date): DateOnly | null {
    return src === null ? null : {
        contentType: DateOnly.contentType,
        year: src.getFullYear(),
        month: src.getMonth(),
        day: src.getDate()
    }
}

/**
 * DateOnly -> Date
 *
 * @param src
 * @returns {Date}
 * @constructor
 */
export function DateOnlyToDate(src: DateOnly | null): Date | null {
    if (isDateOnly(src)) {
        if ("number" !== typeof src.year || "number" !== typeof src.month || "number" !== typeof src.day) {
            return null
        }
        return new Date(src.year, src.month, src.day, 0, 0, 0, 0)
    }
    return null
}
