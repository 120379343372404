/* tslint:disable */
import * as H from "../metadataHelper"
import {EnumField} from "./enumField"
import {FloatField} from "./floatField"

export interface Discount extends H.BaseValue {
    type?: Discount.Type; // Тип скидки - конкретное число или проценты
    valueInMain?: number; // Значение в базовой валюте
    contentType: "Discount"; // Object type
}

export module Discount {
    export const contentType: "Discount" = "Discount";
    export const endpoint = "/api/v3/discount";
    export type Type = "absolute"|"percent";
    export module Type {
        export const absolute: Type = "absolute";
        export const percent: Type = "percent";
    }
    export const newObject: Discount = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const type = {contentType: "EnumField" as "EnumField", name: "type", isMultiple: false, isSortable: false, id: "Discount.type", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["absolute", "percent"])};
        export const valueInMain = {contentType: "FloatField" as "FloatField", name: "valueInMain", isMultiple: false, isSortable: false, id: "Discount.valueInMain", defaultValue: null as FloatField["defaultValue"]};
    }
}

export function isDiscount(arg: any): arg is Discount {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Discount"
}
