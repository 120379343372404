import {BaseEntity} from "src/lib/entities/types";
import {EntitiesStorageInterface} from "src/lib/entities/store/EntitiesStorage/EntitiesStorageInterface";

// декоратор над стораджем, позволяет переопределить только нужные методы
export class DecoratedEntitiesStorage implements EntitiesStorageInterface {
    constructor(
        private $storage: EntitiesStorageInterface
    ) {

    }

    async save(entities: BaseEntity[]) {
        return this.$storage.save(entities)
    }

    async load(entityLinks: BaseEntity[]): Promise<BaseEntity[]> {
        return this.$storage.load(entityLinks)
    }

    async remove(entityLink: BaseEntity) {
        return this.$storage.remove(entityLink)
    }

    async clear() {
        return this.$storage.clear()
    }
}
