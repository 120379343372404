import * as ReduxActions from "redux-actions"
import {TableSortOrderBy} from "../../entities"

export const CHANGE_SORTING_DIRECTION = "bums/common/report/edit/action/CHANGE_SORTING_DIRECTION"

export type ChangeSortingDirection = ReduxActions.Action<{
    sortingDirection: TableSortOrderBy
}>

export function changeSortingDirection(sortingDirection: TableSortOrderBy): ChangeSortingDirection {
    return {
        type: CHANGE_SORTING_DIRECTION,
        payload: {
            sortingDirection
        }
    }
}
