import * as Redux from "redux"
import {ApiStore} from "./store/ApiStore"

export interface ApiStoreMarkFn<T> {
    (store: ApiStore): T
}

export function withApiStoreMiddleware<T>(cb: ApiStoreMarkFn<T>) {
    return {
        __API_STORE_MARK: cb
    }
}

export function createApiStoreMiddleware(apiStore: ApiStore): Redux.Middleware {
    return (store: Redux.Store<any>) =>
        (next: Redux.Dispatch<any>) =>
            (action: any) => {
                if (
                    action &&
                    "object" === typeof action &&
                    "__API_STORE_MARK" in action &&
                    "function" === typeof action.__API_STORE_MARK
                ) {
                    return action.__API_STORE_MARK(apiStore)
                } else {
                    return next(action as any)
                }
            }
}

export interface Dispatch {
    <T>(action: {__API_STORE_MARK: ApiStoreMarkFn<T>}): T
}
