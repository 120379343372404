import * as Actions from "./actions/index"
import {handleActions} from "redux-actions"
import {FunnelData, FunnelRow, DealsData} from "./entities"
import * as Collections from "src/lib/collections"
import LoadState from "src/lib/utils/loadState"
import {DealInfo} from "src/lib/entities/api"

export interface State {
    loadingState: LoadState
    contentType?: number
    backUrl?: string
    programAddUrl?: string
    dealAddUrl?: string
    showPromo?: boolean
    visible?: boolean
    filters: any
    data: FunnelData,
    dealsData: DealsData
    expandedRowKeys: Array<string>
}

const defaultState: State = {
    loadingState: LoadState.None(),
    filters: null,
    data: {
        rows: Collections.List<FunnelRow>(),
        positive: 0,
        negative: 0,
        total: 0,
        totalPrice: 0,
        active: 0,
        currency: "",
        preset: {
            success: 0,
            failed: 0,
            newbie: 0,
            benefit: 0,
            missing: 0
        },
        cacheKey: null,
    },
    dealsData: Collections.Map<string, Array<DealInfo>>(),
    expandedRowKeys: [],
}

export const reducer = handleActions({
    [Actions.TOGGLE_VIEW]: (state: State, action: any) => {
        const {visible} = action.payload
        return Object.assign({}, state, {visible})
    },
    [Actions.FETCH_DATA_STARTED]: (state: State) => {
        const loadingState = LoadState.Pending()
        return Object.assign({}, state, {loadingState})
    },
    [Actions.FETCH_DATA_FAILED]: (state: State, action: any) => {
        const {error} = action.payload
        const loadingState = LoadState.Error(error)
        return Object.assign({}, state, {loadingState})
    },
    [Actions.FETCH_DATA_SUCCESS]: (state: State, action: any) => {
        const {data} = action.payload
        const loadingState = LoadState.Completed()

        return Object.assign({}, state, {loadingState, data, dealsData: Collections.Map<string, Array<DealInfo>>()})
    },
    [Actions.FETCH_DEALS_CHUNK]: (state: State, action: any) => {
        const {data, stateId} = action.payload
        const dealsData = state.dealsData
        return Object.assign({},
            state,
            {
                dealsData: dealsData
                    .mergeWith(
                        (prev, next) => Object.assign({}, prev, next),
                        data
                    )
                    .map(deals => deals.sort((row1: DealInfo, row2: DealInfo) => row1.price - row2.price)),
                expandedRowKeys: [stateId]
            }
        )
    },
    [Actions.EXPAND_FUNNEL_TABLE_ROWS]: (state: State, action: any) => {
        const {rowKeys} = action.payload

        return Object.assign({}, state, {expandedRowKeys: rowKeys.slice(0)})
    },
    [Actions.SET_REPORT_FILTERS]: (state: State, action: any) => {
        const {filters} = action.payload

        return Object.assign({}, state, {filters})
    }
}, defaultState)
