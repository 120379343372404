import {ApiStore} from "src/lib/entities/store/ApiStore";
import * as localForage from "src/lib/utils/localForage";
import {BrowserTabFactory} from "src/bums/common/browserTabs";
import {EntitiesStorageInterface} from "src/lib/entities/store/EntitiesStorage/EntitiesStorageInterface";
import {DebounceSaveEntitiesStorage} from "src/lib/entities/store/EntitiesStorage/DebounceSaveEntitiesStorage";
import {BrowserTabBroadcastEntitiesStorage} from "src/lib/entities/store/EntitiesStorage/BrowserTabBroadcastEntitiesStorage";
import {EntitiesLocalStorage} from "src/lib/entities/store/EntitiesStorage/EntitiesLocalStorage";
import {EntitiesNullStorage} from "src/lib/entities/store/EntitiesStorage/EntitiesNullStorage";

export function createEntitiesStorage(
    apiStore: ApiStore,
    browserTabFactory: BrowserTabFactory
): EntitiesStorageInterface {
    if (window.disable_entities_storage) {
        return new EntitiesNullStorage()
    }

    return !window.bt_mode && localForage
    && (
        localForage.supports(localForage.INDEXEDDB)
        || localForage.supports(localForage.LOCALSTORAGE)
        || localForage.supports(localForage.WEBSQL)
    )
        ? new DebounceSaveEntitiesStorage(
            new BrowserTabBroadcastEntitiesStorage(
                new EntitiesLocalStorage(),
                browserTabFactory,
                entities => apiStore.updateEntitiesFromJson(entities)
            )
        )
        : new EntitiesNullStorage()
}
