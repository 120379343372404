import {autobind} from "core-decorators"
import {Janus} from "janus-gateway"
import {MediaControl} from "./MediaControl"
import {Adapter} from "./Adapter"
import {stopStream} from "../../utils"
import {LocalFeed} from "./types"

@autobind
export class WebAdapter extends Adapter {

    protected initJanus() {
        Janus.init({
            callback: () => {
                this.$client = new Janus(this.getConfigureOptions())
            },
            debug: process.env.NODE_ENV === "development" ? "all" : "error",
        })
    }

    protected async attachMedia(feed: LocalFeed) {
        let videoPermission: boolean
        let audioPermission: boolean
        let stream: MediaStream

        try {
            stream = await navigator.mediaDevices.getUserMedia({audio: true})
            stopStream(stream)
            audioPermission = true
        } catch (e) {
            audioPermission = false
        }

        try {
            stream = await navigator.mediaDevices.getUserMedia({video: true})
            stopStream(stream)
            videoPermission = true
        } catch (e) {
            videoPermission = false
        }

        feed.attachMediaControl(new MediaControl(videoPermission ? "on" : "off", audioPermission ? "on" : "off"))
    }
}
