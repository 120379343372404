import {autobind} from "core-decorators"
import {debounce, Cancelable} from "lodash"
import {BrowserTab, BrowserTabFactory} from "src/bums/common/browserTabs"
import {MainTabEvent} from "src/bums/common/browserTabs/MainTab"
import {TabMessageEvent} from "src/bums/common/browserTabs/transports/AbstractTabTransport"
import {bindArg} from "src/lib/utils/func"
import {Socket} from "./Socket"
import {
    SubscriptionChannelName,
    ResubscriptionEventName,
    SubscriptionEventName,
    UnsubscriptionEventName,
    HandleEventName,
    RemoveSubcriptionDelay,
    SocketEvent,
    SocketDataEvent,
    SocketOptions,
    BroadcastEventName,
    BroadcastMessage,
    SocketStateSubscription,
    SocketStateData
} from "./types"

/* tslint:disable:no-console */

@autobind
export class SingletonSocket {

    protected $socket: Socket | void

    protected $browserTab: BrowserTab

    protected $subscriptions = new Set<string>()

    protected $unsubscribtionsQueue = new Set<string>()

    protected $delayedUnsubscriptions = new Set<string>()

    protected processQueueDeffered: (() => void) & Cancelable


    constructor(
        protected $socketOptions: SocketOptions,
        browserTabFactory: BrowserTabFactory,
        removeSubcriptionDelay = RemoveSubcriptionDelay,
    ) {
        this.$browserTab = browserTabFactory.createBrowserTabChannel(SubscriptionChannelName)
        this.$browserTab.on<boolean>(MainTabEvent.main_tab_changed, debounce(this.initialize, 1000))
        this.processQueueDeffered = debounce(this.processQueue, removeSubcriptionDelay)
    }

    protected initialize(message: TabMessageEvent) {
        if (this.$browserTab.isMainTab()) {
            if (this.$socket) {
                return
            }

            this.$socket = new Socket(this.$socketOptions)
            this.$socket.connect()

            this.$browserTab.on<SocketEvent>(SubscriptionEventName, this.subscribe)
            this.$browserTab.on<SocketEvent>(UnsubscriptionEventName, this.unsubscribe)
            this.$browserTab.on<BroadcastMessage>(BroadcastEventName, this.broadcast)
            this.$browserTab.on<SocketStateData>(SocketStateSubscription, this.sendSocketState)

            void this.$browserTab.broadcastMessage<SocketEvent>({
                eventName: ResubscriptionEventName,
                data: {
                    subscriptions: []
                }
            })

            console.log("WebSocket connected")
        } else if (this.$socket) {
            this.$socket.disconnect()

            this.$browserTab.off<SocketEvent>(SubscriptionEventName, this.subscribe)
            this.$browserTab.off<SocketEvent>(UnsubscriptionEventName, this.unsubscribe)
            this.$browserTab.off<BroadcastMessage>(BroadcastEventName, this.broadcast)
            this.$browserTab.off<SocketStateData>(SocketStateSubscription, this.sendSocketState)

            this.processQueueDeffered.cancel()

            this.$subscriptions.clear()
            this.$unsubscribtionsQueue.clear()
            this.$socket.unsubscribeAll()
            this.$socket = void 0

            console.log("WebSocket disconnected")
        } else {
            void this.$browserTab.broadcastMessage<{}>({
                eventName: SocketStateSubscription,
                data: {}
            })

            console.log("WebSocket is already running in another tab")
        }
    }

    protected broadcast(message: TabMessageEvent<BroadcastMessage>) {
        if (!this.$socket) {
            return
        }

        // Для типизации в цикле
        const socket = this.$socket as Socket
        socket.broadcast(message.data);
    }

    protected subscribe(message: TabMessageEvent<SocketEvent>) {
        if (!this.$socket) {
            return
        }

        const data = message.data
        // Для типизации в цикле
        const socket = this.$socket as Socket

        const subscriptions = data.subscriptions || []

        subscriptions.forEach(subscription => {
            if (this.$unsubscribtionsQueue.has(subscription)) {
                this.$unsubscribtionsQueue.delete(subscription)
            }

            if (this.$subscriptions.has(subscription)) {
                return
            }

            this.$subscriptions.add(subscription)

            socket.subscribe(subscription, bindArg(this.handle, subscription))
        })
    }

    protected unsubscribe(message: TabMessageEvent<SocketEvent>) {
        if (!this.$socket) {
            return
        }

        const data = message.data
        const subscriptions = data.subscriptions || []

        subscriptions.forEach(subscription => {
            this.$unsubscribtionsQueue.add(subscription)
        })

        this.processQueueDeffered()

        void this.$browserTab.broadcastMessage<SocketEvent>({
            eventName: ResubscriptionEventName,
            data: {
                subscriptions
            }
        })
    }

    protected sendSocketState() {
        if (!this.$socket) {
            return
        }

        this.$socket.sendState()
    }

    protected processQueue() {
        if (!this.$socket) {
            this.$unsubscribtionsQueue.clear()
            return
        }

        const socket = this.$socket as Socket

        if (this.$unsubscribtionsQueue.size === 0) {
            return
        }

        this.$unsubscribtionsQueue.forEach(subscription => {
            this.$subscriptions.delete(subscription)
        })

        socket.unsubscribeAll(Array.from(this.$unsubscribtionsQueue.values()))

        this.$unsubscribtionsQueue.clear()
    }

    protected handle(eventName: string, data: any) {
        if (!this.$subscriptions.has(eventName)) {
            return
        }

        void this.$browserTab.broadcastMessage<SocketDataEvent>({
            eventName: HandleEventName,
            data: {
                subscriptions: [eventName],
                data: data
            }
        })
    }
}

