import * as ReduxActions from "redux-actions"
import * as Api from "src/lib/entities/api"

export const CHANGE_SORT_COLUMN = "bums/common/report/edit/action/CHANGE_SORT_COLUMN"

export type ChangeSortColumn = ReduxActions.Action<{
    selectedSortColumn: Api.ReportConfigColumn
}>

export function changeSortColumn(selectedSortColumn: Api.ReportConfigColumn): ChangeSortColumn {
    return {
        type: CHANGE_SORT_COLUMN,
        payload: {
            selectedSortColumn
        }
    }
}
