import {autobind} from "core-decorators"
import {Json} from "src/lib/types"
import {AbstractTabTransport, TabMessageEvent} from "./AbstractTabTransport"

/**
 * Транспорт обмена сообщениями между вкладками через LocalStorage
 */
@autobind
export class LocalStorageTransport extends AbstractTabTransport {

    private lastEvent: TabMessageEvent<{}> //заглушка для IE
    constructor(transportChannelName: string) {
        super(transportChannelName)
        window.addEventListener("storage", this.onStorageEvent)
    }

    public static isSupported = () => {
        return Boolean(window.localStorage)
    }

    public broadcastMessage<T = Json>(message: TabMessageEvent<T>) {
        if (!message.timestamp) {
            message.timestamp = (new Date()).getTime()
        }
        this.lastEvent = message
        window.localStorage.setItem(this.eventsScopeName, JSON.stringify(message));
        message.isFromSameTab = true;
        this.emitMessage(message)
    }

    public onStorageEvent = (event: StorageEvent) => {
        if (event.key === this.eventsScopeName) {
            try {
                const message = JSON.parse(event.newValue) as TabMessageEvent
                //IE тригерит события стораджа в той же вклдаке где была запись в отличии от других браузеров
                if (message.eventName + message.timestamp !== this.lastEvent.eventName + this.lastEvent.timestamp) {
                    this.emitMessage(message)
                }
           } catch (err) {
                console.error("Can't parse JSON from storage", event.newValue, err)
            }
        }
    }
}
