/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface LoyaltyProgram extends H.BaseEntity {
    name?: string;
    id?: string; // Идентификатор
    contentType: "LoyaltyProgram"; // Object type
}

export module LoyaltyProgram {
    export const contentType: "LoyaltyProgram" = "LoyaltyProgram";
    export const endpoint = "/api/v3/loyaltyProgram";

    export const newObject: LoyaltyProgram = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "LoyaltyProgram.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isLoyaltyProgram(arg: any): arg is LoyaltyProgram {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "LoyaltyProgram"
}
