import {MainTab, MainTabEvent} from "src/bums/common/browserTabs/MainTab"
import {isDocumentHidden, Json} from "src/lib/types"
import {
    AbstractTabTransport, TabMessageEvent,
    TabMessageListener
} from "src/bums/common/browserTabs/transports/AbstractTabTransport"
import {createTabsTransport} from "src/bums/common/browserTabs/transports/TransportFactory"
import {bindArg} from "src/lib/utils/func"
import {autobind} from "core-decorators"
import {inject} from "src/lib/utils/inject"

@autobind
export class BrowserTab extends AbstractTabTransport {

    private transport: AbstractTabTransport

    constructor(
        transportChannelName: string,
        private mainTab: MainTab
    ) {
        super(transportChannelName)
        this.transport = createTabsTransport(transportChannelName)

        this.mainTab.on(MainTabEvent.new_tab_need_state, (data: boolean) => {
            this.emit(MainTabEvent.new_tab_need_state, data)
        })
        this.mainTab.on(MainTabEvent.main_tab_changed, (data: boolean) => {
            this.emit(MainTabEvent.main_tab_changed, data)
        })
        this.mainTab.on(MainTabEvent.main_tab_closed, (data: boolean) => {
            this.emit(MainTabEvent.main_tab_closed, data)
        })
    }

    public async broadcastMessage<T = Json>(message: TabMessageEvent<T>) {
        try {
            await this.mainTab.getReadyPromise()
            message.isFromMainTab = this.mainTab.isMainTab()
            message.isFromVisibleTab = !isDocumentHidden()
            this.transport.broadcastMessage(message)
        } catch (e) {
            console.error(e)
        }
    }

    public getReadyPromise() {
        return this.mainTab.getReadyPromise()
    }

    public isMainTab() {
        return this.mainTab.isMainTab()
    }

    public async emitMessage<T = Json>(message: TabMessageEvent<T>) {
        try {
            await this.mainTab.getReadyPromise()
            this.transport.emitMessage(message)
        } catch (e) {
            console.error(e)
        }
    }

    public on<T = Json>(eventName: string, listener: TabMessageListener<T>) {
         this.transport.on(eventName, bindArg(this.addDataToTabMessage, listener))
    }

    public off<T = Json>(eventName: string, listener: TabMessageListener<T>) {
        this.transport.off(eventName, bindArg(this.addDataToTabMessage, listener))
    }

    private async addDataToTabMessage<T = Json>(listener: TabMessageListener<T>, message: TabMessageEvent<any>) {
        try {
            await this.mainTab.getReadyPromise()
            message.isMainTab = this.mainTab.isMainTab()
            listener.apply(this, [message])
        } catch (e) {
            console.error(e)
        }
    }
}

export class BrowserTabFactory {

    private channels: {[index: string]: BrowserTab} = {}
    constructor(@inject(MainTab) private mainTab: MainTab) {}

    public createBrowserTabChannel(channelName: string) {
        if (this.channels[channelName]) {
            return this.channels[channelName]
        }

        return this.channels[channelName] = new BrowserTab(channelName, this.mainTab)
    }
}
