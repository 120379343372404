import {Response, FetchError} from "src/lib/utils/fetch"
import {ApiResponse, ApiErrorResponse} from "src/lib/entities/types"

export function createStubResponse<T>(
    url: string,
    data: T,
    pagination?: {
        count: number,
        limit: number,
        hasMoreNext: boolean,
        hasMorePrev: boolean
    },
): Response<ApiResponse<T>> {
    return {
        url: url,
        status: 200,
        statusText: "OK",
        headers: null,
        value: {
            data,
            meta: {
                status: 200,
                pagination
            }
        },
    }
}

export function createStubErrorResponse(
    url: string,
    errorCode: number
): FetchError<ApiErrorResponse> {
    return new FetchError({
        url: url,
        status: errorCode,
        statusText: String(errorCode),
        headers: null,
        value: {
            meta: {
                status: errorCode,
                errors: []
            }
        },
    });
}
