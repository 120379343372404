import * as localforage from "localforage"
import "localforage-getitems"
import "localforage-setitems"

import {SetItemsMethod} from "localforage-setitems"
import {GetItemsMethod} from "localforage-getitems"

type LocalForageWithMethods = typeof localforage & SetItemsMethod & GetItemsMethod
export type Instance = LocalForageWithMethods

interface LocalForageOptions {
    driver?: string | string[];

    name?: string;

    size?: number;

    storeName?: string;

    version?: number;

    description?: string;
}

export function createInstance(options: LocalForageOptions): Instance {
    return localforage.createInstance(options) as Instance
}

export interface SafeStorage {
    getItem<T>(key: string): Promise<T | void>
    setItem<T>(key: string, value: T): Promise<T | void>
    removeItem(key: string): Promise<void>
    setItems(kvPairArray: Array<{key: string, value: {}}>): Promise<void>
    getItems<T>(keys: Array<string>): Promise<{[index: string]: T}>
    clear(): Promise<void>
}

/**
 * Функция делает безопасное хранилище:
 * в случае хоть одной ошибки оно перестаёт работать и на всё отдаёт void
 */
export function makeSafeStorage(localForage: Instance): SafeStorage {
    let failed = false
    function fail(e: {}) {
        if (!failed) {
            failed = true
            console.warn("Local storage failed: " + e)
            // Хранилище неконсистентно, чистим его
            localForage.clear().catch(function() {
                // игнорим ошибку
            })
        }
    }
    return {
        async getItem<T>(key: string): Promise<T | void> {
            if (!failed) {
                try {
                    return await localForage.getItem<T>(key)
                } catch (e) {
                    fail(e)
                }
            }
        },
        async setItem<T>(key: string, value: T): Promise<T | void> {
            if (!failed) {
                try {
                    return await localForage.setItem<T>(key, value)
                } catch (e) {
                    fail(e)
                }
            }
        },
        async removeItem(key: string): Promise<void> {
            if (!failed) {
                try {
                    return await localForage.removeItem(key)
                } catch (e) {
                    fail(e)
                }
            }
        },
        async setItems(kvPairArray: Array<{key: string, value: {}}>): Promise<void> {
            if (!failed) {
                try {
                    return await localForage.setItems(kvPairArray)
                } catch (e) {
                    fail(e)
                }
            }
        },
        async getItems<T>(keys: Array<string>): Promise<{[index: string]: T}> {
            if (!failed) {
                try {
                    return await localForage.getItems<T>(keys)
                } catch (e) {
                    fail(e)
                    return {}
                }
            } else {
                return {}
            }
        },
        async clear(): Promise<void> {
            if (!failed) {
                try {
                    return await localForage.clear()
                } catch (e) {
                    fail(e)
                }
            }
        },
    }
}

export const supports = localforage.supports
export const INDEXEDDB = localforage.INDEXEDDB
export const LOCALSTORAGE = localforage.LOCALSTORAGE
export const WEBSQL = localforage.WEBSQL
