import {fetchDataChunkSuccess} from "./index"
import {ReportData, ServerResponse} from "../../entities"
import * as Collections from "src/lib/collections"
import * as WebApi from "src/lib/entities/apiWeb"

/**
 *
 * @param offset сдвиг числа строк
 * @param reportId
 * @param filters
 * @param sortColumn
 * @param orderBy
 * @param limit
 * @param retried является ли этот запрос повторным
 * @returns {function(dispatch): undefined}
 */
export function fetchDataChunk(offset: number,
                               reportId: number,
                               filters?: any,
                               sortColumn?: string,
                               orderBy?: number,
                               limit?: number,
                               retried?: boolean) {

    const queryParams: FormData = new FormData();
    queryParams.append("limit", String(limit))
    queryParams.append("offset", String(offset))
    queryParams.append("id", String(reportId))
    queryParams.append("filters", filters)
    queryParams.append("sortBy", (orderBy ? "-" : "") + sortColumn)

    return WebApi.apiFetch<ServerResponse>(
        "/BumsReport/ReportCard/reportData.json",
        {body: queryParams, method: "POST"},
        [
            null,
            response => {
                const data: ReportData = {
                    columns: Collections.List(response.columns),
                    rows: Collections.List(response.rows),
                    nextOffset: response.nextOffset
                }
                return fetchDataChunkSuccess(data)
            },
            () => {
                if (!retried) {
                    fetchDataChunk(offset, reportId, filters, sortColumn, orderBy, limit, true)
                }
            }
        ]
    )
}
