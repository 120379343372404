import {autobind} from "core-decorators"
import {EventEmitter} from "events"
import {throttle, debounce, difference, Cancelable} from "lodash"
import {Json} from "src/lib/types"
import {
    BaseSocketOptions,
    SocketOptions,
    SocketTransport,
    BroadcastMessage,
    SubscriptionMessage,
    SocketStateSubscription,
    SocketStateConnected,
    SocketStateDisconnected
} from "./types"

const DefaultSocketOptions: Partial<SocketOptions> = {
    queueDelay: 1000,
    reconnectDelay: 5000,
    useRecomet: false,
    protocol: "http",
    mobile: false,
}

function makeHost(host: string, useRecomet: boolean, protocol: string, mobile: boolean) {
    if (!host.match(/^wss?:\/\//)) {
        host = `${(protocol.match(/https/) ? "wss" : "ws" )}://${host}`
    }

    if (useRecomet) {
        const randomUid = Math.random().toString().substr(2)
        host += `/comet/${randomUid}`
        if (mobile) {
            host += "/mobile"
        }
    } else {
        host += "/ecomet/websocket"
    }

    return host
}

type Handler<T = Json> = (data: T) => void

@autobind
export class Socket implements SocketTransport {

    protected $eventEmitter = new EventEmitter()

    protected $options: SocketOptions = {}

    protected $socket: WebSocket

    protected $connected = false

    protected $forceDisconnect = false

    protected $authorized = false

    protected $activeSubscriptions = new Set<string>()

    protected $proccessQueueDeffered: (() => void) & Cancelable

    protected $reconnectDeffered: (() => void) & Cancelable

    protected $highLoadConnectTimout: number

    constructor(options: SocketOptions) {
        this.$options = {
            ...DefaultSocketOptions,
            ...options,
        }

        this.$proccessQueueDeffered = throttle(this.proccessQueue, this.$options.queueDelay)
        this.$reconnectDeffered = debounce(this.reconnect, this.$options.reconnectDelay)

        this.setOptions(options)
    }

    public setOptions(options: BaseSocketOptions) {
        if (!options.host) {
            return
        }

        this.$options = {...this.$options, ...options, host: makeHost(options.host, options.useRecomet, options.protocol, options.mobile)}
    }

    public connect() {
        if (this.$connected) {
            return
        }

        if (!this.$options.host) {
            return
        }

        this.$forceDisconnect = false
        clearTimeout(this.$highLoadConnectTimout)

        this.$socket = new WebSocket(this.$options.host, this.$options.header)

        this.$socket.onopen = event => {
            this.$connected = true
            this.$authorized = false
            clearTimeout(this.$highLoadConnectTimout)
            this.sendState()

            if (this.$activeSubscriptions.size !== 0) {
                this.send({type: "subscribe", routes: Array.from(this.$activeSubscriptions.values())})
            }

            this.proccessQueue()
        }

        this.$socket.onclose = event => {
            this.$socket = void 0
            this.$connected = false
            clearTimeout(this.$highLoadConnectTimout)
            this.sendState()

            if (this.$forceDisconnect) {
                this.$reconnectDeffered.cancel()
                this.$proccessQueueDeffered.cancel()
                this.$eventEmitter.removeAllListeners()
                return
            }

            this.$reconnectDeffered()
        }

        this.$socket.onerror = event => {
            if (process.env.NODE_ENV === "production") {
                console.error((event as any).message)
            } else {
                console.warn((event as any).message)
            }
            this.$socket.close()
        }

        this.$socket.onmessage = event => {
            const data = JSON.parse(event.data)
            this.$eventEmitter.emit(data.event, data.message)
        }

        this.$highLoadConnectTimout = setTimeout(this.reconnect, this.$options.reconnectDelay)
    }

    public disconnect() {
        this.$connected = false
        this.$forceDisconnect = true
        this.$socket.close()
    }

    public sendState() {
        this.$eventEmitter.emit(SocketStateSubscription, {state: this.$connected ? SocketStateConnected : SocketStateDisconnected})
    }

    protected reconnect() {
        if (!this.$connected) {
            if (this.$socket) {
                this.$socket.close()
            }

            this.connect()
        }
    }

    public subscribe<T = Json>(eventsName: string | string[], eventHandler: Handler<T>) {
        eventsName = Array.isArray(eventsName) ? eventsName : [eventsName]

        eventsName.forEach(eventName => {
            this.$eventEmitter.addListener(eventName, eventHandler)
        })

        this.$proccessQueueDeffered()
    }

    public unsubscribe<T = Json>(eventsName: string | string[], eventHandler: Handler<T>) {
        eventsName = Array.isArray(eventsName) ? eventsName : [eventsName]

        eventsName.forEach(eventName => {
            this.$eventEmitter.removeListener(eventName, eventHandler)
        })

        this.$proccessQueueDeffered()
    }

    public unsubscribeAll(eventNames: string[] = []) {
        const events = eventNames.length === 0
            ? this.$eventEmitter.eventNames() as string[]
            : eventNames

        events.forEach(eventName => {
            this.$eventEmitter.removeAllListeners(eventName)
        })

        this.proccessQueue()
    }

    public broadcast(message: BroadcastMessage) {
        message.type = "broadcast";
        this.send(message);
    }

    protected send(message: SubscriptionMessage|BroadcastMessage) {
        if (!this.$connected) {
            return
        }

        if (process.env.REACT_NATIVE) {
            try {
                this.$socket.send(this.prepareMessage(message))
            } catch (e) {
                console.error(e)
            }
        } else {
            this.$socket.send(this.prepareMessage(message))
        }
    }

    protected prepareMessage(message: SubscriptionMessage|BroadcastMessage) {
        if (!this.$options.useRecomet && this.$options.authData && !this.$authorized) {
            this.$authorized = true
            message.auth = this.$options.authData
        }

        return JSON.stringify(message)
    }

    protected proccessQueue() {
        if (!this.$connected) {
            return
        }

        const events = difference(this.$eventEmitter.eventNames(), [SocketStateSubscription]) as string[];
        const activeSubscriptions = Array.from(this.$activeSubscriptions.values())

        const subscriptions = difference(events, activeSubscriptions)
        const unsubscriptions = difference(activeSubscriptions, events)

        if (subscriptions.length > 0) {
            subscriptions.forEach(subscription => this.$activeSubscriptions.add(subscription))

            this.send({type: "subscribe", routes: subscriptions})
        }

        if (unsubscriptions.length > 0) {
            unsubscriptions.forEach(subscription => this.$activeSubscriptions.delete(subscription))

            this.send({type: "unsubscribe", routes: unsubscriptions})
        }
    }

}

