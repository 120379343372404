import * as ReduxActions from "redux-actions"

export const SWITCH_CHART_SETTINGS_FLAG = "bums/common/report/card/action/SWITCH_CHART_SETTINGS_FLAG"

export type SwitchChartSettingsFlag = ReduxActions.Action<{
    edit: boolean
}>

export function switchChartSettingsFlag(edit: boolean): SwitchChartSettingsFlag {
    return {
        type: SWITCH_CHART_SETTINGS_FLAG,
        payload: {
            edit
        }
    }
}
