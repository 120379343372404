/* tslint:disable */
import * as H from "../metadataHelper"
import {EnumField} from "./enumField"
import {StringField} from "./stringField"

export interface ApiCall extends H.BaseValue {
    method?: ApiCall.Method; // HTTP-method
    url?: string; // URL part after domain
    body?: string; // Request body content
    contentType: "ApiCall"; // Object type
}

export module ApiCall {
    export const contentType: "ApiCall" = "ApiCall";
    export const endpoint = "/api/v3/apiCall";
    export type Method = "DELETE"|"GET"|"PATCH"|"POST"|"PUT";
    export module Method {
        export const DELETE: Method = "DELETE";
        export const GET: Method = "GET";
        export const PATCH: Method = "PATCH";
        export const POST: Method = "POST";
        export const PUT: Method = "PUT";
    }
    export const newObject: ApiCall = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const method = {contentType: "EnumField" as "EnumField", name: "method", isMultiple: false, isSortable: false, id: "ApiCall.method", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["DELETE", "GET", "PATCH", "POST", "PUT"])};
        export const url = {contentType: "StringField" as "StringField", name: "url", isMultiple: false, isSortable: false, id: "ApiCall.url", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const body = {contentType: "StringField" as "StringField", name: "body", isMultiple: false, isSortable: false, id: "ApiCall.body", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isApiCall(arg: any): arg is ApiCall {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ApiCall"
}
