import {EventEmitter} from "events"
import {Json} from "src/lib/types"
import {autobind} from "core-decorators"
import {noop} from "lodash"

/**
 * Тип сообщения передаваемый между вкладками, транспорты самостоятельно приводят к такому виду
 */

export type TabMessageEvent<T = Json> = {
    eventName: string
    timestamp?: number
    data: T
    isMainTab?: boolean
    isVisibleTab?: boolean
    isFromMainTab?: boolean
    isFromVisibleTab?: boolean
    isFromSameTab?: boolean
}


export type TabMessageListener<T = Json> = (message: TabMessageEvent<T>) => void;

/**
 * Базовый класс транспортоа обмена сообщениями между вкладками браузера
 */
@autobind
export abstract class AbstractTabTransport {

    protected eventsScopeName: string

    /**
     * eventEmitter специально инкапсулирован внутрь транспорта,
     * чтобы закрыть неподдерживаемые методы в нём
     * и разрешить использование только описанных здесь методов
     */
    private eventEmitter: EventEmitter

    constructor(eventsScopeName: string = "bums-tab-transport") {
        this.eventEmitter = new EventEmitter()
        this.eventsScopeName = eventsScopeName
    }

    public static isSupported: () => boolean

    public emit(eventName: string, data: Json) {
        this.emitMessage({eventName, data} as TabMessageEvent)
    }

    public emitMessage<T = Json>(data: TabMessageEvent<T>) {
        this.eventEmitter.emit(data.eventName, data)
    }

    public on(eventName: string, listener: TabMessageListener) {
         this.eventEmitter.addListener(eventName, listener)
    }

    public off(eventName: string, listener: TabMessageListener) {
        this.eventEmitter.removeListener(eventName, listener)
    }

    abstract broadcastMessage<T = Json>(message: TabMessageEvent<T>): void

    public broadcast(eventName: string, data: Json) {
        this.broadcastMessage({eventName, data} as TabMessageEvent)
    }

}

export interface AbstractBrowserTab {
    broadcastMessage: (message: TabMessageEvent) => void
    getReadyPromise: () => Promise<AbstractMainTab>
    isMainTab: () => boolean
    emitMessage: (message: TabMessageEvent) => void
    broadcast: (eventName: string, data: Json) => void
    on: (eventName: string, listener: TabMessageListener) => void
}

export interface AbstractBrowserTabFactory {
    createBrowserTabChannel: (channelName: string) => AbstractBrowserTab
}

export class FakeBrowserTab {
    broadcastMessage = noop
    getReadyPromise = () => Promise.resolve(new FakeMainTab())
    isMainTab = () => true
    emitMessage = noop
    broadcast = noop
    on = noop
}

class FakeMainTab {
    isMainTab = () => true
}

export class FakeBrowserTabFactory {
    createBrowserTabChannel = (channelName: string) => new FakeBrowserTab()
}

export interface AbstractMainTab {
    isMainTab: () => boolean
}
