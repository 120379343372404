import * as ReduxActions from "redux-actions"

export const FETCH_DATA_STARTED = "bums/trade/funnel/action/FETCH_DATA_STARTED"

export type FetchDataStartedAction = ReduxActions.Action<void>

export function fetchDataStarted(): FetchDataStartedAction {
    return {
        type: FETCH_DATA_STARTED,
    }
}
