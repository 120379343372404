/* tslint:disable */
import * as H from "../metadataHelper"
import {Program} from "./program"
import {KanbanColumnInfo} from "./kanbanColumnInfo"
import {KanbanSortField} from "./kanbanSortField"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {Group} from "./group"
import {FilterConfig} from "./filterConfig"
import {SortField} from "./sortField"
import {FilterGroup} from "./filterGroup"
import {IntegerField} from "./integerField"
import {EnumField} from "./enumField"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface TradeFilter extends H.BaseEntity {
    program?: Program;
    kanbanColumns?: H.List<KanbanColumnInfo>;
    kanbanColumnsCount?: number;
    sortField?: KanbanSortField;
    parent?: TradeFilter;
    viewMode?: TradeFilter.ViewMode;
    title?: string; // Название
    count?: number; // Количество
    href?: string; // Ссылка
    shared?: boolean; // Общий
    owner?: ContractorCompany|ContractorHuman|Employee|Group; // Владелец фильтра
    visibleFor?: H.List<ContractorCompany | ContractorHuman | Employee | Group>; // Кому виден данный фильтр
    visibleForCount?: number; // Количество тех, кому виден данный фильтр
    config?: FilterConfig; // Конфигурация фильтра
    availableActions?: H.List<TradeFilter.AvailableActions>; // Доступные действия
    defaultFields?: H.List<string>; // Исходный список полей фильтра
    defaultSortby?: H.List<SortField>; // Исходная сортировка полей
    defaultSortbyCount?: number;
    userFields?: H.List<string>; // Пользовательский список полей фильтра
    userSortby?: H.List<SortField>; // Пользовательская сортировка
    userSortbyCount?: number;
    group?: FilterGroup; // Группа фильтров
    expanded?: boolean; // Расширенный
    id?: string; // Идентификатор
    contentType: "TradeFilter"; // Object type
}

export module TradeFilter {
    export const contentType: "TradeFilter" = "TradeFilter";
    export const endpoint = "/api/v3/tradeFilter";
    export type ViewMode = "kanban"|"list";
    export module ViewMode {
        export const kanban: ViewMode = "kanban";
        export const list: ViewMode = "list";
    }
    export type AvailableActions = "edit";
    export module AvailableActions {
        export const edit: AvailableActions = "edit";
    }
    export const newObject: TradeFilter = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const program = {contentType: "RefLinkField" as "RefLinkField", name: "program", isMultiple: false, isSortable: false, id: "TradeFilter.program", refContentType: H.List(["Program"])};
        export const kanbanColumns = {contentType: "RefLinkField" as "RefLinkField", name: "kanbanColumns", isMultiple: true, isSortable: false, id: "TradeFilter.kanbanColumns", refContentType: H.List(["KanbanColumnInfo"])};
        export const kanbanColumnsCount = {contentType: "IntegerField" as "IntegerField", name: "kanbanColumnsCount", isMultiple: false, isSortable: false, id: "TradeFilter.kanbanColumnsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const sortField = {contentType: "RefLinkField" as "RefLinkField", name: "sortField", isMultiple: false, isSortable: false, id: "TradeFilter.sortField", refContentType: H.List(["KanbanSortField"])};
        export const parent = {contentType: "RefLinkField" as "RefLinkField", name: "parent", isMultiple: false, isSortable: false, id: "TradeFilter.parent", refContentType: H.List(["TradeFilter"])};
        export const viewMode = {contentType: "EnumField" as "EnumField", name: "viewMode", isMultiple: false, isSortable: false, id: "TradeFilter.viewMode", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["kanban", "list"])};
        export const title = {contentType: "StringField" as "StringField", name: "title", isMultiple: false, isSortable: false, id: "TradeFilter.title", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const count = {contentType: "IntegerField" as "IntegerField", name: "count", isMultiple: false, isSortable: false, id: "TradeFilter.count", defaultValue: null as IntegerField["defaultValue"]};
        export const href = {contentType: "StringField" as "StringField", name: "href", isMultiple: false, isSortable: false, id: "TradeFilter.href", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const shared = {contentType: "BoolField" as "BoolField", name: "shared", isMultiple: false, isSortable: false, id: "TradeFilter.shared", defaultValue: null as BoolField["defaultValue"]};
        export const owner = {contentType: "RefLinkField" as "RefLinkField", name: "owner", isMultiple: false, isSortable: false, id: "TradeFilter.owner", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const visibleFor = {contentType: "RefLinkField" as "RefLinkField", name: "visibleFor", isMultiple: true, isSortable: false, id: "TradeFilter.visibleFor", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const visibleForCount = {contentType: "IntegerField" as "IntegerField", name: "visibleForCount", isMultiple: false, isSortable: false, id: "TradeFilter.visibleForCount", defaultValue: null as IntegerField["defaultValue"]};
        export const config = {contentType: "RefLinkField" as "RefLinkField", name: "config", isMultiple: false, isSortable: false, id: "TradeFilter.config", refContentType: H.List(["FilterConfig"])};
        export const availableActions = {contentType: "EnumField" as "EnumField", name: "availableActions", isMultiple: true, isSortable: false, id: "TradeFilter.availableActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["edit"])};
        export const defaultFields = {contentType: "StringField" as "StringField", name: "defaultFields", isMultiple: true, isSortable: false, id: "TradeFilter.defaultFields", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const defaultSortby = {contentType: "RefLinkField" as "RefLinkField", name: "defaultSortby", isMultiple: true, isSortable: false, id: "TradeFilter.defaultSortby", refContentType: H.List(["SortField"])};
        export const defaultSortbyCount = {contentType: "IntegerField" as "IntegerField", name: "defaultSortbyCount", isMultiple: false, isSortable: false, id: "TradeFilter.defaultSortbyCount", defaultValue: null as IntegerField["defaultValue"]};
        export const userFields = {contentType: "StringField" as "StringField", name: "userFields", isMultiple: true, isSortable: false, id: "TradeFilter.userFields", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const userSortby = {contentType: "RefLinkField" as "RefLinkField", name: "userSortby", isMultiple: true, isSortable: false, id: "TradeFilter.userSortby", refContentType: H.List(["SortField"])};
        export const userSortbyCount = {contentType: "IntegerField" as "IntegerField", name: "userSortbyCount", isMultiple: false, isSortable: false, id: "TradeFilter.userSortbyCount", defaultValue: null as IntegerField["defaultValue"]};
        export const group = {contentType: "RefLinkField" as "RefLinkField", name: "group", isMultiple: false, isSortable: false, id: "TradeFilter.group", refContentType: H.List(["FilterGroup"])};
        export const expanded = {contentType: "BoolField" as "BoolField", name: "expanded", isMultiple: false, isSortable: false, id: "TradeFilter.expanded", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isTradeFilter(arg: any): arg is TradeFilter {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "TradeFilter"
}
