import {PlainRoute} from "react-router"
import * as React from "react"
import {lazy} from "src/lib/utils/lazy"

const CMenuItemViewer = lazy(async () => {
    return (await import("./CMenuItemViewer")).CMenuItemViewer
})

const CExternalSourceContainer = lazy(async () => {
    return (await import("src/bums/common/externalSource/CExternalSourceContainer")).CExternalSourceContainer
})

const baseRouteFunc = (menuId?: string) => {
    return (props: any) => {
        if (!!props.location.search || !!props.location.hash || !menuId) {
            let source =  props.location.pathname +
                (!!props.location.search ? props.location.search + "&" : "?") +
                "hideMainMenu=1&hideInformer=1" + props.location.hash
            return React.createElement(CExternalSourceContainer, {source})
        }

        const newProps = {
            ...props,
            params: {
                ...props.params,
                menuId
            }
        }
        return React.createElement(CMenuItemViewer, {...newProps})
    }
}

export const menuItemRoutes: PlainRoute[] = [
    {
        path: "/menuitem/:menuId",
        async getComponent() {
            return CMenuItemViewer
        },

    },
    {
        path: "/support",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("help_support"))
        },
    },
    {
        path: "/certificate/list",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("tests"))
        },
    },
    {
        path: "/faq",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("faq"))
        },
    },

    ////////// <СОТРУДНИКИ>
    {
        path: "/staff/list",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("employee"))
        },
    },
    {
        path: "/salary/show",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("employee_salary"))
        },
    },
    {
        path: "/bonus",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("employee_bonus"))
        },
    },
    {
        path: "/structure/show",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("employee_structure"))
        },
    },
    {
        path: "/relation",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("employee_relation"))
        },
    },
    {
        path: "/vacation",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("employee_vacation"))
        },
    },
    {
        path: "/staff/reportTime",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("employee_report"))
        },
    },
    {
        path: "/staff/:id/card",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc())
        },
    },
    {
        path: "/groups",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("employee_groups"))
        },
    },
    ////////// </СОТРУДНИКИ>

    ////////// <КЛИЕНТЫ>
    {
        path: "/event/list",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("crm_communication"))
        }
    },
    {
        path: "/event/:id/card",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc())
        }
    },
    {
        path: "/autoscript/list",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("crm_autoscript"))
        },
    },
    ////////// </КЛИЕНТЫ>

    ////////// <ОБЩЕНИЕ>
    {
        path: "/topics/add",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("discuss_add"))
        },
    },
    {
        path: "/topics",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("discuss"))
        },
    },
    {
        path: "/topics/:id/card",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc())
        },
    },
    ////////// </ОБЩЕНИЕ>

    ////////// <ДОКУМЕНТЫ>
    {
        path: "/docs/add",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("doc_add"))
        },
    },
    {
        path: "/docs",
        getComponent: (location, callback) => {
            callback(null, (props) => {
                switch (props.location.search) {
                    case "?type=text": props.params.menuId = "doc_text"
                        break
                    case "?type=impress": props.params.menuId = "doc_impress"
                        break
                    case "?type=pdf": props.params.menuId = "doc_pdf"
                        break
                    case "?type=table": props.params.menuId = "doc_table"
                        break
                    case "?type=image": props.params.menuId = "doc_image"
                        break
                    case "?type=others": props.params.menuId = "doc_others"
                        break
                    case "": props.params.menuId = "doc"
                        break
                    default:
                        if (!!props.location.search || !!props.location.hash) {
                            let source =  props.location.pathname +
                                (!!props.location.search ? props.location.search + "&" : "?") +
                                "hideMainMenu=1&hideInformer=1" + props.location.hash;
                            return React.createElement(CExternalSourceContainer, {source});
                        } else {
                            props.params.menuId = "unknown"
                        }
                }

                return React.createElement(CMenuItemViewer, {...props});
            })
        },
    },
    {
        path: "/docs/:id",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc())
        },
    },
    ////////// </ДОКУМЕНТЫ>

    ////////// <TODO>
    {
        path: "/event/add",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("todo_add"))
        },
    },
    {
        path: "/todo/list",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("todo_list"))
        },
    },
    {
        path: "/time/day",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("todo_day"))
        },
    },
    {
        path: "/time/week",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("todo_week"))
        },
    },
    {
        path: "/time/month",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("todo_month"))
        },
    },
    {
        path: "/time",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("todo"))
        },
    },
    ////////// </TODO>

    ////////// <СЧЕТА>
    {
        path: "/invoices/add",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("invoice_add"))
        },
    },
    {
        path: "/invoices",
        getComponent: (location, callback) => {
            callback(null, (props) => {
                switch (props.location.search) {
                    case "?status=2": props.params.menuId = "invoice_drawn"
                        break
                    case "?status=3": props.params.menuId = "invoice_paid"
                        break
                    case "?status=overdue": props.params.menuId = "invoice_overdue"
                        break
                    case "?status=4": props.params.menuId = "invoice_rejected"
                        break
                    case "?status=1": props.params.menuId = "invoice_draft"
                        break
                    case "": props.params.menuId = "invoice"
                        break
                    default:
                        if (!!props.location.search || !!props.location.hash) {
                            let source =  props.location.pathname +
                                (!!props.location.search ? props.location.search + "&" : "?") +
                                "hideMainMenu=1&hideInformer=1" + props.location.hash;
                            return React.createElement(CExternalSourceContainer, {source});
                        } else {
                            props.params.menuId = "unknown"
                        }
                }

                return React.createElement(CMenuItemViewer, {...props});
            })
        },
    },
    ////////// </СЧЕТА>

    ////////// <ФИНАНСЫ>
    {
        path: "/finance/operation/add",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("finance_add"))
        },
    },
    {
        path: "/finance/operation/list",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("finance_list"))
        },
    },
    {
        path: "/finance/report/cashflow/show",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("finance_reports"))
        },
    },
    {
        path: "/finance/refbook",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("finance_settings"))
        },
    },
    {
        path: "/finance",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("finance"))
        },
    },
    ////////// </ФИНАНСЫ>

    ////////// <ОТЧЁТЫ>
    {
        path: "/report/edit",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc("report_add"))
        },
    },
    {
        path: "/report",
        getComponent: (location, callback) => {
            callback(null, (props) => {
                switch (props.location.search) {
                    case "?filterId=salesDepartment":
                        props.params.menuId = "report_sales_department"
                        break;
                    case "?filterId=salesFunnel":
                        props.params.menuId = "report_sales_funnel"
                        break
                    case "?filterId=all":
                        props.params.menuId = "report_list"
                        break
                    case "":
                        props.params.menuId = "report"
                        break;
                    default:
                        if (!!props.location.search || !!props.location.hash) {
                            let source =  props.location.pathname +
                                (!!props.location.search ? props.location.search + "&" : "?") +
                                "hideMainMenu=1&hideInformer=1" + props.location.hash;
                            return React.createElement(CExternalSourceContainer, {source});
                        } else {
                            props.params.menuId = "unknown"
                        }
                }

                return React.createElement(CMenuItemViewer, {...props});
            })
        },
    },
    {
        path: "/report/:id/card",
        getComponent: (location, callback) => {
            callback(null, baseRouteFunc())
        },
    },
    ////////// </ОТЧЁТЫ>
];
