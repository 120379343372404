import {observable, action, observe, IArrayChange, IArraySplice, IObservableArray, runInAction} from "mobx"
import {defaultComparator, isIterable} from "./utils"
import {
    List as IList,
    BaseIterable
} from "./interfaces"
import {Map} from "./types"

export class ListClass<T> implements IList<T> {

    protected inner: Array<T>;

    constructor()
    constructor(source: Array<T> | Iterable<T>)
    constructor(...values: T[])
    constructor(...args: any[]) {
        if (args.length === 0) {
            this.inner = []
        } else if (args.length === 1) {
            const source = args[0]
            if (source == null) {
                this.inner = []
            } else if (Array.isArray(source)) {
                this.inner = source.slice()
            } else if (source instanceof ListClass) {
                this.inner = source.inner.slice()
            } else if (isIterable(source)) {
                this.inner = Array.from(source as Iterable<T>)
            } else {
                this.inner = [source as T]
            }
        } else {
            this.inner = args as T[]
        }
    }

    get length(): number {
        return this.inner.length;
    }

    set length(length: number) {
        this.inner.length = length;
    }

    forEach(callback: (value: T, index: number) => void): void {
        this.inner.forEach(callback);
    }

    find(predicate: (value: T, index: number) => boolean): T | undefined {
        return this.inner.find(predicate);
    }

    findEntry(predicate: (value: T, index: number) => boolean): [number, T] | undefined {
        let entry: [number, T];
        this.inner.every((item, index) => {
            if (predicate(item, index)) {
                entry = [index, item];
                return false;
            }
            return true;
        })
        return entry;
    }

    some(callback: (value: T, index: number) => boolean): boolean {
        return this.inner.some(callback);
    }

    every(predicate: (value: T, index: number) => boolean): boolean {
        return this.inner.every(predicate);
    }

    includes(value: T): boolean {
        return this.inner.includes(value);
    }

    get(index: number): T | undefined;
    get(index: number, notExistsValue: T): T;
    get(index: number, notExistsValue?: T): T | undefined {
        return this.has(index) ? this.inner[index] : notExistsValue;
    }

    has(index: number): boolean {
        return this.inner.length > index ? true : false;
    }

    first(): T | undefined {
        return this.get(0);
    }

    last(): T | undefined {
        return this.inner.length > 0 ? this.inner[this.inner.length - 1] : void 0;
    }

    reduce<R>(reducer: (reduction: R, value: T, index: number) => R, initialReduction?: R): R {
        return this.inner.reduce(reducer, initialReduction);
    }

    join(separator?: string): string {
        return this.inner.join(separator);
    }

    toArray(): Array<T> {
        return this.inner.slice();
    }

    toObject(): {} {
        const obj: {[index: string]: T} = {};
        this.inner.forEach((v, k) => {
            obj[k] = v;
        })
        return obj;
    }

    skip(amount: number): IList<T> {
        return this.slice(amount);
    }

    [Symbol.iterator](): IterableIterator<T> {
        return this.inner[Symbol.iterator]();
    }

    indexOf(value: T): number {
        return this.inner.indexOf(value);
    }

    findIndex(predicate: (value: T) => boolean): number {
        return this.inner.findIndex(predicate);
    }

    map<U>(mapper: (value: T, index: number) => U): IList<U> {
        return new ListClass(
            this.inner.map(mapper)
        );
    }

    filter(predicate: (value: T, index: number) => boolean): IList<T> {
        return new ListClass(
            this.inner.filter(predicate)
        );
    }

    filterNot(predicate: (value: T, index: number) => boolean): IList<T> {
        return new ListClass<T>(
            this.inner.filter((value, index) => !predicate(value, index))
        );
    }

    sort(comparator: (a: T, b: T) => number): IList<T> {
        return new ListClass(
            this.inner.slice().sort(comparator)
        )
    }

    sortBy<C>(comparatorValueMapper: (value: T, index: number) => C, comparator?: (a: C, v: C) => number): IList<T> {
        return this.map((v, i) => [v, comparatorValueMapper(v, i)] as [T, C])
            .sort((a, b) => (comparator || defaultComparator)(a[1], b[1]))
            .map(v => v[0])
    }

    set(index: number, value: T): this {
        if (index > this.inner.length) {
            this.inner.length = index + 1;
        }
        this.inner[index] = value;
        return this;
    }

    remove(index: number): this {
        this.inner.splice(index, 1);
        return this;
    }

    update(index: number, updater: (v: T) => T): this;
    update(index: number, notExistsValue: T, updater: (v: T) => T): this;
    update(index: number, notExistsValue: T | ((v: T) => T), updater?: (v: T) => T): this {
        let resolvedUpdater: (v: T) => T;
        let resolvedNotExistsValue: T;
        if (arguments.length === 2) {
            resolvedUpdater = notExistsValue as (v: T) => T;
        } else {
            resolvedUpdater = updater;
            resolvedNotExistsValue = notExistsValue as T;
        }
        this.set(index, resolvedUpdater(this.get(index, resolvedNotExistsValue)))
        return this;
    }

    merge(other: BaseIterable<number, T>): this {
        other.forEach((value, index) => {
            this.set(index, value);
        })
        return this;
    }

    mergeWith(merger: (previous: T, next: T, key: number) => T, other: BaseIterable<number, T>): this {
        other.forEach((value, index) => {
            if (this.has(index)) {
                this.set(index, merger(this.get(index), value, index))
            } else {
                this.set(index, value);
            }
        })
        return this;
    }

    push(...v: T[]): this {
        this.inner.push(...v);
        return this;
    }

    pop(): this {
        this.inner.pop();
        return this;
    }

    shift(): this {
        this.inner.shift();
        return this;
    }

    unshift(...elements: Array<T>): this {
        this.inner.unshift(...elements);
        return this;
    }

    splice(index: number, removeNum: number, ...values: Array<T>): this {
        this.inner.splice(index, removeNum, ...values);
        return this;
    }

    reverse(): IList<T> {
        return new ListClass(this.inner.slice().reverse())
    }

    slice(begin?: number, end?: number): IList<T> {
        return new ListClass(this.inner.slice(begin, end))
    }

    groupBy<G extends string | number>(
        grouper: (value: T, index: number) => G
    ): Map<G, IList<T>> {
        let groups = Map<G, IList<T>>()
        this.forEach((value, index) => {
            const groupKey = grouper(value, index); // this is a group key,
            const groupList = groups.has(groupKey) ? groups.get(groupKey) : new ListClass<T>();
            groupList.push(value);
            groups = groups.set(groupKey, groupList);
        })

        return groups;
    }

    concat(...other: Array<T | Iterable<T>>): IList<T> {
        let concatInner: Array<T> = this.inner;
        other.forEach(element => {
            if (isIterable(element)) {
                concatInner = concatInner.concat(Array.from(element))
            } else {
                concatInner = concatInner.concat(element);
            }
        });
        return new ListClass(concatInner);
    }
}

export class ImmutableListClass<T> extends ListClass<T> {

    constructor(...args: any[]) {
        super(...args);
        Object.freeze(this.inner);
    }
}

export class ObservableListClass<T> extends ListClass<T> {
    @observable.shallow
    protected inner: IObservableArray<T>;

    @action
    set(index: number, value: T): this {
        return super.set(index, value)
    }

    @action
    remove(index: number): this {
        return super.remove(index);
    }

    @action
    update(index: number, notExistsValue: T | ((v: T) => T), updater?: (v: T) => T): this {
        return super.update.apply(this, arguments);
    }

    @action
    merge(other: BaseIterable<number, T>): this {
        return super.merge(other);
    }

    @action
    mergeWith(merger: (previous: T, next: T, key: number) => T, other: BaseIterable<number, T>): this {
        return super.mergeWith(merger, other);
    }

    @action
    push(...v: T[]): this {
        return super.push(...v);
    }

    @action
    pop(): this {
        return super.pop();
    }

    @action
    shift(): this {
        return super.shift();
    }

    @action
    unshift(...elements: Array<T>): this {
        return super.unshift(...elements);
    }

    @action
    splice(index: number, removeNum: number, ...values: Array<T>): this {
        return super.splice(index, removeNum, ...values);
    }

    set length(newLength: number) {
        runInAction(() => this.inner.length = newLength)
    }

    get length() {
        return this.inner.length
    }

    observe(listener: (change: IArrayChange<T> | IArraySplice<T>) => void) {
        return observe(this.inner, listener)
    }
}
