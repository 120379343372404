import * as ReduxActions from "redux-actions"

export const CHANGE_SHOW_PERMISSION = "bums/common/report/edit/action/CHANGE_SHOW_PERMISSION"

export type ChangeShowPermission = ReduxActions.Action<{
    showPermission: boolean
}>

export function changeShowPermission(showPermission: boolean): ChangeShowPermission {
    return {
        type: CHANGE_SHOW_PERMISSION,
        payload: {
            showPermission
        }
    }
}
