import {PlainRoute} from "react-router"
import {PERIOD_SETTING_NAME} from "src/bums/todo/list/store/TodoLocationStore"
import {getSectionNameForCalendarByPeriod} from "src/bums/todo/utils"
import {lazy} from "src/lib/utils/lazy"

const CTodoListPageContainer = lazy(async () => (await import("src/bums/todo/list/page/CTodoListPageContainer")).CTodoListPageContainer)

export const todoRoutes: PlainRoute[] = [
    {
        path: "/event/:id/card*",
        async getComponent() {
            return (await import("./card/containers/CPageContainer")).CPageContainer
        },
    },
    {
        path: "/time/(:type(month|week|day))(/:filterId)",
        onEnter(nextState, replace) {

            if (!nextState.params?.type) {
                const calendarPeriodName = localStorage?.getItem(PERIOD_SETTING_NAME)
                const sectionName = getSectionNameForCalendarByPeriod(calendarPeriodName)
                replace({pathname: `/${sectionName}/${calendarPeriodName ? `${calendarPeriodName}` : "week"}`})
            }
        },
        component: CTodoListPageContainer
    },
    {
        path: "/todo/(:type(list | filter))(/:filterId)",
        component: CTodoListPageContainer
    },
    {
        path: "/todo/filter(/:filterId)",
        component: CTodoListPageContainer
    },
]
