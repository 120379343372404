import {EventEmitter} from "events"

export type AppStateStatus = "active" | "background"

export class AppStateStore {

    protected eventEmitter = new EventEmitter()

    public addStateListener = (state: AppStateStatus, callback: () => void) => {
        this.eventEmitter.addListener(state, callback)
    }

    public removeStateListener = (state: AppStateStatus, callback: () => void) => {
        this.eventEmitter.removeListener(state, callback)
    }
}
