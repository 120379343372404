/* tslint:disable */
import * as H from "../metadataHelper"
import {IntegerField} from "./integerField"

export interface ShiftDate extends H.BaseValue {
    days?: number; // Дни
    hours?: number; // Часы
    minutes?: number; // Минуты
    contentType: "ShiftDate"; // Object type
}

export module ShiftDate {
    export const contentType: "ShiftDate" = "ShiftDate";
    export const endpoint = "/api/v3/shiftDate";

    export const newObject: ShiftDate = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const days = {contentType: "IntegerField" as "IntegerField", name: "days", isMultiple: false, isSortable: false, id: "ShiftDate.days", defaultValue: null as IntegerField["defaultValue"]};
        export const hours = {contentType: "IntegerField" as "IntegerField", name: "hours", isMultiple: false, isSortable: false, id: "ShiftDate.hours", defaultValue: null as IntegerField["defaultValue"]};
        export const minutes = {contentType: "IntegerField" as "IntegerField", name: "minutes", isMultiple: false, isSortable: false, id: "ShiftDate.minutes", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isShiftDate(arg: any): arg is ShiftDate {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ShiftDate"
}
