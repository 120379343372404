import * as ReduxActions from "redux-actions"
import * as Api from "src/lib/entities/api"

export const ADD_NEW_PERMISSION = "bums/common/report/edit/action/ADD_NEW_PERMISSION"

export type AddNewPermission = ReduxActions.Action<{
    permission:  Api.Group|Api.Employee
}>

export function addNewPermission(permission: Api.Group|Api.Employee): AddNewPermission {
    return {
        type: ADD_NEW_PERMISSION,
        payload: {
            permission
        }
    }
}
