import * as ReduxActions from "redux-actions"

export const CHANGE_FILTER_RULES = "bums/common/report/edit/action/CHANGE_FILTER_RULES"

export type ChangeFilterRules = ReduxActions.Action<{
    filterRules: string
}>

export function changeFilterRules(filterRules: string): ChangeFilterRules {
    return {
        type: CHANGE_FILTER_RULES,
        payload: {
            filterRules
        }
    }
}


