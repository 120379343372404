import {observable} from "mobx"
import {LoadState} from "src/lib/utils/loadState"
import {ObservableListClass} from "src/lib/collections/ListClass"
import {List} from "src/lib/collections"
import {RequestOptions, BaseEntity} from "../types"

export class EntitiesList {

    @observable.ref
    public loadStateNext: LoadState = LoadState.None()

    @observable.ref
    public loadStatePrev: LoadState = LoadState.None()

    @observable
    public hasMoreNext: boolean | void

    @observable
    public hasMorePrev: boolean | void

    @observable.ref
    public items: List<BaseEntity> = new ObservableListClass<BaseEntity>()

    @observable
    public totalItemsCount: number | void

    @observable
    public loadedEndpoint: string | void

    @observable.ref
    public loadedWithOptions: RequestOptions = {}

    @observable.ref
    public invalidatedItems: List<BaseEntity> | void

    @observable
    public invalidatedTotalItemsCount: number | void

    @observable
    public usedCount = 0
}

export namespace EntitiesList {
    export interface ReadonlyEntitiesList {
        readonly loadStateNext: LoadState
        readonly loadStatePrev: LoadState
        readonly hasMoreNext: boolean | void
        readonly hasMorePrev: boolean | void
        readonly items: List<BaseEntity>
        readonly totalItemsCount: number | void
        readonly loadedEndpoint: string | void
        readonly loadedWithOptions: RequestOptions
        readonly invalidatedItems: List<BaseEntity> | void
        readonly invalidatedTotalItemsCount: number | void
        readonly usedCount: number
    }

    export class NoneObservableEntitiesList {
        public loadStateNext: LoadState = LoadState.None()
        public loadStatePrev: LoadState = LoadState.None()
        public hasMoreNext: boolean | void
        public hasMorePrev: boolean | void
        public items: List<BaseEntity> = List<BaseEntity>()
        public totalItemsCount: void
        public loadedEndpoint: string | void
        public loadedWithOptions: RequestOptions = {}
        public invalidatedItems: List<BaseEntity> | void
        public invalidatedTotalItemsCount: number | void = void 0
        public usedCount = 0
    }
}
