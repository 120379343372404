import {AbsctractSingleSubscription} from "src/lib/services/utils"
import {autobind} from "core-decorators"
import {SocketTransport} from "src/lib/services/types"

@autobind
export class OnlineSubscription extends AbsctractSingleSubscription {
    constructor(
        transport: SocketTransport,
        private callback: (data: string) => void
    ) {
        super(transport)
    }

    protected get eventName() {
        return "online"
    }

    protected eventHandle(data: string) {
        this.callback(data)
    }
}
