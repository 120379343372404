import * as ReduxActions from "redux-actions"
import * as Api from "src/lib/entities/api"

export const CHANGE_GROUP = "bums/common/report/edit/action/CHANGE_GROUP"

export type ChangeGroup = ReduxActions.Action<{
    group: Api.ReportConfigGrouping,
    oldKey: string
}>

export function changeGroup(group: Api.ReportConfigGrouping, oldKey: string): ChangeGroup {
    return {
        type: CHANGE_GROUP,
        payload: {
            group,
            oldKey
        }
    }
}
