/* tslint:disable */
import * as H from "../metadataHelper"
import {Sending} from "./sending"
import {Employee} from "./employee"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {IntegerField} from "./integerField"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"
import {DateTimeField} from "./dateTimeField"
import {EnumField} from "./enumField"

export interface SendingTemplate extends H.BaseEntity {
    sendings?: H.List<Sending>;
    sendingsCount?: number;
    userCreated?: Employee;
    name?: string;
    title?: string;
    persons?: H.List<ContractorCompany | ContractorHuman | Employee>;
    personsCount?: number;
    text?: string;
    transport?: string;
    isDropped?: boolean;
    timeCreated?: Date;
    isAuto?: boolean;
    status?: SendingTemplate.Status;
    sendingWay?: SendingTemplate.SendingWay;
    id?: string; // Идентификатор
    contentType: "SendingTemplate"; // Object type
}

export module SendingTemplate {
    export const contentType: "SendingTemplate" = "SendingTemplate";
    export const endpoint = "/api/v3/sendingTemplate";
    export type Status = "failed"|"in_progress"|"manually"|"paused"|"planned"|"sent";
    export module Status {
        export const failed: Status = "failed";
        export const in_progress: Status = "in_progress";
        export const manually: Status = "manually";
        export const paused: Status = "paused";
        export const planned: Status = "planned";
        export const sent: Status = "sent";
    }
    export type SendingWay = "all"|"first"|"prefer";
    export module SendingWay {
        export const all: SendingWay = "all";
        export const first: SendingWay = "first";
        export const prefer: SendingWay = "prefer";
    }
    export const newObject: SendingTemplate = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const sendings = {contentType: "RefLinkField" as "RefLinkField", name: "sendings", isMultiple: true, isSortable: false, id: "SendingTemplate.sendings", refContentType: H.List(["Sending"])};
        export const sendingsCount = {contentType: "IntegerField" as "IntegerField", name: "sendingsCount", isMultiple: false, isSortable: false, id: "SendingTemplate.sendingsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const userCreated = {contentType: "RefLinkField" as "RefLinkField", name: "userCreated", isMultiple: false, isSortable: false, id: "SendingTemplate.userCreated", refContentType: H.List(["Employee"])};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "SendingTemplate.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const title = {contentType: "StringField" as "StringField", name: "title", isMultiple: false, isSortable: false, id: "SendingTemplate.title", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const persons = {contentType: "RefLinkField" as "RefLinkField", name: "persons", isMultiple: true, isSortable: false, id: "SendingTemplate.persons", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const personsCount = {contentType: "IntegerField" as "IntegerField", name: "personsCount", isMultiple: false, isSortable: false, id: "SendingTemplate.personsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const text = {contentType: "StringField" as "StringField", name: "text", isMultiple: false, isSortable: false, id: "SendingTemplate.text", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const transport = {contentType: "StringField" as "StringField", name: "transport", isMultiple: false, isSortable: false, id: "SendingTemplate.transport", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isDropped = {contentType: "BoolField" as "BoolField", name: "isDropped", isMultiple: false, isSortable: false, id: "SendingTemplate.isDropped", defaultValue: null as BoolField["defaultValue"]};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: false, id: "SendingTemplate.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const isAuto = {contentType: "BoolField" as "BoolField", name: "isAuto", isMultiple: false, isSortable: false, id: "SendingTemplate.isAuto", defaultValue: null as BoolField["defaultValue"]};
        export const status = {contentType: "EnumField" as "EnumField", name: "status", isMultiple: false, isSortable: false, id: "SendingTemplate.status", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["failed", "in_progress", "manually", "paused", "planned", "sent"])};
        export const sendingWay = {contentType: "EnumField" as "EnumField", name: "sendingWay", isMultiple: false, isSortable: false, id: "SendingTemplate.sendingWay", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["all", "first", "prefer"])};
    }
}

export function isSendingTemplate(arg: any): arg is SendingTemplate {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "SendingTemplate"
}
