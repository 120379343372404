/* tslint:disable */
import * as H from "../metadataHelper"
import {AddressType} from "./addressType"
import {City} from "./city"
import {Metro} from "./metro"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface Address extends H.BaseValue {
    type?: AddressType; // Тип
    value?: string; // Значение
    isMain?: boolean; // Основной
    city?: City; // Город
    street?: string; // Улица
    house?: string; // Дом
    metro?: Metro; // Метро
    contentType: "Address"; // Object type
}

export module Address {
    export const contentType: "Address" = "Address";
    export const endpoint = "/api/v3/address";

    export const newObject: Address = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const type = {contentType: "RefLinkField" as "RefLinkField", name: "type", isMultiple: false, isSortable: false, id: "Address.type", refContentType: H.List(["AddressType"])};
        export const value = {contentType: "StringField" as "StringField", name: "value", isMultiple: false, isSortable: false, id: "Address.value", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isMain = {contentType: "BoolField" as "BoolField", name: "isMain", isMultiple: false, isSortable: false, id: "Address.isMain", defaultValue: null as BoolField["defaultValue"]};
        export const city = {contentType: "RefLinkField" as "RefLinkField", name: "city", isMultiple: false, isSortable: false, id: "Address.city", refContentType: H.List(["City"])};
        export const street = {contentType: "StringField" as "StringField", name: "street", isMultiple: false, isSortable: false, id: "Address.street", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const house = {contentType: "StringField" as "StringField", name: "house", isMultiple: false, isSortable: false, id: "Address.house", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const metro = {contentType: "RefLinkField" as "RefLinkField", name: "metro", isMultiple: false, isSortable: false, id: "Address.metro", refContentType: H.List(["Metro"])};
    }
}

export function isAddress(arg: any): arg is Address {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Address"
}
