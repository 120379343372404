/* tslint:disable */
import * as H from "../metadataHelper"
import {ContractorCompany} from "./contractorCompany"
import {SystemSetting} from "./systemSetting"
import {UserSetting} from "./userSetting"
import {ContractorType} from "./contractorType"
import {Employee} from "./employee"
import {Group} from "./group"
import {Address} from "./address"
import {ContactInfo} from "./contactInfo"
import {DateOnly} from "./dateOnly"
import {ContractorAdvertisingWay} from "./contractorAdvertisingWay"
import {ContractorActivityType} from "./contractorActivityType"
import {ContractorStatus} from "./contractorStatus"
import {Payer} from "./payer"
import {File} from "./file"
import {RelationLink} from "./relationLink"
import {Todo} from "./todo"
import {Money} from "./money"
import {Comment} from "./comment"
import {FileGroup} from "./fileGroup"
import {AttachesInfo} from "./attachesInfo"
import {InteractionCounter} from "./interactionCounter"
import {Tag} from "./tag"
import {StringField} from "./stringField"
import {EnumField} from "./enumField"
import {IntegerField} from "./integerField"
import {BoolField} from "./boolField"
import {DateField} from "./dateField"
import {DateTimeField} from "./dateTimeField"
import {MoneyField} from "./moneyField"

export interface ContractorHuman extends H.BaseEntity {
    [fieldName: string]: any;
    firstName?: string; // Имя
    middleName?: string; // Отчество
    lastName?: string; // Фамилия
    company?: ContractorCompany|ContractorHuman;
    name?: string;
    gender?: ContractorHuman.Gender;
    position?: string;
    uid?: number;
    possibleActions?: H.List<ContractorHuman.PossibleActions>; // список доступных контрактору действий
    dateLastReadNews?: string; // дата когда в последний раз читал новости в блоге
    isGuestAccessEnabled?: boolean;
    loginEmail?: string; // Почта для выдачи гостевого доступа
    canLogin?: boolean; // Может ли зайти в Мегаплан
    lastOnline?: Date|string; // Когда последний раз заходил
    settings?: H.List<SystemSetting<any> | UserSetting<any>>;
    settingsCount?: number;
    contacts?: H.List<ContractorHuman>; // Массив клиентов-физических лиц
    contactsCount?: number; // Количество клиентов-физических лиц
    type?: ContractorType;
    responsibles?: H.List<ContractorCompany | ContractorHuman | Employee | Group>;
    responsiblesCount?: number;
    contactInfo?: H.List<Address | ContactInfo>;
    contactInfoCount?: number;
    description?: string;
    textDescription?: string;
    birthday?: DateOnly;
    preferTransport?: ContractorHuman.PreferTransport;
    advertisingWay?: ContractorAdvertisingWay;
    activityType?: ContractorActivityType;
    status?: ContractorStatus;
    partners?: H.List<ContractorCompany | ContractorHuman>;
    partnersCount?: number;
    competitors?: H.List<ContractorCompany | ContractorHuman>;
    competitorsCount?: number;
    payers?: H.List<Payer>;
    payersCount?: number;
    isPublic?: boolean;
    attaches?: H.List<File>;
    attachesCount?: number;
    links?: H.List<RelationLink>;
    linksCount?: number;
    relationLinks?: H.List<RelationLink>;
    relationLinksCount?: number;
    timeCreated?: Date;
    timeUpdated?: Date;
    userCreated?: ContractorCompany|ContractorHuman|Employee;
    userUpdated?: ContractorCompany|ContractorHuman|Employee;
    nextCommunicationDate?: DateOnly|Date;
    nextCommunication?: Todo;
    lastCommunicationDate?: DateOnly|Date;
    countCommunications?: number;
    countScheduledCommunications?: number;
    countOverdueCommunications?: number;
    lastDealDate?: Date;
    countDeals?: number;
    countActiveDeals?: number;
    countPositiveDeals?: number;
    summDeals?: Money;
    summActiveDeals?: Money;
    summPositiveDeals?: Money;
    countInvoice?: number;
    countNotPaidInvoice?: number;
    summInvoice?: Money;
    summNotPaidInvoice?: Money;
    summAccountReceivable?: Money;
    summAccountReceivableOverdue?: Money;
    totalIssuesCount?: number;
    totalActiveIssuesCount?: number;
    totalInactiveIssuesCount?: number;
    totalAttachesCount?: number;
    lastComment?: Comment;
    lastCommentTimeCreated?: Date;
    balance?: Money;
    nextTaskDate?: Date;
    canSeeFull?: boolean;
    isDropped?: boolean; // Удален ли клиент
    isFavorite?: boolean; // Is it favorite for current user?
    tasksCount?: number;
    actualTasksCount?: number;
    countBalance?: number;
    messagesCount?: number;
    callsCount?: number;
    humanNumber?: number;
    actualTodosCount?: number; // Количество актуальных дел
    finishedTodosCount?: number; // Количество завершенных дел
    comments?: H.List<Comment>; // Массив комментариев
    commentsCount?: number; // Количество комментариев
    unreadCommentsCount?: number; // Количество непрочитанных комментариев
    attachesCountInComments?: number; // Количество вложений в комментариях
    subscribed?: boolean; // Подписан ли текущий пользователь на комментарии по этой сущности
    allFiles?: H.List<File | FileGroup>; // Массив файлов
    allFilesCount?: number; // Количество файлов
    attachesInfo?: AttachesInfo; // Информация о вложении
    commentsWithoutTransportCount?: number; // Количество комментариев без транспорта
    emailsCount?: number; // Количество емейлов
    whatsappCount?: number; // Количество сообщений whatsapp
    telegramCount?: number; // Количество сообщений telegram
    instagramCount?: number; // Количество сообщений instagram
    interactionsCounters?: H.List<InteractionCounter>;
    id?: string; // Идентификатор
    todos?: H.List<Todo>; // Массив дел
    todosCount?: number; // Количество дел
    avatar?: File;
    photo?: File;
    possibleSubscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники сущности, которые могут получать уведомления по ней.
    possibleSubscribersCount?: number; // Количество возможных подписчиков
    subscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники, которые подписаны на уведомления по сущности.
    subscribersCount?: number; // Количество подписчиков
    hiddenCommentsCount?: number; // Количество скрытых комментариев
    isUnread?: boolean; // Помечен как непрочитанный
    firstUnreadComment?: Comment; // Первый непрочитанный комментарий
    unreadAnswer?: boolean; // Есть ли непрочитанный ответ или упоминание
    lastView?: Date; // Дата последнего просмотра
    tags?: H.List<Tag>; // Метки
    tagsCount?: number; // Количество меток
    reminderTime?: Date; // Дата напоминания
    contentType: "ContractorHuman"; // Object type
}

export module ContractorHuman {
    export const contentType: "ContractorHuman" = "ContractorHuman";
    export const endpoint = "/api/v3/contractorHuman";
    export type Gender = "female"|"male";
    export module Gender {
        export const female: Gender = "female";
        export const male: Gender = "male";
    }
    export type PossibleActions = "act_attaches"|
        "act_create_deal"|
        "act_delete"|
        "act_edit"|
        "act_fake_drop"|
        "act_invite"|
        "act_read"|
        "act_restore"|
        "act_view_fin_operation"|
        "add_deal"|
        "add_item"|
        "addons_enter"|
        "blog_news_read"|
        "can_create_application"|
        "can_create_widgets"|
        "can_delete_the_account"|
        "can_edit_employees_settings"|
        "can_enable_import_from_email_to_deals"|
        "can_hide_menu_items"|
        "can_install_application"|
        "can_start_feature_test_drive"|
        "can_use_telephony"|
        "certificate_enter"|
        "crm_add"|
        "crm_enter"|
        "crm_filter_export"|
        "crm_leadforms"|
        "crm_loyalty"|
        "crm_sending"|
        "crm_view_onboarding_hint"|
        "deal_add"|
        "deal_enter"|
        "discuss_add"|
        "discuss_enter"|
        "doc_add"|
        "doc_enter"|
        "employee_add"|
        "employee_bonus"|
        "employee_enter"|
        "employee_filter_export"|
        "employee_groups"|
        "employee_relation"|
        "employee_report"|
        "employee_salary"|
        "employee_structure"|
        "employee_vacation"|
        "feature_download_all"|
        "feature_new_crm_card"|
        "feature_new_crm_list"|
        "feature_new_employee_card"|
        "feature_new_employee_list"|
        "feature_new_project_card"|
        "feature_new_task_card"|
        "feature_new_task_list"|
        "feature_new_todo_card"|
        "feature_new_todo_list"|
        "feature_new_topic_list"|
        "feature_new_trade_card"|
        "feature_new_trade_list"|
        "fin_operation"|
        "fin_operation_add"|
        "fin_report"|
        "fin_settings"|
        "invoice_add"|
        "invoice_enter"|
        "issues_templates_enter"|
        "message"|
        "project_admin"|
        "project_attaches_available"|
        "project_attaches_available_in_add_form"|
        "project_enter"|
        "project_tags_available"|
        "project_tags_available_in_add_form"|
        "project_todos_available"|
        "project_todos_available_in_add_form"|
        "read_full"|
        "report_add"|
        "report_enter"|
        "report_sales_department"|
        "report_sales_funnel"|
        "settings_account_area"|
        "settings_cards_advertising_ways"|
        "settings_cards_business_processes"|
        "settings_cards_contractors"|
        "settings_cards_contractors_activity_types"|
        "settings_cards_contractors_custom_fields"|
        "settings_cards_contractors_types"|
        "settings_cards_countries"|
        "settings_cards_currencies"|
        "settings_cards_discussion_sections"|
        "settings_cards_employees"|
        "settings_cards_enter"|
        "settings_cards_event_categories"|
        "settings_cards_events_places"|
        "settings_cards_events_statuses"|
        "settings_cards_invoice_templates"|
        "settings_cards_invoice_units"|
        "settings_cards_managers_rights"|
        "settings_cards_offers"|
        "settings_cards_offers_custom_fields"|
        "settings_cards_positions"|
        "settings_cards_projects_custom_fields"|
        "settings_cards_requisites"|
        "settings_cards_sending"|
        "settings_cards_tasks_and_projects"|
        "settings_cards_tasks_custom_fields"|
        "settings_cards_taxes"|
        "settings_cards_vacation_types"|
        "settings_deals_deals_programs"|
        "settings_deals_delivery_types"|
        "settings_deals_enter"|
        "settings_deals_import_deals_from_email"|
        "settings_deals_integrate"|
        "settings_deals_pay_forms"|
        "settings_deals_program_wizard"|
        "settings_file_storage_info"|
        "settings_personal_setting_enter"|
        "settings_personal_setting_notifications"|
        "settings_services_integration_email_communications"|
        "settings_services_integration_eneter"|
        "settings_services_integration_imap_integration"|
        "settings_services_integration_imap_integration_for_deals"|
        "settings_services_integration_newsletters_and_notifications"|
        "settings_services_integration_outcoming_email"|
        "settings_services_integration_reply_to_notifications"|
        "settings_services_integration_rts_tender_integration"|
        "settings_services_integration_telephony"|
        "settings_setting_account_security"|
        "settings_setting_backup_management"|
        "settings_setting_employees_rights"|
        "settings_setting_enter"|
        "settings_setting_interface"|
        "settings_setting_mailouts_mail_server"|
        "settings_setting_menu_items"|
        "settings_setting_product_version"|
        "settings_setting_system"|
        "settings_setting_tasks"|
        "settings_setting_transition_box"|
        "settings_staff_can_hide_menu_items"|
        "task"|
        "task_attaches_available"|
        "task_attaches_available_in_add_form"|
        "task_filter_export"|
        "task_tags_available"|
        "task_tags_available_in_add_form"|
        "task_todos_available"|
        "task_todos_available_in_add_form"|
        "tasks_add"|
        "tasks_delete"|
        "tasks_enter"|
        "todos_add"|
        "todos_enter"|
        "trade_filter_export";
    export module PossibleActions {
        export const act_attaches: PossibleActions = "act_attaches";
        export const act_create_deal: PossibleActions = "act_create_deal";
        export const act_delete: PossibleActions = "act_delete";
        export const act_edit: PossibleActions = "act_edit";
        export const act_fake_drop: PossibleActions = "act_fake_drop";
        export const act_invite: PossibleActions = "act_invite";
        export const act_read: PossibleActions = "act_read";
        export const act_restore: PossibleActions = "act_restore";
        export const act_view_fin_operation: PossibleActions = "act_view_fin_operation";
        export const add_deal: PossibleActions = "add_deal";
        export const add_item: PossibleActions = "add_item";
        export const addons_enter: PossibleActions = "addons_enter";
        export const blog_news_read: PossibleActions = "blog_news_read";
        export const can_create_application: PossibleActions = "can_create_application";
        export const can_create_widgets: PossibleActions = "can_create_widgets";
        export const can_delete_the_account: PossibleActions = "can_delete_the_account";
        export const can_edit_employees_settings: PossibleActions = "can_edit_employees_settings";
        export const can_enable_import_from_email_to_deals: PossibleActions = "can_enable_import_from_email_to_deals";
        export const can_hide_menu_items: PossibleActions = "can_hide_menu_items";
        export const can_install_application: PossibleActions = "can_install_application";
        export const can_start_feature_test_drive: PossibleActions = "can_start_feature_test_drive";
        export const can_use_telephony: PossibleActions = "can_use_telephony";
        export const certificate_enter: PossibleActions = "certificate_enter";
        export const crm_add: PossibleActions = "crm_add";
        export const crm_enter: PossibleActions = "crm_enter";
        export const crm_filter_export: PossibleActions = "crm_filter_export";
        export const crm_leadforms: PossibleActions = "crm_leadforms";
        export const crm_loyalty: PossibleActions = "crm_loyalty";
        export const crm_sending: PossibleActions = "crm_sending";
        export const crm_view_onboarding_hint: PossibleActions = "crm_view_onboarding_hint";
        export const deal_add: PossibleActions = "deal_add";
        export const deal_enter: PossibleActions = "deal_enter";
        export const discuss_add: PossibleActions = "discuss_add";
        export const discuss_enter: PossibleActions = "discuss_enter";
        export const doc_add: PossibleActions = "doc_add";
        export const doc_enter: PossibleActions = "doc_enter";
        export const employee_add: PossibleActions = "employee_add";
        export const employee_bonus: PossibleActions = "employee_bonus";
        export const employee_enter: PossibleActions = "employee_enter";
        export const employee_filter_export: PossibleActions = "employee_filter_export";
        export const employee_groups: PossibleActions = "employee_groups";
        export const employee_relation: PossibleActions = "employee_relation";
        export const employee_report: PossibleActions = "employee_report";
        export const employee_salary: PossibleActions = "employee_salary";
        export const employee_structure: PossibleActions = "employee_structure";
        export const employee_vacation: PossibleActions = "employee_vacation";
        export const feature_download_all: PossibleActions = "feature_download_all";
        export const feature_new_crm_card: PossibleActions = "feature_new_crm_card";
        export const feature_new_crm_list: PossibleActions = "feature_new_crm_list";
        export const feature_new_employee_card: PossibleActions = "feature_new_employee_card";
        export const feature_new_employee_list: PossibleActions = "feature_new_employee_list";
        export const feature_new_project_card: PossibleActions = "feature_new_project_card";
        export const feature_new_task_card: PossibleActions = "feature_new_task_card";
        export const feature_new_task_list: PossibleActions = "feature_new_task_list";
        export const feature_new_todo_card: PossibleActions = "feature_new_todo_card";
        export const feature_new_todo_list: PossibleActions = "feature_new_todo_list";
        export const feature_new_topic_list: PossibleActions = "feature_new_topic_list";
        export const feature_new_trade_card: PossibleActions = "feature_new_trade_card";
        export const feature_new_trade_list: PossibleActions = "feature_new_trade_list";
        export const fin_operation: PossibleActions = "fin_operation";
        export const fin_operation_add: PossibleActions = "fin_operation_add";
        export const fin_report: PossibleActions = "fin_report";
        export const fin_settings: PossibleActions = "fin_settings";
        export const invoice_add: PossibleActions = "invoice_add";
        export const invoice_enter: PossibleActions = "invoice_enter";
        export const issues_templates_enter: PossibleActions = "issues_templates_enter";
        export const message: PossibleActions = "message";
        export const project_admin: PossibleActions = "project_admin";
        export const project_attaches_available: PossibleActions = "project_attaches_available";
        export const project_attaches_available_in_add_form: PossibleActions = "project_attaches_available_in_add_form";
        export const project_enter: PossibleActions = "project_enter";
        export const project_tags_available: PossibleActions = "project_tags_available";
        export const project_tags_available_in_add_form: PossibleActions = "project_tags_available_in_add_form";
        export const project_todos_available: PossibleActions = "project_todos_available";
        export const project_todos_available_in_add_form: PossibleActions = "project_todos_available_in_add_form";
        export const read_full: PossibleActions = "read_full";
        export const report_add: PossibleActions = "report_add";
        export const report_enter: PossibleActions = "report_enter";
        export const report_sales_department: PossibleActions = "report_sales_department";
        export const report_sales_funnel: PossibleActions = "report_sales_funnel";
        export const settings_account_area: PossibleActions = "settings_account_area";
        export const settings_cards_advertising_ways: PossibleActions = "settings_cards_advertising_ways";
        export const settings_cards_business_processes: PossibleActions = "settings_cards_business_processes";
        export const settings_cards_contractors: PossibleActions = "settings_cards_contractors";
        export const settings_cards_contractors_activity_types: PossibleActions = "settings_cards_contractors_activity_types";
        export const settings_cards_contractors_custom_fields: PossibleActions = "settings_cards_contractors_custom_fields";
        export const settings_cards_contractors_types: PossibleActions = "settings_cards_contractors_types";
        export const settings_cards_countries: PossibleActions = "settings_cards_countries";
        export const settings_cards_currencies: PossibleActions = "settings_cards_currencies";
        export const settings_cards_discussion_sections: PossibleActions = "settings_cards_discussion_sections";
        export const settings_cards_employees: PossibleActions = "settings_cards_employees";
        export const settings_cards_enter: PossibleActions = "settings_cards_enter";
        export const settings_cards_event_categories: PossibleActions = "settings_cards_event_categories";
        export const settings_cards_events_places: PossibleActions = "settings_cards_events_places";
        export const settings_cards_events_statuses: PossibleActions = "settings_cards_events_statuses";
        export const settings_cards_invoice_templates: PossibleActions = "settings_cards_invoice_templates";
        export const settings_cards_invoice_units: PossibleActions = "settings_cards_invoice_units";
        export const settings_cards_managers_rights: PossibleActions = "settings_cards_managers_rights";
        export const settings_cards_offers: PossibleActions = "settings_cards_offers";
        export const settings_cards_offers_custom_fields: PossibleActions = "settings_cards_offers_custom_fields";
        export const settings_cards_positions: PossibleActions = "settings_cards_positions";
        export const settings_cards_projects_custom_fields: PossibleActions = "settings_cards_projects_custom_fields";
        export const settings_cards_requisites: PossibleActions = "settings_cards_requisites";
        export const settings_cards_sending: PossibleActions = "settings_cards_sending";
        export const settings_cards_tasks_and_projects: PossibleActions = "settings_cards_tasks_and_projects";
        export const settings_cards_tasks_custom_fields: PossibleActions = "settings_cards_tasks_custom_fields";
        export const settings_cards_taxes: PossibleActions = "settings_cards_taxes";
        export const settings_cards_vacation_types: PossibleActions = "settings_cards_vacation_types";
        export const settings_deals_deals_programs: PossibleActions = "settings_deals_deals_programs";
        export const settings_deals_delivery_types: PossibleActions = "settings_deals_delivery_types";
        export const settings_deals_enter: PossibleActions = "settings_deals_enter";
        export const settings_deals_import_deals_from_email: PossibleActions = "settings_deals_import_deals_from_email";
        export const settings_deals_integrate: PossibleActions = "settings_deals_integrate";
        export const settings_deals_pay_forms: PossibleActions = "settings_deals_pay_forms";
        export const settings_deals_program_wizard: PossibleActions = "settings_deals_program_wizard";
        export const settings_file_storage_info: PossibleActions = "settings_file_storage_info";
        export const settings_personal_setting_enter: PossibleActions = "settings_personal_setting_enter";
        export const settings_personal_setting_notifications: PossibleActions = "settings_personal_setting_notifications";
        export const settings_services_integration_email_communications: PossibleActions = "settings_services_integration_email_communications";
        export const settings_services_integration_eneter: PossibleActions = "settings_services_integration_eneter";
        export const settings_services_integration_imap_integration: PossibleActions = "settings_services_integration_imap_integration";
        export const settings_services_integration_imap_integration_for_deals: PossibleActions = "settings_services_integration_imap_integration_for_deals";
        export const settings_services_integration_newsletters_and_notifications: PossibleActions = "settings_services_integration_newsletters_and_notifications";
        export const settings_services_integration_outcoming_email: PossibleActions = "settings_services_integration_outcoming_email";
        export const settings_services_integration_reply_to_notifications: PossibleActions = "settings_services_integration_reply_to_notifications";
        export const settings_services_integration_rts_tender_integration: PossibleActions = "settings_services_integration_rts_tender_integration";
        export const settings_services_integration_telephony: PossibleActions = "settings_services_integration_telephony";
        export const settings_setting_account_security: PossibleActions = "settings_setting_account_security";
        export const settings_setting_backup_management: PossibleActions = "settings_setting_backup_management";
        export const settings_setting_employees_rights: PossibleActions = "settings_setting_employees_rights";
        export const settings_setting_enter: PossibleActions = "settings_setting_enter";
        export const settings_setting_interface: PossibleActions = "settings_setting_interface";
        export const settings_setting_mailouts_mail_server: PossibleActions = "settings_setting_mailouts_mail_server";
        export const settings_setting_menu_items: PossibleActions = "settings_setting_menu_items";
        export const settings_setting_product_version: PossibleActions = "settings_setting_product_version";
        export const settings_setting_system: PossibleActions = "settings_setting_system";
        export const settings_setting_tasks: PossibleActions = "settings_setting_tasks";
        export const settings_setting_transition_box: PossibleActions = "settings_setting_transition_box";
        export const settings_staff_can_hide_menu_items: PossibleActions = "settings_staff_can_hide_menu_items";
        export const task: PossibleActions = "task";
        export const task_attaches_available: PossibleActions = "task_attaches_available";
        export const task_attaches_available_in_add_form: PossibleActions = "task_attaches_available_in_add_form";
        export const task_filter_export: PossibleActions = "task_filter_export";
        export const task_tags_available: PossibleActions = "task_tags_available";
        export const task_tags_available_in_add_form: PossibleActions = "task_tags_available_in_add_form";
        export const task_todos_available: PossibleActions = "task_todos_available";
        export const task_todos_available_in_add_form: PossibleActions = "task_todos_available_in_add_form";
        export const tasks_add: PossibleActions = "tasks_add";
        export const tasks_delete: PossibleActions = "tasks_delete";
        export const tasks_enter: PossibleActions = "tasks_enter";
        export const todos_add: PossibleActions = "todos_add";
        export const todos_enter: PossibleActions = "todos_enter";
        export const trade_filter_export: PossibleActions = "trade_filter_export";
    }
    export type PreferTransport = "email"|"informer"|"skype"|"sms";
    export module PreferTransport {
        export const email: PreferTransport = "email";
        export const informer: PreferTransport = "informer";
        export const skype: PreferTransport = "skype";
        export const sms: PreferTransport = "sms";
    }
    export const newObject: ContractorHuman = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const firstName = {contentType: "StringField" as "StringField", name: "firstName", isMultiple: false, isSortable: true, id: "ContractorHuman.firstName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const middleName = {contentType: "StringField" as "StringField", name: "middleName", isMultiple: false, isSortable: false, id: "ContractorHuman.middleName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const lastName = {contentType: "StringField" as "StringField", name: "lastName", isMultiple: false, isSortable: false, id: "ContractorHuman.lastName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const company = {contentType: "RefLinkField" as "RefLinkField", name: "company", isMultiple: false, isSortable: true, id: "ContractorHuman.company", refContentType: H.List(["ContractorCompany", "ContractorHuman"])};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "ContractorHuman.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const gender = {contentType: "EnumField" as "EnumField", name: "gender", isMultiple: false, isSortable: true, id: "ContractorHuman.gender", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["female", "male"])};
        export const position = {contentType: "StringField" as "StringField", name: "position", isMultiple: false, isSortable: true, id: "ContractorHuman.position", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const uid = {contentType: "IntegerField" as "IntegerField", name: "uid", isMultiple: false, isSortable: false, id: "ContractorHuman.uid", defaultValue: null as IntegerField["defaultValue"]};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "ContractorHuman.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List([
            "act_attaches",
            "act_create_deal",
            "act_delete",
            "act_edit",
            "act_fake_drop",
            "act_invite",
            "act_read",
            "act_restore",
            "act_view_fin_operation",
            "add_deal",
            "add_item",
            "addons_enter",
            "blog_news_read",
            "can_create_application",
            "can_create_widgets",
            "can_delete_the_account",
            "can_edit_employees_settings",
            "can_enable_import_from_email_to_deals",
            "can_hide_menu_items",
            "can_install_application",
            "can_start_feature_test_drive",
            "can_use_telephony",
            "certificate_enter",
            "crm_add",
            "crm_enter",
            "crm_filter_export",
            "crm_leadforms",
            "crm_loyalty",
            "crm_sending",
            "crm_view_onboarding_hint",
            "deal_add",
            "deal_enter",
            "discuss_add",
            "discuss_enter",
            "doc_add",
            "doc_enter",
            "employee_add",
            "employee_bonus",
            "employee_enter",
            "employee_filter_export",
            "employee_groups",
            "employee_relation",
            "employee_report",
            "employee_salary",
            "employee_structure",
            "employee_vacation",
            "feature_download_all",
            "feature_new_crm_card",
            "feature_new_crm_list",
            "feature_new_employee_card",
            "feature_new_employee_list",
            "feature_new_project_card",
            "feature_new_task_card",
            "feature_new_task_list",
            "feature_new_todo_card",
            "feature_new_todo_list",
            "feature_new_topic_list",
            "feature_new_trade_card",
            "feature_new_trade_list",
            "fin_operation",
            "fin_operation_add",
            "fin_report",
            "fin_settings",
            "invoice_add",
            "invoice_enter",
            "issues_templates_enter",
            "message",
            "project_admin",
            "project_attaches_available",
            "project_attaches_available_in_add_form",
            "project_enter",
            "project_tags_available",
            "project_tags_available_in_add_form",
            "project_todos_available",
            "project_todos_available_in_add_form",
            "read_full",
            "report_add",
            "report_enter",
            "report_sales_department",
            "report_sales_funnel",
            "settings_account_area",
            "settings_cards_advertising_ways",
            "settings_cards_business_processes",
            "settings_cards_contractors",
            "settings_cards_contractors_activity_types",
            "settings_cards_contractors_custom_fields",
            "settings_cards_contractors_types",
            "settings_cards_countries",
            "settings_cards_currencies",
            "settings_cards_discussion_sections",
            "settings_cards_employees",
            "settings_cards_enter",
            "settings_cards_event_categories",
            "settings_cards_events_places",
            "settings_cards_events_statuses",
            "settings_cards_invoice_templates",
            "settings_cards_invoice_units",
            "settings_cards_managers_rights",
            "settings_cards_offers",
            "settings_cards_offers_custom_fields",
            "settings_cards_positions",
            "settings_cards_projects_custom_fields",
            "settings_cards_requisites",
            "settings_cards_sending",
            "settings_cards_tasks_and_projects",
            "settings_cards_tasks_custom_fields",
            "settings_cards_taxes",
            "settings_cards_vacation_types",
            "settings_deals_deals_programs",
            "settings_deals_delivery_types",
            "settings_deals_enter",
            "settings_deals_import_deals_from_email",
            "settings_deals_integrate",
            "settings_deals_pay_forms",
            "settings_deals_program_wizard",
            "settings_file_storage_info",
            "settings_personal_setting_enter",
            "settings_personal_setting_notifications",
            "settings_services_integration_email_communications",
            "settings_services_integration_eneter",
            "settings_services_integration_imap_integration",
            "settings_services_integration_imap_integration_for_deals",
            "settings_services_integration_newsletters_and_notifications",
            "settings_services_integration_outcoming_email",
            "settings_services_integration_reply_to_notifications",
            "settings_services_integration_rts_tender_integration",
            "settings_services_integration_telephony",
            "settings_setting_account_security",
            "settings_setting_backup_management",
            "settings_setting_employees_rights",
            "settings_setting_enter",
            "settings_setting_interface",
            "settings_setting_mailouts_mail_server",
            "settings_setting_menu_items",
            "settings_setting_product_version",
            "settings_setting_system",
            "settings_setting_tasks",
            "settings_setting_transition_box",
            "settings_staff_can_hide_menu_items",
            "task",
            "task_attaches_available",
            "task_attaches_available_in_add_form",
            "task_filter_export",
            "task_tags_available",
            "task_tags_available_in_add_form",
            "task_todos_available",
            "task_todos_available_in_add_form",
            "tasks_add",
            "tasks_delete",
            "tasks_enter",
            "todos_add",
            "todos_enter",
            "trade_filter_export"
        ])};
        export const dateLastReadNews = {contentType: "StringField" as "StringField", name: "dateLastReadNews", isMultiple: false, isSortable: false, id: "ContractorHuman.dateLastReadNews", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isGuestAccessEnabled = {contentType: "BoolField" as "BoolField", name: "isGuestAccessEnabled", isMultiple: false, isSortable: false, id: "ContractorHuman.isGuestAccessEnabled", defaultValue: null as BoolField["defaultValue"]};
        export const loginEmail = {contentType: "StringField" as "StringField", name: "loginEmail", isMultiple: false, isSortable: false, id: "ContractorHuman.loginEmail", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const canLogin = {contentType: "BoolField" as "BoolField", name: "canLogin", isMultiple: false, isSortable: false, id: "ContractorHuman.canLogin", defaultValue: null as BoolField["defaultValue"]};
        export const lastOnline = {contentType: "RefLinkField" as "RefLinkField", name: "lastOnline", isMultiple: false, isSortable: false, id: "ContractorHuman.lastOnline", refContentType: H.List(["DateTime", "string"])};
        export const settings = {contentType: "RefLinkField" as "RefLinkField", name: "settings", isMultiple: true, isSortable: false, id: "ContractorHuman.settings", refContentType: H.List(["SystemSetting", "UserSetting"])};
        export const settingsCount = {contentType: "IntegerField" as "IntegerField", name: "settingsCount", isMultiple: false, isSortable: false, id: "ContractorHuman.settingsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const contacts = {contentType: "RefLinkField" as "RefLinkField", name: "contacts", isMultiple: true, isSortable: false, id: "ContractorHuman.contacts", refContentType: H.List(["ContractorHuman"])};
        export const contactsCount = {contentType: "IntegerField" as "IntegerField", name: "contactsCount", isMultiple: false, isSortable: false, id: "ContractorHuman.contactsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const type = {contentType: "RefLinkField" as "RefLinkField", name: "type", isMultiple: false, isSortable: true, id: "ContractorHuman.type", refContentType: H.List(["ContractorType"])};
        export const responsibles = {contentType: "RefLinkField" as "RefLinkField", name: "responsibles", isMultiple: true, isSortable: true, id: "ContractorHuman.responsibles", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const responsiblesCount = {contentType: "IntegerField" as "IntegerField", name: "responsiblesCount", isMultiple: false, isSortable: false, id: "ContractorHuman.responsiblesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const contactInfo = {contentType: "RefLinkField" as "RefLinkField", name: "contactInfo", isMultiple: true, isSortable: false, id: "ContractorHuman.contactInfo", refContentType: H.List(["Address", "ContactInfo"])};
        export const contactInfoCount = {contentType: "IntegerField" as "IntegerField", name: "contactInfoCount", isMultiple: false, isSortable: false, id: "ContractorHuman.contactInfoCount", defaultValue: null as IntegerField["defaultValue"]};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: false, id: "ContractorHuman.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const textDescription = {contentType: "StringField" as "StringField", name: "textDescription", isMultiple: false, isSortable: false, id: "ContractorHuman.textDescription", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const birthday = {contentType: "DateField" as "DateField", name: "birthday", isMultiple: false, isSortable: true, id: "ContractorHuman.birthday", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const preferTransport = {contentType: "EnumField" as "EnumField", name: "preferTransport", isMultiple: false, isSortable: true, id: "ContractorHuman.preferTransport", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["email", "informer", "skype", "sms"])};
        export const advertisingWay = {contentType: "RefLinkField" as "RefLinkField", name: "advertisingWay", isMultiple: false, isSortable: true, id: "ContractorHuman.advertisingWay", refContentType: H.List(["ContractorAdvertisingWay"])};
        export const activityType = {contentType: "RefLinkField" as "RefLinkField", name: "activityType", isMultiple: false, isSortable: true, id: "ContractorHuman.activityType", refContentType: H.List(["ContractorActivityType"])};
        export const status = {contentType: "RefLinkField" as "RefLinkField", name: "status", isMultiple: false, isSortable: true, id: "ContractorHuman.status", refContentType: H.List(["ContractorStatus"])};
        export const partners = {contentType: "RefLinkField" as "RefLinkField", name: "partners", isMultiple: true, isSortable: true, id: "ContractorHuman.partners", refContentType: H.List(["ContractorCompany", "ContractorHuman"])};
        export const partnersCount = {contentType: "IntegerField" as "IntegerField", name: "partnersCount", isMultiple: false, isSortable: false, id: "ContractorHuman.partnersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const competitors = {contentType: "RefLinkField" as "RefLinkField", name: "competitors", isMultiple: true, isSortable: true, id: "ContractorHuman.competitors", refContentType: H.List(["ContractorCompany", "ContractorHuman"])};
        export const competitorsCount = {contentType: "IntegerField" as "IntegerField", name: "competitorsCount", isMultiple: false, isSortable: false, id: "ContractorHuman.competitorsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const payers = {contentType: "RefLinkField" as "RefLinkField", name: "payers", isMultiple: true, isSortable: false, id: "ContractorHuman.payers", refContentType: H.List(["Payer"])};
        export const payersCount = {contentType: "IntegerField" as "IntegerField", name: "payersCount", isMultiple: false, isSortable: false, id: "ContractorHuman.payersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isPublic = {contentType: "BoolField" as "BoolField", name: "isPublic", isMultiple: false, isSortable: true, id: "ContractorHuman.isPublic", defaultValue: null as BoolField["defaultValue"]};
        export const attaches = {contentType: "RefLinkField" as "RefLinkField", name: "attaches", isMultiple: true, isSortable: false, id: "ContractorHuman.attaches", refContentType: H.List(["File"])};
        export const attachesCount = {contentType: "IntegerField" as "IntegerField", name: "attachesCount", isMultiple: false, isSortable: false, id: "ContractorHuman.attachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const links = {contentType: "RefLinkField" as "RefLinkField", name: "links", isMultiple: true, isSortable: false, id: "ContractorHuman.links", refContentType: H.List(["RelationLink"])};
        export const linksCount = {contentType: "IntegerField" as "IntegerField", name: "linksCount", isMultiple: false, isSortable: false, id: "ContractorHuman.linksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const relationLinks = {contentType: "RefLinkField" as "RefLinkField", name: "relationLinks", isMultiple: true, isSortable: false, id: "ContractorHuman.relationLinks", refContentType: H.List(["RelationLink"])};
        export const relationLinksCount = {contentType: "IntegerField" as "IntegerField", name: "relationLinksCount", isMultiple: false, isSortable: false, id: "ContractorHuman.relationLinksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: true, id: "ContractorHuman.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const timeUpdated = {contentType: "DateTimeField" as "DateTimeField", name: "timeUpdated", isMultiple: false, isSortable: true, id: "ContractorHuman.timeUpdated", defaultValue: null as DateTimeField["defaultValue"]};
        export const userCreated = {contentType: "RefLinkField" as "RefLinkField", name: "userCreated", isMultiple: false, isSortable: false, id: "ContractorHuman.userCreated", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const userUpdated = {contentType: "RefLinkField" as "RefLinkField", name: "userUpdated", isMultiple: false, isSortable: false, id: "ContractorHuman.userUpdated", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const nextCommunicationDate = {contentType: "RefLinkField" as "RefLinkField", name: "nextCommunicationDate", isMultiple: false, isSortable: true, id: "ContractorHuman.nextCommunicationDate", refContentType: H.List(["DateOnly", "DateTime"])};
        export const nextCommunication = {contentType: "RefLinkField" as "RefLinkField", name: "nextCommunication", isMultiple: false, isSortable: false, id: "ContractorHuman.nextCommunication", refContentType: H.List(["Todo"])};
        export const lastCommunicationDate = {contentType: "RefLinkField" as "RefLinkField", name: "lastCommunicationDate", isMultiple: false, isSortable: true, id: "ContractorHuman.lastCommunicationDate", refContentType: H.List(["DateOnly", "DateTime"])};
        export const countCommunications = {contentType: "IntegerField" as "IntegerField", name: "countCommunications", isMultiple: false, isSortable: true, id: "ContractorHuman.countCommunications", defaultValue: null as IntegerField["defaultValue"]};
        export const countScheduledCommunications = {contentType: "IntegerField" as "IntegerField", name: "countScheduledCommunications", isMultiple: false, isSortable: true, id: "ContractorHuman.countScheduledCommunications", defaultValue: null as IntegerField["defaultValue"]};
        export const countOverdueCommunications = {contentType: "IntegerField" as "IntegerField", name: "countOverdueCommunications", isMultiple: false, isSortable: false, id: "ContractorHuman.countOverdueCommunications", defaultValue: null as IntegerField["defaultValue"]};
        export const lastDealDate = {contentType: "DateTimeField" as "DateTimeField", name: "lastDealDate", isMultiple: false, isSortable: true, id: "ContractorHuman.lastDealDate", defaultValue: null as DateTimeField["defaultValue"]};
        export const countDeals = {contentType: "IntegerField" as "IntegerField", name: "countDeals", isMultiple: false, isSortable: true, id: "ContractorHuman.countDeals", defaultValue: null as IntegerField["defaultValue"]};
        export const countActiveDeals = {contentType: "IntegerField" as "IntegerField", name: "countActiveDeals", isMultiple: false, isSortable: true, id: "ContractorHuman.countActiveDeals", defaultValue: null as IntegerField["defaultValue"]};
        export const countPositiveDeals = {contentType: "IntegerField" as "IntegerField", name: "countPositiveDeals", isMultiple: false, isSortable: true, id: "ContractorHuman.countPositiveDeals", defaultValue: null as IntegerField["defaultValue"]};
        export const summDeals = {contentType: "MoneyField" as "MoneyField", name: "summDeals", isMultiple: false, isSortable: true, id: "ContractorHuman.summDeals", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const summActiveDeals = {contentType: "MoneyField" as "MoneyField", name: "summActiveDeals", isMultiple: false, isSortable: true, id: "ContractorHuman.summActiveDeals", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const summPositiveDeals = {contentType: "MoneyField" as "MoneyField", name: "summPositiveDeals", isMultiple: false, isSortable: true, id: "ContractorHuman.summPositiveDeals", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const countInvoice = {contentType: "IntegerField" as "IntegerField", name: "countInvoice", isMultiple: false, isSortable: true, id: "ContractorHuman.countInvoice", defaultValue: null as IntegerField["defaultValue"]};
        export const countNotPaidInvoice = {contentType: "IntegerField" as "IntegerField", name: "countNotPaidInvoice", isMultiple: false, isSortable: true, id: "ContractorHuman.countNotPaidInvoice", defaultValue: null as IntegerField["defaultValue"]};
        export const summInvoice = {contentType: "MoneyField" as "MoneyField", name: "summInvoice", isMultiple: false, isSortable: true, id: "ContractorHuman.summInvoice", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const summNotPaidInvoice = {contentType: "MoneyField" as "MoneyField", name: "summNotPaidInvoice", isMultiple: false, isSortable: true, id: "ContractorHuman.summNotPaidInvoice", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const summAccountReceivable = {contentType: "MoneyField" as "MoneyField", name: "summAccountReceivable", isMultiple: false, isSortable: true, id: "ContractorHuman.summAccountReceivable", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const summAccountReceivableOverdue = {contentType: "MoneyField" as "MoneyField", name: "summAccountReceivableOverdue", isMultiple: false, isSortable: true, id: "ContractorHuman.summAccountReceivableOverdue", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const totalIssuesCount = {contentType: "IntegerField" as "IntegerField", name: "totalIssuesCount", isMultiple: false, isSortable: true, id: "ContractorHuman.totalIssuesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const totalActiveIssuesCount = {contentType: "IntegerField" as "IntegerField", name: "totalActiveIssuesCount", isMultiple: false, isSortable: true, id: "ContractorHuman.totalActiveIssuesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const totalInactiveIssuesCount = {contentType: "IntegerField" as "IntegerField", name: "totalInactiveIssuesCount", isMultiple: false, isSortable: true, id: "ContractorHuman.totalInactiveIssuesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const totalAttachesCount = {contentType: "IntegerField" as "IntegerField", name: "totalAttachesCount", isMultiple: false, isSortable: false, id: "ContractorHuman.totalAttachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const lastComment = {contentType: "RefLinkField" as "RefLinkField", name: "lastComment", isMultiple: false, isSortable: false, id: "ContractorHuman.lastComment", refContentType: H.List(["Comment"])};
        export const lastCommentTimeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "lastCommentTimeCreated", isMultiple: false, isSortable: true, id: "ContractorHuman.lastCommentTimeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const balance = {contentType: "MoneyField" as "MoneyField", name: "balance", isMultiple: false, isSortable: true, id: "ContractorHuman.balance", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const nextTaskDate = {contentType: "DateTimeField" as "DateTimeField", name: "nextTaskDate", isMultiple: false, isSortable: false, id: "ContractorHuman.nextTaskDate", defaultValue: null as DateTimeField["defaultValue"]};
        export const canSeeFull = {contentType: "BoolField" as "BoolField", name: "canSeeFull", isMultiple: false, isSortable: false, id: "ContractorHuman.canSeeFull", defaultValue: null as BoolField["defaultValue"]};
        export const isDropped = {contentType: "BoolField" as "BoolField", name: "isDropped", isMultiple: false, isSortable: false, id: "ContractorHuman.isDropped", defaultValue: null as BoolField["defaultValue"]};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: true, id: "ContractorHuman.isFavorite", defaultValue: null as BoolField["defaultValue"]};
        export const tasksCount = {contentType: "IntegerField" as "IntegerField", name: "tasksCount", isMultiple: false, isSortable: false, id: "ContractorHuman.tasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const actualTasksCount = {contentType: "IntegerField" as "IntegerField", name: "actualTasksCount", isMultiple: false, isSortable: false, id: "ContractorHuman.actualTasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const countBalance = {contentType: "IntegerField" as "IntegerField", name: "countBalance", isMultiple: false, isSortable: false, id: "ContractorHuman.countBalance", defaultValue: null as IntegerField["defaultValue"]};
        export const messagesCount = {contentType: "IntegerField" as "IntegerField", name: "messagesCount", isMultiple: false, isSortable: false, id: "ContractorHuman.messagesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const callsCount = {contentType: "IntegerField" as "IntegerField", name: "callsCount", isMultiple: false, isSortable: false, id: "ContractorHuman.callsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const humanNumber = {contentType: "IntegerField" as "IntegerField", name: "humanNumber", isMultiple: false, isSortable: true, id: "ContractorHuman.humanNumber", defaultValue: null as IntegerField["defaultValue"]};
        export const actualTodosCount = {contentType: "IntegerField" as "IntegerField", name: "actualTodosCount", isMultiple: false, isSortable: false, id: "ContractorHuman.actualTodosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const finishedTodosCount = {contentType: "IntegerField" as "IntegerField", name: "finishedTodosCount", isMultiple: false, isSortable: false, id: "ContractorHuman.finishedTodosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const comments = {contentType: "RefLinkField" as "RefLinkField", name: "comments", isMultiple: true, isSortable: false, id: "ContractorHuman.comments", refContentType: H.List(["Comment"])};
        export const commentsCount = {contentType: "IntegerField" as "IntegerField", name: "commentsCount", isMultiple: false, isSortable: false, id: "ContractorHuman.commentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const unreadCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "unreadCommentsCount", isMultiple: false, isSortable: false, id: "ContractorHuman.unreadCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesCountInComments = {contentType: "IntegerField" as "IntegerField", name: "attachesCountInComments", isMultiple: false, isSortable: false, id: "ContractorHuman.attachesCountInComments", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribed = {contentType: "BoolField" as "BoolField", name: "subscribed", isMultiple: false, isSortable: false, id: "ContractorHuman.subscribed", defaultValue: null as BoolField["defaultValue"]};
        export const allFiles = {contentType: "RefLinkField" as "RefLinkField", name: "allFiles", isMultiple: true, isSortable: false, id: "ContractorHuman.allFiles", refContentType: H.List(["File", "FileGroup"])};
        export const allFilesCount = {contentType: "IntegerField" as "IntegerField", name: "allFilesCount", isMultiple: false, isSortable: false, id: "ContractorHuman.allFilesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesInfo = {contentType: "RefLinkField" as "RefLinkField", name: "attachesInfo", isMultiple: false, isSortable: false, id: "ContractorHuman.attachesInfo", refContentType: H.List(["AttachesInfo"])};
        export const commentsWithoutTransportCount = {contentType: "IntegerField" as "IntegerField", name: "commentsWithoutTransportCount", isMultiple: false, isSortable: false, id: "ContractorHuman.commentsWithoutTransportCount", defaultValue: null as IntegerField["defaultValue"]};
        export const emailsCount = {contentType: "IntegerField" as "IntegerField", name: "emailsCount", isMultiple: false, isSortable: false, id: "ContractorHuman.emailsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const whatsappCount = {contentType: "IntegerField" as "IntegerField", name: "whatsappCount", isMultiple: false, isSortable: false, id: "ContractorHuman.whatsappCount", defaultValue: null as IntegerField["defaultValue"]};
        export const telegramCount = {contentType: "IntegerField" as "IntegerField", name: "telegramCount", isMultiple: false, isSortable: false, id: "ContractorHuman.telegramCount", defaultValue: null as IntegerField["defaultValue"]};
        export const instagramCount = {contentType: "IntegerField" as "IntegerField", name: "instagramCount", isMultiple: false, isSortable: false, id: "ContractorHuman.instagramCount", defaultValue: null as IntegerField["defaultValue"]};
        export const interactionsCounters = {contentType: "RefLinkField" as "RefLinkField", name: "interactionsCounters", isMultiple: true, isSortable: false, id: "ContractorHuman.interactionsCounters", refContentType: H.List(["InteractionCounter"])};
        export const todos = {contentType: "RefLinkField" as "RefLinkField", name: "todos", isMultiple: true, isSortable: false, id: "ContractorHuman.todos", refContentType: H.List(["Todo"])};
        export const todosCount = {contentType: "IntegerField" as "IntegerField", name: "todosCount", isMultiple: false, isSortable: false, id: "ContractorHuman.todosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const avatar = {contentType: "RefLinkField" as "RefLinkField", name: "avatar", isMultiple: false, isSortable: false, id: "ContractorHuman.avatar", refContentType: H.List(["File"])};
        export const photo = {contentType: "RefLinkField" as "RefLinkField", name: "photo", isMultiple: false, isSortable: false, id: "ContractorHuman.photo", refContentType: H.List(["File"])};
        export const possibleSubscribers = {contentType: "RefLinkField" as "RefLinkField", name: "possibleSubscribers", isMultiple: true, isSortable: false, id: "ContractorHuman.possibleSubscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const possibleSubscribersCount = {contentType: "IntegerField" as "IntegerField", name: "possibleSubscribersCount", isMultiple: false, isSortable: false, id: "ContractorHuman.possibleSubscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribers = {contentType: "RefLinkField" as "RefLinkField", name: "subscribers", isMultiple: true, isSortable: false, id: "ContractorHuman.subscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const subscribersCount = {contentType: "IntegerField" as "IntegerField", name: "subscribersCount", isMultiple: false, isSortable: false, id: "ContractorHuman.subscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const hiddenCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "hiddenCommentsCount", isMultiple: false, isSortable: false, id: "ContractorHuman.hiddenCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isUnread = {contentType: "BoolField" as "BoolField", name: "isUnread", isMultiple: false, isSortable: false, id: "ContractorHuman.isUnread", defaultValue: null as BoolField["defaultValue"]};
        export const firstUnreadComment = {contentType: "RefLinkField" as "RefLinkField", name: "firstUnreadComment", isMultiple: false, isSortable: false, id: "ContractorHuman.firstUnreadComment", refContentType: H.List(["Comment"])};
        export const unreadAnswer = {contentType: "BoolField" as "BoolField", name: "unreadAnswer", isMultiple: false, isSortable: false, id: "ContractorHuman.unreadAnswer", defaultValue: null as BoolField["defaultValue"]};
        export const lastView = {contentType: "DateTimeField" as "DateTimeField", name: "lastView", isMultiple: false, isSortable: false, id: "ContractorHuman.lastView", defaultValue: null as DateTimeField["defaultValue"]};
        export const tags = {contentType: "RefLinkField" as "RefLinkField", name: "tags", isMultiple: true, isSortable: true, id: "ContractorHuman.tags", refContentType: H.List(["Tag"])};
        export const tagsCount = {contentType: "IntegerField" as "IntegerField", name: "tagsCount", isMultiple: false, isSortable: false, id: "ContractorHuman.tagsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const reminderTime = {contentType: "DateTimeField" as "DateTimeField", name: "reminderTime", isMultiple: false, isSortable: false, id: "ContractorHuman.reminderTime", defaultValue: null as DateTimeField["defaultValue"]};
    }
}

export function isContractorHuman(arg: any): arg is ContractorHuman {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ContractorHuman"
}
