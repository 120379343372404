import {autobind} from "core-decorators"
import {AbstractTabTransport, TabMessageEvent} from "./AbstractTabTransport"
import {Json} from "src/lib/types"

/**
 * Транспорт обмена сообщениями между вкладками через BroadcastChannel
 */
@autobind
export class BroadcastChannelTransport extends AbstractTabTransport {

    private channel: BroadcastChannel

    constructor(transportChannelName: string) {
        super(transportChannelName)
        this.channel = new window.BroadcastChannel(this.eventsScopeName)
        this.channel.onmessage = (event: MessageEvent) => {
            this.emitMessage(event.data as TabMessageEvent);
        }
    }

    public static isSupported = () => {
        return Boolean(window.BroadcastChannel)
    }

    public broadcastMessage<T = Json>(message: TabMessageEvent<T>) {
        if (!message.timestamp) {
            message.timestamp = (new Date()).getTime()
        }
        this.channel.postMessage(message)
        message.isFromSameTab = true;
        this.emitMessage(message)
    }

}
