import * as Api from "src/lib/entities/api"
import * as WebApi from "src/lib/entities/apiWeb"
import * as Megaplan from "src/megaplan"
import * as Collections from "src/lib/collections"
import {fetchReportTemplateSuccess} from "./index"


export function updateReportTemplate(reportTemplate: Api.Report, settings: Collections.List<Api.ChartSetting>) {
    return (dispatch: Megaplan.Dispatch) => {
        reportTemplate = Object.assign({}, {contentType: Api.Report.contentType, id: reportTemplate.id}, {
                displaySetting: Object.assign({}, reportTemplate.displaySetting, {settings: settings})
            }
        )
        dispatch(fetchReportTemplateSuccess(reportTemplate.displaySetting))
        return dispatch(WebApi.update(reportTemplate, "/api/v3/report"))
    }
}
