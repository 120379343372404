import {Tracker, TrackerInterface, TrackEventValueType} from "src/bums/common/stores/Tracker/Tracker";

type TrackerChannel =
    "track"
    | "rating"
    | "product"
    | "amplitude"
    | "carrotquest"

export class ChannelTracker implements TrackerInterface {
    constructor(
        private tracker: Tracker,
        private channels: TrackerChannel[]
    ) {}

    public trackEvent = (type: string, key: string, value: TrackEventValueType = null) => {
        this.tracker.trackEvent(type, key, value, this.channels)
    }
}
