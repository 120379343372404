import Bluebird from "bluebird";
import {createLock, Lock as SequesterLock} from "sequester";

type LockName = string;
type Disposer = () => void;

function once(cb: Disposer): Disposer {
    let calledOnce = false;
    return function() {
        if (calledOnce) {
            throw new Error("Cannot call this disposer twice!");
        }
        calledOnce = true;
        cb();
    }
}

/**
 * Класс, который реализует shared и exclusive блокировку ресурсов.
 * Работает так, как описано в https://en.wikipedia.org/wiki/Readers%E2%80%93writer_lock.
 *
 * Типичный кейс применения - конкурентная отправка/приём комментариев.
 * Приём комментариев должен быть обёрнут в shared-блокировки, которые блокируют только отправку, но не приём,
 * Отправка комментариев должна быть обёрнута в exclusive-блокировку, которая блокирует другую отправку, а также приём.
 */
export class LockStore {

    private locks = new Map<LockName, Lock>();

    public get = (name: LockName) => {
        let lock = this.locks.get(name);
        if (!lock) {
            lock = new Lock()
            this.locks.set(name, lock);
        }
        return lock;
    }
}

class Lock {
    private sequesterLock: SequesterLock = createLock();

    public shared = (): Bluebird<Disposer> => new Bluebird<Disposer>(resolve => {
        this.sequesterLock.share(() => {
            resolve(once(() => {
                this.sequesterLock.unlock();
            }))
        });
    })

    public exclusive = (): Bluebird<Disposer> => new Bluebird<Disposer>(resolve => {
        this.sequesterLock.exclude(() => {
            resolve(once(() => {
                this.sequesterLock.unlock();
            }))
        });
    })
}
