/* tslint:disable */
import * as H from "../metadataHelper"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {ContactInfo} from "./contactInfo"
import {EnumField} from "./enumField"
import {IntegerField} from "./integerField"

export interface Participant extends H.BaseEntity {
    subject?: ContractorCompany|ContractorHuman|Employee; // Сущность участника
    status?: Participant.Status; // Статус
    contactInfo?: H.List<ContactInfo>; // Массив контактной информации
    contactInfoCount?: number; // Количество элементов в массиве с контактной информации
    id?: string; // Идентификатор
    contentType: "Participant"; // Object type
}

export module Participant {
    export const contentType: "Participant" = "Participant";
    export const endpoint = "/api/v3/participant";
    export type Status = "accepted"|"rejected"|"requested";
    export module Status {
        export const accepted: Status = "accepted";
        export const rejected: Status = "rejected";
        export const requested: Status = "requested";
    }
    export const newObject: Participant = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const subject = {contentType: "RefLinkField" as "RefLinkField", name: "subject", isMultiple: false, isSortable: false, id: "Participant.subject", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const status = {contentType: "EnumField" as "EnumField", name: "status", isMultiple: false, isSortable: false, id: "Participant.status", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["accepted", "rejected", "requested"])};
        export const contactInfo = {contentType: "RefLinkField" as "RefLinkField", name: "contactInfo", isMultiple: true, isSortable: false, id: "Participant.contactInfo", refContentType: H.List(["ContactInfo"])};
        export const contactInfoCount = {contentType: "IntegerField" as "IntegerField", name: "contactInfoCount", isMultiple: false, isSortable: false, id: "Participant.contactInfoCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isParticipant(arg: any): arg is Participant {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Participant"
}
