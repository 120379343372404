import * as ReduxActions from "redux-actions"
import * as Api from "src/lib/entities/api"

export const ADD_NEW_GROUP = "bums/common/report/edit/action/ADD_NEW_GROUP"

export type AddNewGroup = ReduxActions.Action<{
    group: Api.ReportConfigGrouping
}>

export function addNewGroup(group: Api.ReportConfigGrouping): AddNewGroup {
    return {
        type: ADD_NEW_GROUP,
        payload: {
            group
        }
    }
}
