/* tslint:disable */
import * as H from "../metadataHelper"
import {FloatField} from "./floatField"
import {StringField} from "./stringField"

export interface Money extends H.BaseValue {
    value?: number; // Значение
    currency?: string; // ISO код валюты
    valueInMain?: number; // Базовое значение
    rate?: number; // Курс
    contentType: "Money"; // Object type
}

export module Money {
    export const contentType: "Money" = "Money";
    export const endpoint = "/api/v3/money";

    export const newObject: Money = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const value = {contentType: "FloatField" as "FloatField", name: "value", isMultiple: false, isSortable: false, id: "Money.value", defaultValue: null as FloatField["defaultValue"]};
        export const currency = {contentType: "StringField" as "StringField", name: "currency", isMultiple: false, isSortable: false, id: "Money.currency", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const valueInMain = {contentType: "FloatField" as "FloatField", name: "valueInMain", isMultiple: false, isSortable: false, id: "Money.valueInMain", defaultValue: null as FloatField["defaultValue"]};
        export const rate = {contentType: "FloatField" as "FloatField", name: "rate", isMultiple: false, isSortable: false, id: "Money.rate", defaultValue: null as FloatField["defaultValue"]};
    }
}

export function isMoney(arg: any): arg is Money {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Money"
}
