import {PlainRoute} from "react-router"

const filterIdHashExp = /^#filterId=(.+)/i

export const taskRoutes: PlainRoute[] = [
    {
        path: "/task/:id/card",
        async getComponent() {
            const module = await import("./card/containers/CPageContainer")
            return window.react_sentry ? module.CTaskContainerWithTracking : module.CTaskContainer
        },
    },
    {
        path: "/task/:id/template",
        async getComponent() {
            const module = await import("./card/containers/CPageContainer")
            return window.react_sentry ? module.CTaskContainerWithTracking : module.CTaskContainer
        },
    },
    {
        path: "/project/:id/card",
        async getComponent() {
            const module = await import("./card/containers/CPageContainer")
            return window.react_sentry ? module.CProjectContainerWithTracking : module.CProjectContainer
        },
    },
    {
        path: "/project/:id/template",
        async getComponent() {
            const module = await import("./card/containers/CPageContainer")
            return window.react_sentry ? module.CProjectContainerWithTracking : module.CProjectContainer
        },
    },
    {
        path: "/task/templates",
        onEnter(nextState, replace) {
            replace({pathname: "/task/filter/templates"})
        },
    },
    {
        path: "/task/filter(/:filterId)",
        async getComponent() {
            return (await import("./list/page/CTaskListPage")).CTaskListPage
        },
    },
    {
        path: "/task/filter(/:filterId)(/:contentType)(/:taskId)",
        async getComponent() {
            return (await import("./list/page/CTaskListPage")).CTaskListPage
        },
    },
    {
        path: "/task",
        onEnter(nextState, replace) {
            const matchFilterId = (nextState.location.hash || "").match(filterIdHashExp)
            const filterId = (matchFilterId && matchFilterId[1])
                || (nextState.location.query && (nextState.location.query as any)["filterId"])
            replace({pathname: `/task/filter${filterId ? `/${filterId}` : ""}`})
        },
    }
]
