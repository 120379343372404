/* tslint:disable */
import * as H from "../metadataHelper"
import {MessageContact} from "./messageContact"
import {File} from "./file"
import {Comment} from "./comment"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Deal} from "./deal"
import {Project} from "./project"
import {Task} from "./task"
import {Topic} from "./topic"
import {InteractionCounter} from "./interactionCounter"
import {MessageRights} from "./messageRights"
import {FacebookMessage} from "./facebookMessage"
import {InstagramMessage} from "./instagramMessage"
import {TelegramMessage} from "./telegramMessage"
import {WhatsappMessage} from "./whatsappMessage"
import {StringField} from "./stringField"
import {DateTimeField} from "./dateTimeField"
import {IntegerField} from "./integerField"
import {BoolField} from "./boolField"
import {EnumField} from "./enumField"

export interface Message extends H.BaseEntity {
    messageId?: string; // Идентификатор сообщения
    timeSent?: Date; // Время отправки
    from?: MessageContact; // От кого
    to?: H.List<MessageContact>; // Адресат
    copy?: H.List<MessageContact>; // Копии сообщений
    blindCopy?: H.List<MessageContact>; // Скрытые копии сообщений
    subject?: string; // Сущность
    text?: string; // Текст сообщения
    timeCreated?: Date; // Время создания
    attaches?: H.List<File>; // Вложения
    attachesCount?: number; // Количество вложений
    isOutgoing?: boolean; // Исходящее
    isOutgoingForUser?: boolean; // Исходящее ли сообщение дял текущего пользователя
    deliveryStatus?: Message.DeliveryStatus; // Статус доставки
    convertedTo?: Comment|ContractorCompany|ContractorHuman|Deal|Project|Task|Topic; // Конвертируется в сущность
    isUnread?: boolean;
    unreadDeepChildsCount?: number;
    interactionsCounters?: H.List<InteractionCounter>;
    rights?: MessageRights;
    parent?: FacebookMessage|InstagramMessage|Message|TelegramMessage|WhatsappMessage;
    childs?: H.List<FacebookMessage | InstagramMessage | Message | TelegramMessage | WhatsappMessage>;
    childsCount?: number;
    deepChildsCount?: number;
    id?: string; // Идентификатор
    contentType: "Message"; // Object type
}

export module Message {
    export const contentType: "Message" = "Message";
    export const endpoint = "/api/v3/message";
    export type DeliveryStatus = "delivered"|"initial"|"not_delivered"|"not_sent"|"read"|"sent";
    export module DeliveryStatus {
        export const delivered: DeliveryStatus = "delivered";
        export const initial: DeliveryStatus = "initial";
        export const not_delivered: DeliveryStatus = "not_delivered";
        export const not_sent: DeliveryStatus = "not_sent";
        export const read: DeliveryStatus = "read";
        export const sent: DeliveryStatus = "sent";
    }
    export const newObject: Message = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const messageId = {contentType: "StringField" as "StringField", name: "messageId", isMultiple: false, isSortable: false, id: "Message.messageId", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const timeSent = {contentType: "DateTimeField" as "DateTimeField", name: "timeSent", isMultiple: false, isSortable: false, id: "Message.timeSent", defaultValue: null as DateTimeField["defaultValue"]};
        export const from = {contentType: "RefLinkField" as "RefLinkField", name: "from", isMultiple: false, isSortable: false, id: "Message.from", refContentType: H.List(["MessageContact"])};
        export const to = {contentType: "RefLinkField" as "RefLinkField", name: "to", isMultiple: true, isSortable: false, id: "Message.to", refContentType: H.List(["MessageContact"])};
        export const copy = {contentType: "RefLinkField" as "RefLinkField", name: "copy", isMultiple: true, isSortable: false, id: "Message.copy", refContentType: H.List(["MessageContact"])};
        export const blindCopy = {contentType: "RefLinkField" as "RefLinkField", name: "blindCopy", isMultiple: true, isSortable: false, id: "Message.blindCopy", refContentType: H.List(["MessageContact"])};
        export const subject = {contentType: "StringField" as "StringField", name: "subject", isMultiple: false, isSortable: false, id: "Message.subject", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const text = {contentType: "StringField" as "StringField", name: "text", isMultiple: false, isSortable: false, id: "Message.text", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: false, id: "Message.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const attaches = {contentType: "RefLinkField" as "RefLinkField", name: "attaches", isMultiple: true, isSortable: false, id: "Message.attaches", refContentType: H.List(["File"])};
        export const attachesCount = {contentType: "IntegerField" as "IntegerField", name: "attachesCount", isMultiple: false, isSortable: false, id: "Message.attachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isOutgoing = {contentType: "BoolField" as "BoolField", name: "isOutgoing", isMultiple: false, isSortable: false, id: "Message.isOutgoing", defaultValue: null as BoolField["defaultValue"]};
        export const isOutgoingForUser = {contentType: "BoolField" as "BoolField", name: "isOutgoingForUser", isMultiple: false, isSortable: false, id: "Message.isOutgoingForUser", defaultValue: null as BoolField["defaultValue"]};
        export const deliveryStatus = {contentType: "EnumField" as "EnumField", name: "deliveryStatus", isMultiple: false, isSortable: false, id: "Message.deliveryStatus", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["delivered", "initial", "not_delivered", "not_sent", "read", "sent"])};
        export const convertedTo = {contentType: "RefLinkField" as "RefLinkField", name: "convertedTo", isMultiple: false, isSortable: false, id: "Message.convertedTo", refContentType: H.List(["Comment", "ContractorCompany", "ContractorHuman", "Deal", "Project", "Task", "Topic"])};
        export const isUnread = {contentType: "BoolField" as "BoolField", name: "isUnread", isMultiple: false, isSortable: false, id: "Message.isUnread", defaultValue: null as BoolField["defaultValue"]};
        export const unreadDeepChildsCount = {contentType: "IntegerField" as "IntegerField", name: "unreadDeepChildsCount", isMultiple: false, isSortable: false, id: "Message.unreadDeepChildsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const interactionsCounters = {contentType: "RefLinkField" as "RefLinkField", name: "interactionsCounters", isMultiple: true, isSortable: false, id: "Message.interactionsCounters", refContentType: H.List(["InteractionCounter"])};
        export const rights = {contentType: "RefLinkField" as "RefLinkField", name: "rights", isMultiple: false, isSortable: false, id: "Message.rights", refContentType: H.List(["MessageRights"])};
        export const parent = {contentType: "RefLinkField" as "RefLinkField", name: "parent", isMultiple: false, isSortable: false, id: "Message.parent", refContentType: H.List(["FacebookMessage", "InstagramMessage", "Message", "TelegramMessage", "WhatsappMessage"])};
        export const childs = {contentType: "RefLinkField" as "RefLinkField", name: "childs", isMultiple: true, isSortable: false, id: "Message.childs", refContentType: H.List(["FacebookMessage", "InstagramMessage", "Message", "TelegramMessage", "WhatsappMessage"])};
        export const childsCount = {contentType: "IntegerField" as "IntegerField", name: "childsCount", isMultiple: false, isSortable: false, id: "Message.childsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const deepChildsCount = {contentType: "IntegerField" as "IntegerField", name: "deepChildsCount", isMultiple: false, isSortable: false, id: "Message.deepChildsCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isMessage(arg: any): arg is Message {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Message"
}
