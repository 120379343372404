/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface MessageContact extends H.BaseValue {
    email?: string;
    name?: string;
    contentType: "MessageContact"; // Object type
}

export module MessageContact {
    export const contentType: "MessageContact" = "MessageContact";
    export const endpoint = "/api/v3/messageContact";

    export const newObject: MessageContact = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const email = {contentType: "StringField" as "StringField", name: "email", isMultiple: false, isSortable: false, id: "MessageContact.email", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "MessageContact.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isMessageContact(arg: any): arg is MessageContact {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "MessageContact"
}
