import * as ReduxActions from "redux-actions"
import {ReportData} from "../../entities";

export const FETCH_DATA_CHUNK_SUCCESS = "bums/common/report/card/action/FETCH_DATA_CHUNK_SUCCESS"

export type FetchDataChunkSuccessAction = ReduxActions.Action<{
    data: ReportData
}>

export function fetchDataChunkSuccess(data: ReportData): FetchDataChunkSuccessAction {
    return {
        type: FETCH_DATA_CHUNK_SUCCESS,
        payload: {
            data
        }
    }
}
