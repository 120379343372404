/* tslint:disable */
import * as H from "../metadataHelper"
import {DateOnly} from "./dateOnly"
import {DateField} from "./dateField"

export interface IntervalDates extends H.BaseValue {
    from?: DateOnly; // Дата начала
    to?: DateOnly; // Дата завершения
    contentType: "IntervalDates"; // Object type
}

export module IntervalDates {
    export const contentType: "IntervalDates" = "IntervalDates";
    export const endpoint = "/api/v3/intervalDates";

    export const newObject: IntervalDates = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const from = {contentType: "DateField" as "DateField", name: "from", isMultiple: false, isSortable: false, id: "IntervalDates.from", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const to = {contentType: "DateField" as "DateField", name: "to", isMultiple: false, isSortable: false, id: "IntervalDates.to", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
    }
}

export function isIntervalDates(arg: any): arg is IntervalDates {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "IntervalDates"
}
