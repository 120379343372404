/* tslint:disable */
import * as H from "../metadataHelper"
import {Tax} from "./tax"
import {Money} from "./money"
import {Unit} from "./unit"
import {StringField} from "./stringField"
import {MoneyField} from "./moneyField"
import {FloatField} from "./floatField"

export interface Offer extends H.BaseEntity {
    name?: string; // Название
    article?: string; // Артикул
    tax?: Tax; // НДС %
    price?: Money; // Цена
    unit?: Unit; // Единицы измерения
    quantity?: number; // Количество
    originalOffer?: Offer;
    id?: string; // Идентификатор
    contentType: "Offer"; // Object type
}

export module Offer {
    export const contentType: "Offer" = "Offer";
    export const endpoint = "/api/v3/offer";

    export const newObject: Offer = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Offer.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const article = {contentType: "StringField" as "StringField", name: "article", isMultiple: false, isSortable: false, id: "Offer.article", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const tax = {contentType: "RefLinkField" as "RefLinkField", name: "tax", isMultiple: false, isSortable: false, id: "Offer.tax", refContentType: H.List(["Tax"])};
        export const price = {contentType: "MoneyField" as "MoneyField", name: "price", isMultiple: false, isSortable: false, id: "Offer.price", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const unit = {contentType: "RefLinkField" as "RefLinkField", name: "unit", isMultiple: false, isSortable: false, id: "Offer.unit", refContentType: H.List(["Unit"])};
        export const quantity = {contentType: "FloatField" as "FloatField", name: "quantity", isMultiple: false, isSortable: false, id: "Offer.quantity", defaultValue: null as FloatField["defaultValue"]};
        export const originalOffer = {contentType: "RefLinkField" as "RefLinkField", name: "originalOffer", isMultiple: false, isSortable: false, id: "Offer.originalOffer", refContentType: H.List(["Offer"])};
    }
}

export function isOffer(arg: any): arg is Offer {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Offer"
}
