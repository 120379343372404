import {Intl, smartDateHr, workingTimePeriodLongHr} from "./Intl"
import {UnitFormatter} from "./formatters"
import {
    DateOnly, IntervalDates, IntervalTime, isDateOnly, isIntervalDates, isIntervalTime, isShiftDate, Project, ShiftDate,
    Task
} from "src/lib/entities/bums"
import * as dateFunctions from "src/lib/utils/date"
import {DateInterval} from "src/lib/entities/types"
import {DateOnlyToDate, DateToDateOnly} from "src/lib/utils/intl/utils"

export * from "src/lib/utils/intl/utils"

const messages = require("../../messages.yml") as any

export type DateValues = Date | DateOnly | IntervalDates | IntervalTime
export type DateValuesType = "Date" | "DateOnly" | "IntervalTime" | "IntervalDates"

const multiplier = 60 * 60 * 24

/**
 * Возвращает отформатированное значение "сколько осталось времени" для счетчиков.
 * @todo научить принимать минимальную отображаемую единицу, типа, 00:00:35
 * @param seconds
 * @returns {string}
 */
export const formatTimeLeft = (seconds: number) => {
    const sign = seconds < 0 ? "-" : ""
    if (sign) {
        seconds = -seconds
    }

    const hours = (Math.floor(seconds / 3600) % 24)
    const minutes = (Math.floor(seconds / 60) % 60)
    seconds = seconds % 60
    return sign + (hours > 0 ? doublizeTimeNumber(hours) + ":" : "") +
        (minutes >= 0 ? doublizeTimeNumber(minutes) + ":" : "") +
        doublizeTimeNumber(seconds);

}

function doublizeTimeNumber(timeNumber: number) {
    if (timeNumber === 0) {
        return "00"
    }
    if (String(timeNumber).length <= 1) {
        return "0" + timeNumber
    }
    return String(timeNumber)
}

/**
 * Возвращает время для отображения
 * Например: 20:33
 * @param intl
 * @param value
 * @param showSeconds
 */
export const getTime = (intl: Intl, value: Date, showSeconds?: boolean) => {
    if (showSeconds) {
        return intl.date.HHmmss()(value)
    }
    return intl.date.Hm()(value)
}

/**
 * Отображает дату в обычном, человеко-понятном виде.
 * Если не знаете, какой формат даты использовать, используйте этот.
 * Например: 19 сентября; сегодня; 19 сентября 2015;
 *
 * @param intl
 * @param value
 * @param forceDatesOnly - не писать "сегодня", "вчера" и "завтра"
 * @todo исправить баг с отображением "г."
 */
export const getCasualDate = (intl: Intl, value: Date, forceDatesOnly = false) => {
    let smartDateParams = {
        sameYear: intl.date.MMMMd(),
        other: intl.date.yMMMMd()
    }
    // Иногда надо не писать "сегодня", "вчера" и "завтра"
    if (!forceDatesOnly) {
        Object.assign(smartDateParams, {
            today: intl.relativeTime.dayLong()(0),
            yesterday: intl.relativeTime.dayLong()(-1),
            tomorrow: intl.relativeTime.dayLong()(1),
        })
    }
    return smartDateHr(value, smartDateParams)
}

export const getCasualChatDate = (intl: Intl, value: Date) => {
    let smartDateParams = {
        sameYear: intl.date.MMMd(),
        other: intl.date.yMMdd(),
        sameWeek: intl.date.ccc(),
        today: intl.date.timeShort(),
        yesterday: intl.relativeTime.dayLong()(-1)
    }
    return smartDateHr(value, smartDateParams)
}

export const getCasualJournalDate = (intl: Intl, value: Date) => {
    let smartDateParams = {
        other: intl.date.yMMMd(),
        sameYear: intl.date.MMMd(),
        today: intl.date.timeShort(),
    }
    return smartDateHr(value, smartDateParams)
}

export const getCasualDateShort = (intl: Intl, value: Date) => {
    let smartDateParams = {
        other: intl.date.yMMMd(),
        sameYear: intl.date.MMMd(),
    }
    return smartDateHr(value, smartDateParams)
}

/**
 * Возвращает отформатированные день и месяц от даты (строго без года).
 *
 * @param intl
 * @param value
 * @returns {string}
 */
export const getDayAndMonthFromDate = (intl: Intl, value: Date) => {
    return intl.date.MMMMd()(value)
}

/**
 * Отображает дату и время в обычном, человеко-понятном виде.
 * Если не знаете, какой формат даты и времени использовать, используйте этот.
 * Например: 19 сентября в 11:30; сегодня в 12:24; 19 сентября 2016 в 16:40;
 *
 * @param intl
 * @param value
 * @param hideZeroTime
 * @param forceDatesOnly
 *
 */
export const getCasualDateTime = (intl: Intl, value: Date, hideZeroTime?: boolean, forceDatesOnly?: boolean) => {
    return smartDateHr(value, {
        other: (dateToFormat: Date) => {
            return dateFunctions.is00am(dateToFormat) && hideZeroTime
                ? getCasualDate(intl, dateToFormat, forceDatesOnly)
                : intl.formatMessage(messages["dateAtTime"], {
                date: getCasualDate(intl, dateToFormat, forceDatesOnly),
                time: getTime(intl, dateToFormat)
            })
        },
    })
}

/**
 * Отображает дату и время в обычном, человеко-понятном виде.
 * Например: 19.09.2019 в 11:30
 *
 * @param intl
 * @param value
 * @param hideZeroTime
 */
export const getShortDateTime = (intl: Intl, value: Date, hideZeroTime?: boolean) => {
    return smartDateHr(value, {
        other: (dateToFormat: Date) => {
            return dateFunctions.is00am(dateToFormat) && hideZeroTime
                ? getCasualDate(intl, dateToFormat)
                : intl.formatMessage(messages["dateAtTime"], {
                date: intl.date.yMMdd()(value),
                time: getTime(intl, dateToFormat)
            })
        },
    })
}

/**
 * Отображает дату и время в обычном, человеко-понятном виде. Отображает месяц в виде сокращённого слова.
 * Например: 1) 19 нояб. в 11:30
 *           2) 19 нояб. 2018 г. в 11:30
 *
 * @param intl
 * @param value
 */
export const getMediumDateTime = (intl: Intl, value: Date) => {
    return smartDateHr(value, {
        sameYear: (dateToFormat: Date) => {
            return intl.formatMessage(messages["dateAtTime"], {
                date: intl.date.MMMd()(value),
                time: getTime(intl, dateToFormat)
            })
        },
        other: (dateToFormat: Date) => {
            return intl.formatMessage(messages["dateAtTime"], {
                date: intl.date.yMMMd()(value),
                time: getTime(intl, dateToFormat)
            })
        }
    })
}

/**
 * Возвращает дату в сокр виде
 * пример: 1) 2 октября
 *         2) 2 окт. 2012 г.
 *         3) сегодня/завтра/вчера ...
 * @param intl
 * @param value
 * @param forceDatesOnly
 * @returns {string|string|string|string|string|string|any}
 */
export const getCasualShortDate = (intl: Intl, value: Date, forceDatesOnly = false) => {

    let smartDateParams = {
        sameYear: intl.date.MMMMd(),
        other: intl.date.yMMMdd()
    }

    if (!forceDatesOnly) {
        Object.assign(smartDateParams, {
            today: intl.relativeTime.dayLong()(0),
            yesterday: intl.relativeTime.dayLong()(-1),
            tomorrow: intl.relativeTime.dayLong()(1),
        })
    }
    return smartDateHr(value, smartDateParams)
}

/**
 * Возвращает объект состоящий из даты(год не выводится если совпадает с текущим) и времени
 * если есть год название месяца сокращается
 * возможные вариенты
 *  дата: 1) 2 октября
 *        2) 2 окт. 2012 г.
 *        3) сегодня/завтра/вчера ...
 * время: в 12:00
 * @param intl
 * @param value
 * @param hideZeroTime
 * @returns {{date: (string|string|string|string|string|string|any), time: string}}
 */

export const getShortDateTimeForTables = (intl: Intl, value: Date, hideZeroTime?: boolean) => {
    const time = hideZeroTime && dateFunctions.is00am(value) ? "" : intl.formatMessage(messages["inTime"], {time: getTime(intl, value)})

    return {
        date: getCasualShortDate(intl, value),
        time
    }
}

/**
 * Возвращает год
 * Например: 2016
 *
 * @param intl
 * @param value
 */
export const getYear = (intl: Intl, value: Date) => {
    return intl.date.y()(value)
}

/**
 * Возвращает месяц и год
 * Например: ноябрь 2019
 *
 * @param intl
 * @param value
 */
export const getMonthAndYear = (intl: Intl, value: Date) => {
    return `${intl.date.MMMM()(value)} ${intl.date.y()(value)}`
}

/**
 * Возвращает "точную" техническую дату и время для технического отображения (тултипы, инпуты и т.п.)
 * Например: 24.02.2016 13:00:00
 * @param intl
 * @param value
 * @param showSeconds
 */
export const getPreciseDateTime = (intl: Intl, value: Date, showSeconds?: boolean) => {
    if (showSeconds) {
        return intl.date.yyMMddHHmmss()(value)
    }
    return intl.date.yyMMddHHmm()(value)
}

/**
 * Возвращает техническую дату
 * Например: 2016-04-9
 * @param intl
 * @param value
 * @returns {string}
 */
export const getPreciseDate = (intl: Intl, value: Date) => {
    return intl.date.yMMdd()(value)
}

/**
 * Возвращает читабельную дату с днем недели.
 * Например: Среда, 19 сентября; Сегодня, 19 сентября
 *
 * @param intl
 * @param value
 */
export const getDateWithWeekDay = (intl: Intl, value: Date) => {
    return smartDateHr(value, {
        today: (dateToFormat: Date) => {
            return intl.date.MMMMccccd()(dateToFormat).replace(intl.date.cccc()(dateToFormat), intl.relativeTime.dayLong()(0))
        },
        yesterday: (dateToFormat: Date) => {
            return intl.date.MMMMccccd()(dateToFormat).replace(intl.date.cccc()(dateToFormat), intl.relativeTime.dayLong()(-1))
        },
        tomorrow: (dateToFormat: Date) => {
            return intl.date.MMMMccccd()(dateToFormat).replace(intl.date.cccc()(dateToFormat), intl.relativeTime.dayLong()(1))
        },
        sameYear: intl.date.MMMMccccd(),
        other: intl.date.dateFull()
    })
}

/**
 * Возвращает день для чата.
 * Например: Сегодня; Вчера; Вт, 14 апреля;
 *
 * @param intl
 * @param value
 */
export const getChatDay = (intl: Intl, value: Date) => {
    return smartDateHr(value, {
        sameYear: intl.date.MMMMcd(),
        other: intl.date.yMMMdd(),
        today: intl.relativeTime.dayLong()(0),
        yesterday: intl.relativeTime.dayLong()(-1),
        tomorrow: intl.relativeTime.dayLong()(1),
    })
}

/**
 * Возвращает читабельную дату с днем недели и с временем
 * Например: Среда, 19 сентября в 16:30; Сегодня, 19 сентября
 *
 * @param intl
 * @param value
 */
export const getDateTimeWithWeekDay = (intl: Intl, value: Date) => {
    return intl.formatMessage(messages["dateAtTime"], {
        date: getDateWithWeekDay(intl, value),
        time: getTime(intl, value)
    })
}

/**
 * Возвращает сокращенную дату с днем недели, для отображения в всплывающем календаре.
 * Например: Ср, 6 апр.
 * Не отображает год (в календаре год отображается отдельно).
 *
 * @param intl
 * @param value
 */
export const getDateWithWeekDayShort = (intl: Intl, value: Date) => {
    return smartDateHr(value, {
        other: intl.date.MMMcd()
    })
}

/**
 * Отображает дату/время для списка переписок в чате.
 * Например: 11:30; вчера; ср; 19.09.16;
 *
 * @param intl
 * @param value
 */
export const getChatListItemDateTime = (intl: Intl, value: Date) => {
    return smartDateHr(value, {
        today: intl.date.HHmm(),
        yesterday: intl.relativeTime.dayLong()(-1),
        tomorrow: intl.relativeTime.dayLong()(1),
        sameWeek: intl.date.cc(),
        other: intl.date.dateShort()
    })
}


/**
 * Форматирует интервам по времени в полном формате (с днём недели и датой)
 * @todo надо переделать на нативный словарь globalize, а не через наших переводчиков.
 * @todo написать тесты
 *
 * @param intl
 * @param valueStart
 * @param valueEnd
 */
export const getTimeIntervalFull = (intl: Intl, valueStart: Date, valueEnd: Date, isWithWeekDay: boolean = true) => {
    if (valueStart.getTime() === valueEnd.getTime()) {
        return isWithWeekDay ? getDateWithWeekDay(intl, valueStart) : getCasualShortDate(intl, valueStart)
    } else {
        return intl.formatMessage(messages["timeIntervalFull"], {
            date: isWithWeekDay ? getDateWithWeekDay(intl, valueStart) : getCasualShortDate(intl, valueStart),
            timeStart: getTime(intl, valueStart),
            timeEnd: getTime(intl, valueEnd)
        })
    }
}

/**
 *
 *
 * Форматирует интервам по дате в полном формате (с днем недели)
 * @todo надо переделать на нативный словарь globalize, а не через наших переводчиков.
 * @todo написать тесты
 *
 * @param intl
 * @param valueStart
 * @param valueEnd
 */
export const getDateIntervalFull = (intl: Intl, valueStart: Date, valueEnd: Date, isWithWeekDay: boolean = true) => {
    if (valueStart.getTime() === valueEnd.getTime()) {
        return intl.formatMessage(messages["dateIntervalFullSingleDay"], {
            date: isWithWeekDay ? getDateWithWeekDay(intl, valueStart) : getCasualShortDate(intl, valueStart),
        })
    } else {
        if (dateFunctions.isSameMonth(valueStart, valueEnd)) {
            return intl.formatMessage(messages["dateIntervalFull"], {
                dateStart: intl.date.d()(valueStart),
                dateEnd: getCasualDate(intl, valueEnd, true),
            })
        } else {
            return intl.formatMessage(messages["dateIntervalFull"], {
                dateStart: getCasualDate(intl, valueStart, true),
                dateEnd: getCasualDate(intl, valueEnd, true),
            })
        }
    }
}

/**
 *
 *
 * Форматирует интервам по дате в полном формате (с днем недели)
 *
 * @param intl
 * @param valueStart
 * @param valueEnd
 */
export const getDateIntervalShort = (intl: Intl, valueStart: Date, valueEnd: Date, isWithWeekDay: boolean = true) => {
    if (valueStart.getTime() === valueEnd.getTime()) {
        return intl.formatMessage(messages["dateIntervalFullSingleDay"], {
            date: isWithWeekDay ? getDateWithWeekDay(intl, valueStart) : getCasualShortDate(intl, valueStart),
        })
    } else {
        if (dateFunctions.isSameMonth(valueStart, valueEnd)) {
            return intl.formatMessage(messages["timeIntervalShort"], {
                timeStart: intl.date.d()(valueStart),
                timeEnd: getCasualDateShort(intl, valueEnd),
            })
        } else {
            return intl.formatMessage(messages["timeIntervalShort"], {
                timeStart: getCasualDateShort(intl, valueStart),
                timeEnd: getCasualDateShort(intl, valueEnd),
            })
        }
    }
}

export function getFormattedDateFromDatePicker(intl: Intl, value: DateValues) {
    const {date} = intl
    if (isIntervalDates(value) && value.from && value.to) {
        if (value.from.year === value.to.year) {
            if (value.from.month === value.to.month) {
                //1. Same  month and year: eg 10 - 26 сен
                const dateOnlyFrom = DateOnlyToDate(value.from)
                if (!dateOnlyFrom) {
                    return ""
                }
                const dateParts = [
                    value.from.day,
                    value.to.day,
                    date.MMM()(dateOnlyFrom),
                    date.y()(dateOnlyFrom)
                ]
                return `${dateParts[0]} – ${dateParts[1]} ${dateParts[2]} ${dateParts[3]}`
            } else {
                //2. Same year: 10 сен - 26 окт
                const dateOnlyFrom = DateOnlyToDate(value.from)
                const dateOnlyTo = DateOnlyToDate(value.to)
                if (!dateOnlyFrom || !dateOnlyTo) {
                    return ""
                }
                const dateParts = [
                    date.MMMd()(dateOnlyFrom),
                    date.MMMd()(dateOnlyTo),
                    date.y()(dateOnlyFrom)
                ]
                return `${dateParts[0]} – ${dateParts[1]} ${dateParts[2]}`
            }
        } else {
            //3. Different years: 10.12.2016 - 25.01.2017
            const dateOnlyFrom = DateOnlyToDate(value.from)
            const dateOnlyTo = DateOnlyToDate(value.to)
            if (!dateOnlyFrom || !dateOnlyTo) {
                return ""
            }
            const dateParts = [
                date.dateShort()(dateOnlyFrom),
                date.dateShort()(dateOnlyTo)
            ]
            return `${dateParts[0]} – ${dateParts[1]}`
        }
    } else if (isIntervalTime(value) && value.from && value.to) {
        // 4. time interval: 20 сен 2016 13:00-14:00
        const dateParts = [
            date.dateMedium()(value.from),
            date.HHmm()(value.from),
            date.HHmm()(value.to)
        ]
        return `${dateParts[0]} ${dateParts[1]} – ${dateParts[2]}`
    } else if (value instanceof Date) {
        return date.yMMMMdHHmm()(value)
    } else if (isDateOnly(value)) {
        const dateOnlyValue = DateOnlyToDate(value)
        if (!dateOnlyValue) {
            return ""
        }
        return date.yMMMMd()(dateOnlyValue)
    }
    return "";
}

/**
 * Возвращает только представление единиц измерения времени
 * @param value
 * @param unitName - day, month, minute, year, second
 * @returns {string|LoDashExplicitWrapper<string>}
 */
export const extractFormattedUnit = (intl: Intl, value: number, unitName: UnitFormatter.KnownFormat = "dayLong"): string => {
    const unitWithValue = intl.unit.getByName(unitName)(value)
    const decimalValue = intl.number.decimal()(value)
    return unitWithValue.replace(decimalValue, "").trim()
}

export function conflictDateTypeResolution(src: DateValues, possibleType: DateValuesType) {
    if ((isDateOnly(src) && possibleType === "DateOnly") || (src instanceof Date && possibleType === "Date")) {
        return src
    } else if (isDateOnly(src) && possibleType === "Date") {
        return DateOnlyToDate(src)
    } else if (src instanceof Date && possibleType === "DateOnly") {
        return DateToDateOnly(src)
    } else {
        return src
    }
}

/**
 * Returns first Date of DateValue, for single dates this returns the date itself
 * DateValues -> start Date
 *
 * @param value
 * @returns {any}
 */
export function getDateValueStartDate(value: DateValues): Date | null {
    if (isDateOnly(value)) {
        return DateOnlyToDate(value)
    } else if (value instanceof Date) {
        return value
    } else if (isIntervalDates(value) && value.from) {
        return DateOnlyToDate(value.from)
    } else if (isIntervalTime(value) && value.from) {
        return value.from
    }

    return null
}

/**
 * DateValues -> end Date
 *
 * @param value
 * @returns {any}
 */
export function getDateValueEndDate(value: DateValues): Date | null {
    if (isDateOnly(value)) {
        return DateOnlyToDate(value)
    } else if (value instanceof Date) {
        return value
    } else if (isIntervalDates(value) && value.to) {
        return DateOnlyToDate(value.to)
    } else if (isIntervalTime(value) && value.to) {
        return value.to
    }

    return null
}

/**
 * Date -> IntervalTime
 *
 * @param value1
 * @param value2
 * @returns {{contentType: "IntervalTime", from: Date, to: Date}}
 */
export function getDateIntervalFromDates(value1: DateOnly|Date, value2: DateOnly|Date) : IntervalDates {
    if (value1 instanceof Date) {
        value1 = DateToDateOnly(value1) as DateOnly
    }
    if (value2 instanceof Date) {
        value2 = DateToDateOnly(value2) as DateOnly
    }
    return {
        contentType: IntervalDates.contentType,
        from: value1,
        to: value2,
    }
}


export function getTimeIntervalFromDateTimes(value1: Date, value2: Date) : IntervalTime {

    if (value1.getTime() === value2.getTime()) {

        const halfHourInmls = 1800000

        value2 = new Date(value2.getTime() + halfHourInmls)
    }

    return {
        contentType: IntervalTime.contentType,
        from: value1,
        to: value2,
    }
}

/**
 * Возвращает порядковый номер недели в месяце по дате.
 * Если !exact, и неделя последняя, то возвращается 5
 * @param date
 * @param exact
 * @returns {number}
 */
export function getWeekNumberInMonthFromDate(date?: Date, exact?: boolean) {
    if (!date) {
        date = dateFunctions.getToday()
    }
    const month = date.getMonth()
    const year = date.getFullYear()
    const firstWeekday = new Date(year, month, 1).getDay()
    const lastDateOfMonth = new Date(year, month + 1, 0).getDate()
    const offsetDate = date.getDate() + firstWeekday - 1
    const index = 1
    const weeksInMonth = index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7)
    const week = index + Math.floor(offsetDate / 7)
    if (exact || week < 2 + index) {
        return week
    }
    return week === weeksInMonth ? index + 4 : week
}
export function getWeekNumberInMonthFromDateOnly(day?: DateOnly, exact?: boolean) {
    if (!day) {
        day = dateFunctions.getTodayDateOnly() as DateOnly
    }
    const date = DateOnlyToDate(day) as Date
    return getWeekNumberInMonthFromDate(date, exact)
}

/**
 * Возвращает короткое название дня недели по его порядковому номеру
 * пн, вт.
 * @param intl
 * @param date
 * @returns {string}
 */
export function getWeekDayShort(intl: Intl, date: Date) {
    return intl.date.cc()(date)
}

/**
 * Возвращает полное название дня недели по его порядковому номеру
 * пн, вт.
 * @param intl
 * @param date
 * @returns {string}
 */
export function getWeekDay(intl: Intl, date: Date) {
    return intl.date.cccc()(date)
}


/**
 * Возвращает какую-то дату с указанным днем недели.
 * @param day
 * @returns {Date}
 */
export function getDateByWeekDayNumber(day: number) {
    let today = dateFunctions.getToday()
    let distance = day - today.getDay()
    today.setDate(today.getDate() + distance)
    return today
}

/**
 * Возвращает короткое отображения месяца в родительском падеже
 * @param intl
 * @param date
 * @returns {string}
 */
export function getMonthShort(intl: Intl, date: Date) {
    return intl.date.MMMraw()(date)
}

/**
 * Возвращает длинное отображение месяца в родительском падеже
 * @param intl
 * @param date
 * @returns {string}
 */
export function getMonthLong(intl: Intl, date: Date) {
    return intl.date.MMMMraw()(date)
}

/**
 * Возвращает какую-то дату с указанным месяцем.
 * @param month
 * @returns {Date}
 */
export function getDateByMonthNumber(month: number) {
    let today = dateFunctions.getToday()
    today.setMonth(month)
    return today
}

/**
 * Возвращает дату, соответствующую переданному дню и месяцу, с каким-то годом.
 * @param day
 * @param month
 * @returns {Date}
 */
export function getDateByDayAndMonth(day: number, month: number) {
    let today = dateFunctions.getToday()
    today.setDate(day)
    today.setMonth(month)
    return today
}

/**
 * Возвращает строкове представления типа по значению.
 *
 * @param value
 * @returns {any}
 */
export function getValueType(value: DateValues): DateValuesType | null {
    if (value instanceof Date) {
        return "Date"
    } else if (isDateOnly(value)) {
        return "DateOnly"
    } else if (isIntervalDates(value)) {
        return "IntervalDates"
    } else if (isIntervalTime(value)) {
        return "IntervalTime"
    }

    return null
}

export function isInterval(value: DateValues): boolean {
    return isIntervalDates(value) || isIntervalTime(value)
}

export function isIntervalType(type: DateValuesType): boolean {
    return type in ["IntervalDates", "IntervalTime"]
}

export function safeDatesCompare<T extends (Date | DateOnly)>(date1: T, date2: T) {
    if (date1 && date2) {
        if (date1 instanceof Date && date2 instanceof Date) {
            return Date.equals(date1, date2)
        } else if (isDateOnly(date1) && isDateOnly(date2)) {
            return date1.year === date2.year && date1.month === date2.month && date1.day === date2.day;
        }
    }
    return false
}

export function getFormattedDateTypes(intl: Intl, value: DateValues) {
    if (isIntervalTime(value) && value.from && value.to) {
        if (value.from.getTime() === value.to.getTime()) {
            return intl.formatMessage(messages["time"], {time: getTime(intl, value.from)})
        } else {
            return intl.formatMessage(messages["timeIntervalShort"], {
                timeStart: getTime(intl, value.from),
                timeEnd: getTime(intl, value.to),
            })
        }
    } else if (isIntervalDates(value) && value.from && value.to) {
        return getDateIntervalFull(intl, DateOnlyToDate(value.from) as Date, DateOnlyToDate(value.to) as Date)
    } else if (isDateOnly(value)) {
        return getCasualDate(intl, DateOnlyToDate(value) as Date)
    } else if (value instanceof Date) {
        return getCasualDate(intl, value)
    }
    return null
}

/**
 * Возвращает отформатированную дату дедлайна для задач и проектов
 * @param intl
 * @param entity
 * @param time
 * @returns {string}
 */
export function getDeadLineDate(intl: Intl, entity: Task | Project, time?: boolean) {
    return deadLineDate(intl, entity.deadline, time)
}

export function deadLineDate(intl: Intl, deadline: DateOnly | Date | ShiftDate, time?: boolean) {

    if (deadline) {
        if (deadline instanceof Date) {
            if (time) {
                return getCasualDateTime(intl, deadline, true)
            } else {
                return getCasualDate(intl, deadline)
            }
        } else if (isDateOnly(deadline)) {
            return getCasualDate(intl, DateOnlyToDate(deadline))
        } else if (isShiftDate(deadline)) {
            const value = new DateInterval(deadline.days * multiplier)
            if (value && value.getSeconds()) {
                return (workingTimePeriodLongHr)(intl)(value.getSeconds(), 3)
            }
        }
    }
    return null
}

export function chooseDateMethod(intl: Intl, date: Date) {
    if (dateFunctions.isYesterday(date) || dateFunctions.isToday(date)) {
        return getCasualDateTime(intl, date)
    }
    return getCasualDate(intl, date)
}

/**
 * Возвращает строку без точки вконце
 */

export function removeEndDot(dateString: string): string {
    if (dateString?.charAt(dateString.length - 1) === ".")  {
        return dateString.slice(0, dateString.length - 1)
    }
    return dateString
}

export function chooseTaskDateMethod(intl: Intl, date: Date) {
    if (dateFunctions.isToday(date)) {
        return getTime(intl, date)
    }
    return getCasualDateShort(intl, date)
}

export function before(date: DateOnly | Date | ShiftDate, target: Date = new Date()): boolean {
    if (isDateOnly(date)) {
        return DateOnlyToDate(date).getTime() < target.getTime()
    }
    if (date instanceof Date) {
        return date.getTime() < target.getTime()
    }
    if (isShiftDate(date)) {
        return !!(date.days || date.hours || date.minutes)
    }
    return false
}
