import * as ReduxActions from "redux-actions"
import * as Api from "src/lib/entities/api"

export const FETCH_REPORT_TEMPLATE_SUCCESS = "bums/common/report/card/action/FETCH_REPORT_TEMPLATE_SUCCESS"

export type FetchReportTemplateSuccessAction = ReduxActions.Action<{
    settings: Api.ReportDisplaySetting
}>

export function fetchReportTemplateSuccess(settings: Api.ReportDisplaySetting): FetchReportTemplateSuccessAction {
    return {
        type: FETCH_REPORT_TEMPLATE_SUCCESS,
        payload: {
            settings
        }
    }
}
