import {BaseEntity} from "src/lib/entities/types";
import {DecoratedEntitiesStorage} from "src/lib/entities/store/EntitiesStorage/DecoratedEntitiesStorage";
import {EntitiesStorageInterface} from "src/lib/entities/store/EntitiesStorage/EntitiesStorageInterface";
import {debounce} from "lodash"

export class DebounceSaveEntitiesStorage extends DecoratedEntitiesStorage {
    /**
     * Очередь на сохранение сущностей в хранилище
     * @type {Set<BaseEntity>}
     */
    private entitiesToSaveToStorage = new Set<BaseEntity>()

    constructor(
        private storage: EntitiesStorageInterface,
        private debounceTime = 500
    ) {
        super(storage)
    }

    async save(entities: BaseEntity[]) {
        for (const entity of entities) {
            this.entitiesToSaveToStorage.add(entity)
        }
        return this.processEntitiesSave()
    }

    /**
     * Обработчик очереди на сохранение сущностей
     */
    private processEntitiesSave = debounce(
        async () => {
            const entities = [...this.entitiesToSaveToStorage.values()];
            this.entitiesToSaveToStorage.clear()
            return this.storage.save(entities)
        },
        this.debounceTime
    )
}
