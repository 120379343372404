import {autobind} from "core-decorators"
import {EventEmitter} from "events"
import {observable, computed, action} from "mobx"
import {MediaControl} from "./MediaControl"
import {stopStream} from "../../utils"
import {
    VideoRoomMessage,
    AuthParticipant,
    VideoRoomPluginHandle,
    Feed as FeedInterface,
    JSEP,
    PluginOptions,
    Janus,
    MediaControl as MediaControlInterface
} from "./types"
import {Participant} from "../../types"


@autobind
export abstract class Feed extends EventEmitter implements FeedInterface {

    @observable.ref
    protected $stream: MediaStream

    protected $handle: VideoRoomPluginHandle

    protected $audioStream: MediaStream

    protected $videoStream: MediaStream

    protected $id: number

    @observable.ref
    protected $mediaControl: MediaControlInterface

    constructor(
        protected $janus: Janus,
        protected $auth: AuthParticipant,
        protected $participant: Participant
    ) {
        super()
    }

    protected abstract getPluginOptions(): Partial<PluginOptions>

    protected abstract messageHandler(message: VideoRoomMessage, jsep?: JSEP): void

    public abstract get type(): "local" | "remote"

    public attach() {
        this.$janus.attach({
            plugin: "janus.plugin.videoroom",
            success: this.successHandler,
            onmessage: this.messageHandler,
            ...this.getPluginOptions()
        })
    }

    @action
    public attachMediaControl(mediaControl: MediaControl) {
        this.$mediaControl = mediaControl
    }

    protected successHandler(handle: VideoRoomPluginHandle) {
        this.$handle = handle
    }

    @computed
    public get mediaControl(): MediaControlInterface {
        return this.$mediaControl
    }

    @computed
    public get stream(): MediaStream | void {
        return this.$stream
    }

    @computed
    public get audioStream(): MediaStream | void {
        if (this.$stream instanceof MediaStream && this.$mediaControl.audio === "on") {
            if (!this.$audioStream) {
                this.$audioStream = new MediaStream(this.$stream.getAudioTracks())
            }
            return this.$audioStream
        }

        return void 0
    }

    @computed
    public get videoStream(): MediaStream | void {
        if (this.$stream instanceof MediaStream && this.$mediaControl.video === "on") {
            if (!this.$videoStream) {
                this.$videoStream = new MediaStream(this.$stream.getVideoTracks())
            }
            return this.$videoStream
        }

        return void 0
    }

    @computed
    public get participantEntity(): Participant["entity"] {
        return this.$participant.entity as Participant["entity"]
    }

    public get id() {
        return this.$id
    }

    public detach() {
        this.removeAllListeners()

        if (this.$stream instanceof MediaStream) {
            stopStream(this.$stream)
        }

        this.$handle?.detach()
    }
}
