import * as ReduxActions from "redux-actions"

export const TABLE_EXPANDED_KEYS = "bums/common/report/card/action/TABLE_EXPANDED_KEYS"

export type ExpandedKeysAction = ReduxActions.Action<{
    keys: Array<string>
}>

export function expandedKeys(keys: Array<string>): ExpandedKeysAction {
    return {
        type: TABLE_EXPANDED_KEYS,
        payload: {
            keys
        }
    }
}
