import Promise from "bluebird";
import {noop} from "lodash"
import {RequestInit} from "src/lib/utils/fetch"
import {createStubResponse, createStubErrorResponse} from "src/lib/utils/test"

const LIMIT = 2;

export function createEmulator(database: {[url: string]: Array<any> | ((init?: RequestInit) => any)}) {
    let lastRequest: {url: string, init?: RequestInit}
    return {
        on: noop,
        fetch: (url: string, init?: RequestInit) => {
            lastRequest = {url, init};
            const dbItem = database[url]
            if (dbItem) {
                if (Array.isArray(dbItem)) {
                    const items = dbItem
                    if (init && init.queryParams && init.queryParams.pageAfter) {
                        const pageAfter = init.queryParams.pageAfter
                        const startIndex = items.indexOf(
                            items.find(t => t.contentType === pageAfter.contentType && t.id === pageAfter.id)
                        ) + 1
                        return Promise.resolve(createStubResponse(
                            url,
                            items.slice(startIndex, startIndex + LIMIT),
                            {
                                count: items.length,
                                limit: LIMIT,
                                hasMoreNext: startIndex + LIMIT < items.length,
                                hasMorePrev: startIndex > 0,
                            }
                        ))
                    } else {
                        return Promise.resolve(createStubResponse(
                            url,
                            items.slice(0, LIMIT),
                            {
                                count: items.length,
                                limit: LIMIT,
                                hasMoreNext: LIMIT < items.length,
                                hasMorePrev: false,
                            }
                        ))
                    }
                } else {
                    return Promise.try(() => dbItem(init)).then(result => {
                        if (Array.isArray(result)) {
                            return createStubResponse(url, result, {
                                count: result.length,
                                limit: LIMIT,
                                hasMoreNext: false,
                                hasMorePrev: false,
                            })
                        }
                        return createStubResponse(url, result)
                    })
                }
            } else {
                return Promise.reject(createStubErrorResponse(url, 404));
            }
        },
        getLastRequest() {
            return lastRequest
        },
    }
}
