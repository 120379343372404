/* tslint:disable */
import * as H from "../metadataHelper"
import {Comment} from "./comment"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {Group} from "./group"
import {Payer} from "./payer"
import {Money} from "./money"
import {PayForm} from "./payForm"
import {Currency} from "./currency"
import {ProgramState} from "./programState"
import {OfferRow} from "./offerRow"
import {Invoice} from "./invoice"
import {Todo} from "./todo"
import {Program} from "./program"
import {ProgramTrigger} from "./programTrigger"
import {ProgramTransition} from "./programTransition"
import {ContactInfo} from "./contactInfo"
import {RelationLink} from "./relationLink"
import {File} from "./file"
import {InteractionCounter} from "./interactionCounter"
import {FileGroup} from "./fileGroup"
import {AttachesInfo} from "./attachesInfo"
import {Tag} from "./tag"
import {FinOperation} from "./finOperation"
import {BoolField} from "./boolField"
import {StringField} from "./stringField"
import {DateTimeField} from "./dateTimeField"
import {IntegerField} from "./integerField"
import {MoneyField} from "./moneyField"
import {EnumField} from "./enumField"
import {FloatField} from "./floatField"

export interface Deal extends H.BaseEntity {
    [fieldName: string]: any;
    isFavorite?: boolean; // Избранный
    number?: string; // Номер
    name?: string; // Название
    description?: string; // Суть
    shortDescription?: string; // Краткое описание
    lastComment?: Comment; // Последний комментарий
    lastCommentTimeCreated?: Date; // Дата последнего комментария
    contractor?: ContractorCompany|ContractorHuman; // Клиент
    contact?: ContractorCompany|ContractorHuman; // Контактное лицо
    manager?: ContractorHuman|Employee|Group; // Менеджер
    payer?: Payer; // Плательщик
    auditors?: H.List<ContractorHuman | Employee | Group>; // Список аудиторов
    auditorsCount?: number; // Количество аудиторов
    price?: Money; // Общая сумма по сделке
    cost?: Money; // Предварительная стоимость
    payForm?: PayForm; // Форма оплаты
    currency?: Currency; // Валюта
    timeCreated?: Date; // Дата создания
    timeUpdated?: Date; // Дата обновления
    state?: ProgramState; // Текущий статус сделки
    stateTimeUpdated?: Date; // Время изменения статуса
    result?: Deal.Result; // Результат сделки
    positions?: H.List<OfferRow>; // Список товаров или услуг в заказе
    positionsCount?: number; // Количество товаров или услуг в заказе
    debt?: Money; // Долг клиента
    paymentProgress?: number; // Прогресс оплаты в процентах
    invoices?: H.List<Invoice>; // Список выставленных счетов
    invoicesCount?: number; // Количество выставленных счетов
    nearTodo?: Todo; // Ближайшее дело связанное со сделкой
    program?: Program; // Схема сделки
    possibleTriggers?: H.List<ProgramTrigger>; // Список сценариев типа Кнопка, которые доступны в текущем статусе сделки
    possibleTriggersCount?: number; // Количество сценариев типа Кнопка, которые доступны в текущем статусе сделки
    possibleTransitions?: H.List<ProgramTransition>; // Список переходов между статусами, которые доступны в текущем статусе сделки
    possibleTransitionsCount?: number; // Количество переходов между статусами, которые доступны в текущем статусе сделки
    possibleActions?: H.List<Deal.PossibleActions>; // Список возможных действий
    dealsCount?: number; // Количество связанных сделок
    actualDealsCount?: number; // Количество активных связанных сделок
    tasksCount?: number; // Количество связанных задач
    actualTasksCount?: number; // Количество актуальны связанных задач
    contractorPhone?: ContactInfo; // Контактная информация контрагента
    contractorEmail?: string; // E-mail контрагента
    visibleFields?: H.List<string>; // Поля доступные для отображения
    editableFields?: H.List<string>; // Поля доступные для редактирования
    messagesCount?: number; // Количество писем
    callsCount?: number; // Количество звонков
    links?: H.List<RelationLink>; // Связанные ссылки
    linksCount?: number; // Количество связанных ссылок
    relationLinks?: H.List<RelationLink>; // Связанные ссылки
    relationLinksCount?: number; // Количество связанных ссылок
    userCreated?: ContractorHuman|Employee|Group; // Создатель
    userUpdated?: ContractorHuman|Employee|Group; // Обновил
    participantsCount?: number; // Количество участников
    contractorFields?: H.List<string>; // Поля контрагента
    fixCurrencyDate?: Date; // Дата изменения курса валюты
    attaches?: H.List<File>; // Вложения
    attachesCount?: number; // Количество вложений
    commentsWithoutTransportCount?: number; // Количество комментариев без транспорта
    emailsCount?: number; // Количество емейлов
    whatsappCount?: number; // Количество сообщений whatsapp
    telegramCount?: number; // Количество сообщений telegram
    instagramCount?: number; // Количество сообщений instagram
    interactionsCounters?: H.List<InteractionCounter>;
    id?: string; // Идентификатор
    unreadCommentsCount?: number; // Количество непрочитанных комментариев
    attachesCountInComments?: number; // Количество вложений в комментариях
    subscribed?: boolean; // Подписан ли текущий пользователь на комментарии по этой сущности
    possibleSubscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники сущности, которые могут получать уведомления по ней.
    possibleSubscribersCount?: number; // Количество возможных подписчиков
    subscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники, которые подписаны на уведомления по сущности.
    subscribersCount?: number; // Количество подписчиков
    comments?: H.List<Comment>; // Массив комментариев
    commentsCount?: number; // Количество комментариев
    hiddenCommentsCount?: number; // Количество скрытых комментариев
    isUnread?: boolean; // Помечен как непрочитанный
    firstUnreadComment?: Comment; // Первый непрочитанный комментарий
    unreadAnswer?: boolean; // Есть ли непрочитанный ответ или упоминание
    todos?: H.List<Todo>; // Массив дел
    todosCount?: number; // Количество дел
    actualTodosCount?: number; // Количество актуальных дел
    finishedTodosCount?: number; // Количество завершенных дел
    allFiles?: H.List<File | FileGroup>; // Массив файлов
    allFilesCount?: number; // Количество файлов
    attachesInfo?: AttachesInfo; // Информация о вложении
    tags?: H.List<Tag>; // Метки
    tagsCount?: number; // Количество меток
    reminderTime?: Date; // Дата напоминания
    financeOperations?: H.List<FinOperation>; // Финансовые операции
    financeOperationsCount?: number; // Количество финансовых операций
    contentType: "Deal"; // Object type
}

export module Deal {
    export const contentType: "Deal" = "Deal";
    export const endpoint = "/api/v3/deal";
    export type Result = "active"|"negative"|"positive";
    export module Result {
        export const active: Result = "active";
        export const negative: Result = "negative";
        export const positive: Result = "positive";
    }
    export type PossibleActions = "act_delete"|"act_read";
    export module PossibleActions {
        export const act_delete: PossibleActions = "act_delete";
        export const act_read: PossibleActions = "act_read";
    }
    export const newObject: Deal = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: true, id: "Deal.isFavorite", defaultValue: null as BoolField["defaultValue"]};
        export const number = {contentType: "StringField" as "StringField", name: "number", isMultiple: false, isSortable: true, id: "Deal.number", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: true, id: "Deal.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: true, id: "Deal.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const shortDescription = {contentType: "StringField" as "StringField", name: "shortDescription", isMultiple: false, isSortable: false, id: "Deal.shortDescription", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const lastComment = {contentType: "RefLinkField" as "RefLinkField", name: "lastComment", isMultiple: false, isSortable: false, id: "Deal.lastComment", refContentType: H.List(["Comment"])};
        export const lastCommentTimeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "lastCommentTimeCreated", isMultiple: false, isSortable: true, id: "Deal.lastCommentTimeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const contractor = {contentType: "RefLinkField" as "RefLinkField", name: "contractor", isMultiple: false, isSortable: true, id: "Deal.contractor", refContentType: H.List(["ContractorCompany", "ContractorHuman"])};
        export const contact = {contentType: "RefLinkField" as "RefLinkField", name: "contact", isMultiple: false, isSortable: true, id: "Deal.contact", refContentType: H.List(["ContractorCompany", "ContractorHuman"])};
        export const manager = {contentType: "RefLinkField" as "RefLinkField", name: "manager", isMultiple: false, isSortable: true, id: "Deal.manager", refContentType: H.List(["ContractorHuman", "Employee", "Group"])};
        export const payer = {contentType: "RefLinkField" as "RefLinkField", name: "payer", isMultiple: false, isSortable: true, id: "Deal.payer", refContentType: H.List(["Payer"])};
        export const auditors = {contentType: "RefLinkField" as "RefLinkField", name: "auditors", isMultiple: true, isSortable: false, id: "Deal.auditors", refContentType: H.List(["ContractorHuman", "Employee", "Group"])};
        export const auditorsCount = {contentType: "IntegerField" as "IntegerField", name: "auditorsCount", isMultiple: false, isSortable: false, id: "Deal.auditorsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const price = {contentType: "MoneyField" as "MoneyField", name: "price", isMultiple: false, isSortable: true, id: "Deal.price", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const cost = {contentType: "MoneyField" as "MoneyField", name: "cost", isMultiple: false, isSortable: true, id: "Deal.cost", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const payForm = {contentType: "RefLinkField" as "RefLinkField", name: "payForm", isMultiple: false, isSortable: true, id: "Deal.payForm", refContentType: H.List(["PayForm"])};
        export const currency = {contentType: "RefLinkField" as "RefLinkField", name: "currency", isMultiple: false, isSortable: false, id: "Deal.currency", refContentType: H.List(["Currency"])};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: true, id: "Deal.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const timeUpdated = {contentType: "DateTimeField" as "DateTimeField", name: "timeUpdated", isMultiple: false, isSortable: true, id: "Deal.timeUpdated", defaultValue: null as DateTimeField["defaultValue"]};
        export const state = {contentType: "RefLinkField" as "RefLinkField", name: "state", isMultiple: false, isSortable: true, id: "Deal.state", refContentType: H.List(["ProgramState"])};
        export const stateTimeUpdated = {contentType: "DateTimeField" as "DateTimeField", name: "stateTimeUpdated", isMultiple: false, isSortable: true, id: "Deal.stateTimeUpdated", defaultValue: null as DateTimeField["defaultValue"]};
        export const result = {contentType: "EnumField" as "EnumField", name: "result", isMultiple: false, isSortable: true, id: "Deal.result", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["active", "negative", "positive"])};
        export const positions = {contentType: "RefLinkField" as "RefLinkField", name: "positions", isMultiple: true, isSortable: false, id: "Deal.positions", refContentType: H.List(["OfferRow"])};
        export const positionsCount = {contentType: "IntegerField" as "IntegerField", name: "positionsCount", isMultiple: false, isSortable: false, id: "Deal.positionsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const debt = {contentType: "MoneyField" as "MoneyField", name: "debt", isMultiple: false, isSortable: false, id: "Deal.debt", defaultValue: null as MoneyField["defaultValue"], refContentType: H.List(["Money"])};
        export const paymentProgress = {contentType: "FloatField" as "FloatField", name: "paymentProgress", isMultiple: false, isSortable: false, id: "Deal.paymentProgress", defaultValue: null as FloatField["defaultValue"]};
        export const invoices = {contentType: "RefLinkField" as "RefLinkField", name: "invoices", isMultiple: true, isSortable: false, id: "Deal.invoices", refContentType: H.List(["Invoice"])};
        export const invoicesCount = {contentType: "IntegerField" as "IntegerField", name: "invoicesCount", isMultiple: false, isSortable: false, id: "Deal.invoicesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const nearTodo = {contentType: "RefLinkField" as "RefLinkField", name: "nearTodo", isMultiple: false, isSortable: true, id: "Deal.nearTodo", refContentType: H.List(["Todo"])};
        export const program = {contentType: "RefLinkField" as "RefLinkField", name: "program", isMultiple: false, isSortable: false, id: "Deal.program", refContentType: H.List(["Program"])};
        export const possibleTriggers = {contentType: "RefLinkField" as "RefLinkField", name: "possibleTriggers", isMultiple: true, isSortable: false, id: "Deal.possibleTriggers", refContentType: H.List(["ProgramTrigger"])};
        export const possibleTriggersCount = {contentType: "IntegerField" as "IntegerField", name: "possibleTriggersCount", isMultiple: false, isSortable: false, id: "Deal.possibleTriggersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const possibleTransitions = {contentType: "RefLinkField" as "RefLinkField", name: "possibleTransitions", isMultiple: true, isSortable: false, id: "Deal.possibleTransitions", refContentType: H.List(["ProgramTransition"])};
        export const possibleTransitionsCount = {contentType: "IntegerField" as "IntegerField", name: "possibleTransitionsCount", isMultiple: false, isSortable: false, id: "Deal.possibleTransitionsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "Deal.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["act_delete", "act_read"])};
        export const dealsCount = {contentType: "IntegerField" as "IntegerField", name: "dealsCount", isMultiple: false, isSortable: false, id: "Deal.dealsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const actualDealsCount = {contentType: "IntegerField" as "IntegerField", name: "actualDealsCount", isMultiple: false, isSortable: false, id: "Deal.actualDealsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const tasksCount = {contentType: "IntegerField" as "IntegerField", name: "tasksCount", isMultiple: false, isSortable: false, id: "Deal.tasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const actualTasksCount = {contentType: "IntegerField" as "IntegerField", name: "actualTasksCount", isMultiple: false, isSortable: false, id: "Deal.actualTasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const contractorPhone = {contentType: "RefLinkField" as "RefLinkField", name: "contractorPhone", isMultiple: false, isSortable: false, id: "Deal.contractorPhone", refContentType: H.List(["ContactInfo"])};
        export const contractorEmail = {contentType: "StringField" as "StringField", name: "contractorEmail", isMultiple: false, isSortable: false, id: "Deal.contractorEmail", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const visibleFields = {contentType: "StringField" as "StringField", name: "visibleFields", isMultiple: true, isSortable: false, id: "Deal.visibleFields", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const editableFields = {contentType: "StringField" as "StringField", name: "editableFields", isMultiple: true, isSortable: false, id: "Deal.editableFields", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const messagesCount = {contentType: "IntegerField" as "IntegerField", name: "messagesCount", isMultiple: false, isSortable: false, id: "Deal.messagesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const callsCount = {contentType: "IntegerField" as "IntegerField", name: "callsCount", isMultiple: false, isSortable: false, id: "Deal.callsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const links = {contentType: "RefLinkField" as "RefLinkField", name: "links", isMultiple: true, isSortable: false, id: "Deal.links", refContentType: H.List(["RelationLink"])};
        export const linksCount = {contentType: "IntegerField" as "IntegerField", name: "linksCount", isMultiple: false, isSortable: false, id: "Deal.linksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const relationLinks = {contentType: "RefLinkField" as "RefLinkField", name: "relationLinks", isMultiple: true, isSortable: false, id: "Deal.relationLinks", refContentType: H.List(["RelationLink"])};
        export const relationLinksCount = {contentType: "IntegerField" as "IntegerField", name: "relationLinksCount", isMultiple: false, isSortable: false, id: "Deal.relationLinksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const userCreated = {contentType: "RefLinkField" as "RefLinkField", name: "userCreated", isMultiple: false, isSortable: false, id: "Deal.userCreated", refContentType: H.List(["ContractorHuman", "Employee", "Group"])};
        export const userUpdated = {contentType: "RefLinkField" as "RefLinkField", name: "userUpdated", isMultiple: false, isSortable: false, id: "Deal.userUpdated", refContentType: H.List(["ContractorHuman", "Employee", "Group"])};
        export const participantsCount = {contentType: "IntegerField" as "IntegerField", name: "participantsCount", isMultiple: false, isSortable: false, id: "Deal.participantsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const contractorFields = {contentType: "StringField" as "StringField", name: "contractorFields", isMultiple: true, isSortable: false, id: "Deal.contractorFields", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const fixCurrencyDate = {contentType: "DateTimeField" as "DateTimeField", name: "fixCurrencyDate", isMultiple: false, isSortable: false, id: "Deal.fixCurrencyDate", defaultValue: null as DateTimeField["defaultValue"]};
        export const attaches = {contentType: "RefLinkField" as "RefLinkField", name: "attaches", isMultiple: true, isSortable: false, id: "Deal.attaches", refContentType: H.List(["File"])};
        export const attachesCount = {contentType: "IntegerField" as "IntegerField", name: "attachesCount", isMultiple: false, isSortable: false, id: "Deal.attachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const commentsWithoutTransportCount = {contentType: "IntegerField" as "IntegerField", name: "commentsWithoutTransportCount", isMultiple: false, isSortable: false, id: "Deal.commentsWithoutTransportCount", defaultValue: null as IntegerField["defaultValue"]};
        export const emailsCount = {contentType: "IntegerField" as "IntegerField", name: "emailsCount", isMultiple: false, isSortable: false, id: "Deal.emailsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const whatsappCount = {contentType: "IntegerField" as "IntegerField", name: "whatsappCount", isMultiple: false, isSortable: false, id: "Deal.whatsappCount", defaultValue: null as IntegerField["defaultValue"]};
        export const telegramCount = {contentType: "IntegerField" as "IntegerField", name: "telegramCount", isMultiple: false, isSortable: false, id: "Deal.telegramCount", defaultValue: null as IntegerField["defaultValue"]};
        export const instagramCount = {contentType: "IntegerField" as "IntegerField", name: "instagramCount", isMultiple: false, isSortable: false, id: "Deal.instagramCount", defaultValue: null as IntegerField["defaultValue"]};
        export const interactionsCounters = {contentType: "RefLinkField" as "RefLinkField", name: "interactionsCounters", isMultiple: true, isSortable: false, id: "Deal.interactionsCounters", refContentType: H.List(["InteractionCounter"])};
        export const unreadCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "unreadCommentsCount", isMultiple: false, isSortable: false, id: "Deal.unreadCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesCountInComments = {contentType: "IntegerField" as "IntegerField", name: "attachesCountInComments", isMultiple: false, isSortable: false, id: "Deal.attachesCountInComments", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribed = {contentType: "BoolField" as "BoolField", name: "subscribed", isMultiple: false, isSortable: false, id: "Deal.subscribed", defaultValue: null as BoolField["defaultValue"]};
        export const possibleSubscribers = {contentType: "RefLinkField" as "RefLinkField", name: "possibleSubscribers", isMultiple: true, isSortable: false, id: "Deal.possibleSubscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const possibleSubscribersCount = {contentType: "IntegerField" as "IntegerField", name: "possibleSubscribersCount", isMultiple: false, isSortable: false, id: "Deal.possibleSubscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribers = {contentType: "RefLinkField" as "RefLinkField", name: "subscribers", isMultiple: true, isSortable: false, id: "Deal.subscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const subscribersCount = {contentType: "IntegerField" as "IntegerField", name: "subscribersCount", isMultiple: false, isSortable: false, id: "Deal.subscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const comments = {contentType: "RefLinkField" as "RefLinkField", name: "comments", isMultiple: true, isSortable: false, id: "Deal.comments", refContentType: H.List(["Comment"])};
        export const commentsCount = {contentType: "IntegerField" as "IntegerField", name: "commentsCount", isMultiple: false, isSortable: false, id: "Deal.commentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const hiddenCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "hiddenCommentsCount", isMultiple: false, isSortable: false, id: "Deal.hiddenCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isUnread = {contentType: "BoolField" as "BoolField", name: "isUnread", isMultiple: false, isSortable: false, id: "Deal.isUnread", defaultValue: null as BoolField["defaultValue"]};
        export const firstUnreadComment = {contentType: "RefLinkField" as "RefLinkField", name: "firstUnreadComment", isMultiple: false, isSortable: false, id: "Deal.firstUnreadComment", refContentType: H.List(["Comment"])};
        export const unreadAnswer = {contentType: "BoolField" as "BoolField", name: "unreadAnswer", isMultiple: false, isSortable: false, id: "Deal.unreadAnswer", defaultValue: null as BoolField["defaultValue"]};
        export const todos = {contentType: "RefLinkField" as "RefLinkField", name: "todos", isMultiple: true, isSortable: false, id: "Deal.todos", refContentType: H.List(["Todo"])};
        export const todosCount = {contentType: "IntegerField" as "IntegerField", name: "todosCount", isMultiple: false, isSortable: false, id: "Deal.todosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const actualTodosCount = {contentType: "IntegerField" as "IntegerField", name: "actualTodosCount", isMultiple: false, isSortable: false, id: "Deal.actualTodosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const finishedTodosCount = {contentType: "IntegerField" as "IntegerField", name: "finishedTodosCount", isMultiple: false, isSortable: false, id: "Deal.finishedTodosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const allFiles = {contentType: "RefLinkField" as "RefLinkField", name: "allFiles", isMultiple: true, isSortable: false, id: "Deal.allFiles", refContentType: H.List(["File", "FileGroup"])};
        export const allFilesCount = {contentType: "IntegerField" as "IntegerField", name: "allFilesCount", isMultiple: false, isSortable: false, id: "Deal.allFilesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesInfo = {contentType: "RefLinkField" as "RefLinkField", name: "attachesInfo", isMultiple: false, isSortable: false, id: "Deal.attachesInfo", refContentType: H.List(["AttachesInfo"])};
        export const tags = {contentType: "RefLinkField" as "RefLinkField", name: "tags", isMultiple: true, isSortable: true, id: "Deal.tags", refContentType: H.List(["Tag"])};
        export const tagsCount = {contentType: "IntegerField" as "IntegerField", name: "tagsCount", isMultiple: false, isSortable: false, id: "Deal.tagsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const reminderTime = {contentType: "DateTimeField" as "DateTimeField", name: "reminderTime", isMultiple: false, isSortable: false, id: "Deal.reminderTime", defaultValue: null as DateTimeField["defaultValue"]};
        export const financeOperations = {contentType: "RefLinkField" as "RefLinkField", name: "financeOperations", isMultiple: true, isSortable: false, id: "Deal.financeOperations", refContentType: H.List(["FinOperation"])};
        export const financeOperationsCount = {contentType: "IntegerField" as "IntegerField", name: "financeOperationsCount", isMultiple: false, isSortable: false, id: "Deal.financeOperationsCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isDeal(arg: any): arg is Deal {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Deal"
}
