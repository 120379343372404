import {getLink, getGID} from "src/lib/entities/utils";
import {BaseEntity, BaseValue, isBaseEntity, isBaseValue, isDateInterval} from "src/lib/entities/types";
import * as Collections from "src/lib/collections";

/**
 * Функция нормализует сущность (которая является графом с циклами) в массив объектов, пригодных для JSON.stringify
 */
export function normalizeEntity(
    entity: BaseEntity | BaseValue | Array<BaseEntity | BaseValue>,
    result: BaseEntity[] = [],
    visited = new Set<BaseEntity>()
) {
    if (Array.isArray(entity)) {
        for (const oneEntity of entity) {
            normalizeEntity(oneEntity, result, visited)
        }
        return
    }
    if (isBaseEntity(entity) && entity.id) {
        if (visited.has(entity)) {
            return getLink(entity)
        }
        visited.add(entity)
    }
    const normalized: any = {}
    if (isBaseEntity(entity) && entity.id) {
        result.push(normalized)
    }
    for (const key in entity) {
        const value = (entity as any)[key]
        if (isBaseEntity(value) && value.id) {
            normalized[key] = getLink(value)
            normalizeEntity(value, result, visited)
        } else if (isBaseValue(value)) {
            normalized[key] = normalizeEntity(value, result, visited)
        } else if (Collections.isList(value)) {
            normalized[key] = [...value].map(v => {
                if (isBaseEntity(v) && v.id) {
                    normalizeEntity(v, result, visited)
                    return getLink(v)
                } else if (isBaseValue(v)) {
                    return normalizeEntity(v, result, visited)
                } else {
                    return v
                }
            })
        } else if (void 0 !== value) {
            normalized[key] = value
        }
    }

    return normalized
}

// функция возвращает ентити со схлопнутыми в ентити-линк цилкическими ссылками,
// которые встретились в родительском поле ентити
export function getCyclesFreeNormalizedEntity(
    entity: BaseEntity | BaseValue,
    visited = new Set<string>()
) {
    if (isBaseEntity(entity) && entity.id) {
        const gid = getGID(entity)
        if (visited.has(gid)) {
            return getLink(entity)
        }
        visited.add(gid)
    }
    const normalized: any = {}
    for (const key in entity) {
        const value = (entity as any)[key]
        if (
            (isBaseEntity(value) && value.id)
            || isBaseValue(value)
        ) {
            normalized[key] = getCyclesFreeNormalizedEntity(value, visited)
        } else if (Collections.isList(value)) {
            normalized[key] = [...value].map(v => {
                if (
                    (isBaseEntity(v) && v.id)
                    || isBaseValue(v)
                ) {
                    return getCyclesFreeNormalizedEntity(v, visited)
                } else {
                    return v
                }
            })
        } else if (value instanceof Date) {
            normalized[key] = {
                contentType: "DateTime",
                value: value.toISOString()
            }
        } else if (isDateInterval(value)) {
            normalized[key] = value.denormalize()
        } else if (void 0 !== value) {
            normalized[key] = value
        }
    }

    return normalized
}


/**
 * Рекурсивная функция помогающая достать все вложенные сущности у переданного значения
 * @param {BaseValue} entity
 * @returns {IterableIterator<BaseEntity>}
 */
export function* getNestedEntities(entity: BaseValue): IterableIterator<BaseEntity> {
    for (const propValue of Object.values(entity)) {
        if (isBaseEntity(propValue)) {
            yield propValue
        } else if (Array.isArray(propValue)) {
            for (const item of propValue) {
                if (isBaseEntity(item)) {
                    yield item
                }
            }
        } else if (isBaseValue(propValue)) {
            for (const nestedEntity of getNestedEntities(propValue)) {
                yield nestedEntity
            }
        }
    }
}
