import Promise from "bluebird"
import * as React from "react"
import * as ReactDOM from "react-dom"
import {Provider as ReduxProvider} from "react-redux"
import * as Redux from "redux"
import firebase from "firebase/app"
import thunkMiddleware from "redux-thunk"
import {browserHistory} from "react-router"
import {Location} from "history"
import {AuthGuardTransport} from "src/lib/entities/store/transport/AuthGuardTransport"

import * as Megaplan from "src/megaplan"
import * as Intl from "src/lib/utils/intl"
import {createApiStoreMiddleware} from "src/lib/entities/apiStoreMiddleware"
import {onError} from "./errorHandler"
import MegaplanRouter from "./MegaplanRouter"
import MegaplanHelpRouter from "src/bums/help/MegaplanHelpRouter"
import {CGlobalContainer} from "src/bums/common/globalContainer/CGlobalContainer"

// stores
import {ApiStore, realFetch} from "src/lib/entities/store/ApiStore"
import {StoresProvider, StoresMap} from "./StoresProvider"
import {LockStore} from "src/lib/utils/LockStore"
import {Tracker, TransportAggregate} from "src/bums/common/stores/Tracker"
import {BackendTransport} from "src/bums/common/stores/Tracker/BackendTransport"
import {UserStore} from "src/lib/entities/store/UserStore"
import {Ecomet} from "src/lib/services/Ecomet"
import {SingletonSocket} from "src/lib/services/SingletonSocket"
import {Router, NavigationType} from "src/lib/utils/router"
import {WindowLocation} from "src/lib/utils/location/WindowLocation"
import {UserInterfaceListStore} from "src/bums/common/userInterface/UserInterfaceListStore"
import {JanusAdapterImplement} from "src/bums/common/janus/stores/janus/types"
import {WebAdapter as JanusWebAdapter} from "src/bums/common/janus/stores/janus/WebAdapter"
import {SoundPlayerImplement} from "src/bums/common/stores/types"
import {SoundStore} from "src/bums/common/stores/SoundStore"

import {configure} from "mobx"
import {create} from "mobx-persist"
import {RealtimeEntitiesUpdater} from "src/lib/entities/store/RealtimeEntitiesUpdater"
import {ListNamesByFilterGidStore} from "src/bums/common/stores/ListNamesByFilterGidStore"
import {NewEntityToListPusher} from "src/bums/common/stores/NewEntityToListPusher"
import {FetchMultiplexer} from "src/lib/entities/store/FetchMultiplexer"
import {BrowserTabFactory} from "src/bums/common/browserTabs"
import {Profiler} from "src/lib/utils/Profiler"
import {TransportLayer} from "src/lib/entities/store/types"
import {PreloadTransport} from "src/lib/entities/store/transport/PreloadTransport"
import {NormalizeQueryStringTransport} from "src/lib/entities/store/transport/NormalizeQueryStringTransport"
import {createAppTypedTransport, createPendingRequestsCounterTransport} from "src/lib/entities/store/transport/fetchTransport"
import {createHelpBackendEmulator} from "src/bums/help/static/backendEmulator"
import {createEntitiesStorage} from "src/lib/entities/store/EntitiesStorage/entitiesStorageFactory"
import {CErrorHandler} from "src/lib/components"
import {AccountInfoStore} from "src/lib/entities/store/AccountInfoStore"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {UserAppSessionStore} from "src/bums/common/stores/Tracker/UserAppSessionStore"
import {ChannelTracker} from "src/bums/common/stores/Tracker/ChannelTracker"
import {IntlReplacer} from "src/lib/utils/intl/IntlReplacer"
import {CommentsCacheStore} from "src/bums/common/stores/CommentsCacheStore"
import {UserOnlineStore} from "src/lib/entities/store/online/UserOnlineStore"
import {BrowserAppStateStore} from "src/lib/entities/store/online/BrowserAppStateStore"
import {getIntl} from "src/lib/utils/intl/getIntl"
import {DraftStore} from "./lib/entities/store/draft/DraftStore"
import {AppStateStore} from "src/lib/entities/store/online/AppStateStore"
import {getFirebaseConfig} from "src/lib/services/firebaseConfig"
import {FirebaseTrackerTransport} from "src/bums/common/stores/Tracker/transports/FirebaseTrackerTransport"

declare let window: any;

configure({ enforceActions: "observed" })

interface Bundle<P> {
    component: React.ComponentClass<P>,
    reducer: Redux.Reducer<any>,
}

interface Root<P> {
    bundle: Bundle<P>,
    containerId: string,
    props?: P
}

interface Options {
    locale: string
    fallbackLocale: string
    initialState: Megaplan.State
    translationReplacement: void | {search: string, replacement: string}
    onReady: (kernel: Kernel) => void
    preloadedEndpoints: {[endpoint: string]: any}
}

/**
 * Main Kernel for Single Page Application. Instantiates once in initialize script.
 */
export default class Kernel {

    private booted = false

    private running = false

    /**
     * Application store.
     * This is a redux store instance.
     * @type {null}
     */
    private store: Redux.Store<Megaplan.State>

    /**
     * Application initial state
     */
    private initialState: Megaplan.State

    private roots: Array<Root<any>>

    private intl: Intl.Intl

    private apiStore: ApiStore

    private storesMap: StoresMap

    /**
     * не используется в коде, но нужен для GC
     */
    protected singletonSocket: SingletonSocket

    constructor(private options: Options) {
        this.roots = [];
        this.initialState = options.initialState
        void getIntl(options.locale).then(intl => {
            this.intl = intl
            if (options.translationReplacement) {
                intl.setGlobalReplacement(
                    new RegExp(options.translationReplacement.search, "ugi"),
                    options.translationReplacement.replacement
                )
            }
            options.onReady(this)
        })
    }

    /**
     * Добавляет новый рутовый компонент в ядро.
     * @param root
     */
    public addRoot<P>(name: string, root: Root<P>) {
        if (void 0 === root.bundle) {
            throw new Error(`Bundle "${name}" not found. Have you export it to window in "front/index.ts"?`)
        }
        this.roots.push(root)
    }

    private async initializeHelpStores() {
        const lockStore = new LockStore();

        this.apiStore = new ApiStore(createHelpBackendEmulator(), lockStore)
        this.apiStore.setLocale(this.options.locale)

        const router = this.initRouter()

        const userStore = new UserStore(this.apiStore)
        const featureStore = new FeatureStore(this.apiStore, userStore)

        await Promise.all([
            userStore.initialize(),
            featureStore.initialize()
        ])

        const tracker = new Tracker(
            new BackendTransport(userStore, window.location.origin, {
                origin: "help"
            })
        )
        window.tracker = tracker

        this.storesMap = StoresMap.create([
            [ApiStore, this.apiStore],
            [UserStore, userStore],
            [Router, router],
            [Intl.Intl, this.intl],
            [Tracker, tracker],
            [FeatureStore, featureStore],
        ])
    }

    private getAppRouter() {
        if (process.env.APP_NAME === "help_static") {
            return MegaplanHelpRouter
        } else {
            return MegaplanRouter
        }
    }

    private getAppGlobalContainer() {
        if (process.env.APP_NAME === "help_static") {
            return <div />
        } else {
            return <CGlobalContainer />
        }
    }

    private initRouter() {
        return new Router({
            push(location) {
                if (window.ReactRouterFound) {
                    browserHistory.push(location)
                } else {
                    window.location.assign(browserHistory.createHref(location))
                }
            },
            replace(location) {
                if (window.ReactRouterFound) {
                    browserHistory.replace(location)
                } else {
                    window.location.replace(browserHistory.createHref(location))
                }
            },
            location: new WindowLocation(),
            type: window.performance.navigation.type !== 1 ? NavigationType.NAVIGATE : NavigationType.RELOAD
        })
    }

    public async run() {
        if (this.running) {
            return
        }
        this.running = true
        await this.boot()
        let routerFound = false;
        const renderCallbacks: Function[] = []
        function invokeRenderCallbacks() {
            renderCallbacks.forEach(cb => cb());
        }
        for (let root of this.roots) {
            if (root.bundle.component === this.getAppRouter()) {
                if (routerFound) {
                    throw new Error("You can not add more than one router to the page!")
                }
                routerFound = true;
                root.props = {
                    history: browserHistory,
                }
            }
            renderCallbacks.push(() => {
                ReactDOM.render(
                    <StoresProvider storesMap={this.storesMap}>
                        <ReduxProvider store={this.store}>
                            <CErrorHandler>
                                <root.bundle.component {...root.props} />
                            </CErrorHandler>
                        </ReduxProvider>
                    </StoresProvider>,
                    window.document.getElementById(root.containerId)
                )
            })
        }

        if (renderCallbacks.length > 0) {
            let el = window.document.createElement("div")
            el.className = "_sc";
            renderCallbacks.push(() => {
                ReactDOM.render(
                    <StoresProvider storesMap={this.storesMap}>
                        {this.getAppGlobalContainer()}
                    </StoresProvider>,
                    window.document.body.appendChild(el)
                )
            })
        }

        window.ReactRouterFound = routerFound;
        window.document.addEventListener("versionChanged", function() {
            // Если обновилась версия Мегаплана, то все ссылки вместо роутинга без перезагрузки страницы
            // должны эту самую страницу перезагрузить
            window.ReactRouterFound = false
        })
        if (!routerFound) {
            {
                // Костыли-костылики.
                // Отлавливаем изменение browserHistory и переход по ссылке с изменение pathname или search,
                // и форсируем перезагрузку страницы.
                // Это нужно чтобы работала кнопка "Назад" после перехода из
                // front/src/web/MegaplanRouter.tsx:13
                let prevLocation: Location = browserHistory.createLocation(window.location)
                browserHistory.listenBefore(location => {
                    if (prevLocation.pathname !== location.pathname || prevLocation.search !== location.search) {
                        window.location.reload();
                    }
                    prevLocation = location;
                })
            }
        }
        if (window.document.readyState === "loading") {
            window.document.addEventListener("DOMContentLoaded", invokeRenderCallbacks)
        } else {
            invokeRenderCallbacks()
        }
    }

    private async initializeStores() {
        const lockStore = new LockStore();
        const listNamesByFilterGidStore = new ListNamesByFilterGidStore();
        const browserTabFactory = window.MegaplanSPA.BrowserTabFactoryInstance
        const ecomet = window.MegaplanSPA.Ecomet

        let transport: TransportLayer = realFetch

        if (window.use_fetch_multiplexer) {
            transport = new FetchMultiplexer(transport)
        }

        const preloadTransport = new PreloadTransport(transport)

        if (window.bt_mode) {
            transport = new NormalizeQueryStringTransport(transport)
            transport = createPendingRequestsCounterTransport(transport);
            this.apiStore = new ApiStore(createAppTypedTransport("Web", transport), lockStore)
        }  else {
            transport = new NormalizeQueryStringTransport(preloadTransport)
            transport = createAppTypedTransport("Web", transport)
            transport = new AuthGuardTransport(transport, ecomet, browserTabFactory)

            this.apiStore = new ApiStore(transport, lockStore)
            preloadTransport.setApiStore(this.apiStore)
        }

        this.apiStore.setLocale(this.options.locale)

        const newEntityToListPusher = new NewEntityToListPusher(this.apiStore, listNamesByFilterGidStore)
        this.apiStore.setNewEntityToListPusher(newEntityToListPusher)

        const entitiesStorage = createEntitiesStorage(this.apiStore, browserTabFactory)
        this.apiStore.setEntitiesStorage(entitiesStorage)

        const router = this.initRouter()
        const userStore = new UserStore(this.apiStore)
        const accountInfoStore = new AccountInfoStore(this.apiStore)
        const featureStore = new FeatureStore(this.apiStore, userStore)
        const userInterfaceListStore = new UserInterfaceListStore(this.apiStore)

        const realtimeEntitiesUpdater = new RealtimeEntitiesUpdater(
            this.apiStore,
            listNamesByFilterGidStore,
            browserTabFactory,
            ecomet,
            featureStore
        )

        this.intl.setIntlReplacer(new IntlReplacer(featureStore))

        if (this.needInitializeStores(router)) {
            await Promise.all([
                userStore.initialize(),
                accountInfoStore.initialize(),
                featureStore.initialize(),
            ])
            userInterfaceListStore.initialize()
            this.initializeSocket(this.apiStore, userStore)
        }

        const trackerTransport = new BackendTransport(
            userStore,
            window.location.origin,
            {
                origin: "web",
                trackMemory: Boolean(window.memory_track)
            }
        )

        let firebaseTrackerTransport: FirebaseTrackerTransport

        if (process.env.NODE_ENV === "production") {
            firebase.initializeApp(getFirebaseConfig(window?.location?.origin))
            firebaseTrackerTransport = new FirebaseTrackerTransport(userStore, accountInfoStore)
        }

        const tracker = new Tracker(
            new TransportAggregate([
                firebaseTrackerTransport,
                trackerTransport,
            ].filter(Boolean))
        )
        window.tracker = tracker

        const userAppSessionStore = new UserAppSessionStore(
            new ChannelTracker(tracker, ["product", "amplitude"]),
            browserTabFactory
        )

        const appStateStore = new BrowserAppStateStore()
        const userOnlineStore = new UserOnlineStore(ecomet, appStateStore)

        const draftStore = new DraftStore(this.apiStore)
        const hydrate = create()
        hydrate("drafts", draftStore)

        trackerTransport.setSessionIdGenerator(userAppSessionStore.getSessionId)

        const profiler = new Profiler()
        const janusWebAdapter = new JanusWebAdapter()

        const soundStore = new SoundStore(window.MegaplanSPA.BrowserTabFactoryInstance)

        this.storesMap = StoresMap.create([
            [NewEntityToListPusher, newEntityToListPusher],
            [LockStore, lockStore],
            [ApiStore, this.apiStore],
            [CommentsCacheStore, new CommentsCacheStore(this.apiStore)],
            [Ecomet, ecomet],
            [ListNamesByFilterGidStore, listNamesByFilterGidStore],
            [RealtimeEntitiesUpdater, realtimeEntitiesUpdater],
            [Router, router],
            [Intl.Intl, this.intl],
            [Tracker, tracker],
            [UserStore, userStore],
            [Profiler, profiler],
            [BrowserTabFactory, window.MegaplanSPA.BrowserTabFactoryInstance],
            [AccountInfoStore, accountInfoStore],
            [FeatureStore, featureStore],
            [UserInterfaceListStore, userInterfaceListStore],
            [UserAppSessionStore, userAppSessionStore],
            [PreloadTransport, preloadTransport],
            [UserOnlineStore, userOnlineStore],
            [DraftStore, draftStore],
            [AppStateStore, appStateStore],
            [JanusAdapterImplement, janusWebAdapter],
            [SoundPlayerImplement, soundStore],
            [SoundStore, soundStore],
        ])
    }

    private needInitializeStores(router: Router) {
        return !(
            router.location.pathname.includes("/login/")
            || router.location.pathname.indexOf("/inviteForm/") === 0
        )
    }

    protected initializeSocket(apiStore: ApiStore, userStore: UserStore) {
        let socketAuthUrl = window.erpher_ecomet_authUrl

        if (socketAuthUrl && socketAuthUrl[0] === "/") {
            socketAuthUrl = `${window.location.protocol}//${window.location.host}${socketAuthUrl}`
        } else {
            socketAuthUrl = void 0
        }

        this.singletonSocket = new SingletonSocket(
            window.use_recomet
                ? {
                    host: window.location.host,
                    useRecomet: true,
                    protocol: window.location.protocol
                }
                : {
                    host: window.erpher_ecomet_host,
                    protocol: window.location.protocol,
                    authData: socketAuthUrl
                        ? {
                            authUrl: socketAuthUrl,
                            cookie:  document.cookie
                        }
                        : void 0
                },
            window.MegaplanSPA.BrowserTabFactoryInstance
        )
    }

    /**
     * Boots the kernel. It means bootstraping of redux store and etc...
     */
    private async boot() {
        if (this.booted) {
            return
        }
        if (process.env.NODE_ENV === "production") {
            window.onerror = onError(window.onerror);
        }

        this.booted = true

        if (process.env.APP_NAME === "help_static") {
            await this.initializeHelpStores();
        } else {
            await this.initializeStores()
        }

        // empty store
        this.store = this.createStore(Megaplan.reducer)
    }

    /**
     * Creates an application store
     * @param reducer
     * @returns {Store}
     */
    private createStore(reducer: Redux.Reducer<Megaplan.State>): Redux.Store<Megaplan.State> {
        const middlewares: Redux.Middleware[] = [
            thunkMiddleware as any,
            createApiStoreMiddleware(this.apiStore),
        ]

        return Redux.createStore(
            reducer,
            this.initialState,
            Redux.applyMiddleware(...middlewares)
        ) as Redux.Store<Megaplan.State>
    }
}
