import * as ReduxActions from "redux-actions"
import * as Api from "src/lib/entities/api"

export const CHANGE_COLUMN = "bums/common/report/edit/action/CHANGE_COLUMN"

export type ChangeColumn = ReduxActions.Action<{
    column: Api.ReportConfigColumn,
    oldKey: string
}>

export function changeColumn(column: Api.ReportConfigColumn, oldKey: string): ChangeColumn {
    return {
        type: CHANGE_COLUMN,
        payload: {
            column,
            oldKey
        }
    }
}
