import * as ReduxActions from "redux-actions"

export const CHANGE_SHOW_FILTER = "bums/common/report/edit/action/CHANGE_SHOW_FILTER"

export type ChangeShowFilter = ReduxActions.Action<{
    showFilter: boolean
}>

export function changeShowFilter(showFilter: boolean): ChangeShowFilter {
    return {
        type: CHANGE_SHOW_FILTER,
        payload: {
            showFilter
        }
    }
}
