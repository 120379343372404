import * as ReduxActions from "redux-actions"

export const CHANGE_REPORT_DESCRIPTION = "bums/common/report/edit/action/CHANGE_REPORT_DESCRIPTION"

export type ChangeReportDescription = ReduxActions.Action<{
    description: string
}>

export function changeReportDescription(description: string): ChangeReportDescription {
    return {
        type: CHANGE_REPORT_DESCRIPTION,
        payload: {
            description
        }
    }
}
