import {autobind} from "core-decorators"
import {LocationDescriptor, LocationDescriptorObject} from "history"

export type RouterLocation = LocationDescriptorObject & {
    hash?: string
    origin?: string
}

export enum NavigationType {
    NAVIGATE,
    RELOAD
}

interface History {
    push(location: LocationDescriptor): void
    replace(location: LocationDescriptor): void
    location: {
        get(): RouterLocation
    }
    type: NavigationType
}

export interface Confirm {
    when: () => boolean
    message?: string
}

@autobind
export class Router {

    private counter = 0
    private confirms: Confirm[] = []

    constructor(private history: History) {}

    public get location() {
        return this.history.location.get()
    }

    public get isInitial() {
        return this.counter === 0 && this.history.type === NavigationType.NAVIGATE
    }

    public push(path: string) {

        const confirm = this.confirm
        if (confirm) {
            if (!this.isConfirmNavigation(confirm.message)) {
                return
            }
        }

        this.counter++
        this.history.push(path)
    }

    public replace(path: string) {

        const confirm = this.confirm
        if (confirm) {
            if (!this.isConfirmNavigation(confirm.message)) {
                return
            }
        }

        this.counter++
        this.history.replace(path)
    }

    private get confirm() {
        if (this.confirms.length) {
            return this.confirms.find((confirm: Confirm) => confirm.when())
        }
    }

    private isConfirmNavigation(message: string) {
        if (window && typeof window.confirm === "function") {
            return window.confirm(message)
        }
    }

    public addConfirm(confirm: Confirm) {
        this.confirms.push(confirm)
        const removeCallback = () => {
            this.confirms = this.confirms.filter((c: Confirm) => c !== confirm)
        }

        return removeCallback
    }
}
