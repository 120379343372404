import * as localForage from "src/lib/utils/localForage";
import {autobind} from "core-decorators";
import {
    AbstractBrowserTab,
    AbstractBrowserTabFactory,
    TabMessageEvent
} from "src/bums/common/browserTabs/transports/AbstractTabTransport";
import {MainTabEvent} from "src/bums/common/browserTabs/MainTab";
import {throttle} from "lodash";
import {TrackerInterface} from "src/bums/common/stores/Tracker/Tracker";


const SESSION_ID_KEY = "sessionId"
const SESSION_ID_TIMESTAMP_KEY = "sessionIdTimestamp"

const SESSION_TTL = 1800000;    // 30 минут
const CHECK_TIME = 3000

const intervalCallback = new Set<() => void>()
setInterval(() => {
    intervalCallback.forEach(callback => callback())
}, CHECK_TIME)

@autobind
export class UserAppSessionStore {
    private isMainTab = false

    private initPromise: Promise<void>

    constructor(
        private tracker: TrackerInterface,
        browserTabFactory: AbstractBrowserTabFactory,
        private storage = localForage.makeSafeStorage(localForage.createInstance({
            name: "app-session-storage",
        })),
    ) {
        const browserTab = browserTabFactory.createBrowserTabChannel("user-app-session-store")
        browserTab.on(MainTabEvent.main_tab_changed, this.onMainTabChanged)
        this.initPromise = this.initTab(browserTab)

        if (window && window.document && window.document.addEventListener) {
            window.document.addEventListener(
                "mousemove",
                throttle(
                    async () => {
                        await this.initPromise
                        await this.generateNewSessionIfNeed()
                        await this.updateSessionTimeNow()
                    },
                    CHECK_TIME,
                    {
                        leading: true,
                        trailing: true,
                    }
                )
            )
        }
    }

    private async initTab(browserTab: AbstractBrowserTab) {
        const tab = await browserTab.getReadyPromise()
        this.isMainTab = tab.isMainTab()

        const sessionId = await this.getSessionIdNow()
        if (sessionId && await this.isSessionExpired()) {
            this.tracker.trackEvent("session", "expired", {sessionId})
        }

        await this.generateNewSessionIfNeed()
        await this.updateSessionTimeNow()

        intervalCallback.add(this.endSessionChecker)
    }

    private onMainTabChanged(message: TabMessageEvent) {
        this.isMainTab = message.isMainTab
    }

    private async endSessionChecker() {
        if (!this.isMainTab) {
            return
        }

        const sessionId = await this.getSessionIdNow()

        if (
            sessionId
            && await this.isSessionExpired()
        ) {
            this.tracker.trackEvent("session", "end", {sessionId})
            await this.clearSessionId()
        }
    }

    public async getSessionId() {
        await this.initPromise
        return this.getSessionIdNow()
    }


    private async generateNewSessionId() {
        const sessionId = Date.now()
        await this.storage.setItem(SESSION_ID_KEY, sessionId)

        this.tracker.trackEvent("session", "start", {sessionId})

        return sessionId
    }

    private async updateSessionTimeNow() {
        await this.storage.setItem(SESSION_ID_TIMESTAMP_KEY, Date.now())
    }

    private async getSessionTimestamp() {
        return this.storage.getItem<number>(SESSION_ID_TIMESTAMP_KEY)
    }

    private async isSessionExpired() {
        const timestamp = await this.getSessionTimestamp()

        return !timestamp || (timestamp + SESSION_TTL < Date.now())
    }

    private async generateNewSessionIfNeed() {
        const sessionId = await this.getSessionIdNow()

        if (
            !sessionId
            || await this.isSessionExpired()
        ) {
            return this.generateNewSessionId()
        }

        return sessionId
    }

    private async getSessionIdNow() {
        return this.storage.getItem<number>(SESSION_ID_KEY)
    }

    private async clearSessionId() {
        await this.storage.setItem(SESSION_ID_KEY, null)
    }
}
