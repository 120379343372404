import * as React from "react"
import {
    ContentType,
    MassActionAppendCollection,
    MassActionDelete,
    MassActionEditField,
    MassActionEntitiesToFilters,
    MassActionUnlinkEntities,
    MassActionChangeProgramState,
    MassActionApplyTrigger, MassActionSubtractCollection
} from "src/lib/entities/api"

export enum SortDefinition {
    ASC,
    DESC
}

export type sizeType = "large" | "mediumPlus" | "medium" | "small"

export enum Size {
    SMALL,
    MEDIUM,
    BIG,
    XLARGE,
}

export interface Position {
    left?: number
    right?: number
    top?: number
    bottom?: number
}

export interface NormalizedOptionFromSearch {
    index: string,
    sort: string
}

export interface FilterOptions {
    filter?: string
    focused?: boolean
}

export type ExtendsFilterOptions = FilterOptions & {
    types?: ContentType[]
}

export interface KeyedValue<K, V> {
    key: K
    value: V
}

export function isKeyedValue(arg: any): arg is KeyedValue<any, any> {
    return !!arg && "object" === typeof arg && "key" in arg && "value" in arg
}

// цвета разделов  TODO остальные разделы
export type sectionName = "tasks" | "clients" | "trade"

//типы отображения fieldRow

export type fieldRowDisplayType = "default" | "cardList" | "cardGroupList" | "inlineItemMargin" | "inlineItemSmMargin"
export type fieldRowContainerDisplayType = "table" | "rightTable" | "tableGroup" | "fullWidthTable" | "subTable"
export type alignItem = "top" | "bottom" | "middle" | "stretch" | "baseline"
export type justifyItem = "top" | "bottom" | "middle" | "between" | "around"

//
export function isHasTarget(arg: any): arg is Target {
    return arg != null && !!arg.target && ["_blank", "_self", "_parent", "_top"].includes(arg.target)
}

interface Target {
    target?: "_blank" | "_self" | "_parent" | "_top"
}

// отображение блока, страница или карточка
export type ViewType = "card" | "page"

//состояния для элементов форм

export type formFieldStatus = "normal" | "focus" |  "error" | "disabled"

//пропсы обрaботки событий для "стилевых" элементов

export interface BaseEventsProps {
    onClick?: (event?: React.MouseEvent<HTMLElement>) => void
    onDoubleClick?: (event?: React.MouseEvent<HTMLElement>) => void
    onDrag?: (event?: React.DragEvent<HTMLElement>) => void
    onDragEnd?: (event?: React.DragEvent<HTMLElement>) => void
    onDragEnter?: (event: React.DragEvent<HTMLElement>) => void
    onDragLeave?: (event?: React.DragEvent<HTMLElement>) => void
    onDragOver?: (event?: React.DragEvent<HTMLElement>) => void
    onDragStart?: (event?: React.DragEvent<HTMLElement>) => void
    onDragExit?: (event?: React.DragEvent<HTMLElement>) => void
    onDrop?: (event?: React.DragEvent<HTMLElement>) => void
    onKeyDown?: (event?: React.KeyboardEvent<HTMLElement>) => void
    onKeyPress?: (event?: React.KeyboardEvent<HTMLElement>) => void
    onKeyUp?: (event?: React.KeyboardEvent<HTMLElement>) => void
    onMouseDown?: (event?: React.MouseEvent<HTMLElement>) => void
    onMouseEnter?: (event?: React.MouseEvent<HTMLElement>) => void
    onMouseLeave?: (event?: React.MouseEvent<HTMLElement>) => void
    onMouseMove?: (event?: React.MouseEvent<HTMLElement>) => void
    onMouseOut?: (event?: React.MouseEvent<HTMLElement>) => void
    onMouseOver?: (event?: React.MouseEvent<HTMLElement>) => void
    onMouseUp?: (event?: React.MouseEvent<HTMLElement>) => void
}

export type MassAction = MassActionDelete
    | MassActionEditField<string|boolean>
    | MassActionAppendCollection
    | MassActionSubtractCollection
    | MassActionEntitiesToFilters
    | MassActionUnlinkEntities
    | MassActionChangeProgramState
    | MassActionApplyTrigger

export type Json = JsonMap | JsonArray | string | number | boolean | null | undefined |
    JsonMap[] | JsonArray[] | string[] | number[] | boolean[]

interface JsonMap { [key: string]: Json, [key: number]: Json }
interface JsonArray extends Array<Json> {}

/**
 * Пакет visibilityjs имеет кривой тайпинг и обладает куда большим функционалом,
 * в данном случае нам надо только понять видимо ли окно или нет, поэтому обойдёмся без visibilityjs
 */
export const isDocumentHidden = () => {
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      return document.hidden
    } else if (typeof document.msHidden !== "undefined") {
      return document.msHidden
    } else if (typeof document.webkitHidden !== "undefined") {
      return document.webkitHidden
    }

    //Если ничего не подошло, то скажем, что окно всё же видимо, чтобы не потерять событие
    return false
}

/**
 * интерфейс элемнтов, через, которые может происходить запрос на показ подсказок OnBoard
 */
export interface ShowHint {
    onHintRequest?: () => void
    onHintHide?: () => void
}
