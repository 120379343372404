import {getGID, prepareEntityToSave} from "src/lib/entities/utils"
import {isTreeNode} from "src/lib/entities/bums"
import * as localForage from "src/lib/utils/localForage"
import {isEqual} from "lodash"
import {IdentityMap} from "src/lib/entities/store/IdentityMap";
import {getJsonProperty} from "src/lib/entities/store/JsonProperty";
import {EntitiesStorageInterface} from "src/lib/entities/store/EntitiesStorage/EntitiesStorageInterface";
import {
    getCyclesFreeNormalizedEntity, getNestedEntities,
    normalizeEntity
} from "src/lib/entities/store/EntitiesStorage/entitiesStorageUtils";
import {BaseEntity} from "src/lib/entities/types";


const getKey = getGID

export class EntitiesLocalStorage implements EntitiesStorageInterface {

    private initPromise: Promise<void>

    constructor(
        private storage = localForage.makeSafeStorage(localForage.createInstance({
            name: "entities",
        }))
    ) {
        this.initPromise = this.init()
    }

    /**
     * Очищает хранилище сущностей при обновлении версии кода
     */
    private async init(): Promise<void> {
        if (process.env.MEGAPLAN_VERSION !== await this.storage.getItem<string>("MEGAPLAN_VERSION")) {
            await this.storage.clear()
            await this.storage.setItem("MEGAPLAN_VERSION", process.env.MEGAPLAN_VERSION)
        }
    }

    /**
     * Сохраняет сущность и все связанные с ней другие сущности в персистентное хранилище
     */
    async save(entities: BaseEntity[]) {
        let listToSave: BaseEntity[] = []
        normalizeEntity(entities, listToSave)
        listToSave = listToSave.filter(v => !isTreeNode(v))

        if (!listToSave.length) {
            return []
        }

        const filteredListToSave = listToSave.map(prepareEntityToSave)

        // Перед тем как сохранять достанем возможно ранее сохранённые сущности и смержим их,
        // на случай если у нас меньше данных чем лежит в сторейдже
        const existsItems = await this.storage.getItems<BaseEntity>(filteredListToSave.map(getKey))
        const mergedListToSave = existsItems
            ? filteredListToSave.map(entity => {
                const key = getKey(entity)
                if (existsItems[key]) {
                    return {...existsItems[key], ...entity}
                } else {
                    return entity
                }
            }).filter(newEntityToSave => {
                const key = getKey(newEntityToSave)
                return !isEqual(newEntityToSave, existsItems[key])
            })
            : []
        if (mergedListToSave.length > 0) {
            await this.storage.setItems(mergedListToSave.map(v => ({
                key: getKey(v),
                value: v,
            })))
        }

        return mergedListToSave
    }

    private async getItemsFromStorage<T>(links: Array<string>): Promise<Array<T>> {
        // При пустом массиве будет произведена загрузка всего хранилища, исключим это
        if (!links || links.length === 0) {
            return []
        }
        return Object.values<T>(await this.storage.getItems<T>(links)).filter(Boolean)
    }

    /**
     * Загружает из хранилища сущности в ApiStore
     */
    async load(entityLinks: BaseEntity[]) {
        await this.initPromise
        const result = await this.getItemsFromStorage<BaseEntity>(entityLinks.map(getKey))
        const loadedEntities = new Set<string>(result.map(getGID))
        let prevLoadedEntities: Array<BaseEntity> = result
        const nestedEntitiesJson: Array<any> = result
        while (prevLoadedEntities.length > 0) {
            const toLoadGids = new Set<string>()
            for (const entity of prevLoadedEntities) {
                for (const nestedEntity of getNestedEntities(entity)) {
                    const gid = getGID(nestedEntity)
                    if (!loadedEntities.has(gid) && !toLoadGids.has(gid)) {
                        loadedEntities.add(gid)
                        toLoadGids.add(gid)
                    }
                }
            }
            prevLoadedEntities = []
            if (toLoadGids.size > 0) {
                prevLoadedEntities = await this.getItemsFromStorage<BaseEntity>([...toLoadGids.values()])
                nestedEntitiesJson.push(
                    ...prevLoadedEntities
                )
            }
        }

        // данный код перемёржит ентити между собой, если в них есть ссылки друг на друга
        const identityMap = new IdentityMap()
        getJsonProperty(nestedEntitiesJson).normalize(identityMap)

        const mergedEntities: BaseEntity[] = []
        const visitedSet = new Set<string>()
        for (const contentTypeStorage of identityMap.getEntities().values()) {
            for (const entity of contentTypeStorage.values()) {
                const oldSize = visitedSet.size
                const normalizedEntity = getCyclesFreeNormalizedEntity(entity, visitedSet)
                if (oldSize < visitedSet.size) {
                    mergedEntities.push(normalizedEntity)
                }
            }
        }

        return mergedEntities
    }

    /**
     * Удаляет сущность из хранилища
     */
    async remove(entityLink: BaseEntity) {
        await this.initPromise
        await this.storage.removeItem(getKey(entityLink))
    }

    /**
     * Очищает хранилище целиком
     */
    async clear() {
        await this.initPromise
        await this.storage.clear()
    }
}
