import * as ReduxActions from "redux-actions"
import * as Api from "src/lib/entities/api"

export const RENAME_COLUMN = "bums/common/report/edit/action/RENAME_COLUMN"

export type RenameColumn = ReduxActions.Action<{
    column: Api.ReportConfigColumn
}>

export function renameColumn(column: Api.ReportConfigColumn): RenameColumn {
    return {
        type: RENAME_COLUMN,
        payload: {
            column
        }
    }
}
