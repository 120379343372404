import * as ReduxActions from "redux-actions"

export const CHANGE_REPORT_NAME = "bums/common/report/edit/action/CHANGE_REPORT_NAME"

export type ChangeReportName = ReduxActions.Action<{
    name: string
}>

export function changeReportName(name: string): ChangeReportName {
    return {
        type: CHANGE_REPORT_NAME,
        payload: {
            name
        }
    }
}
