import {autobind} from "core-decorators"
import {observable, computed, action} from "mobx"
import {RemoteFeed} from "./types"

@autobind
export class RemoteFeedCollection {

    protected $items = observable.array<RemoteFeed>([])

    public attach(feed: RemoteFeed) {
        feed.once("attached", () => {
            this._add(feed)
        })

        feed.attach()
    }

    @action
    protected _add(feed: RemoteFeed) {
        this.$items.push(feed)
    }

    @action
    public dettach(feed: RemoteFeed) {
        this.$items.remove(feed)
        feed.detach()
    }

    public getById(id: number) {
        return this.$items.find(item => item.id === id)
    }

    public dettachById(id: number) {
        const existedItem = this.getById(id)

        if (existedItem) {
            this.dettach(existedItem)
        }
    }

    @computed
    public get items() {
        return this.$items
    }
}
