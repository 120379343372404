import * as ReduxActions from "redux-actions"

export const CHANGE_COLUMNS_ORDER = "bums/common/report/edit/action/CHANGE_COLUMNS_ORDER"

export type ChangeColumnsOrder = ReduxActions.Action<{
    columnsOrder: Array<string>
}>

export function changeColumnsOrder(columnsOrder: Array<string>): ChangeColumnsOrder {
    return {
        type: CHANGE_COLUMNS_ORDER,
        payload: {
            columnsOrder
        }
    }
}
