import Bluebird from "bluebird"
import {autobind} from "core-decorators"
import {BrowserTab, BrowserTabFactory} from "src/bums/common/browserTabs"
import {MainTabEvent} from "src/bums/common/browserTabs/MainTab"
import {ApiResponse} from "src/lib/entities/api"
import {TransportEvents, TransportLayer} from "src/lib/entities/store/types"
import {Ecomet} from "src/lib/services/Ecomet"
import {SocketStateConnected, SocketStateData, SocketStateSubscription, SubscriptionChannelName} from "src/lib/services/types"
import {RequestInit, Response} from "src/lib/utils/fetch"

const TIMEOUT_POLL_AUTH = 3 * 60 * 1000

@autobind
export class AuthGuardTransport implements TransportLayer {

    protected $browserTab: BrowserTab

    protected $socketState: SocketStateData["state"] = SocketStateConnected

    protected $timeoutPollAuth: number

    constructor(
        protected $transport: TransportLayer,
        protected $ecomet: Ecomet,
        protected $browserTabFactory: BrowserTabFactory
    ) {
        this.$ecomet.subscribe<SocketStateData>(SocketStateSubscription, this.changeEcometState)
        this.$browserTab = this.$browserTabFactory.createBrowserTabChannel(SubscriptionChannelName)
        this.$browserTab.on("reloadPage", this.reloadPage)
        this.$browserTab.on(MainTabEvent.main_tab_changed, this.checkNeedCreatePollAuth)
    }

    protected reloadPage() {
        if (!this.isLoginPage()) {
            window.location.reload()
        }
    }

    protected isLoginPage() {
        return window.location.href.includes("/login")
    }

    protected changeEcometState(data: SocketStateData) {
        if (this.$socketState !== data.state) {
            this.$socketState = data.state
            this.checkNeedCreatePollAuth()
        }
    }

    protected checkNeedCreatePollAuth() {
        clearTimeout(this.$timeoutPollAuth)

        if (this.$socketState === SocketStateConnected || !this.$browserTab.isMainTab() || this.isLoginPage()) {
            return
        }

        this.$timeoutPollAuth = setTimeout(() => {
            void this.fetch(`/api/v3/counter`).catch(() => this.$browserTab.broadcast("reloadPage", {}))
        }, TIMEOUT_POLL_AUTH)
    }

    fetch<V>(url: string, init?: RequestInit): Bluebird<Response<ApiResponse<V>>> {
        return this.$transport.fetch(url, init)
            .then(result => {
                this.checkNeedCreatePollAuth()
                return result as Response<ApiResponse<V>>
            })
            .catch(err => {
                this.checkNeedCreatePollAuth()
                throw err
            })
    }

    on(events: TransportEvents): void {
        this.$transport.on(events)
    }
}
