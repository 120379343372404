import * as React from "react"
import {observer} from "mobx-react"
import {observable, reaction, runInAction} from "mobx"
import {Component} from "src/lib/components"
import classNames from "classnames/bind"
import {Intl} from "src/lib/utils/intl/Intl"
import {inject} from "src/lib/utils/inject"
import {getToday} from "src/lib/utils/date"

let style = classNames.bind(require("./CMenuIcon.styl"));

export namespace CMenuIcon {
    export interface Props {
        type: CIconType
        key?: number|string
        title?: string
        onClick?: () => void,
        isActive?: boolean
        isHover?: boolean
        className?: string
        style?: React.CSSProperties
        src?: string
    }
}

export enum CIconType {
    TASKS,
    CLIENTS,
    ACCOUNT,
    REPORTS,
    COMMUNICATION,
    DEALINGS,
    DESKTOP,
    DOCS,
    EMPLOYEES,
    FINANCE,
    CLOCK,
    LINK,
    USERICON,
    HELP,
    SETTINGS,
    BPM,
    UPGRADES,
    CALENDAR,
}

const iconTypeToFileMap: {[type: number]: string} = {
    [CIconType.TASKS]: "tasks",
    [CIconType.CLIENTS]: "clients",
    [CIconType.ACCOUNT]: "account",
    [CIconType.REPORTS]: "reports",
    [CIconType.COMMUNICATION]: "communication",
    [CIconType.DEALINGS]: "dealings",
    [CIconType.DESKTOP]: "desktop",
    [CIconType.DOCS]: "docs",
    [CIconType.EMPLOYEES]: "employees",
    [CIconType.FINANCE]: "finance",
    [CIconType.CLOCK]: "clock",
    [CIconType.LINK]: "link",
    [CIconType.HELP]: "help",
    [CIconType.SETTINGS]: "settings",
    [CIconType.BPM]: "bpm",
    [CIconType.UPGRADES]: "upgrades",
    [CIconType.CALENDAR]: "calendar",
}

/**
 *  Элемент главного меню
 */
@observer
export class CMenuIcon extends Component<CMenuIcon.Props, {}> {

    @inject(Intl)
    private intl!: Intl

    public static Type = CIconType

    public static defaultProps = {
        isActive: false
    }

    @observable.ref
    private contentProps: {content: any, contentGr: any}

    protected reactionSetIconType = reaction(
        () => this.props.type,
        type => type && this.getIcon(type)
    )

    constructor(props: CMenuIcon.Props, context: {}) {
        super(props, context)

        this.getIcon(props.type)
    }

    private getIcon(type: CIconType) {
        if (type !== CIconType.USERICON) {
            void Promise.all([
                import("!svg-inline!./icons/" + iconTypeToFileMap[type] + ".svg"),
                import("!svg-inline!./icons/" + iconTypeToFileMap[type] + "__bg.svg")
            ]).then(([svgContent, svgContentGr]) => runInAction(() => {
                this.contentProps = {
                    content: {dangerouslySetInnerHTML: {__html: svgContent.default as string}},
                    contentGr: {dangerouslySetInnerHTML: {__html: svgContentGr.default as string}},
                }
            }))
        } else {
            runInAction(() => {
                this.contentProps = {
                    content: {style: {backgroundImage: `url(${this.props.src})`}},
                    contentGr: {style: {backgroundImage: `url(${this.props.src})`}},
                }
            })
        }
    }

    private renderHands() {
        if (this.props.type !== CIconType.CLOCK) {
            return null
        }

        const date = new Date;
        const seconds = date.getSeconds();
        const minutes = date.getMinutes();
        const hours = date.getHours();

        const hands: any = {
            hours: (hours * 30) + (minutes / 2),
            minutes: minutes * 6,
            seconds: seconds * 6
        };

        const handsStyle: any = {
            hours: {},
            minutes: {},
            seconds: {}
        };


        Object.keys(hands).map(hand => {
            handsStyle[hand] = {
                WebkitTransform: "rotate(" + hands[hand] + "deg)",
                transform: "rotate(" + hands[hand] + "deg)"
            }
        })

        return <span className={style("hands")}>
            <span className={style("handsSvg")} style={handsStyle.hours}>
                <span className={style("handsSvg", "hours")}>
                    <svg height="53px" width="53px" version="1.1" viewBox="0 0 53 53" className={style("handsSvg")}>
                        <g fill="none" stroke="none" strokeWidth="1">
                            <path d="M26.5,26.5 L26.5,18" stroke="#4896B9" strokeLinecap="round"/>
                        </g>
                        <g className={style("icon-outline")} fill="none" stroke="none" strokeWidth="1">
                            <path
                                d="M26.5,26.5000001 L26.5,17.0478526"
                                stroke="#989898"
                                strokeLinecap="round"
                            />
                        </g>
                    </svg>
                </span>
            </span>
            <span className={style("handsSvg")} style={handsStyle.minutes}>
                <span className={style("handsSvg", "minutes")}>
                    <svg height="53px" width="53px" version="1.1" viewBox="0 0 53 53" className={style("handsSvg")} >
                        <g fill="none" stroke="none" strokeWidth="1">
                            <path d="M26.5,26.5 L26.5,15" stroke="#4896B9" strokeLinecap="round"/>
                        </g>
                        <g className={style("icon-outline")} fill="none" stroke="none" strokeWidth="1">
                            <path d="M26.5,26.5 L26.5,15" stroke="#989898" strokeLinecap="round"/>
                        </g>
                    </svg>
                </span>
            </span>
            <span className={style("handsSvg")} style={handsStyle.seconds}>
                <span className={style("handsSvg", "seconds")}>
                    <svg height="53px" width="53px" version="1.1" viewBox="0 0 53 53" className={style("handsSvg")}>
                        <g fill="none" stroke="none" strokeWidth="1">
                            <path d="M26.5,26.5 L26.5,15" stroke="#FF3E3E" strokeLinecap="round"/>
                        </g>
                    </svg>
                </span>
            </span>
            <svg height="53px" width="53px" version="1.1" viewBox="0 0 53 53" className={style("handsSvg")} >
                <g fill="none" stroke="none" strokeWidth="1">
                    <ellipse fill="#4896B9" cx="26.5" cy="26.5" rx="2.5" ry="2.5"/>
                    <circle fill="#FFFFFF" cx="26.5" cy="26.5" r="1.5"/>
                </g>
                <g className={style("icon-outline")} fill="none" stroke="none" strokeWidth="1">
                    <ellipse fill="#898989" cx="26.5" cy="26.5" rx="2.5" ry="2.5"/>
                    <circle fill="#FFFFFF" cx="26.5" cy="26.5" r="1.5"/>
                </g>
            </svg>

        </span>
    }

    private renderCalendar() {
        if (this.props.type !== CIconType.CALENDAR) {
            return null
        }
        const today = getToday()

        return <React.Fragment>
            <span className={style("calendarMonth")}>
                {this.intl.date.MMM()(today).replace(".", "")}
            </span>
            <span className={style("calendarDayWrapper")}>
                {today.getDate()}
            </span>
        </React.Fragment>
    }

    private renderBackAnimation() {
        switch (this.props.type) {
            case CIconType.ACCOUNT:
                return <span className={style("animateBox")}>
                    <span className={style("bottomAccount")}/>
                </span>

            case CIconType.EMPLOYEES:
                return <span className={style("animateBox")}>
                    <span className={style("bottomCard")}/>
                    <span className={style("topCard")}/>
                </span>

            default: return null
        }
    }

    private renderAnimation() {
        switch (this.props.type) {
            case CIconType.FINANCE:
                return <span className={style("finance")}>
                    <span className={style("rotate")}/>
                </span>

            case CIconType.DEALINGS:
                return <span className={style("animateBox")}>
                    <span className={style("last")}/>
                    <span className={style("lock")}/>
                </span>

            case CIconType.CLIENTS:
                return <span className={style("animateBox")}>
                    <span className={style("cover")}/>
                    <span className={style("top")}/>
                </span>

            case CIconType.REPORTS:
                return <span className={style("animateBox")}>
                    <span className={style("line1")}/>
                    <span className={style("line2")}/>
                    <span className={style("line3")}/>
                    <span className={style("line4")}/>
                    <span className={style("line5")}/>
                </span>

            case CIconType.COMMUNICATION:
                return <span className={style("animateBox")}>
                    <span className={style("bubble2")}/>
                    <span className={style("bubble3")}/>
                </span>

            case CIconType.DOCS:
                return <span className={style("animateBox")}>
                    <span className={style("paper")}/>
                    <span className={style("top")}/>
                </span>

            case CIconType.LINK:
                return <span className={style("animateBoxLink")}>
                    <span className={style("map")}/>
                </span>

            case CIconType.DESKTOP:
                return <span className={style("animateBox")}>
                    <span className={style("block")}/>
                    <span className={style("block2")}/>
                </span>

            default:
                return null
        }
    }

    public render() {
        const {onClick, type, className} = this.props

        return <div
            className={style(
                "iconWrapper",
                {
                    [CIconType[type]]: !!CIconType[type],
                    menuIconActive: this.props.isActive,
                    menuIconHover: this.props.isHover
                },
                className
            )}
            style={this.props.style}
        >
            {this.contentProps
                ? <React.Fragment>
                    {this.renderBackAnimation()}
                    <span onClick={onClick} className={style("menuIcon")} {...this.contentProps.content}/>
                    {this.renderAnimation()}
                    <span onClick={onClick} className={style("menuIconBg")} {...this.contentProps.contentGr}/>
                    {this.renderHands()}
                    {this.renderCalendar()}
                </React.Fragment>
                : null
            }
        </div>
    }
}
