import {
    observable, isObservable, action,
} from "mobx"

import {isBaseValue, DateInterval, isBaseEntity, BaseValue, BaseEntity} from "src/lib/entities/types";
import {isIterable} from "src/lib/collections/utils";
import {getListName, isIdUnknown, isEntityEquals, getGID} from "../utils"
import {LoadState} from "src/lib/utils/loadState";
import {IdentityMap} from "src/lib/entities/store/IdentityMap";
import {EntitiesList} from "src/lib/entities/store/EntitiesList";
import * as Collections from "src/lib/collections"
import {ObservableListClass, ListClass} from "src/lib/collections/ListClass";

type VersionsMap = {
    [entityGid: string]: number | void
}

interface JsonProperty {
    value: any;
    contentType?: string;
    /**
     * Возвращает нормализованное значение property
     * @param map
     * @param versions
     */
    normalize(map: IdentityMap, versions?: VersionsMap): any
}

function compareLists(initial: Collections.List<BaseValue>, comparable: Collections.List<BaseValue>) {
    let isEqualLists = true
    comparable.forEach((item, index) => {
        if (!isEqualLists) {
            return
        }
        isEqualLists = initial.has(index) && isEntityEquals(item, initial.get(index))
    })
    return isEqualLists
}

/**
 * Резолвит тип property в зависимости от значения и создает класс соответствующего типа
 */

export function getJsonProperty(value: any): JsonProperty
export function getJsonProperty(value: any, parent: any, propertyName: string): JsonProperty
export function getJsonProperty(value: any, parent?: any, propertyName?: string): JsonProperty {
    // Список возможных атрибутов
    // ВНИМАНИЕ: порядок важен! идем от частностей к общему
    let propertyClasses = [
        DateTimeJsonProperty,
        DateIntervalJsonProperty,
        BaseEntityJsonProperty,
        BaseValueJsonProperty,
        IterableJsonProperty
    ];

    for (let propertyClass of propertyClasses) {
        if (propertyClass.isCompatible(value)) {
            return new propertyClass(value, parent, propertyName);
        }
    }

    return new SimpleTypeJsonProperty(value, parent, propertyName);
}

/**
 * Property простого типа без логики
 */
export class SimpleTypeJsonProperty implements JsonProperty {
    propertyName: string;
    value: any;
    parent: any;

    constructor(value: any)
    constructor(value: any, parent: any, propertyName: string)
    constructor(value: any, parent?: any, propertyName?: string) {
        this.value = value;
        this.parent = parent;
        this.propertyName = propertyName;
    }

    normalize(map: IdentityMap) {
        return this.value;
    }
}

/**
 * Property типа DateTime
 */
export class DateTimeJsonProperty extends SimpleTypeJsonProperty {
    value: Date;

    constructor(value: any, parent?: any, propertyName?: string) {
        super(value, parent, propertyName);
        this.value = new Date(value.value);
    }

    normalize(map: IdentityMap) {

        if (!this.parent || !this.propertyName) {
            return this.value;
        }

        const oldDate = this.parent[this.propertyName]

        if (!(oldDate instanceof Date) || oldDate.getTime() !== this.value.getTime()) {
            return this.value;
        }

        return oldDate
    }

    static isCompatible(value: any) {
        return value && "object" === typeof value && value.contentType === "DateTime" && value.value !== void 0
    }
}

/**
 * Property типа DateInterval
 */
export class DateIntervalJsonProperty extends SimpleTypeJsonProperty {
    value: DateInterval;

    constructor(value: any, parent?: any, propertyName?: string) {
        super(value, parent, propertyName);
        this.value = new DateInterval(value.value);
    }

    static isCompatible(value: any) {
        return value && "object" === typeof value && value.contentType === "DateInterval" && value.value !== void 0
    }
}

/**
 * Property типа Iterable
 */
export class IterableJsonProperty extends SimpleTypeJsonProperty {
    value: any[];
    private total: number = -1;

    constructor(value: any, parent?: any, propertyName?: string) {
        super(value, parent, propertyName);
        this.value = Array.from(value);
    }

    normalize(map: IdentityMap, versions?: VersionsMap) {
        let collection = this.getCollection(map);

        let skippedCount = 0;
        const collectionItemsSet = new Set<BaseValue>();
        const newCollectionValues: {[index: number]: any} = {}
        for (let index = 0; index < this.value.length; index++) {
            const collectionItem = this.value[index]
            let isBaseValueCollectionItem = isBaseValue(collectionItem)

            if (isBaseValueCollectionItem)  {
                const collectionItemEntity = getJsonProperty(collectionItem).normalize(map, versions);
                if (collectionItemsSet.has(collectionItemEntity)) {
                    skippedCount++;
                } else {
                    collectionItemsSet.add(collectionItemEntity);
                    newCollectionValues[index - skippedCount] = collectionItemEntity
                }
            } else {
                newCollectionValues[index] = typeof collectionItem === "object" && null !== collectionItem
                    ? observable.box(collectionItem, {defaultDecorator: observable.struct})
                    : collectionItem
            }
        }

        for (const index in newCollectionValues) {
            collection.set(Number(index), newCollectionValues[index])
        }
        collection.length = this.value.length - skippedCount;

        if (this.isParentDefined() && isBaseEntity(this.parent) && this.parent.id) {
            this.updateList(map, collection);

            const listName = getListName(this.parent, this.propertyName);
            return map.hasList(listName) ? map.getList(listName).items : collection;
        }

        return collection;
    }

    setTotalCount(count: number) {
        this.total = count;
    }

    static isCompatible(value: any) {
        return isIterable(value);
    }

    private isParentDefined() {
        return this.parent && this.propertyName;
    }

    /**
     * Вносит изменения в лист IdentityMap
     * @param map
     * @param collection
     */
    @action
    private updateList(map: IdentityMap, collection: Collections.List<BaseValue>): void {
        const listName = getListName(this.parent, this.propertyName);
        const totalItemsCount = this.total > -1 ? this.total : collection.length;
        if (!map.hasList(listName)) {
            const list = new EntitiesList()
            list.loadStateNext = LoadState.Completed()
            list.hasMorePrev = false
            list.hasMoreNext = totalItemsCount > collection.length
            list.items = collection
            list.totalItemsCount = totalItemsCount
            list.usedCount = 1
            map.addList(listName, list);
        } else {
            const existsList = map.getList(listName);

            if (existsList.totalItemsCount !== totalItemsCount || !compareLists(existsList.items, collection)) {
                existsList.items = collection
            }

            existsList.hasMorePrev = false
            existsList.totalItemsCount = totalItemsCount
            existsList.hasMoreNext = totalItemsCount > collection.length
        }
    }

    /**
     * Возвращает коллекцию
     * @param map
     * @returns {Collections.List<BaseValue>}
     */
    private getCollection(map: IdentityMap) {
        let collection: Collections.List<BaseValue>;
        if (this.isParentDefined() && this.parent.id) {
            const listName = getListName(this.parent, this.propertyName);

            collection = this.parent[this.propertyName];
            if (!(collection instanceof ObservableListClass)) {
                if (map.hasList(listName)) {
                    collection = map.getList(listName).items;
                } else {
                    collection = new ObservableListClass<BaseValue>();
                }
            }
        } else {
             collection = new ListClass<any>()
        }

        return collection;
    }
}

/**
 * Property типа BaseEntity
 */
export class BaseEntityJsonProperty extends SimpleTypeJsonProperty {
    contentType: string;
    id: string;
    version: number = 0;
    private map: IdentityMap;

    constructor(value: any, parent?: any, propertyName?: string) {
        super(value, parent, propertyName);
        this.contentType = value.contentType;
        this.id = this.value.id = String(value.id);
    }

    normalize(map: IdentityMap, versions?: VersionsMap) {
        if (isObservable(this.value) || isIdUnknown(this.id)) {
            return this.value; // this is already updated entity
        }

        this.map = map;
        let entity = this.getEntity();

        const version = versions ? versions[getGID(entity)] : void 0

        if (version === void 0 || entity._version < version) {
            entity._version = version || entity._version || 0;
        }
        let result: any = {};

        for (let propertyName in this.value) {
            if (propertyName === "_version") {
                continue;
            }
            if (
                void 0 !== version &&
                entity._fieldValueVersions.has(propertyName) &&
                entity._fieldValueVersions.get(propertyName) > version
            ) {
                // Игнорируем новое значение поля, если в сущности явно более новая версия.
                // TODO Здесь возможна проблема, что внутри игнорируемого поля лежит сущность, которую в свою
                // TODO очередь надо обновить. Как это обработать пока неизвестно, возможно только с поддержкой
                // TODO версий на сервере (пока что этой поддержки нет).
                // TODO REACT-68
                continue;
            }
            entity._fieldValueVersions.set(propertyName, version || entity._fieldValueVersions.get(propertyName) || 0);
            let property = getJsonProperty(this.value[propertyName], entity, propertyName);
            // В случае iterable надо установить общее кол-во элементов
            if (property instanceof IterableJsonProperty) {
                let countablePropertyName = propertyName + "Count";
                if (this.value[countablePropertyName]) {
                    property.setTotalCount(this.value[countablePropertyName]);
                }
            }
            const propValue = property.normalize(this.map, versions)
            result[propertyName] = propValue
        }

        this.map.setValues(entity, result)

        return entity;
    }

    /**
     * Создает новую энтити с необходимыми параметрами
     * @returns {undefined|BaseEntity}
     */
    private getEntity() {
        const repository = this.map.getRepository(this.contentType);
        if (!repository.has(this.id)) {
            const newEntity = this.map.getNewObjectOf<BaseEntity>(this.contentType)
            Object.defineProperty(newEntity, "_fetchStates", {
                value: observable.map<string, LoadState>([], { deep: false }),
                writable: true,
                configurable: true,
                enumerable: false,
            });
            Object.defineProperty(newEntity, "_fieldValueVersions", {
                value: new Map<string, number>(),
                writable: true,
                configurable: true,
                enumerable: false,
            });
            Object.defineProperty(newEntity, "_version", {
                value: this.version,
                writable: true,
                configurable: true,
                enumerable: false,
            });
            newEntity.id = this.id;
            newEntity.contentType = this.contentType;
            repository.set(this.id, newEntity)
        }

        return repository.get(this.id);
    }

    static isCompatible(value: any) {
        return isBaseEntity(value) && null != value.id;
    }
}

/**
 * Property типа BaseValue
 */
export class BaseValueJsonProperty extends SimpleTypeJsonProperty {
    value: any;
    contentType: string;

    constructor(value: any, parent?: any, propertyName?: string) {
        super(value, parent, propertyName);
        this.contentType = value.contentType;
        this.value = value;
    }

    normalize(map: IdentityMap) {
        if (isObservable(this.value)) {
            return this.value
        }

        const objectValue: any = map.getNewObjectOf(this.contentType);
        const newValues: any = {}

        for (let propertyName in this.value) {
            let property = getJsonProperty(this.value[propertyName], objectValue, propertyName);
            // В случае iterable надо установить общее кол-во элементов
            if (property instanceof IterableJsonProperty) {
                let countablePropertyName = propertyName + "Count";
                if (this.value[countablePropertyName]) {
                    property.setTotalCount(this.value[countablePropertyName]);
                }
            }
            newValues[propertyName] = property.normalize(map);
        }

        map.setValues(objectValue, newValues)

        return objectValue
    }
    static isCompatible(value: any) {
        return isBaseValue(value);
    }
}
