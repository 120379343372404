/* tslint:disable */
import * as H from "../metadataHelper"
import {PluralName} from "./pluralName"
import {IntegerField} from "./integerField"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface BpmSettings extends H.BaseValue {
    pluralNames?: H.List<PluralName>;
    pluralNamesCount?: number;
    gender?: string;
    withDuplicatesFinder?: boolean;
    slug?: string;
    contentType: "BpmSettings"; // Object type
}

export module BpmSettings {
    export const contentType: "BpmSettings" = "BpmSettings";
    export const endpoint = "/api/v3/bpmSettings";

    export const newObject: BpmSettings = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const pluralNames = {contentType: "RefLinkField" as "RefLinkField", name: "pluralNames", isMultiple: true, isSortable: false, id: "BpmSettings.pluralNames", refContentType: H.List(["PluralName"])};
        export const pluralNamesCount = {contentType: "IntegerField" as "IntegerField", name: "pluralNamesCount", isMultiple: false, isSortable: false, id: "BpmSettings.pluralNamesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const gender = {contentType: "StringField" as "StringField", name: "gender", isMultiple: false, isSortable: false, id: "BpmSettings.gender", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const withDuplicatesFinder = {contentType: "BoolField" as "BoolField", name: "withDuplicatesFinder", isMultiple: false, isSortable: false, id: "BpmSettings.withDuplicatesFinder", defaultValue: null as BoolField["defaultValue"]};
        export const slug = {contentType: "StringField" as "StringField", name: "slug", isMultiple: false, isSortable: false, id: "BpmSettings.slug", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isBpmSettings(arg: any): arg is BpmSettings {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "BpmSettings"
}
