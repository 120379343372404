import {TransportEvents, TransportLayer} from "../types"
import * as Bluebird from "bluebird"
import {ApiResponse} from "src/lib/entities/types"
import {Response, RequestInit} from "src/lib/utils/fetch"
import {stringify} from "qs"

export function makeJsonEncodedQueryUrl(url: string, queryParams: object) {
    return url + "?" + encodeURIComponent(JSON.stringify(queryParams));
}

export class NormalizeQueryStringTransport implements TransportLayer {

    constructor(
        private inner: TransportLayer,
    ) {

    }

    fetch<V>(url: string, init?: RequestInit): Bluebird<Response<ApiResponse<V>>> {
        if (init && init.queryParams && Object.getOwnPropertyNames(init.queryParams).length > 0) {
            if (url.indexOf("/api/v3") !== -1) {
                url = makeJsonEncodedQueryUrl(url, init.queryParams)
            } else {
                url += "?" + stringify(init.queryParams, {encode: false});
            }
            delete init.queryParams
        }
        return this.inner.fetch(url, init)
    }

    on(events: TransportEvents): void {
        this.inner.on(events)
    }
}
