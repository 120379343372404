/* tslint:disable */
import * as H from "../metadataHelper"
import {DocVersion} from "./docVersion"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"

export interface Doc extends H.BaseEntity {
    actualVersion?: DocVersion; // Версия документа
    possibleActions?: H.List<Doc.PossibleActions>; // Возможные действия
    id?: string; // Идентификатор
    isFavorite?: boolean; // Избранная сущность или нет
    contentType: "Doc"; // Object type
}

export module Doc {
    export const contentType: "Doc" = "Doc";
    export const endpoint = "/api/v3/doc";
    export type PossibleActions = "act_read";
    export module PossibleActions {
        export const act_read: PossibleActions = "act_read";
    }
    export const newObject: Doc = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const actualVersion = {contentType: "RefLinkField" as "RefLinkField", name: "actualVersion", isMultiple: false, isSortable: false, id: "Doc.actualVersion", refContentType: H.List(["DocVersion"])};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "Doc.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["act_read"])};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: false, id: "Doc.isFavorite", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isDoc(arg: any): arg is Doc {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Doc"
}
