export function getFirebaseConfig (origin: string) {
    if (
        origin
        && (!origin.includes(".megaplan.") || origin.includes("presentation.megaplan.ru"))
    ) {
        return FIREBASE_BOX_CONFIG
    }

    return FIREBASE_CONFIG
}


const FIREBASE_CONFIG = {
    apiKey: "AIzaSyCIO3WCT4f-SNJwuXcGb6Ak--_6BCuAeCc",
    authDomain: "megaplan-3.firebaseapp.com",
    databaseURL: "https://megaplan-3.firebaseio.com",
    projectId: "megaplan-3",
    storageBucket: "megaplan-3.appspot.com",
    messagingSenderId: "958719463671",
    appId: "1:958719463671:web:78f02d69dde406ad1043f2",
    measurementId: "G-0DR8DD66PT"
}

const FIREBASE_BOX_CONFIG = {
    apiKey: "AIzaSyCIO3WCT4f-SNJwuXcGb6Ak--_6BCuAeCc",
    authDomain: "megaplan-3.firebaseapp.com",
    databaseURL: "https://megaplan-3.firebaseio.com",
    projectId: "megaplan-3",
    storageBucket: "megaplan-3.appspot.com",
    messagingSenderId: "958719463671",
    appId: "1:958719463671:web:3d43c4b2e26762e81043f2",
    measurementId: "G-J4Q8KMRWG4"
}
