import {RequestInit} from "src/lib/utils/fetch"

// TODO Сейчас эндпоинты захардкожены, нужна автогенерация
// TODO Эндпоинты AbstractETagSupporter.php должны быть индентичны с данным файлом

interface ETagSupporterInterface {
    isCacheableRequest(url: string, init?: RequestInit): boolean
}

class ETagSupporter implements ETagSupporterInterface {
    constructor(
        private $isCacheableRequest: (url: string, init?: RequestInit) => boolean,
        private allowedQueryParams = false,
        private onlyGetMethodAllowed = true
    ) {}

    isCacheableRequest(url: string, init?: RequestInit): boolean {
        const method = init && init.method ? init.method : "GET"
        if (this.onlyGetMethodAllowed && method !== "GET") {
            return false
        }

        if (init && init.queryParams && !this.allowedQueryParams) {
            return false
        }

        return this.$isCacheableRequest(url, init)
    }
}

const contractorTypeETagSupporter = new ETagSupporter(
    (url: string) =>
        "/api/v3/contractorHuman/types" === url ||
        "/api/v3/contractorCompany/types" === url
)

const contractorAddressTypesETagSupporter = new ETagSupporter(
    (url: string) => "/api/v3/contractor/addressTypes" === url
)

const api3ProgramExtraFieldsRegExp = new RegExp("/api/v3/program/\\d+/extraFields/?$")
const extraFieldsListETagSupporter = new ETagSupporter(
    (url: string) =>
        "/api/v3/task/extraFields" === url ||
        "/api/v3/project/extraFields" === url ||
        "/api/v3/deal/extraFields" === url ||
        "/api/v3/contractorHuman/extraFields" === url ||
        "/api/v3/contractorCompany/extraFields" === url ||
        url.search(api3ProgramExtraFieldsRegExp) === 0
)

const api3integrationWidgetValuesRegExp = new RegExp("^/api/v3/integrationWidget/values(\\?|/|$)")
const integrationETagSupporter = new ETagSupporter(
    (url: string) => url.search(api3integrationWidgetValuesRegExp) === 0,
    true
)

const userSettingRegexp = new RegExp("^/api/v3/userSetting/.*/?$")
const userSettingETagSupporter = new ETagSupporter(
    url => url.search(userSettingRegexp) === 0,
)

const eTagSupporters: ETagSupporter[] = [
    contractorTypeETagSupporter,
    contractorAddressTypesETagSupporter,
    extraFieldsListETagSupporter,
    integrationETagSupporter,
    userSettingETagSupporter
]

// кэшируется ли данный запрос через etag
// @see AbstractETagSupporter.php
export function isCacheableRequest(url: string, init?: RequestInit): boolean {
    return !!eTagSupporters.find(eTagSupporter => eTagSupporter.isCacheableRequest(url, init))
}
