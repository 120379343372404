import {fetchDataStart, fetchDataSuccess, fetchDataFailed} from "./index"
import {ReportData, ServerResponse} from "../../entities"
import * as Collections from "src/lib/collections"
import * as Api from "src/lib/entities/api"
import * as Megaplan from "src/megaplan"
import {isFetchError} from "src/lib/utils/fetch"


export function fetchData(
    apiStore: Api.Store,
    reportId: number,
    filters?: any,
    sortColumn?: string,
    orderBy?: number,
) {
    const queryParams: FormData =  new FormData()
    queryParams.append("id", String(reportId))
    queryParams.append("filters", filters)
    queryParams.append("sortBy", (orderBy ? "-" : "") + sortColumn)

    return (dispatch: Megaplan.Dispatch) => {
        dispatch(fetchDataStart());
        return apiStore.fetch<ServerResponse>(
            "/BumsReport/ReportCard/reportData.json",
            {body: queryParams, method: "POST"})
            .then(response => {
                const data: ReportData = {
                    columns: Collections.List(response.value.data.columns),
                    rows: Collections.List(response.value.data.rows),
                    total: response.value.data.total,
                    nextOffset: response.value.data.nextOffset
                }
                dispatch(fetchDataSuccess(data))
            }).catch(err => {
                if (isFetchError<any>(err)) {
                    dispatch(fetchDataFailed(err.response.value))
                }
                // throw err;
            })
    }
}
