/* tslint:disable */
import * as H from "../metadataHelper"
import {FieldSetting} from "./fieldSetting"
import {BoolField} from "./boolField"
import {StringField} from "./stringField"

export interface IntegerField extends H.BaseEntity {
    defaultValue?: number;
    minValue?: number;
    maxValue?: number;
    isUnique?: boolean;
    isIndexable?: boolean; // Доступно для быстрого поиска
    name?: string; // Название поля
    hrName?: string; // Человекопонятное название поля
    type?: string; // Тип поля
    isRequired?: boolean; // Обязательное поле
    isMultiple?: boolean; // Множественное значение
    targetEntity?: string; // Целевая сущность
    settings?: H.List<FieldSetting>; // Массив настроек
    settingsCount?: number; // Количество настроек
    orderPos?: number; // Позиция поля при сортировке
    isSystem?: boolean; // Системное поле
    isSortable?: boolean; // Сортируемое поле
    owner?: H.BaseEntity; // Сущность владельца
    id?: string; // Идентификатор
    contentType: "IntegerField"; // Object type
}

export module IntegerField {
    export const contentType: "IntegerField" = "IntegerField";
    export const endpoint = "/api/v3/integerField";

    export const newObject: IntegerField = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const defaultValue = {contentType: "IntegerField" as "IntegerField", name: "defaultValue", isMultiple: false, isSortable: false, id: "IntegerField.defaultValue", defaultValue: null as IntegerField["defaultValue"]};
        export const minValue = {contentType: "IntegerField" as "IntegerField", name: "minValue", isMultiple: false, isSortable: false, id: "IntegerField.minValue", defaultValue: null as IntegerField["defaultValue"]};
        export const maxValue = {contentType: "IntegerField" as "IntegerField", name: "maxValue", isMultiple: false, isSortable: false, id: "IntegerField.maxValue", defaultValue: null as IntegerField["defaultValue"]};
        export const isUnique = {contentType: "BoolField" as "BoolField", name: "isUnique", isMultiple: false, isSortable: false, id: "IntegerField.isUnique", defaultValue: null as BoolField["defaultValue"]};
        export const isIndexable = {contentType: "BoolField" as "BoolField", name: "isIndexable", isMultiple: false, isSortable: false, id: "IntegerField.isIndexable", defaultValue: null as BoolField["defaultValue"]};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "IntegerField.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const hrName = {contentType: "StringField" as "StringField", name: "hrName", isMultiple: false, isSortable: false, id: "IntegerField.hrName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const type = {contentType: "StringField" as "StringField", name: "type", isMultiple: false, isSortable: false, id: "IntegerField.type", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isRequired = {contentType: "BoolField" as "BoolField", name: "isRequired", isMultiple: false, isSortable: false, id: "IntegerField.isRequired", defaultValue: null as BoolField["defaultValue"]};
        export const isMultiple = {contentType: "BoolField" as "BoolField", name: "isMultiple", isMultiple: false, isSortable: false, id: "IntegerField.isMultiple", defaultValue: null as BoolField["defaultValue"]};
        export const targetEntity = {contentType: "StringField" as "StringField", name: "targetEntity", isMultiple: false, isSortable: false, id: "IntegerField.targetEntity", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const settings = {contentType: "RefLinkField" as "RefLinkField", name: "settings", isMultiple: true, isSortable: false, id: "IntegerField.settings", refContentType: H.List(["FieldSetting"])};
        export const settingsCount = {contentType: "IntegerField" as "IntegerField", name: "settingsCount", isMultiple: false, isSortable: false, id: "IntegerField.settingsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const orderPos = {contentType: "IntegerField" as "IntegerField", name: "orderPos", isMultiple: false, isSortable: false, id: "IntegerField.orderPos", defaultValue: null as IntegerField["defaultValue"]};
        export const isSystem = {contentType: "BoolField" as "BoolField", name: "isSystem", isMultiple: false, isSortable: false, id: "IntegerField.isSystem", defaultValue: null as BoolField["defaultValue"]};
        export const isSortable = {contentType: "BoolField" as "BoolField", name: "isSortable", isMultiple: false, isSortable: false, id: "IntegerField.isSortable", defaultValue: null as BoolField["defaultValue"]};
        export const owner = {contentType: "RefLinkField" as "RefLinkField", name: "owner", isMultiple: false, isSortable: false, id: "IntegerField.owner", refContentType: H.List(["BaseEntity"])};
    }
}

export function isIntegerField(arg: any): arg is IntegerField {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "IntegerField"
}
