import * as ReduxActions from "redux-actions"

export const SET_REPORT_FILTERS = "bums/common/report/card/action/SET_REPORT_FILTERS"

export type ReportFilters = ReduxActions.Action<{
    filters: any
}>

export function setReportFilters(filters: any): ReportFilters {
    return {
        type: SET_REPORT_FILTERS,
        payload: {
            filters
        }
    }
}
