import {PlainRoute} from "react-router"

export const employeeRoutes: PlainRoute[] = [
    {
        path: "/staff/:id/edit*",
        async getComponent() {
            return (await import("./edit/containers/CPageContainer")).CPageContainer
        },
    },
    {
        path: "/staff/:id/card*",
        async getComponent() {
            return (await import("./card/containers/CPageContainer")).CPageContainer
        },
    },
    {
        path: "/inviteForm/:hash",
        async getComponent() {
            return (
                await import("./form/CRegistrationByInviteLinkForm")
            ).CRegistrationByInviteLinkForm
        },
    },
    {
        path: "/staff/list",
        async getComponent() {
            return (await import("./list/page/CEmployeeListPage")).CEmployeeListPage
        },
    },
    {
        path: "/staff/filter(/:filterId)",
        async getComponent() {
            return (await import("./list/page/CEmployeeListPage")).CEmployeeListPage
        },
    },
];
