/* tslint:disable:max-line-length */
import * as React from "react"
import {action} from "mobx"

type RefHandler = (instance: React.ReactInstance) => any

const refsStorage = new WeakMap<{}, Map<string, RefHandler>>()
const boundedToObjByFn = new WeakMap<Function, WeakMap<{}, Function>>()

export function bindArg<A1, R>(fn: (a1: A1) => R, a1: A1): () => R

export function bindArg<A1, A2, R>(fn: (a1: A1, a2: A2) => R, a1: A1): (a2: A2) => R
export function bindArg<A1, A2, R>(fn: (a1: A1, a2: A2) => R, a1: A1, a2: A2): () => R

export function bindArg<A1, A2, A3, R>(fn: (a1: A1, a2: A2, a3: A3) => R, a1: A1): (a2: A2, a3: A3) => R
export function bindArg<A1, A2, A3, R>(fn: (a1: A1, a2: A2, a3: A3) => R, a1: A1, a2: A2): (a3: A3) => R
export function bindArg<A1, A2, A3, R>(fn: (a1: A1, a2: A2, a3: A3) => R, a1: A1, a2: A2, a3: A3): () => R

export function bindArg<A1, A2, A3, A4, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4) => R, a1: A1): (a2: A2, a3: A3, a4: A4) => R
export function bindArg<A1, A2, A3, A4, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4) => R, a1: A1, a2: A2): (a3: A3, a4: A4) => R
export function bindArg<A1, A2, A3, A4, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4) => R, a1: A1, a2: A2, a3: A3): (a4: A4) => R
export function bindArg<A1, A2, A3, A4, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4) => R, a1: A1, a2: A2, a3: A3, a4: A4): () => R

export function bindArg<A1, A2, A3, A4, A5, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5) => R, a1: A1): (a2: A2, a3: A3, a4: A4, a5: A5) => R
export function bindArg<A1, A2, A3, A4, A5, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5) => R, a1: A1, a2: A2): (a3: A3, a4: A4, a5: A5) => R
export function bindArg<A1, A2, A3, A4, A5, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5) => R, a1: A1, a2: A2, a3: A3): (a4: A4, a5: A5) => R
export function bindArg<A1, A2, A3, A4, A5, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5) => R, a1: A1, a2: A2, a3: A3, a4: A4): (a5: A5) => R
export function bindArg<A1, A2, A3, A4, A5, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5) => R, a1: A1, a2: A2, a3: A3, a4: A4, a5: A5): () => R

export function bindArg<A1, A2, A3, A4, A5, A6, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5, a6: A6) => R, a1: A1): (a2: A2, a3: A3, a4: A4, a5: A5, a6: A6) => R
export function bindArg<A1, A2, A3, A4, A5, A6, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5, a6: A6) => R, a1: A1, a2: A2): (a3: A3, a4: A4, a5: A5, a6: A6) => R
export function bindArg<A1, A2, A3, A4, A5, A6, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5, a6: A6) => R, a1: A1, a2: A2, a3: A3): (a4: A4, a5: A5, a6: A6) => R
export function bindArg<A1, A2, A3, A4, A5, A6, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5, a6: A6) => R, a1: A1, a2: A2, a3: A3, a4: A4): (a5: A5, a6: A6) => R
export function bindArg<A1, A2, A3, A4, A5, A6, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5, a6: A6) => R, a1: A1, a2: A2, a3: A3, a4: A4, a5: A5): (a6: A6) => R
export function bindArg<A1, A2, A3, A4, A5, A6, R>(fn: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5, a6: A6) => R, a1: A1, a2: A2, a3: A3, a4: A4, a5: A5, a6: A6): () => R

export function bindArg(fn: Function, ...args: any[]): Function {
    if (args.length > 1) {
        let result: any = fn
        for (let arg of args) {
            result = bindArg(result, arg)
        }
        return result
    } else {
        let a1 = args[0]
        if (Object(a1) !== a1) {
            return fn.bind(null, a1);
        }

        if (!boundedToObjByFn.has(fn)) {
            boundedToObjByFn.set(fn, new WeakMap<Object, Function>());
        }
        const fnStorage = boundedToObjByFn.get(fn);
        if (!fnStorage.has(a1)) {
            fnStorage.set(a1, fn.bind(null, a1))
        }

        return fnStorage.get(a1)
    }
}

/**
 * Функция создает ref-callback, кэширует его, и присваивает ref в source[bindProperty]
 */
export function makeRefHandler<T>(source: T, bindProperty: keyof T, originalRefFunc?: (ref: {}) => void) {
    if (!refsStorage.has(source)) {
        refsStorage.set(source, new Map<string, RefHandler>())
    }
    const refBindPropertyMap = refsStorage.get(source)
    if (!refBindPropertyMap.has(bindProperty)) {
        refBindPropertyMap.set(bindProperty, action((instance: React.ReactInstance) => {
            if (originalRefFunc) {
                originalRefFunc(instance)
            }
            (source as any)[bindProperty] = instance
        }))
    }
    return refBindPropertyMap.get(bindProperty)
}
