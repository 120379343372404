import {PlainRoute} from "react-router"

export const crmRoutes: PlainRoute[] = [
    {
        path: "/crm/:id/card",
        async getComponent() {
            return (await import("./containers/CPageContainer")).CContractorResolver
        },
    },
    {
        path: "/crm/filter(/:filterId)",
        async getComponent() {
            return (await import("./list/page/CCrmListPage")).CCrmListPage
        },
    },
    {
        path: "/crm",
        onEnter(nextState, replace) {
            replace({pathname: "/crm/filter"});
        }
    },
    {
        path: "/crm/form",
        async getComponent() {
            return (await import("./leadForm/page/CLeadFormPage")).CLeadFormPage
        },
    }
]
