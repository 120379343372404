import * as Api from "src/lib/entities/api"
import * as WebApi from "src/lib/entities/apiWeb"
import * as Megaplan from "src/megaplan"
import * as ReduxActions from "redux-actions"
import {ReportImmutable} from "../types"
import * as Utils from "../utils"

export const SAVE_REPORT_TEMPLATE_START = "bums/common/report/edit/action/SAVE_REPORT_TEMPLATE_START"
export const SAVE_REPORT_TEMPLATE_SUCCESS = "bums/common/report/edit/action/SAVE_REPORT_TEMPLATE_SUCCESS"
export const SAVE_REPORT_TEMPLATE_FAILED = "bums/common/report/card/action/SAVE_REPORT_TEMPLATE_FAILED"

export type SaveReportTemplateStart = ReduxActions.Action<void>
export type SaveReportTemplateSuccess = ReduxActions.Action<{
    report: ReportImmutable
}>

export function saveReportTemplateStart(): SaveReportTemplateStart {
    return {
        type: SAVE_REPORT_TEMPLATE_START
    }
}

export function saveReportTemplateSuccess(report: ReportImmutable): SaveReportTemplateSuccess {
    return {
        type: SAVE_REPORT_TEMPLATE_SUCCESS,
        payload: {
            report
        }
    }
}

export function saveReportTemplate(report: Api.Report) {
    return (dispatch: Megaplan.Dispatch) => {
        dispatch(saveReportTemplateStart())
        return dispatch(WebApi.update(report, "/api/v3/report")).then(updatedReport => {
            return dispatch(saveReportTemplateSuccess(Utils.normalizeEntity(updatedReport)))
        })
    }
}
