/* tslint:disable */
import * as H from "../metadataHelper"
import {File} from "./file"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface DocVersion extends H.BaseEntity {
    name?: string; // Название
    description?: string; // Описание
    file?: File; // Файл
    id?: string; // Идентификатор
    isFavorite?: boolean; // Избранная сущность или нет
    contentType: "DocVersion"; // Object type
}

export module DocVersion {
    export const contentType: "DocVersion" = "DocVersion";
    export const endpoint = "/api/v3/docVersion";

    export const newObject: DocVersion = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "DocVersion.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: false, id: "DocVersion.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const file = {contentType: "RefLinkField" as "RefLinkField", name: "file", isMultiple: false, isSortable: false, id: "DocVersion.file", refContentType: H.List(["File"])};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: false, id: "DocVersion.isFavorite", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isDocVersion(arg: any): arg is DocVersion {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "DocVersion"
}
