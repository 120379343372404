/* tslint:disable */
import * as H from "../metadataHelper"
import {DateTimeField} from "./dateTimeField"

export interface IntervalTime extends H.BaseValue {
    from?: Date; // Дата начала
    to?: Date; // Дата завершения
    contentType: "IntervalTime"; // Object type
}

export module IntervalTime {
    export const contentType: "IntervalTime" = "IntervalTime";
    export const endpoint = "/api/v3/intervalTime";

    export const newObject: IntervalTime = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const from = {contentType: "DateTimeField" as "DateTimeField", name: "from", isMultiple: false, isSortable: false, id: "IntervalTime.from", defaultValue: null as DateTimeField["defaultValue"]};
        export const to = {contentType: "DateTimeField" as "DateTimeField", name: "to", isMultiple: false, isSortable: false, id: "IntervalTime.to", defaultValue: null as DateTimeField["defaultValue"]};
    }
}

export function isIntervalTime(arg: any): arg is IntervalTime {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "IntervalTime"
}
