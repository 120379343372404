/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"

export interface SystemSetting<T1> extends H.BaseEntity {
    id?: string; // Идентификатор
    scope?: string; // Название скоупа настроек
    value?: T1; // Значение настройки
    contentType: "SystemSetting"; // Object type
}

export module SystemSetting {
    export const contentType: "SystemSetting" = "SystemSetting";
    export const endpoint = "/api/v3/systemSetting";

    export const newObject: SystemSetting<any> = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const scope = {contentType: "StringField" as "StringField", name: "scope", isMultiple: false, isSortable: false, id: "SystemSetting.scope", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isSystemSetting(arg: any): arg is SystemSetting<any> {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "SystemSetting"
}
