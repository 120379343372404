import {TaskRights, ProjectRights} from "src/lib/entities/bums"

export const TaskPossibleActions: {[index: string]: keyof TaskRights} = {
    act_accept_deadline: "acceptDeadline",
    act_accept_task: "acceptTask",
    act_accept_work: "acceptWork",
    act_cancel: "cancel",
    act_change_deadline: "changeDeadline",
    act_change_extfields: "editExtFields",
    act_convert: "convert",
    act_deal: "linkDeal",
    act_delegate: "delegate",
    act_delete: "remove",
    act_done: "done",
    act_edit: "edit",
    act_edit_executors: "editExecutors",
    act_expire: "expire",
    act_fin_operation: "createFinOperation",
    act_milestone: "readMilestone",
    act_negotiation_subtask: "createNegotiationSubtask",
    act_pause: "pause",
    act_read: "read",
    act_reject_deadline: "rejectDeadline",
    act_reject_task: "rejectTask",
    act_reject_work: "rejectWork",
    act_renew: "renew",
    act_request_deadline: "requestDeadline",
    act_resume: "resume",
    act_subtask: "createSubtask",
    act_takeup: "takeup",
    act_to_template: "toTemplate",
    act_view_bonuses: "readBonuses",
    can_see_votes: "rateSeeVote",
    change_owner: "changeOwner",
    reminder_recipient: "reminderRecipient",
    set_milestone: "createMilestone",
    task_rate_change_vote_available: "rateChangeVoteAvailable",
    task_rate_custom_vote_available: "rateCustomVoteAvailable",
    task_rate_results_available: "rateResultsAvailable",
    task_rate_vote_available: "rateVoteAvailable",
    task_rate_vote_will_be_available: "rateVoteWillBeAvailable",
    task_rate_widget_available: "rateWidgetAvailable",
    use_template: "useTemplate"
}

export const ProjectPossibleActions: {[index: string]: keyof ProjectRights} = {
    act_accept_deadline: "acceptDeadline",
    act_accept_work: "acceptWork",
    act_cancel: "cancel",
    act_change_deadline: "changeDeadline",
    act_change_extfields: "editExtFields",
    act_convert: "convert",
    act_create_negotiation_task: "createNegotiationTask",
    act_create_subproject: "createSubproject",
    act_create_task: "createTask",
    act_deal: "linkDeal",
    act_delete: "remove",
    act_done: "done",
    act_edit: "edit",
    act_edit_executors: "editExecutors",
    act_expire: "expire",
    act_fin_operation: "createFinOperation",
    act_milestone: "readMilestone",
    act_pause: "pause",
    act_read: "read",
    act_reject_deadline: "rejectDeadline",
    act_reject_work: "rejectWork",
    act_renew: "renew",
    act_request_deadline: "requestDeadline",
    act_resume: "resume",
    act_to_template: "toTemplate",
    act_view_bonuses: "readBonuses",
    change_owner: "changeOwner",
    reminder_recipient: "reminderRecipient",
    set_milestone: "createMilestone",
    use_template: "useTemplate",
}
