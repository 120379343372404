import {observable, action, toJS} from "mobx"
import {persist} from "mobx-persist"
import * as Api from "src/lib/entities/api"
import LoadState from "src/lib/utils/loadState"
import {defineOrGetFetchStates} from "src/lib/entities/store/ApiStore"
import {List} from "src/lib/collections"

interface CommentCache {
    id: string
    contentType: Api.Comment["contentType"]
    content: string
    owner: Api.Employee | Api.ContractorCompany | Api.ContractorHuman
    timeCreated: number
    originalComment?: CommentCache
    attaches?: Api.File[]
    forwardFrom?: Api.Comment | Api.Message
}

export class CommentsCacheStore {

    @persist("map")
    @observable
    private cachedComments = observable.map<string, CommentCache[]>({})

    private apiStore: Api.Store

    constructor(
        apiStore?: Api.Store
    ) {
        this.apiStore = apiStore
    }

    @action
    public setApiStore = (apiStore: Api.Store) => {
        this.apiStore = apiStore
        this.initFromCache()
    }

    public initFromCache = () => {
        // Чистим id сохраненных комментариев после поднятия из кеша для синхронизации с новыми отправляемыми комментариями
        Array.from(this.cachedComments.keys())
            .forEach(key => {
                const comments = this.cachedComments.get(key)

                this.cachedComments.set(key, comments.map(comment => {
                    return {
                        ...comment,
                        id: `${Math.random().toString(16)}`,
                    }
                }))
            })
    }

    @action
    public save = (entity: Api.BaseEntity, comment: Api.Comment) => {

        if (!comment?.content && !comment.attaches?.length) {
            return
        }

        const key = this.getEntityLinkKey(entity)
        const comments = toJS(this.cachedComments.get(key))
        const originalComment = this.apiStore.getEntity(comment.originalComment, null)

        const commentCache: CommentCache = {
            id: comment.id,
            contentType: comment.contentType,
            content: comment.content,
            originalComment: originalComment ? {
                id: originalComment.id,
                contentType: originalComment.contentType,
                content: originalComment.content,
                owner: {
                    ...Api.getLink(originalComment.owner),
                    name: originalComment.owner.name,
                },
                timeCreated: originalComment.timeCreated.getTime(),
                attaches: this.getAttachesArray(originalComment.attaches),
            } : void 0,
            attaches: this.getAttachesArray(comment.attaches),
            owner: Api.getLink(comment.owner),
            timeCreated: comment.timeCreated.getTime(),
            forwardFrom: comment.forwardFrom,
        }

        if (Array.isArray(comments)) {
            this.cachedComments.set(key, [commentCache, ...comments])
        } else {
            this.cachedComments.set(key, [commentCache])
        }
    }

    @action
    public remove = (entity: Api.BaseEntity, comment: Api.Comment) => {
        const key = this.getEntityLinkKey(entity)
        const comments = toJS(this.cachedComments.get(key))

        if (Array.isArray(comments)) {
            if (comments.length > 1) {
                this.cachedComments.set(key, comments.filter(c => !Api.isEntityEquals(c, comment)))
            } else {
                this.cachedComments.delete(key)
            }
        }
    }

    public comments = (entity: Api.BaseEntity): Api.Comment[] => {
        const key = this.getEntityLinkKey(entity)
        const comments = toJS(this.cachedComments.get(key))

        if (Array.isArray(comments)) {
            return comments
                .map((el: CommentCache) => {
                    let comment = this.apiStore.getEntity<Api.Comment>({id: el.id, contentType: Api.Comment.contentType}, null)

                    if (comment) {
                        return comment
                    }

                    comment = {
                        ...el,
                        originalComment: el.originalComment ? {
                            id: el.originalComment.id,
                            contentType: el.originalComment.contentType,
                            content: el.originalComment.content,
                            attaches: List(el.originalComment.attaches),
                            owner: this.apiStore.getEntity(el.owner),
                        } : void 0,
                        attaches: List(el.attaches),
                        owner: this.apiStore.getEntity(el.owner),
                        timeCreated: new Date(el.timeCreated),
                        forwardFrom: el.forwardFrom,
                    }

                    defineOrGetFetchStates(comment).set("update", LoadState.Error({
                        meta: {
                            status: 500,
                            errors: [],
                        }
                    }))

                    return comment
                })
        }

        return []
    }

    public entities<T extends Api.BaseEntity>(contentType: T["contentType"]): Api.BaseEntity[] {
        return Array.from(this.cachedComments.keys())
            .map(comment => JSON.parse(comment))
            .filter(entityLink => entityLink.contentType === contentType && this.cachedComments.get(entityLink).length > 0)
    }

    private getEntityLinkKey = (entity: Api.BaseEntity) => {
        return JSON.stringify(Api.getLink(entity))
    }

    private getAttachesArray = (attaches: List<Api.File>) => {
        if (!attaches) {
            return null
        }

        return attaches.map(attach => ({
            id: attach.id,
            contentType: attach.contentType,
            name: attach.name,
            thumbnail: attach.thumbnail,
            metadata: attach.metadata,
            size: attach.size,
            path: attach.path,
            extension: attach.extension,
            mimeType: attach.mimeType,
        })).toArray()
    }
}
