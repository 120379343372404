/* tslint:disable */
import * as H from "../metadataHelper"


export interface UserSetting<T1> extends H.BaseEntity {
    value?: T1;
    id?: string; // Идентификатор
    contentType: "UserSetting"; // Object type
}

export module UserSetting {
    export const contentType: "UserSetting" = "UserSetting";
    export const endpoint = "/api/v3/userSetting";

    export const newObject: UserSetting<any> = {contentType}
    Object.freeze(newObject)

    export module fields {}
}

export function isUserSetting(arg: any): arg is UserSetting<any> {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "UserSetting"
}
