import {ReportColumn, ReportGroup} from "./types"
import * as Collections from "src/lib/collections"
import * as Api from "src/lib/entities/api"
import {BaseValue, isBaseValue} from "src/lib/entities/types"

type Entity = ReportColumn | ReportGroup
type Entities = Collections.List<Entity>

export function getEntityForKey(key: string|number, entityes: Entities) {
    const entity = entityes.find((_entity: Entity) => {
        return _entity.key === key
    })
    if (entity) {
        return entity
    }
    return null
}

export function getLastIndex(entityes: Entities) {
    return entityes.length;
}

export function getFilterEntityList(entytiList: Entities, entities: Entities): Entities {
    return entytiList.filter((entity: Entity) => {
        return getEntityForKey(entity.key, entities) ? false : true
    })
}

export function normalizeEntity(entity: Api.BaseEntity | BaseValue) {
    let normalizedEntity: Api.BaseEntity & {[index: string]: any} = {
        contentType: entity.contentType
    }
    if (Api.isBaseEntity(entity) || isBaseValue(entity)) {
        Object.keys(entity).forEach((key: string) => {
            let entityItem = (entity as any)[key]
            if (Array.isArray(entityItem)) {
                entityItem = entityItem.map((item: Api.BaseEntity | BaseValue) => {
                    if (Api.isBaseEntity(item) || isBaseValue(item)) {
                        return normalizeEntity(item)
                    }
                    return item
                })
                normalizedEntity[key] = Collections.List(entityItem)
            } else {
                if (Api.isBaseEntity(entityItem) || isBaseValue(entityItem)) {
                    normalizedEntity[key] = normalizeEntity(entityItem)
                } else {
                    normalizedEntity[key] = entityItem
                }
            }
        })
    }
    return normalizedEntity
}

export function denormalizeEntity(entity: Api.BaseEntity | BaseValue) {
    let denormalizedEntity: Api.BaseEntity & {[index: string]: any} = {
        contentType: entity.contentType
    }
    if (Api.isBaseEntity(entity) || isBaseValue(entity)) {
        Object.keys(entity).forEach((key: string) => {
            let entityItem = (entity as any)[key]
            if (Collections.isList(entityItem)) {
                denormalizedEntity[key] = entityItem.map((item: Api.BaseEntity | BaseValue) => {
                    if (Api.isBaseEntity(item) || isBaseValue(item)) {
                        return denormalizeEntity(item)
                    }
                    return item
                }).toArray()
            } else {
                if (Api.isBaseEntity(entityItem) || isBaseValue(entityItem)) {
                    denormalizedEntity[key] = denormalizeEntity(entityItem)
                } else {
                    denormalizedEntity[key] = entityItem
                }
            }
        })
    }
    return denormalizedEntity
}
