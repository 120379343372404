/* tslint:disable */
import * as H from "../metadataHelper"
import {FieldSetting} from "./fieldSetting"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"
import {IntegerField} from "./integerField"

export interface EnumField extends H.BaseEntity {
    enumValues?: H.List<string | {name: string, hrName: string}>; // Массив значений
    defaultValue?: string; // Значения по умолчанию
    newValuesAllowed?: boolean; // Допустимы новые значения
    isIndexable?: boolean; // Доступно для быстрого поиска
    name?: string; // Название поля
    hrName?: string; // Человекопонятное название поля
    type?: string; // Тип поля
    isRequired?: boolean; // Обязательное поле
    isMultiple?: boolean; // Множественное значение
    targetEntity?: string; // Целевая сущность
    settings?: H.List<FieldSetting>; // Массив настроек
    settingsCount?: number; // Количество настроек
    orderPos?: number; // Позиция поля при сортировке
    isSystem?: boolean; // Системное поле
    isSortable?: boolean; // Сортируемое поле
    owner?: H.BaseEntity; // Сущность владельца
    id?: string; // Идентификатор
    contentType: "EnumField"; // Object type
}

export module EnumField {
    export const contentType: "EnumField" = "EnumField";
    export const endpoint = "/api/v3/enumField";

    export const newObject: EnumField = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const enumValues = {contentType: "StringField" as "StringField", name: "enumValues", isMultiple: true, isSortable: false, id: "EnumField.enumValues", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const defaultValue = {contentType: "StringField" as "StringField", name: "defaultValue", isMultiple: false, isSortable: false, id: "EnumField.defaultValue", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const newValuesAllowed = {contentType: "BoolField" as "BoolField", name: "newValuesAllowed", isMultiple: false, isSortable: false, id: "EnumField.newValuesAllowed", defaultValue: null as BoolField["defaultValue"]};
        export const isIndexable = {contentType: "BoolField" as "BoolField", name: "isIndexable", isMultiple: false, isSortable: false, id: "EnumField.isIndexable", defaultValue: null as BoolField["defaultValue"]};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "EnumField.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const hrName = {contentType: "StringField" as "StringField", name: "hrName", isMultiple: false, isSortable: false, id: "EnumField.hrName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const type = {contentType: "StringField" as "StringField", name: "type", isMultiple: false, isSortable: false, id: "EnumField.type", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isRequired = {contentType: "BoolField" as "BoolField", name: "isRequired", isMultiple: false, isSortable: false, id: "EnumField.isRequired", defaultValue: null as BoolField["defaultValue"]};
        export const isMultiple = {contentType: "BoolField" as "BoolField", name: "isMultiple", isMultiple: false, isSortable: false, id: "EnumField.isMultiple", defaultValue: null as BoolField["defaultValue"]};
        export const targetEntity = {contentType: "StringField" as "StringField", name: "targetEntity", isMultiple: false, isSortable: false, id: "EnumField.targetEntity", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const settings = {contentType: "RefLinkField" as "RefLinkField", name: "settings", isMultiple: true, isSortable: false, id: "EnumField.settings", refContentType: H.List(["FieldSetting"])};
        export const settingsCount = {contentType: "IntegerField" as "IntegerField", name: "settingsCount", isMultiple: false, isSortable: false, id: "EnumField.settingsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const orderPos = {contentType: "IntegerField" as "IntegerField", name: "orderPos", isMultiple: false, isSortable: false, id: "EnumField.orderPos", defaultValue: null as IntegerField["defaultValue"]};
        export const isSystem = {contentType: "BoolField" as "BoolField", name: "isSystem", isMultiple: false, isSortable: false, id: "EnumField.isSystem", defaultValue: null as BoolField["defaultValue"]};
        export const isSortable = {contentType: "BoolField" as "BoolField", name: "isSortable", isMultiple: false, isSortable: false, id: "EnumField.isSortable", defaultValue: null as BoolField["defaultValue"]};
        export const owner = {contentType: "RefLinkField" as "RefLinkField", name: "owner", isMultiple: false, isSortable: false, id: "EnumField.owner", refContentType: H.List(["BaseEntity"])};
    }
}

export function isEnumField(arg: any): arg is EnumField {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "EnumField"
}
