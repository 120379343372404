import {autobind} from "core-decorators"
import {observer} from "mobx-react"
import * as React from "react"
import {Component, CThemeBg} from "src/lib/components"
import {lazy} from "src/lib/utils/lazy"
import {CGlobalWindowTracker} from "../globalContainer/CGlobalWindowTracker"
import {CGlobalSubscriptions} from "../globalSubscriptions/CGlobalSubscriptions"

// tslint:disable:max-line-length
const CGlobalDragContainer = lazy(async () => (await import("../globalDrag/CGlobalDragContainer")).CGlobalDragContainer)
const CModalFormContainer = lazy(async () => (await import("../modalForm/CModalFormContainer")).CModalFormContainer)
const CEditionExceedModal = lazy(async () => (await import("../editionExceedModal/CEditionExceedModal")).CEditionExceedModal)
const CNewInterfaceWarningModal = lazy(async () => (await import("../newInterfaceWarningModal/CNewInterfaceWarningModal")).CNewInterfaceWarningModal)
const CPromoContainer = lazy(async () => (await import("../promo/CPromoContainer")).CPromoContainer)
const CFirstConfigureGlobalContainer = lazy(async () => (await import("../onboarding/firstConfigure/CFirstConfigureGlobalContainer")).CFirstConfigureGlobalContainer)
const CTutorialGlobalContainer = lazy(async () => (await import("../onboarding/tutorial/CTutorialGlobalContainer")).CTutorialGlobalContainer)
const CTestDriveFeature = lazy(async () => (await import("../testDriveFeature/CTestDriveFeature")).CTestDriveFeature)
const CHotKeysHandler = lazy(async () => (await import("src/lib/components/CHotKey/CHotKeysHandler")).CHotKeysHandler)
const COnBoardingRatingContainer = lazy(async () => (await import("../promo/COnboardingRating/COnBoardingRatingContainer")).COnBoardingRatingContainer)
const CInviteLinkModal = lazy(async () => (await import("../onboarding/tutorial/CInviteTeam/CInviteLinkModal")).CInviteLinkModal)
const CProductQuestRatingContainer = lazy(async () => (await import("../promo/CProductQuestRating/CProductQuestRatingContainer")).CProductQuestRatingContainer)
const CVideoCallContainer = lazy(async () => (await import("../janus/CVideoCallContainer")).CVideoCallContainer)

export namespace CGlobalContainer {
   export interface Props {}
}

@autobind
@observer
export class CGlobalContainer extends Component<CGlobalContainer.Props, {}> {
    public render() {
        return <React.Fragment>
            <CThemeBg/>
            {!window.bt_mode && <CFirstConfigureGlobalContainer/>}
            <CModalFormContainer />
            <CEditionExceedModal />
            <CNewInterfaceWarningModal />
            <CPromoContainer />
            <COnBoardingRatingContainer />
            <CProductQuestRatingContainer />
            <CTutorialGlobalContainer />
            <CGlobalDragContainer />
            <CTestDriveFeature />
            <CHotKeysHandler />
            <CInviteLinkModal />
            <CGlobalWindowTracker />
            <CGlobalSubscriptions />
            <CVideoCallContainer />
        </React.Fragment>
    }
}
