import * as ReduxActions from "redux-actions"

export const EXPAND_FUNNEL_TABLE_ROWS = "bums/trade/funnel/action/EXPAND_FUNNEL_TABLE_ROWS"

export type ExpandFunnelTableRowsAction = ReduxActions.Action<{
    rowKeys: Array<string>
}>

export function expandFunnelTableRows(rowKeys: Array<string>): ExpandFunnelTableRowsAction {
    return {
        type: EXPAND_FUNNEL_TABLE_ROWS,
        payload: {
            rowKeys
        },
    }
}
