import * as ReduxActions from "redux-actions"

export const SET_REPORT_SORT_COLUMN = "bums/common/report/card/action/SET_REPORT_SORT_COLUMN"

export type SetSortColumn = ReduxActions.Action<{
    sortColumn: string
}>

export function setSortColumn(sortColumn: string): SetSortColumn {
    return {
        type: SET_REPORT_SORT_COLUMN,
        payload: {
            sortColumn
        }
    }
}
