import {Component} from "src/lib/components/Component/Component";
import {CWindowEvent} from "src/lib/components";
import * as React from "react";
import {track, TrackingProp} from "src/lib/tracking"
import {autobind} from "core-decorators";
import {observer} from "mobx-react";

export namespace CGlobalWindowTracker {
    export interface Props extends TrackingProp {}
}

const EXISTING_WINDOW_KEY = "existingWindow"
const CLICK_EVENT_TYPE = "Click"
const CLICK_LINK_EVENT = "Click Link"

@track({eventType: "window"})
@autobind
@observer
export class CGlobalWindowTracker extends Component<CGlobalWindowTracker.Props, {}> {
    // хак для попытки трекать именно закрытие окна, а не переходы внутри мегаплана или F5 (хотя тоже это все приблезительно)
    // считаем так, что чтобы закрыть окно курсор должен переместиться на кнопку закрытия (за пределами документа)
    private mouseInWindow = true

    constructor(props: {}, context?: any) {
        super(props, context)

        if (sessionStorage && !sessionStorage.getItem(EXISTING_WINDOW_KEY)) {
            sessionStorage.setItem(EXISTING_WINDOW_KEY, "true")

            this.trackWindowOpened()
        }

        document.addEventListener("mouseleave", () => this.mouseInWindow = false)
        document.addEventListener("mouseenter", () => this.mouseInWindow = true)
        document.addEventListener("mouseup", this.clickLinkListener)
    }

    componentWillUnmount(): void {
        document.removeEventListener("mouseup", this.clickLinkListener)
    }

    private clickLinkListener(event: Event) {
        if (!event.defaultPrevented && event.target) {
            const target: any = event.target
            if (target.tagName === "A"
                && target.href
                && !target.hash) {
                this.props.tracking.trackEvent({
                    eventType: CLICK_EVENT_TYPE,
                    event: CLICK_LINK_EVENT,
                    channels: ["product"],
                    params: {link: target.href}
                })
            }
        }
    }

    @track({event: "opened", channels: ["product", "amplitude"]})
    private trackWindowOpened() {
        return
    }

    @track({event: "closed", channels: ["product", "amplitude"]})
    private trackWindowClosed() {
        return
    }

    private beforeUnloadHandler() {
        if (this.mouseInWindow && window.fakeBeforeUnload) {
            return
        }

        this.trackWindowClosed()
    }

    render() {
        return <React.Fragment>
            <CWindowEvent event="beforeunload" handler={this.beforeUnloadHandler} />
        </React.Fragment>
    }
}
