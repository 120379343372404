import * as React from "react"
import PropTypes from "prop-types"

const nativeExp = /\{\s*\[native code\]\s*\}/

export interface StoreClass<Instance = any> {
    new(...args: any[]): Instance
    constructor: Function
}

const injectParametersByTarget = new Map<StoreClass, Map<number, StoreClass>>()

/**
 *  Позволяет внедрить стор в объект
 *  Примеры:
 *     // Внедрение в React.Component
 *     import {inject} from "src/lib/utils/inject"
 *     import {Component} from "src/lib/components"
 *     import MyStore from "path/to/MyStore"
 *     class MyComponent extends Component<any, any> {
 *
 *        @inject(MyStore)
 *        private store: MyStore
 *
 *        private function myMethod() {
 *            this.store.storeMethod()
 *        }
 *     }
 *
 *     // Внедрение стора в стор
 *     import {inject} from "src/lib/utils/inject"
 *     import OtherStore from "path/to/OtherStore"
 *
 *     class MyStore {
 *
 *        private otherStore: OtherStore
 *
 *        constructor(@inject(OtherStore) otherStore: OtherStore) {
 *            this.otherStore = otherStore
 *        }
 *     }
 *
 */

export function inject<T>(store: StoreClass<T>) {
    return function (target: any, propertyName: string, propertyIndex?: number) {
        if (propertyName && propertyIndex === void 0) {
            return propertyDecorator(target, propertyName, store)
        } else if (!propertyName && propertyIndex !== void 0) {
            return parameterDecorator(target, propertyIndex, store)
        }

        throwError("Decorator is to be applied to property, or to a constructor parameter", target)
    }
}

export function useInject<T>(store: StoreClass<T>): T {
    const storesContext = React.useContext(StoresContext)
    return storesContext.megaplanStores.get(getStoreConstructor(store, null))
}

/**
 *  Создание инстанса стора с разрешением его зависимостей
 */
export function createStoreWithResolvedDependencies(store: StoreClass, storesMap: StoresMap) {
    const resolvedDependencies = resolveDependencies(store, storesMap).map((dependency) => storesMap.get(dependency))
    return new store(...resolvedDependencies)
}

function getStoreConstructor(store: StoreClass, target: any): StoreClass {
    if ("function" === typeof store && !store.name) {
        store = (store as any as (() => StoreClass))()
    }

    checkValidDependency(target, store)

    return store
}

/**
 * Определяет getter для свойства в target, который возвращает определенный Store
 */
function propertyDecorator<T>(target: any, propertyName: string, store: StoreClass) {
    if (!(target instanceof React.Component)) {
        throwError("Injection store can implement only in React.Component", target)
    }

    const targetConstructor = target.constructor

    if (!process.env.REACT_NATIVE) {

        if (targetConstructor.contextTypes == null) {
            targetConstructor.contextTypes = {}
        }

        if (targetConstructor.contextTypes.megaplanStores == null) {
            targetConstructor.contextTypes.megaplanStores = PropTypes.instanceOf(StoresMap).isRequired
        }

    } else {
        targetConstructor.contextType = StoresContext
    }

    let storeInstance: StoreClass = null

    Object.defineProperty(target, propertyName, {
        get: function () {
            if (!storeInstance) {
                storeInstance = this.context.megaplanStores.get(getStoreConstructor(store, target))
            }

            return storeInstance
        }
    })
}

/**
 * Добавляет в метаданные прототипа объекта, информацию о том, что к какому-то параметру конструктора была внедрена зависимость
 */
function parameterDecorator<T>(target: any, parameterIndex: number, store: StoreClass) {
    if (!injectParametersByTarget.has(target)) {
        injectParametersByTarget.set(target, new Map<number, StoreClass>())
    }

    const params = injectParametersByTarget.get(target)
    params.set(parameterIndex, store)
}

/**
 *  Определение наличия циклической зависимости и если она обнаружена, то функция выбрасывает исключение
 */
function detectCircularDependencies(dependencies: Set<StoreClass>, store: StoreClass) {
    if (dependencies.has(store)) {
        const chains = Array.from(dependencies.values()).map(dependency => dependency.name).join(" -> ")
        throwError(`Cyclic dependencies are found in the following chain "${chains}"`)
    }
}

function getConstructorDependencies(store: StoreClass): Map<number, StoreClass> {
    const deps =  injectParametersByTarget.get(store)
    return deps || (isNative(store.constructor) && !!store.prototype && !!store.prototype.constructor
        ? getConstructorDependencies(Object.getPrototypeOf(store.prototype.constructor))
        : new Map<number, StoreClass>()
    )
}

/**
 *  Разрешение зависимостей
 */
function resolveDependencies(store: StoreClass, storesMap: StoresMap, dependenciesChain = new Set<StoreClass>()) {
    const injecDependencyPosition = getConstructorDependencies(store)
    const resolvedDependencies: StoreClass[] = []

    if (injecDependencyPosition.size === 0) {
        return resolvedDependencies
    }

    const args = Array.from(injecDependencyPosition.keys()).sort()

    args.forEach(position => {
        const dependency = getStoreConstructor(injecDependencyPosition.get(position), store)
        checkValidDependency(store, dependency)

        if (!storesMap.has(dependency)) {
            detectCircularDependencies(dependenciesChain, dependency)
            resolveDependencies(dependency, storesMap, dependenciesChain)
        }

        resolvedDependencies.push(dependency)
    })

    dependenciesChain.delete(store)

    return resolvedDependencies
}

/**
 * Выброс стилизованного исключения
 */
function throwError(message: string, target?: any) {
    throw new Error(`${message}.${target ? ` Error occurred in ${target.name}` : ""}`)
}

/**
 *  Является ли функция нативной реализацией
 */
function isNative(fn: Function) {
    return nativeExp.test("" + fn)
}

/**
 *  Проверка на валидность зависимости
 */
function checkValidDependency(target: any, dependency: Function) {
    if (!dependency || !("constructor" in dependency)) {
        throwError("Dependency must have a constructor", target)
    }
    if (isNative(dependency)) {
        throwError("Dependency may not be native implementation", target)
    }
}

type S<I> = [StoreClass<I>, I]

export class StoresMap {
    private map: Map<any, any>

    // constructor<I1>(iterable?: [S<I1>])
    public constructor(iterable: Array<S<any>>) {
        this.map = new Map(iterable)
        this.map.set(StoresMap, this)
    }
    /* tslint:disable */
    public static create<I1>(iterable?: [S<I1>]): StoresMap
    public static create<I1, I2>(iterable?: [S<I1>, S<I2>]): StoresMap
    public static create<I1, I2, I3>(iterable?: [S<I1>, S<I2>, S<I3>]): StoresMap
    public static create<I1, I2, I3, I4>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>]): StoresMap
    public static create<I1, I2, I3, I4, I5>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20, I21>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>, S<I21>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20, I21, I22>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>, S<I21>, S<I22>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20, I21, I22, I23>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>, S<I21>, S<I22>, S<I23>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20, I21, I22, I23, I24>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>, S<I21>, S<I22>, S<I23>, S<I24>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20, I21, I22, I23, I24, I25>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>, S<I21>, S<I22>, S<I23>, S<I24>, S<I25>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20, I21, I22, I23, I24, I25, I26>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>, S<I21>, S<I22>, S<I23>, S<I24>, S<I25>, S<I26>]): StoresMap
    public static create<I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11, I12, I13, I14, I15, I16, I17, I18, I19, I20, I21, I22, I23, I24, I25, I26, I27>(iterable?: [S<I1>, S<I2>, S<I3>, S<I4>, S<I5>, S<I6>, S<I7>, S<I8>, S<I9>, S<I10>, S<I11>, S<I12>, S<I13>, S<I14>, S<I15>, S<I16>, S<I17>, S<I18>, S<I19>, S<I20>, S<I21>, S<I22>, S<I23>, S<I24>, S<I25>, S<I26>, S<I27>]): StoresMap
    /* tslint:enable */
    public static create(iterable: Array<S<any>> = []) {
        return new StoresMap(iterable)
    }

    has(storeClass: StoreClass) {
        return this.map.has(storeClass)
    }

    get<I>(storeClass: StoreClass<I>): I {
        if (!this.map.has(storeClass)) {
            const storeInstance = createStoreWithResolvedDependencies(storeClass, this)
            this.map.set(storeClass, storeInstance)
        }
        return this.map.get(storeClass)
    }
}

export const StoresContext = React.createContext<{megaplanStores: StoresMap}>({megaplanStores: new StoresMap([])})
