import {autobind} from "core-decorators"
import {computed, action} from "mobx"
import * as Api from "src/lib/entities/api"

@autobind
export class UserInterfaceListStore {

    private $listStore: Api.ListStore<Api.UserInterfaceLayout<any>>

    private $initialize = false

    constructor(apiStore: Api.Store) {
        this.$listStore = apiStore.resolveList<Api.UserInterfaceLayout<any>>(() => {
            if (this.$initialize) {
                return {
                    endpoint: Api.UserInterfaceLayout.endpoint
                }
            }

            return void 0
        })
    }

    @action
    public initialize() {
        this.$initialize = true
    }

    @computed
    public get isCompleted() {
        return this.$listStore.loadStateNext.isCompleted()
    }
}
