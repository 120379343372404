/// <reference path="./../../.typings/index.d.ts" />

/**
 * Здесь инициализируется рантайм
 */

declare const global: any

if (process.env.NODE_ENV === "production") {
    require("first-input-delay")
}

import * as Bluebird from "bluebird"
import {createElement} from "react"
import "date-utils"
import {MemoizeCache} from "src/lib/utils/MemoizeCache"
import * as Sentry from "@sentry/react"
import {Integrations} from "@sentry/tracing"

import {memoize} from "lodash"
memoize.Cache = MemoizeCache;

if (global.location && !__webpack_public_path__.includes("//")) {
    if ((global["megaplanStaticHostUri"] || "").includes("//")) {
        const staticHost = global["megaplanStaticHostUri"];
        __webpack_public_path__ = (0 === staticHost.indexOf("//") ? global.location.protocol : "") +
            staticHost + "/spa/"
    } else {
        __webpack_public_path__ = global.location.protocol +
            "//" + global.location.host + (global["megaplanStaticHostUri"] || "") + "/spa/"
    }
}

if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    window.addEventListener("load", async () => {
        try {
            await navigator.serviceWorker.register("/sw.js")
        } catch (registrationError) {
            console.error("SW registration failed: ", registrationError)
        }
    })
}

global.Promise = Bluebird

declare global {
    const dom: typeof createElement
}
global.dom = createElement

/**
 * Sentry block
 */
const releaseVersionRegexp = /^(r\d{0,4})\.\d+\.\d+$/i
const isReleaseVersion = (version: string = "") => {
    return version.indexOf("r") === 1 && releaseVersionRegexp.test(version)
}
if (global.window !== void 0 && global.window.react_sentry && isReleaseVersion(process.env.MEGAPLAN_VERSION)) {
    const [releaseVersion, ] = process.env.MEGAPLAN_VERSION.split(".")
    Sentry.init({
        dsn: "https://234ecaae01594437b4ff86950ede6d33@sentry.megaplan.ru/13",
        // This enables automatic instrumentation (highly recommended), but is not
        // necessary for purely manual usage
        integrations: [new Integrations.BrowserTracing()],
        release: "megaplan@" + releaseVersion,
        // To set a uniform sample rate
        tracesSampleRate: 1,
    });
}
