/* tslint:disable:no-import-immutable */
import * as Immutable from "immutable";
/* tslint:enable:no-import-immutable */

import * as Interfaces from "./interfaces"

export const List = Immutable.List as any as Interfaces.ListConstructor;
export const isList = Immutable.List.isList as Interfaces.IsListFn;
export const Set = Immutable.Set as any as Interfaces.SetConstructor;
export const isSet = Immutable.Set.isSet as Interfaces.IsSetFn;
export const Map = Immutable.Map as any as Interfaces.MapConstructor;
export const isMap = Immutable.Map.isMap as Interfaces.IsMapFn;
export const OrderedMap = Immutable.OrderedMap as any as Interfaces.OrderedMapConstructor;
export const isOrderedMap = Immutable.OrderedMap.isOrderedMap as Interfaces.IsOrderedMapFn;
export const isIterable = Immutable.Iterable.isIterable;
