import * as ReduxActions from "redux-actions"

export const CHANGE_REPORT_OBJECT = "bums/common/report/edit/action/CHANGE_REPORT_OBJECT"

export type ChangeReportObject = ReduxActions.Action<{
    selectedObject: string
}>

export function changeReportObject(selectedObject: string): ChangeReportObject {
    return {
        type: CHANGE_REPORT_OBJECT,
        payload: {
            selectedObject
        }
    }
}
