import {LoadState} from "../utils/loadState"
/**
 * Базовый интефрейс всех значений.
 */
export interface BaseValue {
    contentType: string
    /**
     * Версии значений полей. В ключе - имя поля, в значении - номер версии.
     */
    _fieldValueVersions?: Map<string, number>
    /**
     * Номер версии сущности, по сути максимальное значение из _fieldValueVersions
     */
    _version?: number
}

export function isBaseValue(arg: any): arg is BaseValue {
    return arg && "object" === typeof arg && "contentType" in arg
}

/**
 * Базовый интефрейс всех сущностей.
 */
export interface BaseEntity extends BaseValue {
    _fetchStates?: Map<string, LoadState>
    id?: string
}

export function isBaseEntity(arg: any): arg is BaseEntity {
    return isBaseValue(arg) && "id" in arg
}


export class DateInterval {
    private _s: number

    constructor(seconds: number) {
        this._s = seconds
    }

    public getSeconds(): number {
        return this._s
    }

    public denormalize() {
        return {
            contentType: "DateInterval",
            value: this._s
        }
    }
}

export function isDateInterval(a: any): a is DateInterval {
    return a instanceof DateInterval
}


export interface ApiError {
    type: string
    message: string
    field: string
    trace: Array<string>
    meta?: object
}

export type ApiErrors = Array<ApiError>

export interface ApiErrorResponse {
    meta: {
        status: number
        errors: Array<ApiError>
    }
}

export interface ApiErrorBulkResponse {
    meta: {
        status: number
        errors: Array<ApiError>
    },
    data: Array<ApiErrorResponse|ApiResponse<any>>
}

export interface ApiResponseMeta {
    status: number
    pagination?: {
        count: number
        limit: number
        hasMoreNext: boolean
        hasMorePrev: boolean
        elementIndex?: number
    }
}
export interface ApiResponse<T> {
    meta: ApiResponseMeta
    data: T
}

export function isApiErrorResponse(value: any): value is ApiErrorResponse {
    return "object" === typeof value && "meta" in value && "errors" in value.meta && isApiErrors(value.meta.errors)
}

export function isApiError(value: any): value is ApiError {
    return "object" === typeof value && "type" in value && value.type && "message" in value
}


export function isApiErrors(value: any): value is ApiErrors {
    return value && Array.isArray(value) && value.length !== 0 && value.every(isApiError)
}


export function isApiResponse<T>(value: any): value is ApiResponse<T> {
    return "object" === typeof value && "data" in value && "meta" in value
}

export function isFailedDependencyResponse(value: any): value is ApiErrorResponse {
    return "object" === typeof value && "meta" in value && "status" in value.meta && 424 === value.meta.status
}

export function isApiErrorBulkResponse(value: any): value is ApiErrorBulkResponse {
    return "object" === typeof value && "data" in value && Array.isArray(value.data) && value.data.some(isApiErrorResponse)
}
