import {observable, ObservableMap, action} from "mobx"

import {BaseEntity, BaseValue} from "src/lib/entities/types"
import {EntitiesList} from "./EntitiesList"

/**
 *
 */
export class IdentityMap {
    /**
     * this is identity map of all entities.
     * grouped by content types
     * @type {ObservableMap<ObservableMap<BaseEntity>>}
     */
    @observable
    private entities = observable.map<string, ObservableMap<string, BaseEntity>>([], { deep: false });

    @observable
    private lists = observable.map<string, EntitiesList>();

    public getRepository(contentType: string): ObservableMap<string, BaseEntity> {
        if (!this.entities.has(contentType)) {
            this.entities.set(contentType, observable.map<string, BaseEntity>([], { deep: false }))
        }
        return this.entities.get(contentType);
    }

    public getNewObjectOf<T extends BaseValue = BaseValue>(contentType: string, values: Partial<T> = {}): T {
        const entity = observable(({contentType}) as T)
        this.setValues(entity, values)
        return entity
    }

    @action
    public setValues<T extends BaseValue = BaseEntity>(entity: T, values: Partial<T>) {
        for (const propName in values) {
            entity[propName] = values[propName]
        }
    }

    public hasList(listName: string) {
        return this.lists.has(listName);
    }

    public getList(listName: string) {
        return this.lists.get(listName);
    }

    public addList(listName: string, list: EntitiesList) {
        this.lists.set(listName, list);
    }

    public getLists() {
        return this.lists;
    }

    public getEntities() {
        return this.entities;
    }

}
