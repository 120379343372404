import {IReactionDisposer} from "mobx"
import * as React from "react"
import * as ReactDOM from "react-dom"

const AttributeName = "data-element"

declare var window: any

function attributeInitialize() {
    if (this.displayName || this.constructor.name !== "t") {
        const node = ReactDOM.findDOMNode(this) as Element;

        if (node instanceof Element) {
            const attributeValue = node.getAttribute(AttributeName) || "";
            if (attributeValue.search(this.displayName || this.constructor.name) === -1) {
                const components = getComponentsWithSameNode(this);
                const componentNames = components.map((component) => component.displayName || component.constructor.name);
                node.setAttribute(AttributeName, componentNames.join(" "));
                const dataName = this.props["data-name"] || "";
                if (dataName) {
                    node.setAttribute("data-name", node.getAttribute("data-name") + " " + dataName);
                }
            }
        }
    }
}

/**
 * Наш кастомный компонент, нужен чтобы:
 *  - Добавлять тег data-element на компонент для нужд тестировщиков
 */
interface IDisposable {
    dispose(): void
}

type Lambda = () => void
const disposersSymbol = Symbol("disposeOnUnmount")

/* tslint:disable:no-react-component-usage */
export class Component<P = {}, S = {}> extends React.Component<P, S> {

    displayName: any;

    private [disposersSymbol]: Lambda[] | null

    protected disposeOnUnmount(reactionDisposer: Lambda | IReactionDisposer): void
    protected disposeOnUnmount<T extends IDisposable>(store: T): T
    protected disposeOnUnmount<T extends IDisposable>(lambdaOrStore: IReactionDisposer | Lambda | T): void | T {
        if (!lambdaOrStore) {
            return
        }

        const disposer = typeof lambdaOrStore === "function" ? lambdaOrStore : lambdaOrStore.dispose
        this[disposersSymbol] = this[disposersSymbol] ? this[disposersSymbol].concat(disposer) : [disposer]

        if (typeof lambdaOrStore !== "function") {
            return lambdaOrStore
        }
    }

    constructor(props?: P, context?: any) {
        super(props, context);
        if (window.bt_mode) {
            const componentDidMount = this.componentDidMount
            const componentDidUpdate = this.componentDidUpdate
            this.componentDidMount = () => {
                attributeInitialize.call(this)
                if (componentDidMount) {
                    componentDidMount.call(this);
                }
            }
            this.componentDidUpdate = (...args) => {
                attributeInitialize.call(this)
                if (componentDidUpdate) {
                    componentDidUpdate.apply(this, args)
                }
            }
        }
        const componentWillUnmount = this.componentWillUnmount
        this.componentWillUnmount = () => {
            componentWillUnmount?.call(this)
            this[disposersSymbol]?.forEach(disposer => disposer?.())
        }
    }
}

/**
 * Функция возвращает список компонентов, которые завязаны на ту же ноду
 *
 * |_ CPlanCardContainer.render() {return <CLayoutWrapper />; } - нет собственной ноды в DOM
 * |  \
 * |  |_ CLayoutWrapper.render() { return <div>Bla Bla Bla</div>; } - Есть нода в DOM
 *
 * @example getRootComponentWithSameNode(CLayoutWrapper) returns CPlanCardContainer CLayoutWrapper
 *
 * @returns Component
 * @param component
 */
function getComponentsWithSameNode(component: Component<any, any>): Component<any, any>[] {
    let components = [component]
    let parentNode
    // Хитро вытаскиваем родителя
    // Так же учитываем лейауты
    try {
        parentNode = (component as any);
        do {
            parentNode = (parentNode as any).hasOwnProperty("_reactInternalFiber")
                            ? (parentNode as any)._reactInternalFiber.return
                            : (parentNode as any).return
        } while ((parentNode.stateNode === null))

        parentNode = parentNode.stateNode
    } catch (e) {
        return components
    }
    try {
        // Если родитель компонента на другой dom ноде, то возвращаем текущий компонент
        if (!parentNode
            || parentNode.constructor.name === "WrapperComponent"
            || !ReactDOM.findDOMNode(component).isEqualNode(ReactDOM.findDOMNode(parentNode))
        ) {
            return components
        }
    } catch (e) {
        // В случае ошибки - возвращаем текущий элемент
        return components
    }
    // Если нет, входим в рекурсию
    return components.concat(getComponentsWithSameNode(parentNode))
}

