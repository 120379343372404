import * as React from "react"
import {Component} from "src/lib/components"

type GetComponentFn<T extends React.ComponentType<any>> = () => Promise<T>

const CSuspenseFallback = React.createElement("span")

export function lazy<T extends React.ComponentType<any>>(
    getComponent: GetComponentFn<T>,
    fallback = CSuspenseFallback
) {
    return class extends Component<any, {component: React.ComponentType<any>}> {
        async componentWillMount() {
            this.setState({component: await getComponent()})
        }

        render() {
            if (this.state && this.state.component) {
                return React.createElement(this.state.component, this.props)
            } else {
                return fallback
            }
        }
    } as any as T
}
