/* tslint:disable */
import * as H from "../metadataHelper"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {StringField} from "./stringField"
import {DateTimeField} from "./dateTimeField"
import {EnumField} from "./enumField"

export interface CallInfo<T1> extends H.BaseEntity {
    fromPhone?: string; // Номер телефона абонента А (кто звонит)
    toPhone?: string; // Номер телефона абонента Б (кому звонят)
    fromUser?: ContractorCompany|ContractorHuman|Employee; // Пользователь абонента А
    toUser?: ContractorCompany|ContractorHuman|Employee; // Пользователь абонента Б
    providerId?: T1; // Id звонка со стороны провайдера
    timeFrom?: Date; // Время начала звонка
    timeTo?: Date; // Время завершения звонка
    type?: string; // Тип звонка  Возможные варианты: unknown, in, out, miss, fail
    state?: CallInfo.State; // Статус звонка  Возможны варианты: unknown, calling, talking, finished, hold
    recordLinks?: H.List<string>; // Массив ссылок на запись разговора
    id?: string; // Идентификатор
    contentType: "CallInfo"; // Object type
}

export module CallInfo {
    export const contentType: "CallInfo" = "CallInfo";
    export const endpoint = "/api/v3/callInfo";
    export type State = "calling"|"finished"|"hold"|"talking"|"unknown";
    export module State {
        export const calling: State = "calling";
        export const finished: State = "finished";
        export const hold: State = "hold";
        export const talking: State = "talking";
        export const unknown: State = "unknown";
    }
    export const newObject: CallInfo<any> = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const fromPhone = {contentType: "StringField" as "StringField", name: "fromPhone", isMultiple: false, isSortable: false, id: "CallInfo.fromPhone", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const toPhone = {contentType: "StringField" as "StringField", name: "toPhone", isMultiple: false, isSortable: false, id: "CallInfo.toPhone", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const fromUser = {contentType: "RefLinkField" as "RefLinkField", name: "fromUser", isMultiple: false, isSortable: false, id: "CallInfo.fromUser", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const toUser = {contentType: "RefLinkField" as "RefLinkField", name: "toUser", isMultiple: false, isSortable: false, id: "CallInfo.toUser", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const timeFrom = {contentType: "DateTimeField" as "DateTimeField", name: "timeFrom", isMultiple: false, isSortable: false, id: "CallInfo.timeFrom", defaultValue: null as DateTimeField["defaultValue"]};
        export const timeTo = {contentType: "DateTimeField" as "DateTimeField", name: "timeTo", isMultiple: false, isSortable: false, id: "CallInfo.timeTo", defaultValue: null as DateTimeField["defaultValue"]};
        export const type = {contentType: "StringField" as "StringField", name: "type", isMultiple: false, isSortable: false, id: "CallInfo.type", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const state = {contentType: "EnumField" as "EnumField", name: "state", isMultiple: false, isSortable: false, id: "CallInfo.state", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["calling", "finished", "hold", "talking", "unknown"])};
        export const recordLinks = {contentType: "StringField" as "StringField", name: "recordLinks", isMultiple: true, isSortable: false, id: "CallInfo.recordLinks", defaultValue: null as StringField["defaultValue"], maxLength: 0};
    }
}

export function isCallInfo(arg: any): arg is CallInfo<any> {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "CallInfo"
}
