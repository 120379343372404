import {observer} from "mobx-react"
import {autobind} from "core-decorators"
import {Component} from "src/lib/components/Component/Component"
import DOM from "react-dom-factories"
require("./CThemeBg.styl")

@observer
@autobind
export class CThemeBg extends Component<{}, {}> {

    public componentDidMount() {
        document.body.classList.add("newInterfaceBackground")
    }

    render() {
        return DOM.noscript();
    }
}
