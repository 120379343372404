import {AppStateStore} from "src/lib/entities/store/online/AppStateStore"

export class BrowserAppStateStore extends AppStateStore {

    constructor() {
        super()

        if (window && window.document && window.document.addEventListener) {
            if (!window.document.hidden) {
                this.eventEmitter.emit("active")
            }

            window.onunload = () => {
                this.eventEmitter.emit("background")
            }

            window.document.addEventListener("visibilitychange", this.handleAppStateChange)
        }
    }

    private handleAppStateChange = () => {
        if (window.document.hidden) {
            this.eventEmitter.emit("background")
        } else {
            this.eventEmitter.emit("active")
        }
    }
}
