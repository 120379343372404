import {rawFetch} from "src/lib/utils/fetch"
import {debounce, noop} from "lodash"

interface Profile {
    name: string
    durationFromNavigation: number
}

export class Profiler {

    private profilesToSend: Array<Profile> = []

    private currentNavigationStart: number = 0

    private alreadyVisibleComponents = new Set<string>()

    constructor(
        // private router: Router,
    ) {
        window.addEventListener("beforeunload", this.flush)
    }

    private flush = () => {
        const data = this.profilesToSend
        if (data.length === 0) {
            return
        }
        this.profilesToSend = []
        rawFetch({
            method: "POST",
            url: "/profileBatch",
            data
        }).catch(noop)
    }

    private flushDebounced = debounce(() => this.flush(), 5000);

    public componentVisible = (componentId: string) => {
        this.profilesToSend.push(this.createProfile(`componentVisible ${componentId}`))
        this.flushDebounced()
    }

    public componentVisibleOnce = (componentId: string) => {
        if (this.alreadyVisibleComponents.has(componentId)) {
            return
        }
        this.alreadyVisibleComponents.add(componentId)
        this.componentVisible(componentId)
    }

    private createProfile(name: string): Profile {
        return {
            name,
            durationFromNavigation: window.performance.now() - this.currentNavigationStart
        }
    }
}
