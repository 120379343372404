import {LocalStorageTransport} from "./LocalStorageTransport"
import {BroadcastChannelTransport} from "./BroadcastChannelTransport"
import {SharedWorkerTransport} from "./SharedWorkerTransport"
import {AbstractTabTransport} from "./AbstractTabTransport"
import {FallbackTransport} from "src/bums/common/browserTabs/transports/FallbackTransport"
/*
Фабрика транспортов для обмена сообщениями между вкладками браузера
на первом месте BroadcastChannel как наиболее нативный метод
потом SharedWorker который можно отдельно использовать для MainTab
далее localStorage - более медленный но более широко поддерживаемый метод
*/
export const createTabsTransport = (transportChannelName: string): AbstractTabTransport => {

    let transport: AbstractTabTransport = null;

    if (window.bt_mode) {
        return new FallbackTransport(transportChannelName)
    }

    if (BroadcastChannelTransport.isSupported()) {
        transport = new BroadcastChannelTransport(transportChannelName)
    } else if (SharedWorkerTransport.isSupported()) {
        transport = new SharedWorkerTransport(transportChannelName)
    } else if (LocalStorageTransport.isSupported()) {
        transport = new LocalStorageTransport(transportChannelName)
    } else if (FallbackTransport.isSupported()) {
        transport = new FallbackTransport(transportChannelName)
    }

    if (transport === null) {
        //TODO тут, видимо, имеет смысл отдавать Noop транспорт
        throw new Error("Tab message transport is not supported in your browser")
    }

    return transport
}
