import * as ReduxActions from "redux-actions"

export const REMOVE_PERMISSION = "bums/common/report/edit/action/REMOVE_PERMISSION"

export type RemovePermission = ReduxActions.Action<{
    key: string
}>

export function removePermission(key: string): RemovePermission {
    return {
        type: REMOVE_PERMISSION,
        payload: {
            key
        }
    }
}
