import {autobind} from "core-decorators"
import {Json} from "src/lib/types"
import {AbstractTabTransport, TabMessageEvent} from "./AbstractTabTransport"

/**
 * Транспорт обмена сообщениями между вкладками через BroadcastChannel
 */
@autobind
export class FallbackTransport extends AbstractTabTransport {

    constructor(transportChannelName: string) {
        super(transportChannelName)
    }

    public static isSupported = () => {
        return true
    }

    public broadcastMessage<T = Json>(message: TabMessageEvent<T>) {
        message.isFromSameTab = true
        message.isMainTab = true
        message.isFromVisibleTab = true
        message.isFromMainTab = true
        message.isVisibleTab = true
        this.emitMessage(message)
    }

}
