import {PlainRoute} from "react-router"

export const accountRoutes: PlainRoute[] = [
    {
        path: "/settings/account/order/list",
        async getComponent() {
            return (await import("./order/page/CAccountOrderTablePage")).CAccountOrderTablePage
        },
    },
    {
        path: "/settings/account/order/new",
        async getComponent() {
            return (await import("./order/page/CAccountOrderNewPage")).CAccountOrderNewPage
        },
    },
    {
        path: "/settings/account/order/:id/card",
        async getComponent() {
            return (await import("./order/page/CAccountOrderEditPage")).CAccountOrderEditPage
        },
    },
    {
        path: "/settings/account/telephony",
        onEnter(nextState, replace) {
            window.open("/BumsSettings/Account/gotoVox.html", "_blank")
            replace({pathname: "/settings/account/order/list"})
        }
    },
    {
        path: "/settings/account/closingdocs",
        async getComponent() {
            return (await import("./order/page/CAccountClosingdocsPage")).CAccountClosingdocsPage
        },
    },
    {
        path: "/settings/account/autopays",
        async getComponent() {
            return (await import("./order/page/CAccountAutopaysPage")).CAccountAutopaysPage
        },
    },
    {
        path: "/settings/account/referralprogram",
        async getComponent() {
            return (await import("./order/page/CAccountEmptyPage")).CAccountEmptyPage
        },
    },
];
