import {PlainRoute} from "react-router"

export const adminSettingsRoutes: PlainRoute[] = [
    {
        path: "/admin/cards/pluralnames",
        async getComponent() {
            return (await import("./containers/CPageContainer")).CPageContainer
        },
    },
]
