import {autobind} from "core-decorators"
import {observe, computed} from "mobx"
import {EventEmitter} from "events"
import {isEqual} from "lodash"
import {Json} from "src/lib/types"
import {SocketSubscription, SocketTransport, BaseListener} from "./types"

@autobind
abstract class AbstractBaseSubscription<T = Json> implements SocketSubscription {

    private $eventNamesDisposer: () => void

    private $eventListenerMap = new Map<string, BaseListener<T>>()

    protected $eventEmitter = new EventEmitter()

    protected abstract eventNames: string[]

    protected abstract handleEvent(event: string, data: T): void

    constructor(protected $transport: SocketTransport) {}

    /**
     * Не использовать в компонентах !!!
     */
    @computed
    public get _unstable_event_names() {
        return this.eventNames
    }

    @computed
    protected get isSubscribed() {
        return this.$eventNamesDisposer !== void 0
    }

    public subscribe() {
        this.$eventNamesDisposer = observe(this, "_unstable_event_names", change => {
            if (change.oldValue && !isEqual(change.oldValue, change.newValue)) {
                this.resolveUnsubscribtion(change.oldValue)
                this.resolveSubscribtion(change.newValue)
            }
        })

        if (this.eventNames.length === 0) {
            return
        }

        this.resolveSubscribtion(this.eventNames)
    }

    public addListener<T1>(eventName: string, listener: (data?: T1) => void) {
        this.$eventEmitter.addListener(eventName, listener)
    }

    public removeListener<T1>(eventName: string, listener: (data?: T1) => void) {
        this.$eventEmitter.removeListener(eventName, listener)
    }

    protected afterSubscribtion() {
        return
    }

    protected afterUnsubscribtion() {
        return
    }

    public unsubscribe() {
        if (!this.isSubscribed) {
            return
        }

        this.$eventNamesDisposer()
        this.$eventNamesDisposer = void 0
        this.resolveUnsubscribtion(this.eventNames)
        this.$eventEmitter.removeAllListeners()
    }

    protected resolveSubscribtion(events: string[]) {
        this.$eventListenerMap.clear()

        events.forEach(eventName => {
            this.$eventListenerMap.set(eventName, (data: T) => this.handleEvent(eventName, data))
            this.$transport.subscribe(eventName, this.$eventListenerMap.get(eventName))
        })

        this.afterSubscribtion()
        this.$eventEmitter.emit("subscribe")
    }

    protected resolveUnsubscribtion(events: string[]) {
        events.forEach(eventName => this.$transport.unsubscribe(eventName, this.$eventListenerMap.get(eventName)))
        this.$eventListenerMap.clear()
        this.afterUnsubscribtion()
        this.$eventEmitter.emit("unsubscribe")
    }
}


@autobind
export abstract class AbsctractSingleSubscription<T = Json> extends AbstractBaseSubscription<T> {

    protected abstract eventName: string

    protected abstract eventHandle(data: {}): void

    @computed
    protected get eventNames() {
        return this.eventName ? [this.eventName] : []
    }

    protected handleEvent(event: string, data: T) {
        if (event === this.eventName) {
            this.eventHandle(data)
        }
    }
}


@autobind
export abstract class AbstractMultiSubscription<T = Json> extends AbstractBaseSubscription<T> {

    protected abstract eventHandleMap: Map<string, BaseListener<any>> | void

    @computed
    protected get eventNames() {
        return this.eventHandleMap ? Array.from(this.eventHandleMap.keys()) : []
    }

    protected handleEvent(event: string, data: any) {
        if (!this.eventHandleMap) {
            return
        }

        if (this.eventHandleMap.has(event)) {
            this.eventHandleMap.get(event)(data, event)
        }
    }
}
