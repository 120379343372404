import * as ReduxActions from "redux-actions"

export const REMOVE_GROUP = "bums/common/report/edit/action/REMOVE_GROUP"

export type RemoveGroup = ReduxActions.Action<{
    key: string
}>

export function removeGroup(key: string): RemoveGroup {
    return {
        type: REMOVE_GROUP,
        payload: {
            key
        }
    }
}
