import Bluebird from "bluebird";

import {LockStore} from "src/lib/utils/LockStore";

export class PromisesStorage {

    constructor(private locks: LockStore) {
    }

    public after<T>(name: string, ready: () => Bluebird<T> | T): Bluebird<T> {
        return Bluebird.try(async () => {
            const release = await this.locks.get(name).exclusive().catch((e) => { throw e })
            try {
                return await ready()
            } catch (e) {
                throw e
            } finally {
                release()
            }
        }).catch((e) => { throw e })
    }
}
