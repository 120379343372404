/* tslint:disable */
import * as H from "../metadataHelper"
import {RelationLinkMetadata} from "./relationLinkMetadata"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"

export interface RelationLink extends H.BaseEntity {
    placeholder?: string; // Текст подсказки
    relationType?: string; // Тип связи
    representation?: string; // Представление
    data?: string; // Данные
    subject?: H.BaseEntity; // Базовая сущность
    origin?: string; // Оригинал
    target?: H.BaseEntity; // Целевая сущность
    isTargetVisible?: boolean; // Видимость цели
    isSubjectReadableForMentionUser?: boolean; // Читаема ли сущность для упомянутого пользователя
    metadata?: RelationLinkMetadata;
    id?: string; // Идентификатор
    contentType: "RelationLink"; // Object type
}

export module RelationLink {
    export const contentType: "RelationLink" = "RelationLink";
    export const endpoint = "/api/v3/relationLink";

    export const newObject: RelationLink = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const placeholder = {contentType: "StringField" as "StringField", name: "placeholder", isMultiple: false, isSortable: false, id: "RelationLink.placeholder", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const relationType = {contentType: "StringField" as "StringField", name: "relationType", isMultiple: false, isSortable: false, id: "RelationLink.relationType", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const representation = {contentType: "StringField" as "StringField", name: "representation", isMultiple: false, isSortable: false, id: "RelationLink.representation", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const data = {contentType: "StringField" as "StringField", name: "data", isMultiple: false, isSortable: false, id: "RelationLink.data", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const subject = {contentType: "RefLinkField" as "RefLinkField", name: "subject", isMultiple: false, isSortable: false, id: "RelationLink.subject", refContentType: H.List(["BaseEntity"])};
        export const origin = {contentType: "StringField" as "StringField", name: "origin", isMultiple: false, isSortable: false, id: "RelationLink.origin", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const target = {contentType: "RefLinkField" as "RefLinkField", name: "target", isMultiple: false, isSortable: false, id: "RelationLink.target", refContentType: H.List(["BaseEntity"])};
        export const isTargetVisible = {contentType: "BoolField" as "BoolField", name: "isTargetVisible", isMultiple: false, isSortable: false, id: "RelationLink.isTargetVisible", defaultValue: null as BoolField["defaultValue"]};
        export const isSubjectReadableForMentionUser = {contentType: "BoolField" as "BoolField", name: "isSubjectReadableForMentionUser", isMultiple: false, isSortable: false, id: "RelationLink.isSubjectReadableForMentionUser", defaultValue: null as BoolField["defaultValue"]};
        export const metadata = {contentType: "RefLinkField" as "RefLinkField", name: "metadata", isMultiple: false, isSortable: false, id: "RelationLink.metadata", refContentType: H.List(["RelationLinkMetadata"])};
    }
}

export function isRelationLink(arg: any): arg is RelationLink {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "RelationLink"
}
