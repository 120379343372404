export interface Transport {
    trackScreen(name: string): void
    trackEvent(type: string, key: string, value?: string, channels?: string[]): void
    trackTiming(type: string, name: string, time: number): void
    trackPurchase(event: PurchaseEvent): void
}

export class TransportAggregate implements Transport {
    constructor(private inner: Array<Transport>) {}

    trackEvent(type: string, key: string, value?: string, channels?: string[]): void {
        this.inner.forEach(inner => inner.trackEvent(type, key, value, channels))
    }

    trackScreen(name: string): void {
        this.inner.forEach(inner => inner.trackScreen(name))
    }

    trackTiming(type: string, name: string, time: number): void {
        this.inner.forEach(inner => inner.trackTiming(type, name, time))
    }

    trackPurchase(event: PurchaseEvent): void {
        this.inner.forEach(inner => inner.trackPurchase(event))
    }
}

interface AddToCart {
    key: "add_to_cart"
    quantity: number
    item_id: string
    value: number
    price: number
    currency: string
}

interface EcommercePurchase {
    key: "ecommerce_purchase"
    currency: string
    value: number
    transaction_id: string
    item_id: string
}

interface EcommercePurchaseFail {
    key: "ecommerce_purchase_fail"
    currency: string
    value: number
    transaction_id: string
    item_id: string
}

interface ViewItemList {
    key: "view_item_list"
}

interface ViewItem {
    key: "view_item"
    item_id: string
}

export type PurchaseEvent = AddToCart | EcommercePurchase | EcommercePurchaseFail | ViewItemList | ViewItem

export function isEcomercePurchase(arg: PurchaseEvent): arg is EcommercePurchase {
    return Boolean(arg) && typeof arg === "object" && arg.key === "ecommerce_purchase"
}
