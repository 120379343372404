import {createAtom, IAtom} from "mobx"
import {autobind} from "core-decorators"
import {browserHistory} from "react-router"

declare var window: Window & {
    ReactRouterFound: boolean
}

@autobind
export class WindowLocation {

    private $atom: IAtom

    private reactRouterFoundListen: () => void

    constructor() {
        this.$atom = createAtom(
            "Observable window location",
            () => this.subscribe(),
            () => this.unsubscribe()
        )
    }

    private subscribe() {
        if (window.ReactRouterFound) {
            this.reactRouterFoundListen = browserHistory.listen(this.changeHandler)
        } else {
            window.addEventListener("popstate", this.changeHandler)
            window.addEventListener("hashchange", this.changeHandler)
        }
    }

    private unsubscribe() {
        if (this.reactRouterFoundListen) {
            this.reactRouterFoundListen()
            this.reactRouterFoundListen = void 0
        }
        window.removeEventListener("popstate", this.changeHandler)
        window.removeEventListener("hashchange", this.changeHandler)
    }

    private changeHandler() {
        this.$atom.reportChanged()
    }

    public get() {
        this.$atom.reportObserved()
        return window.location
    }
}
