export const notificationSound = "notification"
export const videocallIncomingSound = "videocall_incoming"

export type DocumentVisibilityListener = (visible: boolean) => void
export type SoundVariable = typeof notificationSound | typeof videocallIncomingSound

export interface DocumentVisibility {
    subscribe(listener: DocumentVisibilityListener): void
    unsubscribe(listener: DocumentVisibilityListener): void
    visible: boolean
}

export interface FaviconManager {
    inc(): void
    reset(): void
}

export interface FaviconChanger {
    badge(counter: number | string): void
    reset(): void
}

export interface SoundPlayer {
    play(type: SoundVariable): void
    stop(type: SoundVariable): void
}

export class SoundPlayerImplement implements SoundPlayer {
    play(type: SoundVariable) {
        return
    }

    stop(type: SoundVariable) {
        return
    }
}
