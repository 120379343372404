import {Json} from "src/lib/types"
import {SocketTransport, BaseListener, BroadcastMessage} from "./types"

/**
 * Класс заглушка
 */
export class Ecomet implements SocketTransport {

    public broadcast<T = Json>(message: BroadcastMessage): void {
        return
    }

    public subscribe<T = Json>(subscription: string, listener: BaseListener<T>) {
        return
    }

    public unsubscribe<T = Json>(subscription: string, listener: BaseListener<T>) {
        return
    }

    public unsubscribeAll() {
        return
    }
}
