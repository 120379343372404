/* tslint:disable */
import * as H from "../metadataHelper"
import {Task} from "./task"
import {ProjectRights} from "./projectRights"
import {InteractionCounter} from "./interactionCounter"
import {Employee} from "./employee"
import {Group} from "./group"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {DateOnly} from "./dateOnly"
import {ShiftDate} from "./shiftDate"
import {Reminder} from "./reminder"
import {File} from "./file"
import {RelationLink} from "./relationLink"
import {FileGroup} from "./fileGroup"
import {Milestone} from "./milestone"
import {Deal} from "./deal"
import {DeadlineChangeRequest} from "./deadlineChangeRequest"
import {Comment} from "./comment"
import {Tag} from "./tag"
import {FinOperation} from "./finOperation"
import {AttachesInfo} from "./attachesInfo"
import {Todo} from "./todo"
import {EnumField} from "./enumField"
import {IntegerField} from "./integerField"
import {StringField} from "./stringField"
import {BoolField} from "./boolField"
import {DateTimeField} from "./dateTimeField"
import {DurationField} from "./durationField"

export interface Project extends H.BaseEntity {
    [fieldName: string]: any;
    status?: Project.Status; // Статус
    tasks?: H.List<Project | Task>;
    tasksCount?: number;
    superProject?: Project;
    subProjects?: H.List<Project>;
    subProjectsCount?: number;
    rights?: ProjectRights; // Список возможных действий
    possibleActions?: H.List<string>; // Список возможных действий (Устаревшее)
    parents?: H.List<Project>;
    parent?: Project;
    issues?: H.List<Project | Task>;
    issuesCount?: number;
    actualIssuesCount?: number;
    messagesCount?: number;
    interactionsCounters?: H.List<InteractionCounter>;
    humanNumber?: number; // Человекочитаемый номер
    name?: string; // Название
    subject?: string; // Описание задачи
    isTemplate?: boolean; // Является шаблоном
    originalTemplate?: Project; // Оригинальный шаблон
    templateUsers?: H.List<Employee | Group>; // Пользователи шаблона
    templateUsersCount?: number; // Количество пользователей шаблона
    isTemplateOwnerCurrentUser?: boolean; // Владелец текущий пользователь
    owner?: Employee; // Владелец (Создатель)
    responsible?: ContractorCompany|ContractorHuman|Employee|Group; // Ответственный
    deadline?: DateOnly|Date|ShiftDate; // Дата дедлайна
    deadlineReminders?: H.List<Reminder>; // Напоминания о неизбежном дедлайне
    deadlineRemindersCount?: number; // Количество напоминаний о дедлайне
    isOverdue?: boolean; // Просрочена
    activity?: Date; // Дата активности
    auditors?: H.List<ContractorCompany | ContractorHuman | Employee | Group>; // Аудиторы
    auditorsCount?: number; // Количество аудиторов
    executors?: H.List<ContractorCompany | ContractorHuman | Employee | Group>; // Соисполнители
    executorsCount?: number; // Количество соисполнителей
    participants?: H.List<ContractorCompany | ContractorHuman | Employee | Group>; // Участники
    participantsCount?: number; // Количество участников
    completed?: number; // Выполнено
    attaches?: H.List<File>; // Вложения
    attachesCount?: number; // Количество вложений
    statement?: string; // Текст задачи
    textStatement?: string; // Человекочитаемый текст задачи
    actualFinish?: Date; // Дата финиша задачи (закрытия)
    plannedFinish?: Date; // Планируемое время окончания
    duration?: H.DateInterval; // Планируемая длительность задачи
    responsibleCanEditExtFields?: boolean; // Ответственные могут редактировать расширенные поля
    executorsCanEditExtFields?: boolean; // Соисполнители могут редактировать расширенные поля
    auditorsCanEditExtFields?: boolean; // Аудиторы могут редактировать расширенные поля
    actualWork?: H.DateInterval; // Актуальное время в мс
    relationLinks?: H.List<RelationLink>; // Связанные ссылки
    relationLinksCount?: number; // Количество связанных ссылок
    links?: H.List<RelationLink>; // Связанные ссылки
    linksCount?: number; // Количество связанных ссылок
    allFiles?: H.List<File | FileGroup>; // Список вложений
    allFilesCount?: number; // Количество вложений
    milestones?: H.List<Milestone>; // Вехи
    milestonesCount?: number; // Количество вех
    deals?: H.List<Deal>; // Сделки
    dealsCount?: number; // Количество сделок
    actualDealsCount?: number; // Количество актуальных сделок
    plannedWork?: H.DateInterval; // Планируемое время в мс
    deadlineChangeRequest?: DeadlineChangeRequest; // Запрос на изменение дедлайна
    contractor?: ContractorCompany|ContractorHuman; // Заказчик
    timeCreated?: Date; // Дата создания задачи (только чтение)
    actualStart?: Date; // Дата старта задачи (создания или принятия)
    parentsCount?: number; // Количество родительских сущностей
    statusChangeTime?: Date; // Время последнего изменения статуса
    entitiesByTemplate?: H.List<Project>; // Сущности по шаблону
    entitiesByTemplateCount?: number; // Количество сущностей по шаблону
    actualEntitiesByTemplateCount?: number; // Актуальное количество сущностей по шаблону
    isFavorite?: boolean; // Избранный
    lastComment?: Comment; // Последний комментарий
    lastCommentTimeCreated?: Date; // Дата последнего комментария
    commentsWithoutTransportCount?: number; // Количество комментариев без транспорта
    emailsCount?: number; // Количество емейлов
    whatsappCount?: number; // Количество сообщений whatsapp
    telegramCount?: number; // Количество сообщений telegram
    instagramCount?: number; // Количество сообщений instagram
    editableFields?: H.List<string>; // Поля доступные для редактирования
    id?: string; // Идентификатор
    unreadCommentsCount?: number; // Количество непрочитанных комментариев
    attachesCountInComments?: number; // Количество вложений в комментариях
    subscribed?: boolean; // Подписан ли текущий пользователь на комментарии по этой сущности
    possibleSubscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники сущности, которые могут получать уведомления по ней.
    possibleSubscribersCount?: number; // Количество возможных подписчиков
    subscribers?: H.List<ContractorCompany | ContractorHuman | Employee>; // Пользователи-участники, которые подписаны на уведомления по сущности.
    subscribersCount?: number; // Количество подписчиков
    comments?: H.List<Comment>; // Массив комментариев
    commentsCount?: number; // Количество комментариев
    hiddenCommentsCount?: number; // Количество скрытых комментариев
    isUnread?: boolean; // Помечен как непрочитанный
    firstUnreadComment?: Comment; // Первый непрочитанный комментарий
    unreadAnswer?: boolean; // Есть ли непрочитанный ответ или упоминание
    lastView?: Date; // Дата последнего просмотра
    tags?: H.List<Tag>; // Метки
    tagsCount?: number; // Количество меток
    reminderTime?: Date; // Дата напоминания
    financeOperations?: H.List<FinOperation>; // Финансовые операции
    financeOperationsCount?: number; // Количество финансовых операций
    attachesInfo?: AttachesInfo; // Информация о вложении
    todos?: H.List<Todo>; // Массив дел
    todosCount?: number; // Количество дел
    actualTodosCount?: number; // Количество актуальных дел
    finishedTodosCount?: number; // Количество завершенных дел
    contentType: "Project"; // Object type
}

export module Project {
    export const contentType: "Project" = "Project";
    export const endpoint = "/api/v3/project";
    export type Status = "accepted"|
        "assigned"|
        "cancelled"|
        "completed"|
        "created"|
        "delayed"|
        "done"|
        "expired"|
        "overdue"|
        "rejected"|
        "template";
    export module Status {
        export const accepted: Status = "accepted";
        export const assigned: Status = "assigned";
        export const cancelled: Status = "cancelled";
        export const completed: Status = "completed";
        export const created: Status = "created";
        export const delayed: Status = "delayed";
        export const done: Status = "done";
        export const expired: Status = "expired";
        export const overdue: Status = "overdue";
        export const rejected: Status = "rejected";
        export const template: Status = "template";
    }
    export const newObject: Project = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const status = {contentType: "EnumField" as "EnumField", name: "status", isMultiple: false, isSortable: true, id: "Project.status", defaultValue: null as EnumField["defaultValue"], enumValues: H.List([
            "accepted",
            "assigned",
            "cancelled",
            "completed",
            "created",
            "delayed",
            "done",
            "expired",
            "overdue",
            "rejected",
            "template"
        ])};
        export const tasks = {contentType: "RefLinkField" as "RefLinkField", name: "tasks", isMultiple: true, isSortable: false, id: "Project.tasks", refContentType: H.List(["Project", "Task"])};
        export const tasksCount = {contentType: "IntegerField" as "IntegerField", name: "tasksCount", isMultiple: false, isSortable: false, id: "Project.tasksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const superProject = {contentType: "RefLinkField" as "RefLinkField", name: "superProject", isMultiple: false, isSortable: false, id: "Project.superProject", refContentType: H.List(["Project"])};
        export const subProjects = {contentType: "RefLinkField" as "RefLinkField", name: "subProjects", isMultiple: true, isSortable: false, id: "Project.subProjects", refContentType: H.List(["Project"])};
        export const subProjectsCount = {contentType: "IntegerField" as "IntegerField", name: "subProjectsCount", isMultiple: false, isSortable: false, id: "Project.subProjectsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const rights = {contentType: "RefLinkField" as "RefLinkField", name: "rights", isMultiple: false, isSortable: false, id: "Project.rights", refContentType: H.List(["ProjectRights"])};
        export const possibleActions = {contentType: "StringField" as "StringField", name: "possibleActions", isMultiple: true, isSortable: false, id: "Project.possibleActions", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const parents = {contentType: "RefLinkField" as "RefLinkField", name: "parents", isMultiple: true, isSortable: false, id: "Project.parents", refContentType: H.List(["Project"])};
        export const parent = {contentType: "RefLinkField" as "RefLinkField", name: "parent", isMultiple: false, isSortable: false, id: "Project.parent", refContentType: H.List(["Project"])};
        export const issues = {contentType: "RefLinkField" as "RefLinkField", name: "issues", isMultiple: true, isSortable: false, id: "Project.issues", refContentType: H.List(["Project", "Task"])};
        export const issuesCount = {contentType: "IntegerField" as "IntegerField", name: "issuesCount", isMultiple: false, isSortable: false, id: "Project.issuesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const actualIssuesCount = {contentType: "IntegerField" as "IntegerField", name: "actualIssuesCount", isMultiple: false, isSortable: false, id: "Project.actualIssuesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const messagesCount = {contentType: "IntegerField" as "IntegerField", name: "messagesCount", isMultiple: false, isSortable: false, id: "Project.messagesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const interactionsCounters = {contentType: "RefLinkField" as "RefLinkField", name: "interactionsCounters", isMultiple: true, isSortable: false, id: "Project.interactionsCounters", refContentType: H.List(["InteractionCounter"])};
        export const humanNumber = {contentType: "IntegerField" as "IntegerField", name: "humanNumber", isMultiple: false, isSortable: true, id: "Project.humanNumber", defaultValue: null as IntegerField["defaultValue"]};
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: true, id: "Project.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const subject = {contentType: "StringField" as "StringField", name: "subject", isMultiple: false, isSortable: false, id: "Project.subject", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isTemplate = {contentType: "BoolField" as "BoolField", name: "isTemplate", isMultiple: false, isSortable: false, id: "Project.isTemplate", defaultValue: null as BoolField["defaultValue"]};
        export const originalTemplate = {contentType: "RefLinkField" as "RefLinkField", name: "originalTemplate", isMultiple: false, isSortable: false, id: "Project.originalTemplate", refContentType: H.List(["Project"])};
        export const templateUsers = {contentType: "RefLinkField" as "RefLinkField", name: "templateUsers", isMultiple: true, isSortable: false, id: "Project.templateUsers", refContentType: H.List(["Employee", "Group"])};
        export const templateUsersCount = {contentType: "IntegerField" as "IntegerField", name: "templateUsersCount", isMultiple: false, isSortable: false, id: "Project.templateUsersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isTemplateOwnerCurrentUser = {contentType: "BoolField" as "BoolField", name: "isTemplateOwnerCurrentUser", isMultiple: false, isSortable: false, id: "Project.isTemplateOwnerCurrentUser", defaultValue: null as BoolField["defaultValue"]};
        export const owner = {contentType: "RefLinkField" as "RefLinkField", name: "owner", isMultiple: false, isSortable: true, id: "Project.owner", refContentType: H.List(["Employee"])};
        export const responsible = {contentType: "RefLinkField" as "RefLinkField", name: "responsible", isMultiple: false, isSortable: true, id: "Project.responsible", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const deadline = {contentType: "RefLinkField" as "RefLinkField", name: "deadline", isMultiple: false, isSortable: true, id: "Project.deadline", refContentType: H.List(["DateOnly", "DateTime", "ShiftDate"])};
        export const deadlineReminders = {contentType: "RefLinkField" as "RefLinkField", name: "deadlineReminders", isMultiple: true, isSortable: false, id: "Project.deadlineReminders", refContentType: H.List(["Reminder"])};
        export const deadlineRemindersCount = {contentType: "IntegerField" as "IntegerField", name: "deadlineRemindersCount", isMultiple: false, isSortable: false, id: "Project.deadlineRemindersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isOverdue = {contentType: "BoolField" as "BoolField", name: "isOverdue", isMultiple: false, isSortable: true, id: "Project.isOverdue", defaultValue: null as BoolField["defaultValue"]};
        export const activity = {contentType: "DateTimeField" as "DateTimeField", name: "activity", isMultiple: false, isSortable: true, id: "Project.activity", defaultValue: null as DateTimeField["defaultValue"]};
        export const auditors = {contentType: "RefLinkField" as "RefLinkField", name: "auditors", isMultiple: true, isSortable: false, id: "Project.auditors", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const auditorsCount = {contentType: "IntegerField" as "IntegerField", name: "auditorsCount", isMultiple: false, isSortable: false, id: "Project.auditorsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const executors = {contentType: "RefLinkField" as "RefLinkField", name: "executors", isMultiple: true, isSortable: false, id: "Project.executors", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const executorsCount = {contentType: "IntegerField" as "IntegerField", name: "executorsCount", isMultiple: false, isSortable: false, id: "Project.executorsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const participants = {contentType: "RefLinkField" as "RefLinkField", name: "participants", isMultiple: true, isSortable: false, id: "Project.participants", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const participantsCount = {contentType: "IntegerField" as "IntegerField", name: "participantsCount", isMultiple: false, isSortable: false, id: "Project.participantsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const completed = {contentType: "IntegerField" as "IntegerField", name: "completed", isMultiple: false, isSortable: true, id: "Project.completed", defaultValue: null as IntegerField["defaultValue"]};
        export const attaches = {contentType: "RefLinkField" as "RefLinkField", name: "attaches", isMultiple: true, isSortable: false, id: "Project.attaches", refContentType: H.List(["File"])};
        export const attachesCount = {contentType: "IntegerField" as "IntegerField", name: "attachesCount", isMultiple: false, isSortable: false, id: "Project.attachesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const statement = {contentType: "StringField" as "StringField", name: "statement", isMultiple: false, isSortable: false, id: "Project.statement", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const textStatement = {contentType: "StringField" as "StringField", name: "textStatement", isMultiple: false, isSortable: true, id: "Project.textStatement", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const actualFinish = {contentType: "DateTimeField" as "DateTimeField", name: "actualFinish", isMultiple: false, isSortable: true, id: "Project.actualFinish", defaultValue: null as DateTimeField["defaultValue"]};
        export const plannedFinish = {contentType: "DateTimeField" as "DateTimeField", name: "plannedFinish", isMultiple: false, isSortable: true, id: "Project.plannedFinish", defaultValue: null as DateTimeField["defaultValue"]};
        export const duration = {contentType: "DurationField" as "DurationField", name: "duration", isMultiple: false, isSortable: true, id: "Project.duration", defaultValue: null as DurationField["defaultValue"]};
        export const responsibleCanEditExtFields = {contentType: "BoolField" as "BoolField", name: "responsibleCanEditExtFields", isMultiple: false, isSortable: false, id: "Project.responsibleCanEditExtFields", defaultValue: null as BoolField["defaultValue"]};
        export const executorsCanEditExtFields = {contentType: "BoolField" as "BoolField", name: "executorsCanEditExtFields", isMultiple: false, isSortable: false, id: "Project.executorsCanEditExtFields", defaultValue: null as BoolField["defaultValue"]};
        export const auditorsCanEditExtFields = {contentType: "BoolField" as "BoolField", name: "auditorsCanEditExtFields", isMultiple: false, isSortable: false, id: "Project.auditorsCanEditExtFields", defaultValue: null as BoolField["defaultValue"]};
        export const actualWork = {contentType: "DurationField" as "DurationField", name: "actualWork", isMultiple: false, isSortable: false, id: "Project.actualWork", defaultValue: null as DurationField["defaultValue"]};
        export const relationLinks = {contentType: "RefLinkField" as "RefLinkField", name: "relationLinks", isMultiple: true, isSortable: false, id: "Project.relationLinks", refContentType: H.List(["RelationLink"])};
        export const relationLinksCount = {contentType: "IntegerField" as "IntegerField", name: "relationLinksCount", isMultiple: false, isSortable: false, id: "Project.relationLinksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const links = {contentType: "RefLinkField" as "RefLinkField", name: "links", isMultiple: true, isSortable: false, id: "Project.links", refContentType: H.List(["RelationLink"])};
        export const linksCount = {contentType: "IntegerField" as "IntegerField", name: "linksCount", isMultiple: false, isSortable: false, id: "Project.linksCount", defaultValue: null as IntegerField["defaultValue"]};
        export const allFiles = {contentType: "RefLinkField" as "RefLinkField", name: "allFiles", isMultiple: true, isSortable: false, id: "Project.allFiles", refContentType: H.List(["File", "FileGroup"])};
        export const allFilesCount = {contentType: "IntegerField" as "IntegerField", name: "allFilesCount", isMultiple: false, isSortable: false, id: "Project.allFilesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const milestones = {contentType: "RefLinkField" as "RefLinkField", name: "milestones", isMultiple: true, isSortable: false, id: "Project.milestones", refContentType: H.List(["Milestone"])};
        export const milestonesCount = {contentType: "IntegerField" as "IntegerField", name: "milestonesCount", isMultiple: false, isSortable: false, id: "Project.milestonesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const deals = {contentType: "RefLinkField" as "RefLinkField", name: "deals", isMultiple: true, isSortable: false, id: "Project.deals", refContentType: H.List(["Deal"])};
        export const dealsCount = {contentType: "IntegerField" as "IntegerField", name: "dealsCount", isMultiple: false, isSortable: false, id: "Project.dealsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const actualDealsCount = {contentType: "IntegerField" as "IntegerField", name: "actualDealsCount", isMultiple: false, isSortable: false, id: "Project.actualDealsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const plannedWork = {contentType: "DurationField" as "DurationField", name: "plannedWork", isMultiple: false, isSortable: true, id: "Project.plannedWork", defaultValue: null as DurationField["defaultValue"]};
        export const deadlineChangeRequest = {contentType: "RefLinkField" as "RefLinkField", name: "deadlineChangeRequest", isMultiple: false, isSortable: false, id: "Project.deadlineChangeRequest", refContentType: H.List(["DeadlineChangeRequest"])};
        export const contractor = {contentType: "RefLinkField" as "RefLinkField", name: "contractor", isMultiple: false, isSortable: true, id: "Project.contractor", refContentType: H.List(["ContractorCompany", "ContractorHuman"])};
        export const timeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "timeCreated", isMultiple: false, isSortable: true, id: "Project.timeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const actualStart = {contentType: "DateTimeField" as "DateTimeField", name: "actualStart", isMultiple: false, isSortable: true, id: "Project.actualStart", defaultValue: null as DateTimeField["defaultValue"]};
        export const parentsCount = {contentType: "IntegerField" as "IntegerField", name: "parentsCount", isMultiple: false, isSortable: false, id: "Project.parentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const statusChangeTime = {contentType: "DateTimeField" as "DateTimeField", name: "statusChangeTime", isMultiple: false, isSortable: true, id: "Project.statusChangeTime", defaultValue: null as DateTimeField["defaultValue"]};
        export const entitiesByTemplate = {contentType: "RefLinkField" as "RefLinkField", name: "entitiesByTemplate", isMultiple: true, isSortable: false, id: "Project.entitiesByTemplate", refContentType: H.List(["Project"])};
        export const entitiesByTemplateCount = {contentType: "IntegerField" as "IntegerField", name: "entitiesByTemplateCount", isMultiple: false, isSortable: false, id: "Project.entitiesByTemplateCount", defaultValue: null as IntegerField["defaultValue"]};
        export const actualEntitiesByTemplateCount = {contentType: "IntegerField" as "IntegerField", name: "actualEntitiesByTemplateCount", isMultiple: false, isSortable: false, id: "Project.actualEntitiesByTemplateCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: true, id: "Project.isFavorite", defaultValue: null as BoolField["defaultValue"]};
        export const lastComment = {contentType: "RefLinkField" as "RefLinkField", name: "lastComment", isMultiple: false, isSortable: false, id: "Project.lastComment", refContentType: H.List(["Comment"])};
        export const lastCommentTimeCreated = {contentType: "DateTimeField" as "DateTimeField", name: "lastCommentTimeCreated", isMultiple: false, isSortable: true, id: "Project.lastCommentTimeCreated", defaultValue: null as DateTimeField["defaultValue"]};
        export const commentsWithoutTransportCount = {contentType: "IntegerField" as "IntegerField", name: "commentsWithoutTransportCount", isMultiple: false, isSortable: false, id: "Project.commentsWithoutTransportCount", defaultValue: null as IntegerField["defaultValue"]};
        export const emailsCount = {contentType: "IntegerField" as "IntegerField", name: "emailsCount", isMultiple: false, isSortable: false, id: "Project.emailsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const whatsappCount = {contentType: "IntegerField" as "IntegerField", name: "whatsappCount", isMultiple: false, isSortable: false, id: "Project.whatsappCount", defaultValue: null as IntegerField["defaultValue"]};
        export const telegramCount = {contentType: "IntegerField" as "IntegerField", name: "telegramCount", isMultiple: false, isSortable: false, id: "Project.telegramCount", defaultValue: null as IntegerField["defaultValue"]};
        export const instagramCount = {contentType: "IntegerField" as "IntegerField", name: "instagramCount", isMultiple: false, isSortable: false, id: "Project.instagramCount", defaultValue: null as IntegerField["defaultValue"]};
        export const editableFields = {contentType: "StringField" as "StringField", name: "editableFields", isMultiple: true, isSortable: false, id: "Project.editableFields", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const unreadCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "unreadCommentsCount", isMultiple: false, isSortable: false, id: "Project.unreadCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesCountInComments = {contentType: "IntegerField" as "IntegerField", name: "attachesCountInComments", isMultiple: false, isSortable: false, id: "Project.attachesCountInComments", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribed = {contentType: "BoolField" as "BoolField", name: "subscribed", isMultiple: false, isSortable: false, id: "Project.subscribed", defaultValue: null as BoolField["defaultValue"]};
        export const possibleSubscribers = {contentType: "RefLinkField" as "RefLinkField", name: "possibleSubscribers", isMultiple: true, isSortable: false, id: "Project.possibleSubscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const possibleSubscribersCount = {contentType: "IntegerField" as "IntegerField", name: "possibleSubscribersCount", isMultiple: false, isSortable: false, id: "Project.possibleSubscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const subscribers = {contentType: "RefLinkField" as "RefLinkField", name: "subscribers", isMultiple: true, isSortable: false, id: "Project.subscribers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee"])};
        export const subscribersCount = {contentType: "IntegerField" as "IntegerField", name: "subscribersCount", isMultiple: false, isSortable: false, id: "Project.subscribersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const comments = {contentType: "RefLinkField" as "RefLinkField", name: "comments", isMultiple: true, isSortable: false, id: "Project.comments", refContentType: H.List(["Comment"])};
        export const commentsCount = {contentType: "IntegerField" as "IntegerField", name: "commentsCount", isMultiple: false, isSortable: false, id: "Project.commentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const hiddenCommentsCount = {contentType: "IntegerField" as "IntegerField", name: "hiddenCommentsCount", isMultiple: false, isSortable: false, id: "Project.hiddenCommentsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const isUnread = {contentType: "BoolField" as "BoolField", name: "isUnread", isMultiple: false, isSortable: false, id: "Project.isUnread", defaultValue: null as BoolField["defaultValue"]};
        export const firstUnreadComment = {contentType: "RefLinkField" as "RefLinkField", name: "firstUnreadComment", isMultiple: false, isSortable: false, id: "Project.firstUnreadComment", refContentType: H.List(["Comment"])};
        export const unreadAnswer = {contentType: "BoolField" as "BoolField", name: "unreadAnswer", isMultiple: false, isSortable: false, id: "Project.unreadAnswer", defaultValue: null as BoolField["defaultValue"]};
        export const lastView = {contentType: "DateTimeField" as "DateTimeField", name: "lastView", isMultiple: false, isSortable: false, id: "Project.lastView", defaultValue: null as DateTimeField["defaultValue"]};
        export const tags = {contentType: "RefLinkField" as "RefLinkField", name: "tags", isMultiple: true, isSortable: true, id: "Project.tags", refContentType: H.List(["Tag"])};
        export const tagsCount = {contentType: "IntegerField" as "IntegerField", name: "tagsCount", isMultiple: false, isSortable: false, id: "Project.tagsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const reminderTime = {contentType: "DateTimeField" as "DateTimeField", name: "reminderTime", isMultiple: false, isSortable: false, id: "Project.reminderTime", defaultValue: null as DateTimeField["defaultValue"]};
        export const financeOperations = {contentType: "RefLinkField" as "RefLinkField", name: "financeOperations", isMultiple: true, isSortable: false, id: "Project.financeOperations", refContentType: H.List(["FinOperation"])};
        export const financeOperationsCount = {contentType: "IntegerField" as "IntegerField", name: "financeOperationsCount", isMultiple: false, isSortable: false, id: "Project.financeOperationsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const attachesInfo = {contentType: "RefLinkField" as "RefLinkField", name: "attachesInfo", isMultiple: false, isSortable: false, id: "Project.attachesInfo", refContentType: H.List(["AttachesInfo"])};
        export const todos = {contentType: "RefLinkField" as "RefLinkField", name: "todos", isMultiple: true, isSortable: false, id: "Project.todos", refContentType: H.List(["Todo"])};
        export const todosCount = {contentType: "IntegerField" as "IntegerField", name: "todosCount", isMultiple: false, isSortable: false, id: "Project.todosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const actualTodosCount = {contentType: "IntegerField" as "IntegerField", name: "actualTodosCount", isMultiple: false, isSortable: false, id: "Project.actualTodosCount", defaultValue: null as IntegerField["defaultValue"]};
        export const finishedTodosCount = {contentType: "IntegerField" as "IntegerField", name: "finishedTodosCount", isMultiple: false, isSortable: false, id: "Project.finishedTodosCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isProject(arg: any): arg is Project {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Project"
}
