import {Json} from "src/lib/types"

export const SubscriptionChannelName = "socketSubscriptions"
export const ResubscriptionEventName = "resubscription"
export const SubscriptionEventName = "subscription"
export const UnsubscriptionEventName = "unsubscription"
export const BroadcastEventName = "broadcast"
export const HandleEventName = "handle"
export const RemoveSubcriptionDelay = 1000
export const SocketStateSubscription = "socketStateSubscription"
export const SocketStateConnected = "socketConnected"
export const SocketStateDisconnected = "socketDisconnected"

export type SocketStateData = {state: typeof SocketStateConnected | typeof SocketStateDisconnected}

export type BaseListener<T = Json> = (data: T, event?: string) => void

export interface BasicMessage {
    auth?: {},
    type?: string,
}

export interface SubscriptionMessage extends BasicMessage {
    routes: string[]
}

export interface BroadcastMessage extends BasicMessage {
    message: string
    event: string
}

export interface SocketSubscription<T = Json> {
    subscribe(): void
    unsubscribe(): void
}

export interface SocketTransport {
    broadcast<T = Json>(message: BroadcastMessage): void
    subscribe<T = Json>(subscription: string, listener: BaseListener<T>): void
    unsubscribe<T = Json>(subscription: string, listener: BaseListener<T>): void
    unsubscribeAll(): void
}

export interface SocketEvent {
    subscriptions: string[]
}

export interface SocketDataEvent<T = Json> {
    subscriptions: string[]
    data?: T
}

export interface BaseSocketOptions {
    host?: string
    protocol?: string
    authData?: {[index: string]: string}
    header?: string
    useRecomet?: boolean
    mobile?: boolean
}

export interface SocketOptions extends BaseSocketOptions {
    queueDelay?: number
    reconnectDelay?: number
    onStatusChangeListener?: (state: "close" | "open" | "error") => void
    hostParam?: string
}


