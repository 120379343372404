import {PurchaseEvent, Transport} from "./Transport"

export class NopTransport implements Transport {
    trackEvent(type: string, key: string, value?: string): void {
        // nop
    }

    trackScreen(name: string): void {
        // nop
    }

    trackTiming(type: string, name: string, time: number): void {
        // nop
    }

    trackPurchase(event: PurchaseEvent): void {
        // nop
    }
}
