import * as ReduxActions from "redux-actions"
import * as Api from "src/lib/entities/api"

export const CHANGE_PERMISSION = "bums/common/report/edit/action/CHANGE_PERMISSION"

export type ChangePermission = ReduxActions.Action<{
    permission: Api.Group | Api.Employee
    isEditor: boolean
}>

export function changePermission(permission: Api.Group | Api.Employee, isEditor: boolean): ChangePermission {
    return {
        type: CHANGE_PERMISSION,
        payload: {
            permission,
            isEditor
        }
    }
}
