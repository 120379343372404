import {PlainRoute} from "react-router"


declare const window: Window & {setting_messengers_list: boolean}

export const settingsIntegrationsMessengersRoutes: PlainRoute[] = [
    {
        path: "/BumsSettings/Wazzup/*",
        onEnter(nextState, replace) {
            if (window.setting_messengers_list) {
                replace({pathname: "/settings/integrations/messengers/#whatsapp"});
            }
        },
        async getComponent() {
            return (await import("../../../lib/components")).CReloader
        }
    },
    {
        path: "/BumsSettings/Telegram/*",
        onEnter(nextState, replace) {
            if (window.setting_messengers_list) {
                replace({pathname: "/settings/integrations/messengers/#telegram"});
            }
        },
        async getComponent() {
            return (await import("../../../lib/components")).CReloader
        }
    },
    {
        path: "/BumsSettings/Instagram/*",
        onEnter(nextState, replace) {
            {
                if (window.setting_messengers_list) {
                    replace({pathname: "/settings/integrations/messengers/#instagram"});
                }
            }
        },
        async getComponent() {
            return (await import("../../../lib/components")).CReloader
        }
    },
]


export const settingsIntegrationsRoutes: PlainRoute[] = [
        ...settingsIntegrationsMessengersRoutes,
    {
        path: "/settings/integrations(/:section)(/:mailTab)",
        async getComponent() {
            return (await import("./containers/CPageContainer")).CPageContainer
        },
    },
    {
        path: "wazzup/connectRequest/",
        async getComponent() {
            return (await import("./containers/CPageContainer")).CPageContainer

        },
    },
    {
        path: "/settings/integrations/mail/",
        async getComponent() {
            return (await import("./mail/CMailList")).CMailList
        },
    },
    {
        path: "/settings/integrations/mail/(:type(template|newsletters))",
        async getComponent() {
            return (await import("./mail/CMailList")).CMailList
        },
    }
]
