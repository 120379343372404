import {TableReportData, BaseColumn, BaseRow, TableSortOrderBy} from "../entities"
import LoadState from "src/lib/utils/loadState"
import * as Actions from "./actions/index"
import {Tabs} from "./types"
import * as Collections from "src/lib/collections"
import {mergeChunks} from "./reportDataFormat"
import {ReportDisplaySetting} from "src/lib/entities/api"
import {handleActions} from "redux-actions"

export type State = ReportCardState

interface ReportCardState {
    loadingState: LoadState
    data: TableReportData
    nextOffset: number,
    hasMore: boolean,
    selectedTab: number
    expandedRowKeys: Array<string>
    orderBy: number
    sortColumn: string
    requiredReloadData: boolean
    filters: any
    edit: boolean
    pendingSettings: boolean
    settings: ReportDisplaySetting
}

/**
 * Начальное состояние
 */
const emptyReportCardState: ReportCardState = {
    data: {
        columns: Collections.List<BaseColumn>(),
        rows: Collections.List<BaseRow>(),
    },
    nextOffset: 0,
    hasMore: false,
    loadingState: LoadState.None(),
    expandedRowKeys: null,
    selectedTab: Tabs.TABLE,
    orderBy: TableSortOrderBy.ASC,
    sortColumn: "__first__column",
    requiredReloadData: false,
    filters: null,
    edit: false,
    pendingSettings: false,
    settings: null
}

/**
 * @param state
 * @param action
 * @returns {State}
 */
export const reducer = handleActions({
    [Actions.FETCH_DATA_START](state: State, action: Actions.FetchDataStartAction): State {
        const loadingState = LoadState.Pending()
        return Object.assign({}, state, {loadingState})
    },
    [Actions.FETCH_DATA_SUCCESS](state: State, action: Actions.FetchDataSuccessAction): State {
        const {data} = action.payload
        const loadingState = LoadState.Completed()
        const hasMore = (data.rows.length > 0) && (data.nextOffset > state.nextOffset)

        return Object.assign({}, state, {
            loadingState,
            data,
            nextOffset: data.nextOffset,
            hasMore,
            requiredReloadData: false,
            expandedRowKeys: null
        })
    },
    [Actions.FETCH_DATA_FAILED](state: State, action: Actions.FetchDataFailedAction): State {
        const {error} = action.payload
        const loadingState = LoadState.Error(error)
        return Object.assign({}, state, {loadingState, requiredReloadData: false})
    },
    [Actions.SWITCH_TAB](state: State, action: Actions.SwitchTabAction): State {
        const {selectedTab} = action.payload
        return Object.assign({}, state, {selectedTab})
    },
    [Actions.FETCH_DATA_CHUNK_SUCCESS](state: State, action: Actions.FetchDataChunkSuccessAction): State {
        const {data} = action.payload
        const chunk = data.rows
        const hasMore = (data.rows.length > 0) && (data.nextOffset > state.nextOffset)

        return Object.assign({}, state, {
            // перезапишем поле rows в data
            data: Object.assign({}, state.data, {rows: mergeChunks(state.data.rows, chunk)}),
            nextOffset: data.nextOffset,
            hasMore,
            requiredReloadData: false
        })
    },
    [Actions.TABLE_EXPANDED_KEYS](state: State, action: Actions.ExpandedKeysAction): State {
        const {keys} = action.payload
        return Object.assign({}, state, {expandedRowKeys: keys})
    },
    [Actions.SWITCH_SORT_COLUMN](state: State, action: Actions.SwitchSortColumn): State {
        const {sortColumn} = action.payload
        const orderBy = sortColumn === state.sortColumn ?
            state.orderBy === TableSortOrderBy.ASC ? TableSortOrderBy.DESC : TableSortOrderBy.ASC
            : TableSortOrderBy.ASC
        return Object.assign({}, state, {sortColumn: sortColumn, orderBy: orderBy, requiredReloadData: true})
    },
    [Actions.SET_REPORT_FILTERS](state: State, action: Actions.ReportFilters): State {
        const {filters} = action.payload
        return Object.assign({}, state, {filters: filters, requiredReloadData: true})
    },
    [Actions.SWITCH_CHART_SETTINGS_FLAG](state: State, action: Actions.SwitchChartSettingsFlag): State {
        const {edit} = action.payload
        return Object.assign({}, state, {edit: edit})
    },
    [Actions.FETCH_REPORT_TEMPLATE_START](state: State, action: Actions.FetchReportTemplateStartAction): State {
        return Object.assign({}, state, {pendingSettings: true})
    },
    [Actions.FETCH_REPORT_TEMPLATE_SUCCESS](state: State, action: Actions.FetchReportTemplateSuccessAction): State {
        const {settings} = action.payload
        return Object.assign({}, state, {pendingSettings: false, settings})
    },
    [Actions.SET_REPORT_SORT_COLUMN](state: State, action: Actions.SetSortColumn): State {
        const defaultSortColumn = action.payload.sortColumn
        let orderBy = TableSortOrderBy.ASC
        let sortColumn = "__first__column"
        if (defaultSortColumn) {
            if (defaultSortColumn.slice(0, 1) === "-") {
                orderBy = TableSortOrderBy.DESC
                sortColumn = defaultSortColumn.slice(1, defaultSortColumn.length)
            } else {
                orderBy = TableSortOrderBy.ASC
                sortColumn = defaultSortColumn
            }
        }
        return Object.assign({}, state, {sortColumn, orderBy})
    }
}, emptyReportCardState)

