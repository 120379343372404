/* tslint:disable */
import * as H from "../metadataHelper"
import {ProgramState} from "./programState"
import {ProgramTransition} from "./programTransition"
import {ProgramTrigger} from "./programTrigger"
import {DealShortCardSettings} from "./dealShortCardSettings"
import {ProgramRole} from "./programRole"
import {SortField} from "./sortField"
import {BpmSettings} from "./bpmSettings"
import {ProgramGroup} from "./programGroup"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"

export interface Program extends H.BaseEntity {
    name?: string; // Название схемы
    states?: H.List<ProgramState>; // Список статусов схемы
    statesCount?: number; // Количество статусов схемы
    transitions?: H.List<ProgramTransition>; // Список переходов между статусами
    transitionsCount?: number; // Количество переходов между статусами
    triggers?: H.List<ProgramTrigger>; // Список сценариев схемы
    triggersCount?: number; // Количество сценариев схемы
    dealShortCardSettings?: DealShortCardSettings;
    userDealShortCardSettings?: DealShortCardSettings;
    roles?: H.List<ProgramRole>; // Роли программы
    rolesCount?: number; // Количество ролей программы
    fixedFields?: H.List<string>; // Закрепленные поля
    orderPositionsFields?: H.List<string>; // Поля позиций в заказе
    orderPositionsSortField?: SortField; // Сортировка позиций по полю
    possibleActions?: H.List<Program.PossibleActions>; // Список возможных действий
    isBpm?: boolean; // Является ли программа бизнес процессом
    bmpSettings?: BpmSettings;
    programGroup?: ProgramGroup;
    id?: string; // Идентификатор
    contentType: "Program"; // Object type
}

export module Program {
    export const contentType: "Program" = "Program";
    export const endpoint = "/api/v3/program";
    export type PossibleActions = "can_create"|"can_export";
    export module PossibleActions {
        export const can_create: PossibleActions = "can_create";
        export const can_export: PossibleActions = "can_export";
    }
    export const newObject: Program = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Program.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const states = {contentType: "RefLinkField" as "RefLinkField", name: "states", isMultiple: true, isSortable: false, id: "Program.states", refContentType: H.List(["ProgramState"])};
        export const statesCount = {contentType: "IntegerField" as "IntegerField", name: "statesCount", isMultiple: false, isSortable: false, id: "Program.statesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const transitions = {contentType: "RefLinkField" as "RefLinkField", name: "transitions", isMultiple: true, isSortable: false, id: "Program.transitions", refContentType: H.List(["ProgramTransition"])};
        export const transitionsCount = {contentType: "IntegerField" as "IntegerField", name: "transitionsCount", isMultiple: false, isSortable: false, id: "Program.transitionsCount", defaultValue: null as IntegerField["defaultValue"]};
        export const triggers = {contentType: "RefLinkField" as "RefLinkField", name: "triggers", isMultiple: true, isSortable: false, id: "Program.triggers", refContentType: H.List(["ProgramTrigger"])};
        export const triggersCount = {contentType: "IntegerField" as "IntegerField", name: "triggersCount", isMultiple: false, isSortable: false, id: "Program.triggersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const dealShortCardSettings = {contentType: "RefLinkField" as "RefLinkField", name: "dealShortCardSettings", isMultiple: false, isSortable: false, id: "Program.dealShortCardSettings", refContentType: H.List(["DealShortCardSettings"])};
        export const userDealShortCardSettings = {contentType: "RefLinkField" as "RefLinkField", name: "userDealShortCardSettings", isMultiple: false, isSortable: false, id: "Program.userDealShortCardSettings", refContentType: H.List(["DealShortCardSettings"])};
        export const roles = {contentType: "RefLinkField" as "RefLinkField", name: "roles", isMultiple: true, isSortable: false, id: "Program.roles", refContentType: H.List(["ProgramRole"])};
        export const rolesCount = {contentType: "IntegerField" as "IntegerField", name: "rolesCount", isMultiple: false, isSortable: false, id: "Program.rolesCount", defaultValue: null as IntegerField["defaultValue"]};
        export const fixedFields = {contentType: "StringField" as "StringField", name: "fixedFields", isMultiple: true, isSortable: false, id: "Program.fixedFields", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const orderPositionsFields = {contentType: "StringField" as "StringField", name: "orderPositionsFields", isMultiple: true, isSortable: false, id: "Program.orderPositionsFields", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const orderPositionsSortField = {contentType: "RefLinkField" as "RefLinkField", name: "orderPositionsSortField", isMultiple: false, isSortable: false, id: "Program.orderPositionsSortField", refContentType: H.List(["SortField"])};
        export const possibleActions = {contentType: "EnumField" as "EnumField", name: "possibleActions", isMultiple: true, isSortable: false, id: "Program.possibleActions", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["can_create", "can_export"])};
        export const isBpm = {contentType: "BoolField" as "BoolField", name: "isBpm", isMultiple: false, isSortable: false, id: "Program.isBpm", defaultValue: null as BoolField["defaultValue"]};
        export const bmpSettings = {contentType: "RefLinkField" as "RefLinkField", name: "bmpSettings", isMultiple: false, isSortable: false, id: "Program.bmpSettings", refContentType: H.List(["BpmSettings"])};
        export const programGroup = {contentType: "RefLinkField" as "RefLinkField", name: "programGroup", isMultiple: false, isSortable: false, id: "Program.programGroup", refContentType: H.List(["ProgramGroup"])};
    }
}

export function isProgram(arg: any): arg is Program {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Program"
}
