import fetch, {FetchError} from "src/lib/utils/fetch"
import * as ErrorStackParser from "error-stack-parser"

export function onError(previousHandler: Function) {
    return function(message: string, filename?: string, lineno?: number, colno?: number, error?: Error) {
        if (!error || !error.stack || !error.stack.includes("/spa/")) {
            return previousHandler.apply(this, arguments);
        }
        return handleError(error)
    }
}

function handleError(error: Error) {
    if (error instanceof FetchError) {
        return true;
    }
    let stack = null
    try {
        stack = ErrorStackParser.parse(error);
    } catch (e) {
        // ignore stack parse error
    }
    void fetch("/error/report/megaspa", {
        method: "POST",
        bodyEntity: Object.assign({message: error.message}, error, {stack}),
        headers: {
            "Content-Type": "application/json"
        }
    });
    return true;
}
