/**
 * Это генерируемый файл. Все изменения, которые ты внесешь руками -- затрутся
 *
 * Что бы добавить иконку:
 * - убедись что её ещё нет (это обычные графические файлы -- их можно смотреть глазами)
 * - скачай и закинь иконку в папку icons
 * - запусти ./scripts/buildIconFont.js или из корня make front-icon-font
 */
export enum CIconType {
    ACCESS_TIME = 59905,
    ACCOUNT_BALANCE = 59906,
    ACCOUNT_QUESTION = 59907,
    ADD = 59908,
    ADD_CIRCLE = 59909,
    ADD_CIRCLE_OUTLINE = 59910,
    ADD_SHOPPING_CART = 59911,
    ADD_TASK = 59912,
    ADD_TO_PHOTOS = 59913,
    ALARM = 59914,
    ALERT_CIRCLE = 59915,
    ALL_FIELDS = 59916,
    ALL_INFO = 59917,
    ARCHIVE_OUTLINE = 59918,
    ARROW_BACK = 59919,
    ARROW_CIRCLE = 59920,
    ARROW_DOWNWARD = 59921,
    ARROW_DROP_DOWN = 59922,
    ARROW_DROP_RIGHT = 59923,
    ARROW_DROP_UP = 59924,
    ARROW_EXPAND = 59925,
    ARROW_EXPAND_IN = 59926,
    ARROW_FORWARD = 59927,
    ARROW_RIGHT_ALT = 59928,
    ARROW_UPWARD = 59929,
    ASSIGNMENT = 59930,
    ASSINGMENT_TURNED = 59931,
    AT = 59932,
    AT_SIGN_CHAT = 59933,
    ATTACH = 59934,
    ATTACH_CHAT = 59935,
    BAR_CHART = 59936,
    BAR_TIME_CHART = 59937,
    BASED_ON = 59938,
    BASELINE_PEOPLE_OUTLINE = 59939,
    BEACH_ACCESS = 59940,
    BLOCK = 59941,
    BOLD = 59942,
    BRUSH = 59943,
    BULLET_LIST = 59944,
    BUSINESS_CENTER = 59945,
    CAKE = 59946,
    CALENDAR = 59947,
    CALL_DECLINE = 59948,
    CALL_MERGE = 59949,
    CANCEL = 59950,
    CANCEL_CIRCLE = 59951,
    CARD = 59952,
    CARD_SETTINGS = 59953,
    CHANGE = 59954,
    CHARITY = 59955,
    CHAT = 59956,
    CHAT_LINK = 59957,
    CHAT_OUTLINED = 59958,
    CHECK_ALL_SUB = 59959,
    CHECKBOX = 59960,
    CHECKBOX_BLANK = 59961,
    CLEAR = 59962,
    CLEAR_ALARM = 59963,
    CLEAR_ALL = 59964,
    CLEAR_INPUT = 59965,
    CLOCK_CHECK = 59966,
    CODE = 59967,
    COLLAPSE = 59968,
    COMMENT_TEXT_MULTIPLE = 59969,
    COMPUTER = 59970,
    CONFIRM = 59971,
    CONFIRM_CIRCLE = 59972,
    CONTACTS = 59973,
    CONTRACTOR_LINK = 59974,
    COPY = 59975,
    CREDIT_CARD = 59976,
    CROWN = 59977,
    DATA_DOWNLOAD = 59978,
    DATE_RANGE = 59979,
    DEAL_LINK = 59980,
    DEALS = 59981,
    DELETE = 59982,
    DELETE_FOREVER = 59983,
    DELIVERY = 59984,
    DESCRIPTION = 59985,
    DEVELOPER_BOARD = 59986,
    DIAGRAM = 59987,
    DIRECTIONS_RUN = 59988,
    DOMAIN_BLACK = 59989,
    DONE_ALL = 59990,
    DOWNLOAD = 59991,
    DRAG_INDICATOR = 59992,
    EDIT = 59993,
    EMOJI_FOOD_BEVERAGE = 59994,
    EMOJI_OBJECTS = 59995,
    EMPTY_FIELDS = 59996,
    ERROR_OUTLINE = 59997,
    EVENT_AVAILABLE = 59998,
    EVENT_BUSINESS = 59999,
    EVENT_PERSON = 60000,
    EXCEL = 60001,
    EXIT = 60002,
    EXTENSION = 60003,
    EXTRAFIELDS = 60004,
    EYE = 60005,
    EYE_OUTLINE = 60006,
    FACEBOOK = 60007,
    FACEBOOK_BLACK = 60008,
    FAST_FORWARD = 60009,
    FAVORITE = 60010,
    FAVORITE_BORDER = 60011,
    FAVOURITE = 60012,
    FAVOURITE_OUTLINED = 60013,
    FEEDBACK = 60014,
    FILE = 60015,
    FILE_AI = 60016,
    FILE_ARCHIVE = 60017,
    FILE_COPY = 60018,
    FILE_DOCUMENT = 60019,
    FILE_EXCEL = 60020,
    FILE_GIF = 60021,
    FILE_IMAGE = 60022,
    FILE_MUSIC = 60023,
    FILE_PDF = 60024,
    FILE_PPOINT = 60025,
    FILE_PSD = 60026,
    FILE_SKETCH = 60027,
    FILE_UNKNOWN = 60028,
    FILE_VIDEO = 60029,
    FILTER = 60030,
    FILTER_ADD = 60031,
    FILTER_BLOCK = 60032,
    FILTER_DEL = 60033,
    FINANCE = 60034,
    FIRE = 60035,
    FIRED = 60036,
    FLAG = 60037,
    FLASH = 60038,
    FLIP_TO_BACK = 60039,
    FOLDER_DELETE = 60040,
    FOLDER_OPEN = 60041,
    FORMAT_PAINT = 60042,
    FORUM = 60043,
    FORWARD = 60044,
    FUNNEL = 60045,
    GOOGLE_CALENDAR = 60046,
    GROUP = 60047,
    GROUP_ADD = 60048,
    GROUPS = 60049,
    HASHTAG = 60050,
    HEADLINE = 60051,
    HELP = 60052,
    HIERARCHY = 60053,
    HISTORY = 60054,
    HYPERLINK = 60055,
    IN_WORK = 60056,
    INBOX = 60057,
    INFO_OUTLINE = 60058,
    INSERT_CHART = 60059,
    INSERT_EMOJI = 60060,
    INSTAGRAM = 60061,
    ITALIC = 60062,
    KANBAN_SELECT_MENU = 60063,
    KEYBOARD_ARROW_DOWN = 60064,
    KEYBOARD_ARROW_LEFT = 60065,
    KEYBOARD_ARROW_RIGHT = 60066,
    KEYBOARD_ARROW_UP = 60067,
    KEYBOARD_RETURN = 60068,
    LABEL_OUTLINE = 60069,
    LIBRARY_BOOKS = 60070,
    LINE_CHART = 60071,
    LINK = 60072,
    LIST = 60073,
    LIST_ALT = 60074,
    LIST_ALT_CROP = 60075,
    LIST_BULLETED = 60076,
    LIST_NUMBERED = 60077,
    LIST_TABLE = 60078,
    LIVE_HELP = 60079,
    LOCK = 60080,
    LOCK_OPEN = 60081,
    LOCK_OUTLINE = 60082,
    LOCK_OUTLINE_OPEN = 60083,
    LOGO = 60084,
    MAIL = 60085,
    MAKE_COPY = 60086,
    MDI_CLIPBOARD_CHECK_MULTIPLE = 60087,
    MENU_BOOK = 60088,
    MESSAGE_OUTLINE = 60089,
    MESSAGE_TEXT_LOCK_OUTLINE = 60090,
    MICROPHONE_OFF = 60091,
    MICROPHONE_ON = 60092,
    MILESTONE = 60093,
    MORE_HORIZ = 60094,
    MORE_MOD = 60095,
    MORE_VERT = 60096,
    MOVE = 60097,
    NEWS = 60098,
    NEWSPAPER = 60099,
    NOTE = 60100,
    NOTIFICATION = 60101,
    NOTIFICATION_ACTIVE = 60102,
    NOTIFICATION_OFF_OUTLINE = 60103,
    NOTIFICATION_OUTLINED = 60104,
    NUMBER = 60105,
    NUMBER_NOT = 60106,
    OPEN_IN_NEW = 60107,
    OUTBOX = 60108,
    PARAGRAPH_LEFT_ALIGN = 60109,
    PARENT_FILTER = 60110,
    PAUSE = 60111,
    PERCENT = 60112,
    PERSON = 60113,
    PERSON_ADD = 60114,
    PERSON_APPROVE = 60115,
    PERSON_OFF = 60116,
    PERSON_OUTLINE = 60117,
    PERSON_REMOVE = 60118,
    PHONE = 60119,
    PHONE_IN_TALK = 60120,
    PHONE_NUMBER = 60121,
    PHOTO_CAMERA = 60122,
    PICK_UP = 60123,
    PIE_CHART = 60124,
    PIN = 60125,
    PIN_DROP = 60126,
    PIN_OUTLINE = 60127,
    PLACE = 60128,
    PLAY_ARROW = 60129,
    PLAY_CIRCLE_FILLED = 60130,
    PLAYLIST_ADD = 60131,
    PLAYLIST_ADD_CHECK = 60132,
    PRINT = 60133,
    PROJECT = 60134,
    QUOTE = 60135,
    RADIO_BUTTON_CHECKED = 60136,
    RADIO_BUTTON_UNCHECKED = 60137,
    RATING = 60138,
    RECENT_ACTORS = 60139,
    REMOVE_CIRCLE = 60140,
    REPEAT = 60141,
    REPLY = 60142,
    REPLY_REFLECTION = 60143,
    RETURN = 60144,
    ROUND_HEADSET_MIC = 60145,
    SCRIPT = 60146,
    SEARCH = 60147,
    SEND = 60148,
    SETTING = 60149,
    SETTINGS = 60150,
    SETTINGS_OUTLINE = 60151,
    SHARE = 60152,
    SHARE_SCREEN = 60153,
    SHOPPING_BASKET = 60154,
    SIM_CARD_ALERT = 60155,
    SIMILAR = 60156,
    SMARTPHONE = 60157,
    SORT = 60158,
    SORT_A_Z = 60159,
    SORT_Z_A = 60160,
    SQUARE_EDIT_OUTLINE = 60161,
    STICKERS = 60162,
    STORAGE = 60163,
    STRIKETHROUGH = 60164,
    SUBDIRECTORY_ARROW_RIGHT = 60165,
    SUBPROJECT = 60166,
    SUPERVISED_USER_CIRCLE = 60167,
    TABLE = 60168,
    TABLE_COLUMN = 60169,
    TAG_ADD = 60170,
    TAG_DEL = 60171,
    TARGET = 60172,
    TASK_FAIL = 60173,
    TASK_LINK = 60174,
    TASK_PAUSE = 60175,
    TASK_START = 60176,
    TECH_SUPPORT = 60177,
    TELEGRAM = 60178,
    TEST = 60179,
    THUMB_DOWN = 60180,
    THUMB_UP = 60181,
    TIMELAPSE = 60182,
    TODO_FAILED = 60183,
    TODO_LINK = 60184,
    TWO_CHART = 60185,
    UNARCHIVE_OUTLINE = 60186,
    UNDERLINE = 60187,
    UNLINK = 60188,
    UNPIN_OUTLINE = 60189,
    VARIABLE = 60190,
    VERTICAL_COLUMNS = 60191,
    VERTICAL_COLUMNS_OK = 60192,
    VIDEO_LIBRARY = 60193,
    VIDEOCAM = 60194,
    VIDEOCAM_OFF = 60195,
    VIDEOCAM_ON = 60196,
    VIEW_CAROUSEL = 60197,
    VISIBILITY_OFF = 60198,
    VISIBILITY_ON = 60199,
    VK = 60200,
    VOLUME_DOWN = 60201,
    VOLUME_OFF = 60202,
    VOLUME_ON = 60203,
    WARNING = 60204,
    WEEKEND = 60205,
    WHATSAPP = 60206,
    XS_CLEAR = 60207,
    XS_DELETE = 60208,
    XS_GROUP_FOLDER = 60209,
    YANDEX = 60210,
    YOUTUBE_SEARCHED_FOR = 60211
}
