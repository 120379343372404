import {PlainRoute} from "react-router"

const pageType = {
    article: "article",
    search: "search",
    bug: "bug",
    educate: "educate",
    noInfo: "noInfo",
    help: "help"
}

export const helpRoutes: PlainRoute[] = [
    {
        path: "/help",
        async getComponent() {
            return (await import("./containers/CPageContainer")).CPageContainer
        },
    },
    {
        path: `/help/${pageType.article}/*`,
        async getComponent() {
            return (await import("./containers/CPageArticle")).CPageContainer
        },
    },
    {
        path: `/help/${pageType.bug}`,
        async getComponent() {
            return (await import("./containers/CPageArticle")).CPageContainer
        },
    },
    {
        path: `/help/${pageType.noInfo}`,
        async getComponent() {
            return (await import("./containers/CPageArticle")).CPageContainer
        },
    },
    {
        path: `/help/${pageType.educate}`,
        async getComponent() {
            return (await import("./containers/CPageArticle")).CPageContainer
        },
    },
    {
        path: `/help/${pageType.search}/*`,
        async getComponent() {
            return (await import("./containers/CPageArticle")).CPageContainer
        },
    },
    {
        path: `/help/*`,
        async getComponent() {
            return (await import("./containers/CPageArticle")).CPageContainer
        },
    },
]
