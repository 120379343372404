/* tslint:disable */
import * as H from "../metadataHelper"
import {ApiCall} from "./apiCall"

export interface BulkApiCall extends H.BaseValue {
    calls?: H.List<ApiCall>;
    contentType: "BulkApiCall"; // Object type
}

export module BulkApiCall {
    export const contentType: "BulkApiCall" = "BulkApiCall";
    export const endpoint = "/api/v3/bulkApiCall";

    export const newObject: BulkApiCall = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const calls = {contentType: "RefLinkField" as "RefLinkField", name: "calls", isMultiple: true, isSortable: false, id: "BulkApiCall.calls", refContentType: H.List(["ApiCall"])};
    }
}

export function isBulkApiCall(arg: any): arg is BulkApiCall {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "BulkApiCall"
}
