import {getGID, getLink, prepareEntityToSave} from "src/lib/entities/utils";
import {TabMessageEvent} from "src/bums/common/browserTabs/transports/AbstractTabTransport";
import {BrowserTab, BrowserTabFactory} from "src/bums/common/browserTabs";
import {BaseEntity, isBaseEntity} from "src/lib/entities/types";
import {EntitiesStorageInterface} from "src/lib/entities/store/EntitiesStorage/EntitiesStorageInterface";
import {DecoratedEntitiesStorage} from "src/lib/entities/store/EntitiesStorage/DecoratedEntitiesStorage";
import {debounce} from "lodash"


const storageUpdatedKey = "entities_updated_event"

export class BrowserTabBroadcastEntitiesStorage extends DecoratedEntitiesStorage {

    private browserTab: BrowserTab

    /**
     * Очередь на обновление сущностей из хранилища в ApiStore
     * @type {Array}
     */
    private linksToLoadFromStorage = new Map<string, BaseEntity>()

    constructor(
        private storage: EntitiesStorageInterface,
        browserTabFactory: BrowserTabFactory,
        private onEntitiesLoadByBrowserTabEvent: (entities: BaseEntity[]) => void,
        private debounceTime = 500
    ) {
        super(storage)
        this.browserTab = browserTabFactory.createBrowserTabChannel("EntitiesStorage")
        this.browserTab.on(storageUpdatedKey, this.storageEventListener)
    }

    /**
     * Обработчик событий от других вкладок на обновление сущностей из сторейджа
     */
    private storageEventListener = (message: TabMessageEvent<BaseEntity[]>) => {
        try {
            if (message.isFromSameTab || !Array.isArray(message.data)) {
                return;
            }
            const links = message.data.filter(isBaseEntity)
            for (const link of links) {
                this.linksToLoadFromStorage.set(getGID(link), link)
            }
            void this.processLinksLoadFromStorage()
        } catch (e) {
            console.error(e)
        }
    }

    /**
     * Обработчик очереди на обновление сущностей
     */
    private processLinksLoadFromStorage = debounce(
        async () => {
            const links = [...this.linksToLoadFromStorage.values()]
            this.linksToLoadFromStorage.clear()
            const linksToLoad = links.filter(isBaseEntity)
            if (linksToLoad.length > 0) {
                const loadedEntities = await this.load(linksToLoad)
                this.onEntitiesLoadByBrowserTabEvent(loadedEntities)
            }
        },
        this.debounceTime
    )

    async save(entities: BaseEntity[]) {
        const savedEntities = await this.storage.save(entities)
        this.browserTab.broadcast(storageUpdatedKey, savedEntities.map(getLink).map(prepareEntityToSave))

        return savedEntities
    }
}
