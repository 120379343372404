/* tslint:disable */
import * as H from "../metadataHelper"
import {File} from "./file"
import {ContractorCompany} from "./contractorCompany"
import {ContractorHuman} from "./contractorHuman"
import {Employee} from "./employee"
import {Group} from "./group"
import {UserColor} from "./userColor"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"
import {BoolField} from "./boolField"

export interface MenuItem extends H.BaseEntity {
    url?: string;
    frameUrl?: string;
    id?: string;
    title?: string;
    icon?: File;
    showForUsers?: H.List<ContractorCompany | ContractorHuman | Employee | Group>; // Кому виден данный пункт меню
    showForUsersCount?: number;
    blank?: boolean;
    isActive?: boolean;
    parent?: MenuItem;
    subMenu?: H.List<MenuItem>;
    subMenuCount?: number;
    color?: UserColor;
    urlAliases?: H.List<string>;
    isExternal?: boolean;
    isEnabled?: boolean;
    isCustom?: boolean;
    contentType: "MenuItem"; // Object type
}

export module MenuItem {
    export const contentType: "MenuItem" = "MenuItem";
    export const endpoint = "/api/v3/menuItem";

    export const newObject: MenuItem = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const url = {contentType: "StringField" as "StringField", name: "url", isMultiple: false, isSortable: false, id: "MenuItem.url", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const frameUrl = {contentType: "StringField" as "StringField", name: "frameUrl", isMultiple: false, isSortable: false, id: "MenuItem.frameUrl", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const title = {contentType: "StringField" as "StringField", name: "title", isMultiple: false, isSortable: false, id: "MenuItem.title", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const icon = {contentType: "RefLinkField" as "RefLinkField", name: "icon", isMultiple: false, isSortable: false, id: "MenuItem.icon", refContentType: H.List(["File"])};
        export const showForUsers = {contentType: "RefLinkField" as "RefLinkField", name: "showForUsers", isMultiple: true, isSortable: false, id: "MenuItem.showForUsers", refContentType: H.List(["ContractorCompany", "ContractorHuman", "Employee", "Group"])};
        export const showForUsersCount = {contentType: "IntegerField" as "IntegerField", name: "showForUsersCount", isMultiple: false, isSortable: false, id: "MenuItem.showForUsersCount", defaultValue: null as IntegerField["defaultValue"]};
        export const blank = {contentType: "BoolField" as "BoolField", name: "blank", isMultiple: false, isSortable: false, id: "MenuItem.blank", defaultValue: null as BoolField["defaultValue"]};
        export const isActive = {contentType: "BoolField" as "BoolField", name: "isActive", isMultiple: false, isSortable: false, id: "MenuItem.isActive", defaultValue: null as BoolField["defaultValue"]};
        export const parent = {contentType: "RefLinkField" as "RefLinkField", name: "parent", isMultiple: false, isSortable: false, id: "MenuItem.parent", refContentType: H.List(["MenuItem"])};
        export const subMenu = {contentType: "RefLinkField" as "RefLinkField", name: "subMenu", isMultiple: true, isSortable: false, id: "MenuItem.subMenu", refContentType: H.List(["MenuItem"])};
        export const subMenuCount = {contentType: "IntegerField" as "IntegerField", name: "subMenuCount", isMultiple: false, isSortable: false, id: "MenuItem.subMenuCount", defaultValue: null as IntegerField["defaultValue"]};
        export const color = {contentType: "RefLinkField" as "RefLinkField", name: "color", isMultiple: false, isSortable: false, id: "MenuItem.color", refContentType: H.List(["UserColor"])};
        export const urlAliases = {contentType: "StringField" as "StringField", name: "urlAliases", isMultiple: true, isSortable: false, id: "MenuItem.urlAliases", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isExternal = {contentType: "BoolField" as "BoolField", name: "isExternal", isMultiple: false, isSortable: false, id: "MenuItem.isExternal", defaultValue: null as BoolField["defaultValue"]};
        export const isEnabled = {contentType: "BoolField" as "BoolField", name: "isEnabled", isMultiple: false, isSortable: false, id: "MenuItem.isEnabled", defaultValue: null as BoolField["defaultValue"]};
        export const isCustom = {contentType: "BoolField" as "BoolField", name: "isCustom", isMultiple: false, isSortable: false, id: "MenuItem.isCustom", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isMenuItem(arg: any): arg is MenuItem {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "MenuItem"
}
