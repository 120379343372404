import * as Api from "src/lib/entities/api"
import {ModalFormStore} from "src/bums/common/modalForm/ModalFormStore"
import {CIconType} from "src/lib/components/CMenuIcon/CMenuIcon"
import {CIconType as BaseIconType} from "src/lib/components/CIcon/iconMetadata"
import {UserStore} from "src/lib/entities/api"

const srcWidthExp = /\{width\}/
const srcHeightExp = /\{height\}/

const thumbnailWidth = 55
const thumbnailHeight = 45

const transformExp = /-?(\d+)px/g

const chainUrlExp = /\/((?:(bp)\/([\w|\-]+)\/add)|(?:(\w+)\/add))(?:[([\b|\/]?$|[\?])/

export interface MenuItemProps {
    entity: Api.MenuItem
    className?: string
}

interface StaticMenuItemConfig {
    iconType: CIconType
}

interface UrlStruct {
    features: Api.Feature.Name[]
    handler: (modalFormStore: ModalFormStore, parsedArguments: string[], itemType: "profile" | "fastButton" | "submenu") => void
}

const defaultConfigureStaticMenuItem: StaticMenuItemConfig = {
    iconType: CIconType.LINK
}

const oldIdMap = new Map<string, string>([
    ["employees", "employee"],
    ["deals", "deal"],
    ["tasks", "task"],
    ["todos", "todo"],
    ["finances", "finance"],
])

export const staticModuleItemsMap = new Map<string, StaticMenuItemConfig>([
    ["desktop", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.DESKTOP,
    }],
    ["employee", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.EMPLOYEES
    }],
    ["todo", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.CLOCK
    }],
    ["report", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.REPORTS
    }],
    ["task", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.TASKS
    }],
    ["doc", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.DOCS
    }],
    ["discuss", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.COMMUNICATION
    }],
    ["crm", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.CLIENTS
    }],
    ["finance", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.FINANCE
    }],
    ["invoice", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.ACCOUNT
    }],
    ["deal", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.DEALINGS
    }],
    ["help", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.HELP,
    }],
    ["settings", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.SETTINGS,
    }],
    ["bpm", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.BPM,
    }],
    ["upgrades", {
        ...defaultConfigureStaticMenuItem,
        iconType: CIconType.UPGRADES,
    }],
])

export const replaceableModuleItemsMap = new Map<string, (userStore: UserStore) => StaticMenuItemConfig>([
    ["todo", (userStore) => {
        const itemConfig = {
            ...defaultConfigureStaticMenuItem,
            iconType: CIconType.CLOCK
        }
        if (userStore.user.possibleActions.includes("feature_new_todo_list")) {
            itemConfig.iconType = CIconType.CALENDAR
        }
        return itemConfig
    }]
])

const profileMenuIconMap = new Map<string, BaseIconType>([
    ["profile", BaseIconType.PERSON],
    ["news", BaseIconType.NEWS],
    ["settings", BaseIconType.SETTINGS],
    ["tests", BaseIconType.TEST],
    ["addons", BaseIconType.EXTENSION],
    ["faq", BaseIconType.LIVE_HELP],
    ["tariff", BaseIconType.LOGO]
])

const availableUrlFormMap = new Map<string, UrlStruct>([
    ["task", {
        features: ["bums.task_card_component"],
        handler: (modalFormStore: ModalFormStore, parsedArguments, itemType) => {
            return modalFormStore.open("taskProject", {type: itemType})
        },
    }],
    ["crm", {
        features: ["bums.company_card_component"],
        handler: (modalFormStore: ModalFormStore, parsedArguments, itemType) => {
            return modalFormStore.open("contractor", {type: itemType})
        },
    }],
    ["deals", {
        features: ["bums.trade_card_component"],
        handler: (modalFormStore: ModalFormStore, parsedArguments, itemType) => {
            return modalFormStore.open("deal", {
                presets: [{contentType: Api.Deal.contentType, state: void 0, program: void 0}],
                type: itemType
            })
        },
    }],
    ["staff", {
        features: [],
        handler: (modalFormStore: ModalFormStore, parsedArguments, itemType) => {
            return modalFormStore.open("employee", {type: itemType})
        },
    }],
    ["bp", {
        features: ["bums.trade_card_component", "logic.stm.b_p_m"],
        handler: (modalFormStore: ModalFormStore, parsedArguments, itemType) => {
            return modalFormStore.open("deal", {
                slug: parsedArguments[0],
                fixedProgram: true,
                type: itemType
            })
        },
    }],
    ["event", {
        features: ["bums.time.todo_card_with_tabs"],
        handler: (modalFormStore: ModalFormStore, parsedArguments, itemType) => {
            return modalFormStore.open("todo", {type: itemType})
        },
    }]
])

export function getItemConfig(entity: Api.MenuItem, userStore?: UserStore) {
    if (userStore && replaceableModuleItemsMap.has(entity.id)) {
        return replaceableModuleItemsMap.get(entity.id)(userStore)
    } else if (staticModuleItemsMap.has(entity.id)) {
        return staticModuleItemsMap.get(entity.id)
    }

    return {...defaultConfigureStaticMenuItem}
}

export function getProfileIconType(entity: Api.MenuItem) {
    if (profileMenuIconMap.has(entity.id)) {
        return profileMenuIconMap.get(entity.id)
    }

    return void 0
}

export function isStaticItem(entity: Api.MenuItem) {
    return isNaN(Number(entity.id))
}

export function normalizeSrc(src: string) {
    return src.replace(srcWidthExp, String(thumbnailWidth)).replace(srcHeightExp, String(thumbnailHeight))
}

export function getAllItems(items: Api.MenuItem[]) {
    const result: Api.MenuItem[] = []

    items.forEach(item => {
        result.push(item)
        if (item.subMenu && item.subMenu.length > 0) {
            result.push(...getAllItems(item.subMenu.toArray()))
        }
    })

    return result
}


export function generateUrls(entity: Api.MenuItem, entityUrlSet = new Map<string, string[]>()): string[] {
    const gid = Api.getGID(entity)

    if (entityUrlSet.has(gid)) {
        return entityUrlSet.get(gid)
    }

    const result: string[] = [entity.url, ...(entity.urlAliases ? entity.urlAliases.toArray() : [])]

    return result
}

export function getAllParentIds(entity: Api.MenuItem): string[] {
    const result = [entity.id]

    if (entity.parent) {
        result.push(...getAllParentIds(entity.parent))
    }

    return result
}

export function weightDetermination(currentOrigin: string, currentUrl: string, queryParams: string[], urls: string[]): number[] {
    return urls.reduce((result, url = "") => {
        const [pathname, search] = url.replace(currentOrigin, "").split("?")

        let weight = 0

        if (currentUrl.indexOf(pathname) === 0) {
            weight = currentUrl === pathname ? 1000 : currentUrl.replace(pathname, "").length

            if (search) {
                const urlQueryParams = normalizeQueryParams(search)

                if (urlQueryParams.every(param => queryParams.includes(param))) {
                    weight = weight + (urlQueryParams.length * 10)
                }
            }
        }

        if (weight !== 0) {
            result.push(weight)
        }

        return result
    }, [])
}


export function normalizeQueryParams(query: string) {
    if (!query) {
        return []
    }

    if ("string" === typeof query) {
        const noHash = query.split("#")[0]
        return noHash.replace("?", "").split("&")
    }

    return Object.entries(query).map(([key, value]) => `${key}=${value}`)
}

export function getTransformTranslate(transform: string) {
    if (!transform) {
        return {x: 0, y: 0}
    }
    const matches = transform.match(transformExp)
    return {
        x: parseInt(matches[0], 10) || 0,
        y: parseInt(matches[1], 10) || 0
    }
}

export function getUrlAddStruct(url: string): UrlStruct & {parameters: string[]} {
    const matched = url.match(chainUrlExp)

    if (matched) {
        let formHandler: UrlStruct
        let parsedArguments: string[] = []

        // простые урлы вида {scope}/add
        if (!!matched[4] && availableUrlFormMap.has(matched[4])) {
            formHandler = availableUrlFormMap.get(matched[4])
        // урлы для бизнес процессов
        } else if (!!matched[2] && !!matched[3] && availableUrlFormMap.has(matched[2])) {
            formHandler = availableUrlFormMap.get(matched[2])
            parsedArguments = [matched[3]]
        }

        if (formHandler) {
            return {...formHandler, parameters: parsedArguments}
        }
    }

    return void 0
}


export function prepareOldSortSettingValue(value: {[index: string]: number}) {
    const sortFlat: [string, number][] = []

    Object.keys(value).forEach(key => {
        const newKey = oldIdMap.has(key) ? oldIdMap.get(key) : key
        sortFlat.push([newKey, value[key]])
    })

    return sortFlat.sort((a, b) => a[1] - b[1]).map(item => item[0])
}

const dealsHints = [
    {text: "workInKanban", links: ["deals/list/canban"]},
    {text: "importFromMail", links: ["deals/IQ/import"]},
    {text: "createDealSchemes", links: ["settings/deals/programm"]},
    {text: "launchTriggers", links: ["deals/IQ/populartriggers"]},
    {text: "configureAccessRights", links: ["deals/rights"]},
]

export const availableForHintsModules = new Map<string, {text: string, links: string[]}[]>([
    [
        "desktop",
        [
            {text: "activityFeed", links: ["dashboard/widgets/activity"]},
            {text: "tasksWidget", links: ["dashboard/widgets/task"]},
            {text: "clientsWidget", links: ["dashboard/widgets/contact"]},
            {text: "dealsWidget", links: ["dashboard/widgets/deals"]},
            {text: "reportsWidget", links: ["dashboard/widgets/report"]}
        ]
    ],
    [
        "crm",
        [
            {text: "importContacts", links: ["clients/list/import"]},
            {text: "scheduleCommunications", links: ["clients/events/plan"]},
            {text: "configureAccessRights", links: ["clients/access"]},
            {text: "doMailing", links: ["clients/mailing/create"]},
            {text: "automateWork", links: ["clients/autoscript/create"]},
        ]
    ],
    [
        "employee",
        [
            {text: "trackEffectiveness", links: ["staff/staffsection/effectiveness"]},
            {text: "configureStructure", links: ["staff/structure"]},
            {text: "addVacation", links: ["staff/vacation/add"]},
            {text: "makeGroups", links: ["staff/structure/newdept"]},
        ]
    ],
    [
        "task",
        [
            {text: "makeProjects", links: ["tasks/difference"]},
            {text: "trackDeadline", links: ["tasks/newtask/deadline"]},
            {text: "makeTemplates", links: ["tasks/template"]},
            {text: "approveDocuments", links: ["tasks/agreement"]},
        ]
    ],
    [
        "deal",
        dealsHints,
    ],
    [
        "bpm",
        dealsHints,
    ],
    [
        "todo",
        [
            {text: "createTodo", links: ["todos/work"]},
            {text: "addEvents", links: ["todos/work/periodevent"]},
            {text: "watchSchedule", links: ["todos/datelog"]},
        ]
    ],
    [
        "report",
        [
            {text: "newReport", links: ["reports/work/addnew"]},
            {text: "salesFunnel", links: ["reports/salesfunnel"]},
            {text: "periodReport", links: ["reports/work/period"]},
            {text: "formula", links: ["reports/work/formula"]},
            {text: "reportRights", links: ["reports/rights/reportrights"]},
        ]
    ],
    [
        "invoice",
        [
            {text: "configureModule", links: ["invoices/work/preset"]},
            {text: "invoiceTemplate", links: ["invoices/work/owntemplate"]},
            {text: "workWithInvoices", links: ["invoices/work/work"]},
            {text: "anotherLanguage", links: ["invoices/work/foreigninvoica"]},
            {text: "invoiceRights", links: ["invoices/work/access"]},
        ]
    ],
    [
        "finance",
        [
            {text: "configureModule", links: ["finances/set"]},
            {text: "createTypesTemplates", links: ["finances/set/newtypeoper", "finances/set/template"]},
            {text: "operationsList", links: ["finances/work/list"]},
            {text: "financeReport", links: ["finances/work/finreports"]},
            {text: "linkOperations", links: ["finances/work/finlink", "finances/work/comlink"]},
        ]
    ],
    [
        "doc",
        [
            {text: "createFolders", links: ["documents/list/addfolder"]},
            {text: "access", links: ["documents/access/folder"]},
            {text: "uploadFiles", links: ["documents/loading/mass"]},
            {text: "addTemplates", links: ["documents/templates/addown"]},
            {text: "findDoc", links: ["documents/list/search"]},
        ]
    ],
    [
        "discuss",
        [
            {text: "createDiscuss", links: ["discussions/work/newdiscuss"]},
            {text: "createTask", links: ["discussions/work/taskfromdiscuss"]},
            {text: "hideDiscuss", links: ["discussions/rights/howto"]},
            {text: "makePoll", links: ["discussions/work/poll"]},
            {text: "readMsgFormCard", links: ["discussions/mail"]},
        ]
    ],
    [
        "settings",
        [
            {text: "integration", links: ["settings/integration"]},
            {text: "addApp", links: ["settings/apps"]},
            {text: "setupAddons", links: ["settings/addons"]},
            {text: "configureProgram", links: ["settings/deals/programm"]},
            {text: "createExtraFields", links: ["settings/manual/contractor_fields", "settings/manual/extfields"]},
        ]
    ],
])

export function getModuleAlias(moduleId: string): string {
    switch (moduleId) {
        case "desktop":
            return "dashboard"
        case "crm":
            return "clients"
        case "employee":
            return "staff"
        case "task":
            return "tasks"
        case "deal":
        case "bpm":
            return "deals"
        case "todo":
            return "todos"
        case "report":
            return "reports"
        case "invoice":
            return "invoices"
        case "finance":
            return "finances"
        case "doc":
            return "documents"
        case "discuss":
            return "discussions"
        default:
            return moduleId
    }
}
