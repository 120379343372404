/**
 * MS Word: Формирует древовидные списки из плоских
 */
import {ListArray, List} from "./html-list-classes"

// It just mixed up all together while in development. It is better to make it nice in release.

const ifNotSupportShort = /<!\[if !supportLists\]>[\s\S]*?<!\[endif\]>/g
const ifNotSupport = /<!--\[if !supportLists\]-->[\s\S]*?<!--\[endif\]-->/g
const ieConditional = /<!--\[if[\s\S]*?<!\[endif\]-->/g
const officeRelated = /<o:(.*?)>.*<\/o:\1>/g // usually <o:p></o:p>
// remove newlines in tags
const newLineInStyleFrom = /(style=['"].*?)[\s]*(.*?['"])/g
const newLineInStylesTo = "$1$2"

const newLineInTagsFrom = /<(.*?)[\s]*( .*?)>/g
const newLineInTagsTo = "<$1$2>"

const styleFilter = / style=["'].*?["'].*?/g
const everyTagExceptBIU = /<(?!\/?b|\/?i|\/?u)[^>]+>/g

const listReplacer = /<p.*?mso-list:[ ]*(l\d+).*?[\s]*.*?<\/p>/g

//const listDetector = /(@list (l\d+):level1[\s\S]*?{[\s\S]*?(mso-level-number-format:bullet;[\s\S]*?){0,1}})/g
const listDetector = /@list (l\d+):level1[\s\S]*?{[\s\S]*?}/g
// @list l1:
const listId = /l\d+/
const isBullet = /mso-level-number-format:bullet;/

// const listClassFirst = "MsoListParagraphCxSpFirst"
// const listClassMiddle = "MsoListParagraphCxSpMiddle"
// const listClassLast = "MsoListParagraphCxSpLast"
//
// const msOfficeListStyle = "mso-list";
// mso-list:l1 level1 lfo1
const msoList = /mso-list:[ ]*(l\d+) level(\d+) lfo\d+/;

const prepareHtml = (html: string): string => {
    // todo make it as array or something iterable through rules
    // Очистка и подготовка
    return html.
        replace(ieConditional, "").
        replace(officeRelated, "").
        replace(ifNotSupportShort, "").
        replace(ifNotSupport, "").
        replace(newLineInStyleFrom, newLineInStylesTo).
        replace(newLineInTagsFrom, newLineInTagsTo)
}

const convertMsoHtml = function(pastedHtml: string): string {

    if (!pastedHtml.length) {
        return pastedHtml
    }

    let html = prepareHtml(pastedHtml)

    // Создаём пустые списки с метаданными (имя, тип)
    let lists = new ListArray();
    const rawLists = html.match(listDetector);
    if (rawLists) {
        rawLists.forEach(function(item: string) {
            const name = item.match(listId)[0];
            const isOrdered = !isBullet.test(item);
            lists.addList(new List(name, isOrdered));
        });
    }

    // Создать DOM документ для удобной работы
    let container = document.createElement("div")
    container.innerHTML = html

    // Каждый элемент списка представлен параграфом
    const paragraphs = container.getElementsByTagName("p");

    let listInfo: RegExpMatchArray
    for (let i = 0; i < paragraphs.length; i++) {

        const p = paragraphs[i]
        // Извлечь данные об элементе списка из стиля...
        const style = p.getAttribute("style")
        if (style) {
            // 0 - match, 1 - name, 2 - level
            listInfo = style.match(msoList)
            // Есть нужное совпадение, значит элемент списка
            if (listInfo) {
                // Извлечь данные об элементе
                const name = listInfo[1]
                const value = p.innerHTML.replace(styleFilter, "").replace(everyTagExceptBIU, "")
                const level = parseInt(listInfo[2], 10)

                // Добавить в список
                lists.getList(name).addItem(value, level);
            }
        }
    }

    // Произвести замену маркеров списков на сами списки
    if (lists.getSize()) {

        // replace p-list items in html
        html = html.replace(listReplacer, "{{$1}}"); // make something else, may be AST https://astexplorer.net/
        html = html.replace(/{{(l\d+)}}[\s\S]*{{\1}}/g, function (item: any, name: string) {
            return lists.getList(name).getHtml()
        });
        // remove styles, comments

        container.innerHTML = html.trim()
    }

    return html
}

export default convertMsoHtml
