/* tslint:disable */
import * as H from "../metadataHelper"
import {MessengerType} from "./messengerType"
import {MessengerChannel} from "./messengerChannel"
import {StringField} from "./stringField"
import {IntegerField} from "./integerField"

export interface Messenger extends H.BaseEntity {
    name?: string;
    messageSendUri?: string;
    type?: MessengerType;
    channels?: H.List<MessengerChannel>;
    channelsCount?: number;
    id?: string; // Идентификатор
    contentType: "Messenger"; // Object type
}

export module Messenger {
    export const contentType: "Messenger" = "Messenger";
    export const endpoint = "/api/v3/messenger";

    export const newObject: Messenger = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Messenger.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const messageSendUri = {contentType: "StringField" as "StringField", name: "messageSendUri", isMultiple: false, isSortable: false, id: "Messenger.messageSendUri", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const type = {contentType: "RefLinkField" as "RefLinkField", name: "type", isMultiple: false, isSortable: false, id: "Messenger.type", refContentType: H.List(["MessengerType"])};
        export const channels = {contentType: "RefLinkField" as "RefLinkField", name: "channels", isMultiple: true, isSortable: false, id: "Messenger.channels", refContentType: H.List(["MessengerChannel"])};
        export const channelsCount = {contentType: "IntegerField" as "IntegerField", name: "channelsCount", isMultiple: false, isSortable: false, id: "Messenger.channelsCount", defaultValue: null as IntegerField["defaultValue"]};
    }
}

export function isMessenger(arg: any): arg is Messenger {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Messenger"
}
