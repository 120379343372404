import {ListClass} from "./ListClass"
import {MapClass} from "./MapClass"

export function List<T>(source?: Array<T> | Iterable<T>): ListClass<T>
export function List<T>(...values: T[]): ListClass<T>
export function List<T>(...args: any[]): ListClass<T> {
    return new ListClass<T>(...args)
}
export function isList(arg: any): arg is ListClass<any> {
    return arg instanceof ListClass;
}


export function Map(): MapClass<any, any>;
export function Map<K, V>(): MapClass<K, V>;
export function Map<K, V>(source: Array<[K, V]>): MapClass<K, V>;
export function Map<V>(source: {[index: string]: V}): MapClass<string, V>;
export function Map<V>(source: {[index: number]: V}): MapClass<string, V>;
export function Map<K, V>(source: Iterable<[K, V]>): MapClass<K, V>;
export function Map<K, V>(source?: any): MapClass<K, V> {
    return new MapClass<K, V>(source);
}
export function isMap(arg: any): arg is MapClass<any, any> {
    return arg instanceof MapClass;
}


// export function Set<T>(elements?: Array<T> | Iterable<T> | IObservableArray<T>): SetClass<T> {
//     return new SetClass<T>(elements);
// }
// class SetClass<T> implements ISet<T> {
//     constructor(elements?: Array<T> | Iterable<T> | IObservableArray<T>) {
//
//     }
// }
//
// class OrderedMapClass<K, V> implements IOrderedMap<K, V> {
//
// }
