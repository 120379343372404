import {untracked} from "mobx"
import {noop} from "lodash"
import {BaseEntity, BaseValue} from "src/lib/entities/types"
import {getEntityTypeEndpoint} from "src/lib/entities/utils"
import {fromPromise, PromiseBasedObservable} from "src/lib/utils/fromPromise"
import {ApiStore, FETCH_FULL_NAME} from "src/lib/entities/store/ApiStore"
import {getEntityFetchState, isEntityEquals} from "src/lib/entities/utils"

export namespace EntityStore {
    export type EntitySettings<T extends BaseEntity> = {
        contentType: T["contentType"]
        id: string
        endpoint?: Endpoint
        fields?: any[]
    }

    export type EntityFactory<T extends BaseEntity> = EntitySettings<T> | void

    export type Method = "get" | "post" | "delete"
    export type Endpoint = string | {get: string, post?: string, delete?: string}
}

function getEndpointByType(type: EntityStore.Method, endpoint: EntityStore.Endpoint): string {
    if (!endpoint || typeof endpoint === "string") {
        return endpoint as string
    } else {
        return endpoint[type] || endpoint["get"]
    }
}

function createEndpoint<T extends BaseEntity>(options: EntityStore.EntitySettings<T>, type: EntityStore.Method) {
    return getEndpointByType(type, options.endpoint) || getEntityTypeEndpoint({contentType: options.contentType})
}

function createLink<T extends BaseEntity>(options: EntityStore.EntitySettings<T>) {
    return {contentType: options.contentType, id: options.id} as T
}

const notResolvedPromise = new Promise(noop)

export class EntityStore<T extends BaseEntity> {

    private $entity: PromiseBasedObservable<T>

    private $fromPromiseSettings: PromiseBasedObservable<EntityStore.EntityFactory<T>>

    private $deletedEntity: T | void

    constructor(
        private $apiStore: ApiStore,
        entityOptionsFactory: () => EntityStore.EntityFactory<T> | PromiseBasedObservable<EntityStore.EntityFactory<T> | void>,
        returnFull = true
    ) {
        this.$fromPromiseSettings = fromPromise(() => entityOptionsFactory()) as PromiseBasedObservable<EntityStore.EntityFactory<T>>

        this.$entity = fromPromise.map(
            this.$fromPromiseSettings,
            options => fromPromise.map(
                fromPromise(() => {
                    if (!options) {
                        return notResolvedPromise as Promise<T>
                    }
                    const endpoint = createEndpoint(options, "get")
                    const link = createLink(options)
                    this.$deletedEntity = void 0

                    return untracked(() => $apiStore.getOrLoadEntity(link, endpoint, options.fields, returnFull))
                }),
                () => {
                    const link = createLink(options as EntityStore.EntitySettings<T>)
                    const entity = this.$apiStore.getEntity(link, null)

                    if (!entity && this.$deletedEntity && isEntityEquals(link, this.$deletedEntity)) {
                        return this.$deletedEntity
                    }

                    const state = getEntityFetchState(entity, FETCH_FULL_NAME)

                    if (state.isError()) {
                        throw state.getErrors()[0]
                    } else {
                        return entity
                    }
                }
            )
        ) as PromiseBasedObservable<T>
    }

    public get() {
        return this.$entity
    }

    public async update(value: Partial<T>, fetchName?: string) {
        await this.$entity.promise
        if (this.$fromPromiseSettings.state === "fulfilled") {
            const options = this.$fromPromiseSettings.value as EntityStore.EntitySettings<T>
            return this.$apiStore.update(
                Object.assign({}, value, createLink(options)),
                {
                    endpoint: createEndpoint(options, "post"),
                    fetchName
                }
            )
        }

    }

    public async updateWithRequest<V extends BaseValue>(value: V, fetchName?: string) {
        await this.$entity.promise
        if (this.$fromPromiseSettings.state === "fulfilled") {
            const options = this.$fromPromiseSettings.value as EntityStore.EntitySettings<T>
            return this.$apiStore.update(
                createLink(options),
                {
                    endpoint: createEndpoint(options, "post"),
                    fetchName,
                    requestEntity: value,
                    prepareToSave: false,
                }
            )
        }
    }

    public async delete(cached = true) {
        await this.$entity.promise
        if (this.$fromPromiseSettings.state === "fulfilled" && this.$entity.state === "fulfilled") {
            const options = this.$fromPromiseSettings.value as EntityStore.EntitySettings<T>
            if (cached) {
                this.$deletedEntity = this.$entity.value
            }
            return this.$apiStore.deleteEntity(createLink(options), createEndpoint(options, "delete"))
        }
    }

    public async refresh() {
        await this.$entity.promise
        this.$deletedEntity = void 0
        if (this.$fromPromiseSettings.state === "fulfilled") {
            const options = this.$fromPromiseSettings.value as EntityStore.EntitySettings<T>
            return this.$apiStore.fetchFullEntity(createLink(options), createEndpoint(options, "get"))
        }
    }
}
