import * as Api from "src/lib/entities/api"
import * as WebApi from "src/lib/entities/apiWeb"
import * as ReduxActions from "redux-actions"
import {ApiErrorResponse} from "src/lib/entities/api"
import {ReportData, ServerResponse} from "../../entities"
import * as Collections from "src/lib/collections"

export const FETCH_REPORT_DATA_START = "bums/common/report/edit/action/FETCH_REPORT_DATA_START"
export const FETCH_REPORT_DATA_SUCCESS = "bums/common/report/edit/action/FETCH_REPORT_DATA_SUCCESS"
export const FETCH_REPORT_DATA_FAILED = "bums/common/report/card/action/FETCH_REPORT_DATA_FAILED"

export type FetchReportDataStart = ReduxActions.Action<void>
export type FetchReportDataSuccess = ReduxActions.Action<{
    reportData: ReportData
}>
export type FetchReportDataFailed = ReduxActions.Action<{
    error: ApiErrorResponse
}>


export function fetchReportDataStart(): FetchReportDataStart {
    return {
        type: FETCH_REPORT_DATA_START
    }
}

export function fetchReportDataSuccess(reportData: ReportData): FetchReportDataSuccess {
    return {
        type: FETCH_REPORT_DATA_SUCCESS,
        payload: {
            reportData
        }
    }
}

export function fetchReportDataFailed(error: ApiErrorResponse): FetchReportDataFailed {
    return {
        type: FETCH_REPORT_DATA_FAILED,
        payload: {
            error
        }
    }
}

export function fetchReportData(reportConfig: Api.ReportConfig) {
    let body = new FormData()
    body.append("report", JSON.stringify({config: reportConfig}))

    return WebApi.apiFetch<ServerResponse>(
        "/BumsReport/ReportCard/reportData.json",
        {
            method: "POST",
            body
        },
        [
            fetchReportDataStart,
            response => {
                const data: ReportData = {
                    columns: Collections.List(response.columns),
                    rows: Collections.List(response.rows),
                    total: response.total,
                    nextOffset: response.nextOffset
                }
                return fetchReportDataSuccess(data)
            },
            fetchReportDataFailed
        ]
    )
}

