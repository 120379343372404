import * as ReduxActions from "redux-actions"

export const FETCH_REPORT_TEMPLATE_START = "bums/common/report/card/action/FETCH_REPORT_TEMPLATE_START"

export type FetchReportTemplateStartAction = ReduxActions.Action<void>

export function fetchReportTemplateStart(): FetchReportTemplateStartAction {
    return {
        type: FETCH_REPORT_TEMPLATE_START
    }
}
