import * as Api from "src/lib/entities/api"
import * as ReduxActions from "redux-actions"
import {ApiErrorResponse} from "src/lib/entities/api"
import {ReportImmutable} from "../types"
import * as Utils from "../utils"
import * as Megaplan from "src/megaplan"

export const FETCH_REPORT_TEMPLATE_START = "bums/common/report/edit/action/FETCH_REPORT_TEMPLATE_START"
export const FETCH_REPORT_TEMPLATE_SUCCESS = "bums/common/report/edit/action/FETCH_REPORT_TEMPLATE_SUCCESS"
export const FETCH_REPORT_TEMPLATE_FAILED = "bums/common/report/card/action/FETCH_REPORT_TEMPLATE_FAILED"

export type FetchReportTemplateStart = ReduxActions.Action<void>
export type FetchReportTemplateSuccess = ReduxActions.Action<{
    report: ReportImmutable
}>
export type FetchReportTemplateFailedAction = ReduxActions.Action<{
    error: ApiErrorResponse
}>


export function fetchReportTemplateStart(): FetchReportTemplateStart {
    return {
        type: FETCH_REPORT_TEMPLATE_FAILED
    }
}

export function fetchReportTemplateSuccess(report: ReportImmutable): FetchReportTemplateSuccess {
    return {
        type: FETCH_REPORT_TEMPLATE_SUCCESS,
        payload: {
            report
        }
    }
}

export function fetchReportTemplateFailed(error: ApiErrorResponse): FetchReportTemplateFailedAction {
    return {
        type: FETCH_REPORT_TEMPLATE_FAILED,
        payload: {
            error
        }
    }
}

export function fetchReportTemplate(apiStore: Api.Store, reportId: number) {
    return (dispatch: Megaplan.Dispatch) => {
        dispatch(fetchReportTemplateStart());
        return apiStore.fetch<Api.Report>(`/api/v3/report/${reportId}`)
            .then(response => {
                dispatch(fetchReportTemplateSuccess(Utils.normalizeEntity(response.value.data)))
                return response;
            })
            .catch(error => {
                if (error && error.response && error.response.value) {
                    dispatch(fetchReportTemplateFailed(error.response.value))
                }
                throw error;
            })
    }
}
