import {action, computed, observable} from "mobx"
import * as Api from "src/lib/entities/api"
import {FetchErrors, getFetchError} from "src/lib/utils/form/types"
import {parseVersion} from "src/lib/utils/url"
import {DateOnlyToDate} from "src/lib/utils/intl/dateHelper"
import {List} from "src/lib/collections"

const ECOMET_URL = "wss://e-comet1.megaplan.ru"

export type Edition = "BumsEdition_Start2018"
    | "BumsEdition_CrmLite"
    | "BumsEdition_Start"
    | "BumsEdition_StartLite"
    | "BumsEdition_Collaboration2012"
    | "BumsEdition_CrmPlus"
    | "BumsEdition_CollaborationLite"

export class AccountInfoStore {

    @observable
    private accountInfo: Api.AccountInfo

    @observable
    private $isLoaded = false

    @observable
    public error: FetchErrors

    constructor(
        private apiStore: Api.Store,
        private backendVersion?: number,
    ) {}

    @computed
    public get id() {
        return this.accountInfo ? this.accountInfo.id : ""
    }

    @computed
    public get ecometHost() {
        return this.accountInfo && this.accountInfo.ecometHost ? "wss://" + this.accountInfo.ecometHost : ECOMET_URL
    }

    @computed
    public get currentBackendVersion(): number {
        return parseVersion(this.accountInfo ? this.accountInfo.buildVersion : null)
    }

    @computed
    public get systemProductName(): Edition|null {
        return this.accountInfo
            ? this.accountInfo.systemProductName as Edition
            : null
    }

    @computed
    public get productName() {
        return this.accountInfo && this.accountInfo.productName
    }

    @computed
    public get licenseEndDate() {
        return this.accountInfo && this.accountInfo.licenceEndDate
    }

    @computed
    public get paidToDate() {
        return this.accountInfo && this.accountInfo.paidToDate
    }

    @computed
    public get licenseInfo() {
        if (this.accountInfo) {
            return {
                product: this.accountInfo.systemProductName,
                licenceQuantity: String(this.accountInfo.licenceQuantity),
                paidLicenceQuantity: String(this.accountInfo.paidLicenceQuantity),
                licenceEndDate: AccountInfoStore.getDateString(this.accountInfo.licenceEndDate),
                paidToDate: AccountInfoStore.getDateString(this.accountInfo.paidToDate),
                mobileEndDate: AccountInfoStore.getDateString(this.accountInfo.mobileEndDate),
            }
        }

        return {}
    }

    @computed
    private get mobileLicenseEndDate(): Date {
        if (!this.accountInfo || !this.accountInfo.mobileEndDate) {
            return new Date().addYears(-100)
        }
        return DateOnlyToDate(this.accountInfo.mobileEndDate).addDays(1)
    }

    @computed
    public get isMobileLicenseAvailable() {
        return this.mobileLicenseEndDate.getTime() >= new Date().getTime()
    }

    @computed
    public get isBackendVersionAvailable() {
        return this.currentBackendVersion >= this.backendVersion
    }

    @computed
    public get isTrial() {
        return this.accountInfo && this.accountInfo.isTest
    }

    @computed
    public get isFree() {
        return this.systemProductName === "BumsEdition_Start"
    }

    public get isAvailable() {
        return this.isBackendVersionAvailable && this.isMobileLicenseAvailable
    }

    @computed
    public get hasPartner() {
        return this.accountInfo && this.accountInfo.partnerId > 0
    }

    @action
    public async initialize() {
        try {
            const result = await this.apiStore.fetch<Api.AccountInfo>(Api.AccountInfo.endpoint)
            this.accountInfo = result.value.data
            this.error = null
        } catch (e) {
            this.accountInfo = null
            this.error = getFetchError(e)
            throw e
        } finally {
            this.$isLoaded = true
       }
    }

    @computed
    public get isLoaded() {
        return this.$isLoaded
    }

    public isEdition(edition: Edition) {
        return edition === this.systemProductName
    }

    @computed
    public get accountTarifId() {
        return this.accountInfo
            ? this.accountInfo.tarifId
            : null
    }

    @computed
    public get licenseExpired() {
        return this.accountInfo && this.accountInfo.licenseExpired
    }

    @computed
    public get modules() {
        return this.accountInfo && this.accountInfo.enabledModules
    }

    @computed
    public get megamailDomain() {
        return this.accountInfo && this.accountInfo.megamailDomain
    }

    @computed
    public get caldavSyncs() {
        return this.accountInfo && this.accountInfo.caldavSyncs || List()
    }

    @computed
    public get isSaas() {
        return this.accountInfo && this.accountInfo.isSaas
    }

    public moduleAvailableInPaidVersionOnly(moduleId: string) {
        const module = this.modules && this.modules.find(item => moduleId === item.id)

        return module && module.availableInPaidVersionOnly
    }

    public static getDateString(date: Api.DateOnly) {
        if (Api.isDateOnly(date)) {
            return `${date.day}-${date.month + 1}-${date.year}`
        } else {
            return null
        }
    }
}
