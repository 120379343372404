/* tslint:disable */
import * as H from "../metadataHelper"
import {Employee} from "./employee"
import {DateOnly} from "./dateOnly"
import {StringField} from "./stringField"
import {DateField} from "./dateField"
import {BoolField} from "./boolField"

export interface Vacation extends H.BaseEntity {
    name?: string;
    employee?: Employee; // Сотрудник, запланировавший отпуск
    firstDay?: DateOnly; // Первый день отпуска
    lastDay?: DateOnly; // Последний день отпуска
    type?: string; // Тип отпуска
    id?: string; // Идентификатор
    isFavorite?: boolean; // Избранная сущность или нет
    contentType: "Vacation"; // Object type
}

export module Vacation {
    export const contentType: "Vacation" = "Vacation";
    export const endpoint = "/api/v3/vacation";

    export const newObject: Vacation = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "Vacation.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const employee = {contentType: "RefLinkField" as "RefLinkField", name: "employee", isMultiple: false, isSortable: false, id: "Vacation.employee", refContentType: H.List(["Employee"])};
        export const firstDay = {contentType: "DateField" as "DateField", name: "firstDay", isMultiple: false, isSortable: false, id: "Vacation.firstDay", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const lastDay = {contentType: "DateField" as "DateField", name: "lastDay", isMultiple: false, isSortable: false, id: "Vacation.lastDay", defaultValue: null as DateField["defaultValue"], refContentType: H.List(["DateOnly"])};
        export const type = {contentType: "StringField" as "StringField", name: "type", isMultiple: false, isSortable: false, id: "Vacation.type", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isFavorite = {contentType: "BoolField" as "BoolField", name: "isFavorite", isMultiple: false, isSortable: false, id: "Vacation.isFavorite", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isVacation(arg: any): arg is Vacation {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Vacation"
}
