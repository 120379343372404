import Promise from "bluebird"
import {createEmulator} from "src/lib/entities/store/test/backendEmulator"
import * as Bums from "src/lib/entities/bums"
import {RequestInit} from "src/lib/utils/fetch"
import {createStubResponse} from "src/lib/utils/test"

export function createHelpBackendEmulator() {
    const featuresDisabledByDefault: string[] = []

    const emulator = createEmulator({
        "/api/v3/currentUser": () => [{
            ...Bums.Employee.newObject,
            id: "100000",
        }],
        "/api/v3/feature": () => Bums.Feature.fields.name.enumValues.toArray().map(name => ({
            ...Bums.Feature.newObject,
            name,
            isAvailable: !featuresDisabledByDefault.includes(name),
        }))
    })

    return {
        on: emulator.on,
        fetch: (url: string, init?: RequestInit) => {
            if (url.startsWith("/api/v3/userSetting/")) {
                const settingId = url.replace("/api/v3/userSetting/", "")
                return Promise.resolve(createStubResponse(url, {
                    ...Bums.UserSetting.newObject,
                    id: settingId,
                }))
            } else {
                return emulator.fetch(url, init)
            }
        }
    }
}
