import withTrackingComponentDecorator from "./withTrackingComponentDecorator"
import trackEventMethodDecorator from "./trackEventMethodDecorator"
import {Options, ToDecorate, TrackingInfo} from "src/lib/tracking/types"

/**
 * @see http://doc.megoplan.ru/books/frontend/page/%D0%B4%D0%B5%D0%BA%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80-track
 */
export default function hoc(trackingInfo: TrackingInfo = {}, options: Options = {}) {
  return function decorator(...toDecorate: ToDecorate) {

    if (toDecorate.length === 1) {
      // decorating a class
      return withTrackingComponentDecorator(trackingInfo, options)(
        toDecorate[0]
      )
    }

    // decorating a method
      const [target, name, descriptor] = toDecorate
    return <typeof target>trackEventMethodDecorator(trackingInfo, options)(target, name, descriptor)
  }
}
