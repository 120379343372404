

class ListItem {
    private child: List

    constructor(public value: string) {
    }

    addChild(name: string, isOrdered: boolean, value: string, level: number) {
        if (!this.child) {
            this.child = new List(name, isOrdered)
        }
        this.child.addItem(value, level)
    }

    getHtml(): string {

        let html: string = ""
        if (this.child) {
            html += this.child.getHtml()
        }
        if (this.value != null) {
            html = `<li>${this.value} ${html}</li>\n`
        }
        return html
    }
}

class List {
    private items: ListItem[] = []

    constructor(private name: string,
                private isOrdered: boolean) {
    }

    getName(): string {
        return this.name
    }

    addItem(value: string, level: number) {
        level -= 1
        if (level > 0) {
            // send item to child list
            // array may be empty if we skip level and go from 1 to 3
            if (!this.items.length) {
                this.items.push(new ListItem(null))
            }
            this.items[this.items.length - 1].addChild(this.name, this.isOrdered, value, level)
        } else {
            // add item to current list
            this.items.push(new ListItem(value))
        }
    }

    private getOpenTag(): string {
        let tag = this.isOrdered ? "ol" : "ul"
        return `\n<${tag} class="mso-list-${this.name}">\n`
    }

    private getCloseTag(): string {
        return this.isOrdered ? "</ol>\n" : "</ul>\n"
    }

    getHtml(): string {
        let html: string = ""
        html += this.getOpenTag()
        // TODO create list List
        this.items.forEach(function (item) {
            html += item.getHtml()
        })
        html += this.getCloseTag()
        return html
    }
}

class ListArray {
    private lists: {
        [key: string]: List
    } = {}

    addList(list: List) {
        this.lists[list.getName()] = list
    }

    getList(name: string): List {
        if (!this.lists[name]) { // if no meta info in the page
            this.addList(new List(name, false))
        }
        return this.lists[name]
    }

    getSize() {
        return Object.keys(this.lists).length
    }
}

export {ListArray, List}

