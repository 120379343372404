import * as Collections from "src/lib/collections"
import {BaseCellContent, isTextCell, TextCellContent} from "src/lib/components/CRcTable/entities"

export interface BaseColumn {
    type: string
    name: string
    title: string
    align: string
    nowrap: boolean
}
export interface FirstColumn extends BaseColumn {
}

export function isFirstColumn(column: any): column is FirstColumn {
    return "object" === typeof column && "type" in column && column.type === "first"
}

export interface SumUpCellContent extends BaseCellContent {
    sum: number
    avg?: number
    min?: number
    max?: number
    isDuration: boolean
    isInteger: boolean
    workingHours: boolean
}

export interface ComplexCellContent extends BaseCellContent {
    values: Array<BaseCellContent>
}

export interface DurationCellContent extends BaseCellContent {
    value: number
    workingHours: boolean
}

export function isSumUpCell(cell: any): cell is SumUpCellContent {
    return "object" === typeof cell && "type" in cell && cell.type === "sumup"
}

export function isComplexCell(cell: any): cell is ComplexCellContent {
    return "object" === typeof cell && "type" in cell && cell.type === "complex"
}
export function isMultipleTextCell(cell: any): cell is Array<TextCellContent> {
    return "object" === typeof cell && cell.length && cell.every((value: TextCellContent) => isTextCell(value))
}

export function isDurationCell(cell: any): cell is DurationCellContent {
    return "object" === typeof cell && "type" in cell && cell.type === "duration"
}

export function complexCell(values: Array<BaseCellContent>): ComplexCellContent {
    return {
        type: "complex",
        values
    }
}

export interface BaseRow {
    type: string
    cells: {[index: string]: BaseCellContent}
}

export interface GroupRow extends BaseRow {
    isGroup: boolean
    collapsed: boolean
    subRows: BaseRow[]
    count: number
    value: string
    name: string
    id: string
    parentId: string
    dataOffset: number
}

export function isGroupRow(row: any): row is GroupRow {
    return Boolean(row) && "object" === typeof row && "type" in row && row.type === "group"
}
export function isGroupRowList(rowList: any): rowList is GroupRowList {
    return Collections.isList(rowList) &&
        (rowList as Collections.List<any>).length > 0 &&
        (rowList as Collections.List<any>).every(isGroupRow)
}
export function isRowList(rowList: any): rowList is RowList {
    return Collections.isList(rowList)
}

export interface TotalRow extends BaseRow {
    count: number
    additionalMetrics: boolean
}

export interface TableReportData {
    columns: Collections.List<BaseColumn>
    rows: RowList | GroupRowList
    total?: TotalRow
}

interface ReportDataNextOffset {
    nextOffset: number
}
export type ReportData = TableReportData & ReportDataNextOffset

export interface ServerResponse {
    columns: Array<BaseColumn>,
    rows: Array<BaseRow>,
    total: TotalRow,
    nextOffset: number,
}

export type RowList = Collections.List<BaseRow>
export type GroupRowList = Collections.List<GroupRow>
export type ColumnList = Collections.List<BaseColumn>

export enum TableSortOrderBy {
    ASC,
    DESC
}
