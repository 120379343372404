import * as Api from "src/lib/entities/api"
import * as ReduxActions from "redux-actions"
import {ApiErrorResponse} from "src/lib/entities/api"
import {ReportObjectImmutable} from "../types"
import * as Collections from "src/lib/collections"
import * as Utils from "../utils"
import * as Megaplan from "src/megaplan";

export const FETCH_REPORT_OBJECTS_START = "bums/common/report/edit/action/FETCH_REPORT_OBJECTS_START"
export const FETCH_REPORT_OBJECTS_SUCCESS = "bums/common/report/edit/action/FETCH_REPORT_OBJECTS_SUCCESS"
export const FETCH_REPORT_OBJECTS_FAILED = "bums/common/report/card/action/FETCH_REPORT_OBJECTS_FAILED"

export type FetchReportObjectsStart = ReduxActions.Action<void>
export type FetchReportObjectsSuccess = ReduxActions.Action<{
    objectList: Collections.List<ReportObjectImmutable>
}>
export type FetchReportObjectsFailedAction = ReduxActions.Action<{
    error: ApiErrorResponse
}>


export function fetchReportObjectsStart(): FetchReportObjectsStart {
    return {
        type: FETCH_REPORT_OBJECTS_START
    }
}

export function fetchReportObjectsSuccess(objectList: Collections.List<ReportObjectImmutable>): FetchReportObjectsSuccess {
    return {
        type: FETCH_REPORT_OBJECTS_SUCCESS,
        payload: {
            objectList
        }
    }
}

export function fetchReportObjectsFailed(error: ApiErrorResponse): FetchReportObjectsFailedAction {
    return {
        type: FETCH_REPORT_OBJECTS_FAILED,
        payload: {
            error
        }
    }
}

export function fetchReportObjects(apiStore: Api.Store) {
    return (dispatch: Megaplan.Dispatch) => {
        dispatch(fetchReportObjectsStart());
        return apiStore.fetch<Api.ReportObject[]>("/api/v3/report/object")
            .then(response => {
                const objects = Collections.List(response.value.data.map(Utils.normalizeEntity))
                dispatch(fetchReportObjectsSuccess(objects))
                return response;
            })
            .catch(error => {
                if (error && error.resposne && error.response.value) {
                    dispatch(fetchReportObjectsFailed(error.response.value))
                }
                throw error;
            })
    }
}
