import {computed} from "mobx"
import * as Api from "src/lib/entities/api"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {getNow} from "src/lib/utils/date"
import {CommentDraft} from "./DraftStore"

const DRAFTS_USER_SETTING_ID = "global_drafts"

interface DraftStorage {
    [link: string]: CommentDraft
}

export class GlobalDraftStorage {
    constructor(
        private apiStore: Api.Store,
    ) {}

    private userSettingStore = new UserSettingStore<DraftStorage>(
        this.apiStore,
        () => DRAFTS_USER_SETTING_ID,
        () => ({}),
    )

    @computed
    public get drafts() {
        const drafts = this.userSettingStore.get()

        if (drafts.state === "fulfilled") {
            return drafts.value
        }

        return null
    }

    public addDraft = (entity: Api.BaseEntity, content?: string) => {
        if (this.drafts) {
            const gid = Api.getGID(entity)

            if (!this.drafts[gid] || this.drafts[gid].content !== content) {
                const newDrafts = {...this.drafts, [gid]: {content, date: getNow().getTime()}}
                void this.userSettingStore.set(newDrafts)
            }
        }
    }

    public removeDraft = (entity: Api.BaseEntity) => {
        const gid = Api.getGID(entity)

        if (this.drafts && this.drafts[gid]) {
            const newDrafts = Object.keys(this.drafts)
                .filter(el => el !== gid)
                .reduce((acc, key) => ({ ...acc, [key]: this.drafts[key] }), {})

            void this.userSettingStore.set(newDrafts)
        }
    }

    public getDraft = (entity: Api.BaseEntity) => {
        const gid = Api.getGID(entity)

        if (this.drafts && this.drafts[gid]) {
            return this.drafts[gid]
        }

        return null
    }

    @computed
    public get isReady() {
        return !!this.drafts
    }
}
