import {AbstractTabTransport, TabMessageEvent} from "src/bums/common/browserTabs/transports/AbstractTabTransport"
import {createTabsTransport} from "src/bums/common/browserTabs/transports/TransportFactory"
import {autobind} from "core-decorators"
import {EventEmitter} from "events"
import {FallbackTransport} from "src/bums/common/browserTabs/transports/FallbackTransport"

export type MainTabEvent = "MainTab:main_tab_changed" |
    "MainTab:new_tab_need_state" |
    "MainTab:main_tab_closed";

export module MainTabEvent {
    export const main_tab_changed: MainTabEvent = "MainTab:main_tab_changed";
    export const new_tab_need_state: MainTabEvent = "MainTab:new_tab_need_state";
    export const main_tab_closed: MainTabEvent = "MainTab:main_tab_closed";
}

declare const window: Window & {MegaplanSPAPromise: Promise<{}>}

const HEARTBEAT = 3000
const CHECK_HEARTBEAT = 5000
const CHECK_NEW_TAB = 1000

const SELF_MAIN_TAB_CLOSED = "MainTab.active_closed"
const SELF_NEW_TAB = "MainTab.new_tab"
const SELF_HEARTBEAT = "MainTab.heartbeat"

@autobind
export class MainTab {

    private readyPromise: Promise<MainTab>
    private readyPromiseState: "pending" | "resolved" | "rejected" | "new" = "new"
    private readyPromiseResolve: (val: MainTab) => void
    private transport: AbstractTabTransport
    private eventEmitter: EventEmitter
    private _isMainTab: boolean = false
    private startTime: number
    private heartbeatTimeoutId = 0
    private activeChangedTimeoutId = 0

    constructor() {
        this.eventEmitter = new EventEmitter()
        const version = String(window.sdf.settings.get("currentVersion")).replace(/[^d]/g , "")
        const currentVersion = Boolean(version) ? parseInt(version, 10) : 0
        this.transport = createTabsTransport("mp-active-tab")
        this.startTime = (new Date()).getTime();

        this.createPromise()

        this.transport.on(SELF_MAIN_TAB_CLOSED, this.onMainTabClosed)
        this.transport.on(SELF_NEW_TAB, this.onNewTab)
        this.transport.on(SELF_HEARTBEAT, this.onHeartbeat)

        // Закрытие окна, если были главными, надо об этом сказать
        window.addEventListener("beforeunload", this.onBeforeUnload)

        this.transport.broadcast(SELF_NEW_TAB, currentVersion)

        if (this.transport instanceof FallbackTransport) {
            this.setActive(true)
        } else {
            this.checkHeartbeat(CHECK_NEW_TAB)
        }
    }

    public createPromise() {
        if (this.readyPromiseState !== "pending") {
            this.readyPromise = new Promise<MainTab>((resolve) => {
                this.readyPromiseState = "pending";
                this.readyPromiseResolve = async () => {
                    await window.MegaplanSPAPromise
                    this.readyPromiseState = "resolved"
                    this.ready()
                    resolve(this)
                }
            })
        }
    }

    public on(eventName: MainTabEvent, listener: (isActive?: boolean) => void) {
        this.eventEmitter.on(eventName, listener)
    }

    public isMainTab() {
        return this._isMainTab
    }

    public getReadyPromise(): Promise<MainTab> {
        return this.readyPromise
    }

    private ready() {
        console.warn("Active tab is Ready: " + (this._isMainTab ? "ACTIVE" : "NOT ACTIVE"))
        this.eventEmitter.emit(MainTabEvent.main_tab_changed, this._isMainTab)
        if (this._isMainTab) {
            this.eventEmitter.emit(MainTabEvent.new_tab_need_state)
        }
    }

    private onBeforeUnload(): undefined | boolean | string {
        if (this._isMainTab) {
          this.transport.broadcast(SELF_MAIN_TAB_CLOSED, this.startTime)
        }
        // Заглушка для IE
        return undefined
    }

    /**
     * Закрытие активной вкладки, попробуем стать активной вкладкой
     */
    private onMainTabClosed(message: TabMessageEvent) {
        console.warn("Active tab closed")
        this.createPromise()
        this.eventEmitter.emit(MainTabEvent.main_tab_closed)
        this.checkCanBeActive(!this._isMainTab)
    }

    /**
     * Открылась новая вкладка, скажем ей сразу кто главный
     */
    private onNewTab(message: TabMessageEvent) {
        if (message.isFromSameTab) {
            return
        }
        if (this._isMainTab) {
            console.warn("New tab appeared, i am active")
            this.heartbeat()
            this.readyPromise
                .then(() => {
                  this.eventEmitter.emit(MainTabEvent.new_tab_need_state)
                })
                .catch((e) => { console.error(e) })
        }
    }

    /**
     * Получили heartbeat, если вдруг мы в главной вкладке, то это конфликт, будем снова бороться за первенство
     */
    private onHeartbeat(message: TabMessageEvent) {
        if (message.isFromSameTab) {
            return
        }
        const time = message.data
        if (this._isMainTab) {
            if (time && time < this.startTime) {
                this.setActive(false)
                this.checkHeartbeat()
            } else {
                this.setActive(false)
                this.setActive(true)
                this.heartbeat()
            }
        } else {
            if (this.readyPromiseState === "pending") {
                this.readyPromiseResolve(this)
            }
            this.checkHeartbeat()
        }
    }


    /**
     * сбрасывает текущее ожидание heartbeat и ожидает с новым интервалом
     * если за установленное время его нет, то определение главной вкладки запускается
     * @param {int} timeout время которое ожидаем heartbeat
     */
    private checkHeartbeat(timeout: number = CHECK_HEARTBEAT) {
        clearTimeout(this.heartbeatTimeoutId)
        this.heartbeatTimeoutId = setTimeout( () => {
            this.checkCanBeActive()
        }, timeout)
    }

    /**
     * Выбор главной вкладке путем случайного таймаута и становления главной, если кто-то уже не стал главной
     * @type {boolean}
     */
    private checkCanBeActive(prior = false) {
        if (!window.sdf.settings.get("currentuser.id")) {
            this.setActive(false);
            this.readyPromiseResolve(this)
            return;
        }

        const time = (new Date()).getTime()
        // приоритетном варианте 1000 иначе 2000
        let rand = prior ? CHECK_NEW_TAB : (HEARTBEAT - 1000)
        let subRand = time / 1000000
        subRand -= parseInt(subRand.toString(), 10)
        subRand *= 1000
        rand -= parseInt( subRand.toString(), 10)

        this.createPromise()
        clearTimeout(this.heartbeatTimeoutId)
        this.heartbeatTimeoutId = setTimeout( () => {
            // необходимо релоудить состояние через сброс на false и снова на true
            this.setActive(false)
            this.setActive(true)
        },  rand)
    }

    /**
     * Установка активности
     * @param {boolean} active - текущая активность
     */
    private setActive(active: boolean) {
        // обрабатываем только изменение состояния
        if (this._isMainTab === active) {
          return
        }

        this._isMainTab = active
        this.beat()
        // Тригерим изменение активности, нужно это сделать только после 100% уверенности что мы активная вкладка
        clearTimeout(this.activeChangedTimeoutId)
        this.activeChangedTimeoutId = setTimeout( () => {
            console.warn("Active changed: " + (active ? "TRUE" : "FALSE"))
            if (this.readyPromiseState === "pending") {
                this.readyPromiseResolve(this)
            } else {
                this.eventEmitter.emit(MainTabEvent.main_tab_changed, active)
            }
        }, HEARTBEAT)
    }

    /**
     * В зависимости от активности либо проверка heartbeat либо сам heartbeat
     */
    private beat() {
        if (!this._isMainTab) {
            this.checkHeartbeat()
        } else {
            this.heartbeat()
            if (this.readyPromiseState === "pending") {
                this.readyPromiseResolve(this)
            }
        }
    }

    /**
     * Событие heartbeat во все остальные вкладки
     */
    private heartbeat() {
        this.transport.broadcast(SELF_HEARTBEAT, this.startTime)
        clearTimeout(this.heartbeatTimeoutId)
        this.heartbeatTimeoutId = setTimeout(this.beat, HEARTBEAT)
    }

}
