/* tslint:disable */
import * as H from "../metadataHelper"
import {StringField} from "./stringField"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"

export interface ProgramState extends H.BaseEntity {
    name?: string; // Название статуса
    entryPointName?: string; // Название точки входа
    description?: string; // Описание статуса
    type?: ProgramState.Type;
    color?: string;
    isEntry?: boolean;
    id?: string; // Идентификатор
    contentType: "ProgramState"; // Object type
}

export module ProgramState {
    export const contentType: "ProgramState" = "ProgramState";
    export const endpoint = "/api/v3/programState";
    export type Type = "active"|"negative"|"positive";
    export module Type {
        export const active: Type = "active";
        export const negative: Type = "negative";
        export const positive: Type = "positive";
    }
    export const newObject: ProgramState = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "StringField" as "StringField", name: "name", isMultiple: false, isSortable: false, id: "ProgramState.name", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const entryPointName = {contentType: "StringField" as "StringField", name: "entryPointName", isMultiple: false, isSortable: false, id: "ProgramState.entryPointName", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const description = {contentType: "StringField" as "StringField", name: "description", isMultiple: false, isSortable: false, id: "ProgramState.description", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const type = {contentType: "EnumField" as "EnumField", name: "type", isMultiple: false, isSortable: false, id: "ProgramState.type", defaultValue: null as EnumField["defaultValue"], enumValues: H.List(["active", "negative", "positive"])};
        export const color = {contentType: "StringField" as "StringField", name: "color", isMultiple: false, isSortable: false, id: "ProgramState.color", defaultValue: null as StringField["defaultValue"], maxLength: 0};
        export const isEntry = {contentType: "BoolField" as "BoolField", name: "isEntry", isMultiple: false, isSortable: false, id: "ProgramState.isEntry", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isProgramState(arg: any): arg is ProgramState {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "ProgramState"
}
