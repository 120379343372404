import * as React from "react"
import PropTypes from "prop-types"
import {StoresMap, StoresContext} from "src/lib/utils/inject"

export {StoreClass, StoresMap, StoresContext} from "src/lib/utils/inject"

interface StoresProviderProps {
    storesMap: StoresMap
}
/* tslint:disable:no-react-component-usage */
export class StoresProvider extends React.Component<StoresProviderProps, {}> {

    public static childContextTypes = {
        megaplanStores: PropTypes.instanceOf(StoresMap)
    }

    getChildContext() {
        return {
            megaplanStores: this.props.storesMap,
        }
    }

    render() {
        return <StoresContext.Provider value={{megaplanStores: this.props.storesMap}}>
            {React.Children.only(this.props.children)}
        </StoresContext.Provider>
    }
}

export class NativeStoresProvider extends React.Component<{store: StoresMap}, {}> {

    render() {
        return <StoresContext.Provider value={{megaplanStores: this.props.store}}>
            {React.Children.only(this.props.children)}
        </StoresContext.Provider>
    }
}
