import * as ReduxActions from "redux-actions"

export const SWITCH_TAB = "bums/common/report/card/action/SWITCH_TAB"

export type SwitchTabAction = ReduxActions.Action<{
    selectedTab: number
}>

export function switchTab(selectedTab: number): SwitchTabAction {
    return {
        type: SWITCH_TAB,
        payload: {
            selectedTab
        }
    }
}
