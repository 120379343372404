/* tslint:disable */
import * as H from "../metadataHelper"
import {EnumField} from "./enumField"
import {BoolField} from "./boolField"

export interface Feature extends H.BaseValue {
    name?: Feature.Name; // Уникальное название фичи
    isAvailable?: boolean; // Включена ли фича (в момент запроса)
    isTestDriveAvailable?: boolean; // Доступен ли тест драйв для фичи
    isEnabledForUser?: boolean; // Включена ли фича для текущего пользователя
    contentType: "Feature"; // Object type
}

export module Feature {
    export const contentType: "Feature" = "Feature";
    export const endpoint = "/api/v3/feature";
    export type Name = "bums.common.collapsible_main_menu"|
        "bums.common.common.addons"|
        "bums.common.common.control_menu_items"|
        "bums.common.common.duplicate_comment_in_email_feature"|
        "bums.common.common.email_message_quoting"|
        "bums.common.common.email_widget_redesign"|
        "bums.common.common.exact_time_reminder_feature"|
        "bums.common.common.feedback"|
        "bums.common.common.hidden_comments_feature"|
        "bums.common.common.ignore_promo_two_days_condition"|
        "bums.common.common.inbox_widget_redesign"|
        "bums.common.common.journal_email_redesign"|
        "bums.common.common.journal_hierarchy"|
        "bums.common.common.journal_redesign"|
        "bums.common.common.linked_deals_new_filters"|
        "bums.common.common.mass_action_limit_notifications"|
        "bums.common.common.memory_track_feature"|
        "bums.common.common.preserve_whitespaces"|
        "bums.common.common.resize_image_in_c_viewer"|
        "bums.common.common.show_mass_action_apply_trigger"|
        "bums.common.common.show_one_time_key_url_in_qr_code"|
        "bums.common.common.task_default"|
        "bums.common.common.work_time"|
        "bums.common.component_project_card"|
        "bums.common.component_todo_card"|
        "bums.common.component_todo_list"|
        "bums.common.da_data_suggestion_api"|
        "bums.common.email_template"|
        "bums.common.facebook.facebook_integration"|
        "bums.common.history_diff_view"|
        "bums.common.inline_tables_edit"|
        "bums.common.item.preset_filters"|
        "bums.common.item.tasks_on_calendar"|
        "bums.common.list_filters_hide"|
        "bums.common.lite_mobile"|
        "bums.common.megaplan_outsource_feature"|
        "bums.common.messenger_tabs_in_journal"|
        "bums.common.new_main_menu"|
        "bums.common.new_system_filters"|
        "bums.common.on_boarding_tutorial"|
        "bums.common.onboarding_demo_accounts"|
        "bums.common.onboarding_first_configure_show"|
        "bums.common.onboarding_hint"|
        "bums.common.onboarding_poll_phone_end"|
        "bums.common.product_news_popup"|
        "bums.common.product_quest_context_help"|
        "bums.common.product_quest_feature"|
        "bums.common.product_quest_feedback"|
        "bums.common.product_quest_icon"|
        "bums.common.product_quest_rating"|
        "bums.common.program_trigger_editor_new"|
        "bums.common.promo"|
        "bums.common.promo.december2019.promo_rating"|
        "bums.common.promo.december2019.promo_rating_with_start_pack_feedback"|
        "bums.common.promo.december2019.promo_rating_with_trust_land_feedback"|
        "bums.common.promo.on_boarding_rating"|
        "bums.common.promo.product_quest_rating_march2020"|
        "bums.common.promo.promo_chat_widget_redesign"|
        "bums.common.promo.promo_deals_import_from_megapost"|
        "bums.common.promo.promo_february23_female"|
        "bums.common.promo.promo_gantt"|
        "bums.common.promo.promo_gantt_task_duration"|
        "bums.common.promo.promo_march8_male"|
        "bums.common.promo.promo_mobile_release"|
        "bums.common.promo.promo_new_deal_card"|
        "bums.common.promo.promo_rating_sept2019"|
        "bums.common.promo.promo_spring_themes"|
        "bums.common.registration_with_email"|
        "bums.common.restore_modal_form"|
        "bums.common.setting_mail_list"|
        "bums.common.setting_messengers_list"|
        "bums.common.setting_module"|
        "bums.common.task_kanban_todo_list"|
        "bums.common.telegram.telegram"|
        "bums.common.todo_form_calendar"|
        "bums.common.todo_input_on_calendar"|
        "bums.common.todo_mini_card_component"|
        "bums.common.todo_mini_card_date_picker"|
        "bums.common.todo_on_mini_calendar"|
        "bums.common.todo_one_line_list"|
        "bums.common.unsupported_browser_popup"|
        "bums.common.upgrades_module"|
        "bums.common.videocall.videocall_feature"|
        "bums.common.videocall.videocall_hint_feature"|
        "bums.common.wazzup.instagram"|
        "bums.common.wazzup.wazzup"|
        "bums.common.web_push_notifications"|
        "bums.common.yandex_metrika"|
        "bums.company_card_component"|
        "bums.crm.add_contractor_redesign"|
        "bums.crm.clients_import_new_design"|
        "bums.crm.contacts_for_contractor_human"|
        "bums.crm.contractor_type_field_settings"|
        "bums.crm.duplicate_notifier"|
        "bums.crm.new_crm_filters"|
        "bums.crm.payer_fields_in_table"|
        "bums.crm_list_component"|
        "bums.discuss.component_topic_list"|
        "bums.discuss.mail_discuss_feature"|
        "bums.discuss.personal_megamail"|
        "bums.discuss.subordinates_chats_feature"|
        "bums.enabled_desktop_switcher"|
        "bums.force_switch_crm_card_to_react"|
        "bums.force_switch_crm_list_to_react"|
        "bums.force_switch_employee_card_to_react"|
        "bums.force_switch_employee_to_react"|
        "bums.force_switch_task_card_to_react"|
        "bums.force_switch_task_list_to_react"|
        "bums.force_switch_todo_card_to_react"|
        "bums.force_switch_todo_list_to_react"|
        "bums.force_switch_trade_card_to_react"|
        "bums.force_switch_trade_list_to_react"|
        "bums.informer_enabled"|
        "bums.integration.uis_promo"|
        "bums.invoice.custom_template"|
        "bums.issue.form_field_select"|
        "bums.ny_theme_2020"|
        "bums.ny_theme_2021"|
        "bums.report.periodic_grouping"|
        "bums.settings.account_auto_payment"|
        "bums.settings.allow_to_delete_account"|
        "bums.settings.voximplant"|
        "bums.staff.change_chief_and_subordinate"|
        "bums.staff.component_employee"|
        "bums.staff.component_employee_card"|
        "bums.staff.edit_employee_layout"|
        "bums.staff.effectiveness"|
        "bums.staff.effectiveness_chart"|
        "bums.staff.effectiveness_chart_with_deals"|
        "bums.staff.employee_card_with_tabs"|
        "bums.staff.flexible_home_module"|
        "bums.staff.freelancer"|
        "bums.staff.invite_employee_link"|
        "bums.task.auto_change_start"|
        "bums.task.can_create_resource_fields"|
        "bums.task.change_dates_from_prev_task"|
        "bums.task.custom_fields"|
        "bums.task.diagram_in_subtasks"|
        "bums.task.modal_change_status_next_task"|
        "bums.task.negotiation_new_card"|
        "bums.task.new_task_filters"|
        "bums.task.planned_finish_term"|
        "bums.task.project_todos"|
        "bums.task.show_effectiveness_in_form"|
        "bums.task.task_calendar_view"|
        "bums.task.task_card_with_tabs"|
        "bums.task.task_kanban_view"|
        "bums.task.task_main_card_setting"|
        "bums.task.task_project_card_field_create_select"|
        "bums.task.template_prev_task"|
        "bums.task.top_responsibles"|
        "bums.task.top_roles"|
        "bums.task_card_component"|
        "bums.task_list_component"|
        "bums.time.calendar_new_integration"|
        "bums.time.todo_calendar_additional_list"|
        "bums.time.todo_card_with_tabs"|
        "bums.time.todo_list_filter"|
        "bums.time.videocall_in_todo"|
        "bums.trade.can_create_resource_fields"|
        "bums.trade.card_header_status_change"|
        "bums.trade.create_deal_operation"|
        "bums.trade.deal_card_with_tabs"|
        "bums.trade.deal_main_card_setting"|
        "bums.trade.deal_preset_master"|
        "bums.trade.offer_price_without_nds"|
        "bums.trade.offer_row_tax_info"|
        "bums.trade.switch_both_list_and_card"|
        "bums.trade_card_component"|
        "bums.trade_list_component"|
        "logic.stm.b_p_m"|
        "logic.stm.b_p_m_module"|
        "logic.stm.linked_business_processes_names"|
        "logic.stm.processes_names_settings"|
        "megaplan.api3.use_realtime_entities_updater_feature"|
        "sdf.event.recomet"|
        "task_negotiations_full_enabled";
    export module Name {
        export const task_negotiations_full_enabled: Name = "task_negotiations_full_enabled";
    }
    export const newObject: Feature = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const name = {contentType: "EnumField" as "EnumField", name: "name", isMultiple: false, isSortable: false, id: "Feature.name", defaultValue: null as EnumField["defaultValue"], enumValues: H.List([
            "bums.common.collapsible_main_menu",
            "bums.common.common.addons",
            "bums.common.common.control_menu_items",
            "bums.common.common.duplicate_comment_in_email_feature",
            "bums.common.common.email_message_quoting",
            "bums.common.common.email_widget_redesign",
            "bums.common.common.exact_time_reminder_feature",
            "bums.common.common.feedback",
            "bums.common.common.hidden_comments_feature",
            "bums.common.common.ignore_promo_two_days_condition",
            "bums.common.common.inbox_widget_redesign",
            "bums.common.common.journal_email_redesign",
            "bums.common.common.journal_hierarchy",
            "bums.common.common.journal_redesign",
            "bums.common.common.linked_deals_new_filters",
            "bums.common.common.mass_action_limit_notifications",
            "bums.common.common.memory_track_feature",
            "bums.common.common.preserve_whitespaces",
            "bums.common.common.resize_image_in_c_viewer",
            "bums.common.common.show_mass_action_apply_trigger",
            "bums.common.common.show_one_time_key_url_in_qr_code",
            "bums.common.common.task_default",
            "bums.common.common.work_time",
            "bums.common.component_project_card",
            "bums.common.component_todo_card",
            "bums.common.component_todo_list",
            "bums.common.da_data_suggestion_api",
            "bums.common.email_template",
            "bums.common.facebook.facebook_integration",
            "bums.common.history_diff_view",
            "bums.common.inline_tables_edit",
            "bums.common.item.preset_filters",
            "bums.common.item.tasks_on_calendar",
            "bums.common.list_filters_hide",
            "bums.common.lite_mobile",
            "bums.common.megaplan_outsource_feature",
            "bums.common.messenger_tabs_in_journal",
            "bums.common.new_main_menu",
            "bums.common.new_system_filters",
            "bums.common.on_boarding_tutorial",
            "bums.common.onboarding_demo_accounts",
            "bums.common.onboarding_first_configure_show",
            "bums.common.onboarding_hint",
            "bums.common.onboarding_poll_phone_end",
            "bums.common.product_news_popup",
            "bums.common.product_quest_context_help",
            "bums.common.product_quest_feature",
            "bums.common.product_quest_feedback",
            "bums.common.product_quest_icon",
            "bums.common.product_quest_rating",
            "bums.common.program_trigger_editor_new",
            "bums.common.promo",
            "bums.common.promo.december2019.promo_rating",
            "bums.common.promo.december2019.promo_rating_with_start_pack_feedback",
            "bums.common.promo.december2019.promo_rating_with_trust_land_feedback",
            "bums.common.promo.on_boarding_rating",
            "bums.common.promo.product_quest_rating_march2020",
            "bums.common.promo.promo_chat_widget_redesign",
            "bums.common.promo.promo_deals_import_from_megapost",
            "bums.common.promo.promo_february23_female",
            "bums.common.promo.promo_gantt",
            "bums.common.promo.promo_gantt_task_duration",
            "bums.common.promo.promo_march8_male",
            "bums.common.promo.promo_mobile_release",
            "bums.common.promo.promo_new_deal_card",
            "bums.common.promo.promo_rating_sept2019",
            "bums.common.promo.promo_spring_themes",
            "bums.common.registration_with_email",
            "bums.common.restore_modal_form",
            "bums.common.setting_mail_list",
            "bums.common.setting_messengers_list",
            "bums.common.setting_module",
            "bums.common.task_kanban_todo_list",
            "bums.common.telegram.telegram",
            "bums.common.todo_form_calendar",
            "bums.common.todo_input_on_calendar",
            "bums.common.todo_mini_card_component",
            "bums.common.todo_mini_card_date_picker",
            "bums.common.todo_on_mini_calendar",
            "bums.common.todo_one_line_list",
            "bums.common.unsupported_browser_popup",
            "bums.common.upgrades_module",
            "bums.common.videocall.videocall_feature",
            "bums.common.videocall.videocall_hint_feature",
            "bums.common.wazzup.instagram",
            "bums.common.wazzup.wazzup",
            "bums.common.web_push_notifications",
            "bums.common.yandex_metrika",
            "bums.company_card_component",
            "bums.crm.add_contractor_redesign",
            "bums.crm.clients_import_new_design",
            "bums.crm.contacts_for_contractor_human",
            "bums.crm.contractor_type_field_settings",
            "bums.crm.duplicate_notifier",
            "bums.crm.new_crm_filters",
            "bums.crm.payer_fields_in_table",
            "bums.crm_list_component",
            "bums.discuss.component_topic_list",
            "bums.discuss.mail_discuss_feature",
            "bums.discuss.personal_megamail",
            "bums.discuss.subordinates_chats_feature",
            "bums.enabled_desktop_switcher",
            "bums.force_switch_crm_card_to_react",
            "bums.force_switch_crm_list_to_react",
            "bums.force_switch_employee_card_to_react",
            "bums.force_switch_employee_to_react",
            "bums.force_switch_task_card_to_react",
            "bums.force_switch_task_list_to_react",
            "bums.force_switch_todo_card_to_react",
            "bums.force_switch_todo_list_to_react",
            "bums.force_switch_trade_card_to_react",
            "bums.force_switch_trade_list_to_react",
            "bums.informer_enabled",
            "bums.integration.uis_promo",
            "bums.invoice.custom_template",
            "bums.issue.form_field_select",
            "bums.ny_theme_2020",
            "bums.ny_theme_2021",
            "bums.report.periodic_grouping",
            "bums.settings.account_auto_payment",
            "bums.settings.allow_to_delete_account",
            "bums.settings.voximplant",
            "bums.staff.change_chief_and_subordinate",
            "bums.staff.component_employee",
            "bums.staff.component_employee_card",
            "bums.staff.edit_employee_layout",
            "bums.staff.effectiveness",
            "bums.staff.effectiveness_chart",
            "bums.staff.effectiveness_chart_with_deals",
            "bums.staff.employee_card_with_tabs",
            "bums.staff.flexible_home_module",
            "bums.staff.freelancer",
            "bums.staff.invite_employee_link",
            "bums.task.auto_change_start",
            "bums.task.can_create_resource_fields",
            "bums.task.change_dates_from_prev_task",
            "bums.task.custom_fields",
            "bums.task.diagram_in_subtasks",
            "bums.task.modal_change_status_next_task",
            "bums.task.negotiation_new_card",
            "bums.task.new_task_filters",
            "bums.task.planned_finish_term",
            "bums.task.project_todos",
            "bums.task.show_effectiveness_in_form",
            "bums.task.task_calendar_view",
            "bums.task.task_card_with_tabs",
            "bums.task.task_kanban_view",
            "bums.task.task_main_card_setting",
            "bums.task.task_project_card_field_create_select",
            "bums.task.template_prev_task",
            "bums.task.top_responsibles",
            "bums.task.top_roles",
            "bums.task_card_component",
            "bums.task_list_component",
            "bums.time.calendar_new_integration",
            "bums.time.todo_calendar_additional_list",
            "bums.time.todo_card_with_tabs",
            "bums.time.todo_list_filter",
            "bums.time.videocall_in_todo",
            "bums.trade.can_create_resource_fields",
            "bums.trade.card_header_status_change",
            "bums.trade.create_deal_operation",
            "bums.trade.deal_card_with_tabs",
            "bums.trade.deal_main_card_setting",
            "bums.trade.deal_preset_master",
            "bums.trade.offer_price_without_nds",
            "bums.trade.offer_row_tax_info",
            "bums.trade.switch_both_list_and_card",
            "bums.trade_card_component",
            "bums.trade_list_component",
            "logic.stm.b_p_m",
            "logic.stm.b_p_m_module",
            "logic.stm.linked_business_processes_names",
            "logic.stm.processes_names_settings",
            "megaplan.api3.use_realtime_entities_updater_feature",
            "sdf.event.recomet",
            "task_negotiations_full_enabled"
        ])};
        export const isAvailable = {contentType: "BoolField" as "BoolField", name: "isAvailable", isMultiple: false, isSortable: false, id: "Feature.isAvailable", defaultValue: null as BoolField["defaultValue"]};
        export const isTestDriveAvailable = {contentType: "BoolField" as "BoolField", name: "isTestDriveAvailable", isMultiple: false, isSortable: false, id: "Feature.isTestDriveAvailable", defaultValue: null as BoolField["defaultValue"]};
        export const isEnabledForUser = {contentType: "BoolField" as "BoolField", name: "isEnabledForUser", isMultiple: false, isSortable: false, id: "Feature.isEnabledForUser", defaultValue: null as BoolField["defaultValue"]};
    }
}

export function isFeature(arg: any): arg is Feature {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Feature"
}
