/**
 * Эта карта полезна при интеграции реакт-компонентов в старый код.
 */
export const contentTypeToClassMap: {[index: string]: string} = {
    "Notification": "SdfNotifyM_Notification",
    "Favorite": "SdfConfM_UserSetting",
    "Comment": "BumsCommonM_Comment",
    "Person": "BumsPerson",
    "Todo": "BumsItem",
    "Task": "BumsTaskM_Task",
    "Project": "BumsProjectM_Project",
    "Employee": "BumsStaffM_Employee",
    "Topic": "BumsDiscussM_Topic",
    "Poll": "BumsDiscussM_Poll",
    "Message": "BumsDiscussM_Message",
    "Doc": "BumsDocM_Doc",
    "DocVersion": "BumsDocM_Version",
    "DocFolder": "BumsDocM_Folder",
    "Report": "BumsReportM_ReportTemplate",
    "ContractorCompany": "BumsAlienM_Contractor",
    "ContractorHuman": "BumsAlienM_Contractor",
    "Invoice": "BumsInvoiceM_Invoice",
    "LoyaltyProgram": "BumsCrmM_LoyaltyProgram",
    "Vacation": "BumsStaffM_Vacation",
    "FinOperation": "BumsFinM_Operation",
    "Deal": "BumsTradeM_Deal",

    "SdfNotifyM_Notification": "Notification",
    "SdfConfM_UserSetting": "Favorite",
    "BumsCommonM_Comment": "Comment",
    "BumsPerson": "Person",
    "BumsItem": "Todo",
    "BumsTaskM_Task": "Task",
    "BumsProjectM_Project": "Project",
    "BumsStaffM_Employee": "Employee",
    "BumsDiscussM_Topic": "Topic",
    "BumsDiscussM_Poll": "Poll",
    "BumsDiscussM_Message": "Message",
    "BumsDocM_Doc": "Doc",
    "BumsDocM_Version": "DocVersion",
    "BumsDocM_Folder": "DocFolder",
    "BumsReportM_ReportTemplate": "Report",
    "BumsAlienM_Contractor:company": "ContractorCompany",
    "BumsAlienM_Contractor:human": "ContractorHuman",
    "BumsInvoiceM_Invoice": "Invoice",
    "BumsCrmM_LoyaltyProgram": "LoyaltyProgram",
    "BumsStaffM_Vacation": "Vacation",
    "BumsFinM_Operation": "FinOperation",
    "BumsTradeM_Deal": "Deal",
}
