import {autobind} from "core-decorators"
import {EventEmitter} from "events"

import {
    JanusAdapter as JanusAdapterInterface,
    JanusAuth,
    LocalFeed as LocalFeedInterface,
    Janus
} from "./types"

import {Participant} from "../../types"
import {LocalFeed} from "./LocalFeed"

@autobind
export abstract class Adapter extends EventEmitter implements JanusAdapterInterface {

    protected $client: Janus

    protected $auth: JanusAuth

    protected $participant: Participant

    public init(auth: JanusAuth, participant: Participant) {
        this.$auth = auth
        this.$participant = participant
        this.initJanus()
    }

    protected abstract initJanus(): void

    protected abstract async attachMedia(feed: LocalFeedInterface): Promise<void>

    protected getConfigureOptions() {
        return {
            server: this.$auth.server,
            token: this.$auth.token,
            success: this.successHandler,
            error: console.error,
            destroyed: this.destroyHandler,
        }
    }

    protected async successHandler() {
        const feed = new LocalFeed(
            this.$client,
            {room: this.$auth.room, token: this.$auth.token},
            this.$participant
        )

        feed.once("joined", (localFeed: LocalFeedInterface) => {
            this.emit("joined", localFeed)
        })

        feed.once("leave", () => {
            this.emit("leave")
        })

        feed.once("ready", () => {
            this.emit("ready")
        })

        await this.attachMedia(feed)

        feed.attach()
    }

    protected destroyHandler() {
        this.emit("leave")
    }

    public destroy() {
        this.$auth = null
        this.$participant = null

        this.removeAllListeners()

        if (this.$client?.destroy) {
            this.$client.destroy()
        }

        if (this.$client) {
            this.$client = null
        }
    }
}
