import * as ReduxActions from "redux-actions"

export const REMOVE_COLUMN = "bums/common/report/edit/action/REMOVE_COLUMN"

export type RemoveColumn = ReduxActions.Action<{
    key: string
}>

export function removeColumn(key: string): RemoveColumn {
    return {
        type: REMOVE_COLUMN,
        payload: {
            key
        }
    }
}
