/**
 * Scala-like Option[T] class.
 * All implemented methods behave like scala's Option methods.
 * Instead of `map`: actually, it works as a map and flatMap, so it impossible to create Option<Option<T>>
 */

export abstract class Option<T> {
    abstract get(): T;
    abstract getOrElse(defaultValue: T | (() => T)): T
    abstract map<R>(mapper: (value: T) => R | Option<R>): Option<R>
    abstract isEmpty(): boolean
    abstract orElse(alternative: T | (() => T | Option<T>)): Option<T>
    abstract filter(filter: T | ((value: T) => boolean)): Option<T>
    abstract filterNot(filter: T | ((value: T) => boolean)): Option<T>
}
class NoneClass extends Option<any> {

    public get(): any {
        throw new Error(`Trying to call "get" on None! Consider to use getOrElse or map!`)
    }

    public getOrElse(defaultValue: (() => any)|any): any {
        return typeof defaultValue === "function" ? defaultValue() : defaultValue;
    }

    public map<R>(mapper: (value: any) => (Option<R>|R)): Option<R> {
        return this;
    }

    public isEmpty(): boolean {
        return true
    }

    public orElse(alternative: any): Option<any> {
        return Some(typeof alternative === "function" ? alternative() : alternative)
    }

    public filter(filter: ((value: any) => boolean) | any): Option<any> {
        return this
    }

    public filterNot(filter: ((value: any) => boolean) | any): Option<any> {
        return this
    }
}

export const None: Option<any> = new NoneClass()

class SomeClass<T> extends Option<T> {

    private value: T

    constructor(value: T) {
        super()
        this.value = value
    }

    public get(): T {
        return this.value
    }

    public getOrElse(defaultValue: (() => T) | T): T {
        return this.value
    }

    public map<R>(mapper: (value: T) => (Option<R> | R)): Option<R> {
        return Some(mapper(this.value));
    }

    public isEmpty(): boolean {
        return false
    }

    public orElse(alternative: T | (() => T | Option<T>)): Option<T> {
        return this
    }

    public filter(filter?: ((value: T) => boolean) | T): Option<T> {
        let matched: boolean
        if (typeof filter === "function") {
            matched = (filter as ((value: T) => boolean))(this.value)
        } else {
            matched = filter === this.value
        }
        return matched ? this : None
    }

    public filterNot(filter: ((value: T) => boolean) | T): Option<T> {
        let matched: boolean
        if (typeof filter === "function") {
            matched = (filter as ((value: T) => boolean))(this.value)
        } else {
            matched = filter === this.value
        }
        return matched ? None : this
    }
}

export function Some<T>(value: T | Option<T>): Option<T> {
    if (value instanceof Option) {
        return value
    } else {
        return new SomeClass(value)
    }

}
