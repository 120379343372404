import {Globalize} from "globalize"
import {Intl} from "src/lib/utils/intl/Intl"

const intlPromises = new Map<string, Promise<Intl>>();

export function getIntl(locale: string): Promise<Intl> {
    if (!intlPromises.has(locale)) {
        intlPromises.set(locale, new Promise<Intl>(resolve => {
            let fn: any;
            // TODO Jest
            /*if (process.env.NODE_ENV === "test") {
                fn = require("./test-formatters")
            } else*/
            if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
                fn = require(
                    "globalize-config-loader?sync=true&locale=ru!json-loader!extract-globalize-formatters-loader" +
                    "!./formatters"
                )
            } else {
                fn = require(
                    "globalize-config-loader!json-loader!extract-globalize-formatters-loader" +
                    "!./formatters"
                )
            }
            fn(locale, (initFn: any) => {
                initFn((globalize: Globalize) => {
                    resolve(new Intl(
                        locale,
                        globalize,
                        require(`src/../../_translations/suppliment/${locale}-names.json`)
                    ))
                })
            })
        }));
    }
    return intlPromises.get(locale);
}
