import * as ReduxActions from "redux-actions"

export const TOGGLE_VIEW = "bums/trade/funnel/action/TOGGLE_VIEW"

export type ToggleViewAction = ReduxActions.Action<{
    visible: boolean
}>

export function toggleView(visible: boolean): ToggleViewAction {
    return {
        type: TOGGLE_VIEW,
        payload: {
            visible
        }
    }
}

