import {autobind} from "core-decorators"
import {computed, observable, action} from "mobx"
import {MediaControl as MediaControlInterface} from "./types"
import {DeviceState} from "../../types"

@autobind
export class MediaControl implements MediaControlInterface {

    @observable
    protected $videoState: DeviceState

    @observable
    protected $audioState: DeviceState

    @observable
    protected $talkState: DeviceState = "off"

    constructor(
        protected $permissionVideo: DeviceState,
        protected $permissionAudio: DeviceState,
        $videoState?: DeviceState,
        $audioState?: DeviceState
    ) {
        this.$videoState = $videoState || this.$permissionVideo
        this.$audioState = $audioState || this.$permissionAudio
    }

    @computed
    public get video() {
        if (this.$permissionVideo === "off") {
            return "off"
        }

        return this.$videoState
    }

    @computed
    public get audio() {
        if (this.$permissionAudio === "off") {
            return "off"
        }

        return this.$audioState
    }

    @computed
    public get talk() {
        if (this.audio === "off") {
            return "off"
        }

        return this.$talkState
    }

    public hasVideoPermission() {
        return this.$permissionVideo === "on"
    }

    public hasAudioPermission() {
        return this.$permissionAudio === "on"
    }

    @action
    public changeVideoState(state: DeviceState, successCallback?: Function) {
        if (this.$permissionVideo === "on") {
            this.$videoState = state

            if (successCallback) {
                successCallback()
            }
        }
    }

    @action
    public changeAudioState(state: DeviceState, successCallback?: Function) {
        if (this.$permissionAudio === "on") {
            this.$audioState = state

            if (successCallback) {
                successCallback()
            }
        }
    }

    @action
    public changeTalkState(state: DeviceState, successCallback?: Function) {
        if (this.$permissionAudio === "on") {
            this.$talkState = state

            if (successCallback) {
                successCallback()
            }
        }
    }
}
