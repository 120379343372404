import makeClassMemberDecorator from "./makeClassMemberDecorator"
import {Options, TrackingInfo} from "src/lib/tracking/types"

export default function trackEventMethodDecorator(trackingData: TrackingInfo = {}, options: Options = {}) {
  return makeClassMemberDecorator(
      (decoratedFn: Function) =>
      function decorateClassMember(...args: any[]) {
        const trackEvent = (...promiseArguments: any[]) => {
          if (
            this.props &&
            this.props.tracking &&
            typeof this.props.tracking.trackEvent === "function"
          ) {
            const thisTrackingData =
              typeof trackingData === "function"
                ? trackingData(this, args, promiseArguments)
                : trackingData
            if (thisTrackingData) {
              this.props.tracking.trackEvent(thisTrackingData)
            }
          }
        }

          if (options.dispatchFirst) {
              try {
                  trackEvent()
              } catch (e) {
                  console.error(e)
              }
          }

        const fn = Reflect.apply(decoratedFn, this, args)
          if (!options.dispatchFirst) {
              if (Promise && Promise.resolve(fn) === fn) {
                  return fn.then(trackEvent.bind(this)).catch((error: Error) => {
                      trackEvent(null, error)
                      throw error
                  })
              }
              try {
                  trackEvent()
              } catch (e) {
                  console.error(e)
              }
          }
        return fn
      }
  )
}
