/* tslint:disable */
import * as H from "../metadataHelper"
import {BoolField} from "./boolField"
import {IntegerField} from "./integerField"

export interface TreeNode extends H.BaseEntity {
    value?: H.BaseEntity; // Tree element value (representable)
    auxiliary?: boolean; // True if current value is only to show hierarсhy in correct order, but the value itself is not right for currently filtered tree
    childrenCount?: number; // Amount of children of current filter
    children?: H.List<TreeNode>; // Filtered first page of children of the node. Can be filled to optimize tree loading for an auxiliary node
    expanded?: boolean; // Is current level was previously expanded by user To save expanding state of the node, post a TreeNode to /task/expand
    parent?: TreeNode; // Родитель
    id?: string; // Идентификатор
    contentType: "TreeNode"; // Object type
}

export module TreeNode {
    export const contentType: "TreeNode" = "TreeNode";
    export const endpoint = "/api/v3/treeNode";

    export const newObject: TreeNode = {contentType}
    Object.freeze(newObject)

    export module fields {
        export const value = {contentType: "RefLinkField" as "RefLinkField", name: "value", isMultiple: false, isSortable: false, id: "TreeNode.value", refContentType: H.List(["BaseEntity"])};
        export const auxiliary = {contentType: "BoolField" as "BoolField", name: "auxiliary", isMultiple: false, isSortable: false, id: "TreeNode.auxiliary", defaultValue: null as BoolField["defaultValue"]};
        export const childrenCount = {contentType: "IntegerField" as "IntegerField", name: "childrenCount", isMultiple: false, isSortable: false, id: "TreeNode.childrenCount", defaultValue: null as IntegerField["defaultValue"]};
        export const children = {contentType: "RefLinkField" as "RefLinkField", name: "children", isMultiple: true, isSortable: false, id: "TreeNode.children", refContentType: H.List(["TreeNode"])};
        export const expanded = {contentType: "BoolField" as "BoolField", name: "expanded", isMultiple: false, isSortable: false, id: "TreeNode.expanded", defaultValue: null as BoolField["defaultValue"]};
        export const parent = {contentType: "RefLinkField" as "RefLinkField", name: "parent", isMultiple: false, isSortable: false, id: "TreeNode.parent", refContentType: H.List(["TreeNode"])};
    }
}

export function isTreeNode(arg: any): arg is TreeNode {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "TreeNode"
}
