import {autobind} from "core-decorators"
import {computed} from "mobx"
import {AbsctractSingleSubscription} from "src/lib/services/utils"
import {SocketTransport} from "src/lib/services/types"
import * as Api from "src/lib/entities/api"

@autobind
export class UserChangeAuthSubscription extends AbsctractSingleSubscription {

    constructor(
        $transport: SocketTransport,
        protected $userStore: Api.UserStore,
        protected $handler: (data: any) => void,
    ) {
        super($transport)
    }

    @computed
    protected get eventName() {
        if (this.$userStore.isLoaded) {
            const uid = this.$userStore.user.uid
            return `perm.auth.change:${uid}`
        }
    }

    protected eventHandle(data: any) {
        void this.$handler(data)
    }
}
