import * as ReduxActions from "redux-actions"
import {DealsData} from "../entities"

export const FETCH_DEALS_CHUNK = "bums/trade/funnel/action/FETCH_DEALS_CHUNK"

export type FetchDealsChunkAction = ReduxActions.Action<{
    data: DealsData,
    stateId: string
}>

export function fetchDealsChunk(data: DealsData, stateId: string): FetchDealsChunkAction {
    return {
        type: FETCH_DEALS_CHUNK,
        payload: {
            data,
            stateId
        }
    }
}
